import { Injectable, OnInit } from '@angular/core';
import { SearchIntenseSelectors } from 'src/app/logic/search-intense/search-intense.selectors';
import { Subject } from 'rxjs';
import { takeUntil, take, timeoutWith, map, tap, withLatestFrom } from 'rxjs/operators';
import { AppDataSelectors } from 'src/app/logic/app-data/app-data.selectors';

@Injectable()
export class SearchIntenseService  {

    constructor(
        private appDataSelectors: AppDataSelectors,
        private searchIntenseSelectors: SearchIntenseSelectors
    ) {}

    // getCurrentResults$() {
    //     return this.searchIntenseSelectors.getResults$().pipe(
    //         withLatestFrom(this.appDataSelectors.getSchede$()),
    //         map( ([res, data]) => {
    //             const temp = [];
    //             for (let i = 0; i < res.length; i++) {
    //                 const scheda = res[i];
    //                 let item;
    //                 for (let j = 0; j < data.length; j++) {
    //                     if (data[j].id === scheda.id) {
    //                         item = data[j];
    //                         break;
    //                     }
    //                 }
    //                 if (item) {
    //                     temp.push(item);
    //                     break;
    //                 }

    //             }
    //             return temp;
    //         })
    //     );
    // }

    // getSelectedResult$() {
    //     return this.searchIntenseSelectors.getSelectedResult$().pipe(
    //         withLatestFrom(this.appDataSelectors.getSchede$()),
    //         map( ([selected, data]) => {
    //             if(selected){
    //                 let intense = null;
    //                 for (let i = 0; i < data.length; i++) {
    //                     if(data[i].id === selected){
    //                         intense = data[i];
    //                         break;
    //                     }
    //                 }
    //                 return intense;
    //             }
    //             return null;
    //         })
    //     );
    // }
}
