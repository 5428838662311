import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { State } from './search-intense.interface';
import { searchIntenseSelector, AppState } from '..';
import { ApiService } from 'src/app/services/api.service';
@Injectable({
    providedIn: 'root'
})
export class SearchIntenseSelectors {

    private searchIntense$: Observable<State>;
    private completeState$: Observable<AppState>;

    constructor(private store: Store<any>, private api : ApiService) {
        // this.searchIntense$ = this.store.pipe(select((state: any) => state.search_intense));
        this.searchIntense$ = this.store.pipe(select(searchIntenseSelector));
        this.completeState$ = this.store.pipe(select((state: any) => state));
    }

    getQuery$() {
        return this.searchIntense$.pipe(
            map(state => state.query)
        );
    }

    getResults$() {
        return this.searchIntense$.pipe(
            map(state => state.results)
        );
    }

    getNumResults$() {
        return this.searchIntense$.pipe(
            map(state => state.numResults)
        );
    }

    getCurrentPage$() {
        return this.searchIntense$.pipe(
            map(state => state.page)
        );
    }

    getPageSize$() {
        return this.searchIntense$.pipe(
            map(state => state.size)
        );
    }

    getFilters$() {
        return this.searchIntense$.pipe(
            map(state => state.filters)
        );
    }
    
    getUidFilter$() {
        return this.searchIntense$.pipe(
            map(state => state.uid)
        );
    }

    getStatusFilter$() {
        return this.searchIntense$.pipe(
            map(state => state.stato)
        );
    }

    getSearchRequest$() {
        return this.completeState$.pipe(
            map(state => {
                const stateIntense = state.search_intense;
                return {
                    query: stateIntense.query,
                    filters: stateIntense.activeFilters,
                    page: stateIntense.page,
                    size: stateIntense.size,
                    boundingBox: stateIntense.boundingBox,
                    lang: state.common.locale,
                    uid: stateIntense.uid,
                    stato: stateIntense.stato,
                    status: stateIntense.status
                };
            })
        );
    }

    getActiveFilters$() {
        return this.searchIntense$.pipe(
            map(state => state.activeFilters)
        );
    }

    getSelectedResult$() {
        return this.searchIntense$.pipe(
            distinctUntilChanged(),
            map(state => state.selectedResult)
        );
    }

    getFacets$() {
        return this.searchIntense$.pipe(
            distinctUntilChanged(),
            map(state => {
                let ret = [];
                state.filters.forEach(s => {
                    if (s['name'] === 'regioni') {
                        ret = s['values'];
                    }
                });
                return ret;
            })
        );
    }

}
