
<!-- Tooltip aggiungi tratto - no descrizione -->
<div class="ras-tooltip" style="max-width: 380px; margin: 0 auto;">
    <div class="ras-tooltip__content">
        <h2 class="ras-tooltip__track-title">{{ost?.name}}</h2>
    </div>

    <div class="ras-tooltip__footer">
        <!-- <button class="ras-btn" (click)="onClick()"><i aria-hidden="true" class="far fa-plus-circle"></i> aggiungi tratto</button> -->
    </div>
</div>
<!-- Tooltip aggiungi tratto --> 
<!-- <div class="ras-tooltip" style="max-width: 380px; margin: 0 auto;">
    <div class="ras-tooltip__content">
        <div class="ras-tooltip__track-description" *ngIf="ost.descrizione">
            <i aria-hidden="true" class="far fa-route"></i>
            {}
        </div>
        <h2 class="ras-tooltip__track-title">{{ost?.name}}</h2>
    </div>

    <div class="ras-tooltip__footer">
        <button class="ras-btn" (click)="onClick()"><i aria-hidden="true" class="far fa-plus-circle"></i> aggiungi tratto</button>
    </div>
</div> -->

