export const config = {
    // lingue accettate dall'app, la prima è quella di default (se la lingua del browser non è nella lista)
    LANGUAGES: ['it', 'en','fr'],

    // mostrare semicierchi agli estremi delle tratte ost
    SHOW_OST_HEADS: false,

    // Regioni soriche
    REGION_0_COLOR: '#BBB',
    REGION_10_COLOR: '#8ed95b',
    REGION_100_COLOR: '#edcd3e',
    REGION_MAX_COLOR: 'red',
    REGION_FILL_OPACITY: 0.8,
    REGION_STROKE_COLOR: '#FFF',
    REGION_STROKE_WEIGHT: 1,

    // preview del percorso su mouse over al punto successivo
    PREVIEW_COLOR: '#FFF',
    PREVIEW_WEIGHT: 3,
    PREVIEW_DASH_ARRAY: '3 8',

    // highlight del percorso al mouseover sulla lsita
    HIGHLIGHT_COLOR: 'yellow',
    HIGHLIGHT_RADIUS: 25,
    HIGHLIGHT_WEIGHT: 11,
    HIGHLIGHT_DASH_ARRAY: '0',
    HIGHLIGHT_OPACITY: 0.8,

    // visualizzazione del percorso
    PERCORSO_INTAPPA_COLOR: '#2e5bff',
    PERCORSO_FUORITAPPA_COLOR: '#426877',
    PERCORSO_INOST_WEIGHT: 8,
    PERCORSO_INOST_DASHARRAY: '0',
    PERCORSO_FUORIOST_WEIGHT: 5,
    PERCORSO_FUORIOST_DASHARRAY: '10 10',
    PERCORSO_MARKER_INTAPPA_COLOR: 'blue',
    PERCORSO_MARKER_FUORITAPPA_COLOR: 'cadetblue',

    // attrattori
    MARKER_NEAR_COLOR: 'green',
    MARKER_DISTANT_COLOR: 'darkred',

    // raggio massimo entro cui vengono proposti gli inizi degli ost da collegare all'ost precedente
    DISTANZA_TRA_ESTREMI_PROPOSTA_KM: 50,
    // distanza massima per cui un estremo di ost viene aggiunto senza richiesta di conferma
    DISTANZA_MASSIMA_AGGIUNTA_NONSEGNALATA_KM: 5,

    // distanza con cui vengono filtrati gli incroci troppo vicini
    DISTANZA_FILTRO_INCROCI_KM: 0.4,

    // distanza con cui vengono filtrati nodi troppo vicini
    DISTANZA_FILTRO_NODI_KM: 0.02,

    // distanza massima tra gli attrattori e accettati e il percorso
    DISTANZA_PERCORSO_ATTRATTORE_KM: 15,

    // distanza massima tra due ost per essere automaticamente collegabili
    DISTANZA_LIMITE_CONCATENABILI_KM: 0.05,

    // distanza tra un tratto e un estremo per cui si considera ci sia un incrocio fra questi
    DISTANZA_INCROCIO_KM: 0.15,

    // estensioni dei file che possono esser allegati alle schede intense
    FILES_IN_INTENSE: ['pdf', 'gpx'],
    // dimensioni massime file allegabile a scheda intense
    DIMENSIONI_MASSIME_FILE: 20971520, // 20MB
    // dimensioni massime file allegabili a ost
    DIMENSIONI_MASSIME_IMMAGINE: 10485760, // 10MB

    // forzare la visualizzazione delle anteprime leaflet invece delle tiles
    FORCE_LEAFLET_PREVIEW: true,

    // eliminazione di un tratto collegamento se il tratto successivo viene eliminato
    DELETE_GHOST_TRACK: true,

    // livelli di zoom massimi per la mappa
    MAX_ZOOM: 19,
    MIN_ZOOM: 8,

    // configurazione editor di testo
    QUILL_MODULES: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            // ['blockquote', 'code-block'],
            // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            // [{ 'direction': 'rtl' }],                         // text direction
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            // [{ 'align': [] }],
            // ['clean'],                                         // remove formatting button
            ['link']                                  // link and image ['link', 'image']
        ]
    },

    //starting map bounds
    STARTING_BOUNDS: [[
        41.31494988250965,
        8.118896484375,
    ],
    [
        38.850938169890064,
        9.7943115234375
    ]],

    IDM_AUTH: true,
    PRIVACY_POLICY_URL: 'https://api.turismoattivo.sardegnaturismo.it/privacy-cookie'

};
