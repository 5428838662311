import * as tslib_1 from "tslib";
import { Ost, Intense, Percorso, Geometry, RegionData, OstTraduzione, TappaTraduzione } from 'src/app/model/model.interfaces';
import { NgForage } from 'ngforage';
import * as turf from '@turf/helpers';
import * as turf_distance from '@turf/distance';
import * as point_to_line_distance from '@turf/point-to-line-distance';
import * as line_slice from '@turf/line-slice';
import * as bearing from '@turf/bearing';
import * as point_on_line from '@turf/boolean-point-on-line';
import * as line_intersect from '@turf/line-intersect';
import * as bbox from '@turf/bbox';
import { config } from 'src/environments/config/config';
import { CacheService } from 'src/app/services/cache.service';
import { AppDataRequests } from '../logic/app-data/app-data.request';
import * as wellknown from 'wellknown';
import * as geojson_precision from 'geojson-precision';
import { isNumber } from 'util';
import * as i0 from "@angular/core";
import * as i1 from "ngforage";
import * as i2 from "./cache.service";
import * as i3 from "../logic/app-data/app-data.request";
var UtilsService = /** @class */ (function () {
    function UtilsService(ngfg, cache, appDataRequest) {
        this.ngfg = ngfg;
        this.cache = cache;
        this.appDataRequest = appDataRequest;
    }
    /////////////////////////////////// OPERAZIONI GENERICHE /////////////////////////////////
    UtilsService.prototype.compareGeneralObj = function (prev, current) {
        return JSON.stringify(prev) === JSON.stringify(current);
    };
    UtilsService.prototype.clone = function (object) {
        if (object) {
            return JSON.parse(JSON.stringify(object));
        }
        return object;
    };
    UtilsService.prototype.cleanString = function (str) {
        return str.replace(/[^a-zA-Z0-9 ]/g, '');
    };
    ///////////////////////////////////  CONVERSIONI ///////////////////////
    UtilsService.prototype.convertToGeoJSONpoint = function (point) {
        if (point.type && point.type === 'Point') {
            return point;
        }
        if (Array.isArray(point)) {
            return {
                'type': 'Point',
                'coordinates': point
            };
        }
    };
    UtilsService.prototype.GetGeometry = function (geoJSONobj) {
        if (geoJSONobj.type === 'Feature') {
            return geoJSONobj.geometry;
        }
        return geoJSONobj;
    };
    UtilsService.prototype.featuretoPoint = function (p) {
        if (Array.isArray(p) || !p) {
            return p;
        }
        if (p.type && p.type === 'Point' || Array.isArray(p)) {
            return p;
        }
        if (p.type && p.type === 'Feature') {
            return this.featuretoPoint(p.geometry);
        }
        return p;
    };
    UtilsService.prototype.featureToArray = function (p) {
        if (Array.isArray(p) || !p) {
            return p;
        }
        if (p.type && p.type === 'Point') {
            return p.coordinates;
        }
        if (p.type && p.type === 'Feature') {
            return this.featureToArray(p.geometry);
        }
        return p;
    };
    //////////////////////////////// DETTAGLI DI UNA GEOMETRIA  ////////////////////////////
    UtilsService.prototype.getLastCoords = function (geoJSONobj) {
        if (!geoJSONobj || Array.isArray(geoJSONobj)) {
            return geoJSONobj;
        }
        var res;
        if (geoJSONobj.type === 'MultiLineString') {
            var lastLine = geoJSONobj.coordinates[geoJSONobj.coordinates.length - 1];
            res = lastLine[lastLine.length - 1];
        }
        else if (geoJSONobj.type === 'LineString') {
            res = geoJSONobj.coordinates[geoJSONobj.coordinates.length - 1];
        }
        else if (geoJSONobj.type === 'Point') {
            res = geoJSONobj.coordinates;
        }
        else if (geoJSONobj.type === 'Feature') {
            return this.getLastCoords(geoJSONobj.geometry);
        }
        return res;
    };
    UtilsService.prototype.getFirstCoords = function (geoJSONobj) {
        if (Array.isArray(geoJSONobj) || !geoJSONobj) {
            return geoJSONobj;
        }
        var res;
        if (geoJSONobj.type === 'MultiLineString') {
            var firstLine = geoJSONobj.coordinates[0];
            res = firstLine[0];
        }
        else if (geoJSONobj.type === 'LineString') {
            res = geoJSONobj.coordinates[0];
        }
        else if (geoJSONobj.type === 'Point') {
            res = geoJSONobj.coordinates;
        }
        else if (geoJSONobj.type === 'Feature') {
            return this.getFirstCoords(geoJSONobj.geometry);
        }
        return res;
    };
    UtilsService.prototype.getCoords = function (geoJSONobj) {
        // wrapper
        return this.getFirstCoords(geoJSONobj);
    };
    UtilsService.prototype.calculateDirection = function (geoJSONobj, start) {
        var line;
        if (geoJSONobj.type === 'MultiLineString') {
            if (start) {
                line = geoJSONobj.coordinates[0];
            }
            else {
                line = geoJSONobj.coordinates[geoJSONobj.coordinates.length - 1];
            }
        }
        else if (geoJSONobj.type === 'LineString') {
            line = geoJSONobj.coordinates;
        }
        else {
            return 0;
        }
        if (line.length >= 2) {
            var p1 = void 0, p2 = void 0;
            if (start) {
                p1 = line[0];
                p2 = line[1];
            }
            else {
                p1 = line[line.length - 1];
                p2 = line[line.length - 2];
            }
            return turf.bearingToAzimuth(bearing.default(p1, p2, { final: false }));
        }
        return 0;
    };
    UtilsService.prototype.getMiddlePoint = function (coords) {
        console.log(coords);
        var p1 = coords[0];
        var p2 = coords[1];
        var x, y = 0;
        if (isNumber(p1[0]) && isNumber(p2[0])) {
            x = (p1[0] + p2[0]) / 2;
        }
        if (isNumber(p1[1]) && isNumber(p2[1])) {
            y = (p1[1] + p2[1]) / 2;
        }
        return [x, y];
    };
    /////////////////////////////////// OPERAZIONI SU PERCORSO e INTENSE ////////////////////////
    UtilsService.prototype.hasIntenseTratti = function (intense) {
        var osts = this.getOstsList(intense);
        return osts && osts.length > 0;
    };
    UtilsService.prototype.isIntenseInterrupted = function (intense) {
        var res = false;
        this.getOstsList(intense).forEach(function (o) { return res = (o.status === 0) ? true : res; });
        return res;
    };
    UtilsService.prototype.getTappeListTrad = function (intense) {
        var res = [];
        intense.tappe.forEach(function (tappa) {
            res.push({ name: tappa.name, id: tappa.id });
        });
        return res;
    };
    UtilsService.prototype.getOstsListTrad = function (intense) {
        var res = [];
        this.getOstsList(intense).forEach(function (ost) {
            res.push({ id: ost.id, descrizione: ost.descrizione });
        });
        return res;
    };
    UtilsService.prototype.getOstsList = function (intense) {
        var res = [];
        // ost già modificati
        if (intense.osts) {
            intense.osts.forEach(function (ost) {
                res.push(ost);
            });
        }
        // ost dentro le tappe
        if (intense.tappe) {
            intense.tappe.forEach(function (tappa) {
                if (tappa.osts) {
                    tappa.osts.forEach(function (ost) {
                        if (!res.find(function (x) { return x.id === ost.id; })) {
                            res.push(ost);
                        }
                    });
                }
                if (tappa.percorso && tappa.percorso.tratti) {
                    // ost nei tratti del percorso
                    tappa.percorso.tratti.forEach(function (tratto) {
                        var ost = tratto.ost;
                        if (ost && ost.id && !res.find(function (x) { return x.id === ost.id; })) {
                            res.push(ost);
                        }
                    });
                }
            });
        }
        return res;
    };
    UtilsService.prototype.calculateIndex = function (intense, ost) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentTappaIdx, ostGeoJSON, tappaIdx, osts, _i, osts_1, tappaOst, tratto, lastTrattoEnd, firstTrattoStart, ostStart, ostEnd, fromLastTrattoEndToOstStart, fromLastTrattoEndToOstEnd, fromOstEndToFirstTrattoStart, fromOstStartToFirstTrattoStart, punto, geoJSONobj, distance;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentTappaIdx = intense.currentTappa;
                        if (!intense) {
                            return [2 /*return*/, -1];
                        }
                        return [4 /*yield*/, this.ngfg.getItem('' + ost.id)];
                    case 1:
                        ostGeoJSON = _a.sent();
                        // ostGeoJSON['properties'] = { id: ost.id };
                        // const osts = intense.tappe[intense.tappe.length - 1].osts;
                        if (!intense.tappe) {
                            return [2 /*return*/]; // TODO: remove this
                        }
                        tappaIdx = intense.tappe.length - 1;
                        if (currentTappaIdx != null && currentTappaIdx >= 0) {
                            tappaIdx = currentTappaIdx;
                        }
                        osts = intense.tappe[tappaIdx].osts;
                        if (!osts.length) {
                            if (this.isOstTratta(ost)) {
                                return [2 /*return*/, 0];
                            }
                            else {
                                return [2 /*return*/, -1];
                            }
                        }
                        _i = 0, osts_1 = osts;
                        _a.label = 2;
                    case 2:
                        if (!(_i < osts_1.length)) return [3 /*break*/, 7];
                        tappaOst = osts_1[_i];
                        if (!this.isOstTratta(tappaOst)) {
                            return [3 /*break*/, 6];
                        }
                        if (!this.isOstTratta(ost)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.ngfg.getItem('' + tappaOst.id)];
                    case 3:
                        tratto = _a.sent();
                        lastTrattoEnd = turf.point(this.getLastCoords(tratto));
                        firstTrattoStart = turf.point(this.getFirstCoords(tratto));
                        ostStart = turf.point(this.getFirstCoords(ostGeoJSON));
                        ostEnd = turf.point(this.getLastCoords(ostGeoJSON));
                        fromLastTrattoEndToOstStart = turf_distance.default(lastTrattoEnd, ostStart, { units: 'kilometers' });
                        fromLastTrattoEndToOstEnd = turf_distance.default(lastTrattoEnd, ostEnd, { units: 'kilometers' });
                        fromOstEndToFirstTrattoStart = turf_distance.default(firstTrattoStart, ostEnd, { units: 'kilometers' });
                        fromOstStartToFirstTrattoStart = turf_distance.default(firstTrattoStart, ostStart, { units: 'kilometers' });
                        if (Math.min(fromLastTrattoEndToOstStart, fromLastTrattoEndToOstEnd, fromOstEndToFirstTrattoStart, fromOstStartToFirstTrattoStart) <= config.DISTANZA_PERCORSO_ATTRATTORE_KM) {
                            return [2 /*return*/, 1];
                        }
                        return [3 /*break*/, 6];
                    case 4:
                        punto = turf.point(ostGeoJSON['coordinates']);
                        return [4 /*yield*/, this.ngfg.getItem('' + tappaOst.id)];
                    case 5:
                        geoJSONobj = _a.sent();
                        distance = this.calculateDistanceFromTratto(geoJSONobj, punto);
                        if (distance <= config.DISTANZA_PERCORSO_ATTRATTORE_KM) {
                            return [2 /*return*/, 1];
                        }
                        _a.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 2];
                    case 7: return [2 /*return*/, -1];
                }
            });
        });
    };
    UtilsService.prototype.isNearPercorso = function (point, percorso) {
        // controlla tutti i tratti del percorso e se la distanza dal punto è < della distanza massima ritorna true
        if (percorso && percorso.tratti) {
            for (var _i = 0, _a = percorso.tratti; _i < _a.length; _i++) {
                var trattaPercorso = _a[_i];
                if (point) {
                    var d = this.calculateDistanceFromTratto(trattaPercorso.tratto, point);
                    if (config.DISTANZA_PERCORSO_ATTRATTORE_KM > d) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
    UtilsService.prototype.isNearTratto = function (tratto, ost) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var jsonobj, d;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getOstGeometry(ost)];
                    case 1:
                        jsonobj = _a.sent();
                        d = this.calculateDistanceFromTratto(tratto.tratto, jsonobj);
                        if (config.DISTANZA_PERCORSO_ATTRATTORE_KM > d) {
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    };
    // public getFirstTrattoOfIntense(intense: Intense, tappaIdx: number) {
    //   if (!intense) {
    //     return null;
    //   }
    //   return this.getFirstTratto(intense.tappe[tappaIdx].osts);
    // }
    // public getLastTrattoOfIntense(intense: Intense, tappaIdx: number) {
    //   if (!intense) {
    //     return null;
    //   }
    //   return this.getLastTratto(intense.tappe[tappaIdx].osts);
    // }
    ///////////////////////// OPERAZIONI TRA LINEE E PUNTI /////////////////////////////
    UtilsService.prototype.isEstremo = function (linea, punto) {
        var inizio = this.getFirstCoords(linea);
        var fine = this.getLastCoords(linea);
        // console.log('TCL: UtilsService -> estremo --- linea,inizio,fine,punto', linea, inizio, fine, punto);
        return this.isSamePoint(punto, inizio) || this.isSamePoint(punto, fine);
    };
    UtilsService.prototype.calculateDistanceFromTratto = function (trattoGeojson, pointGeojson) {
        if (!pointGeojson || !trattoGeojson) {
            return 0;
        }
        if (trattoGeojson.type === 'LineString') {
            var from = turf.lineString(trattoGeojson['coordinates']);
            // return this.distance(pointGeojson, from);
            return point_to_line_distance.default(pointGeojson, from);
        }
        else if (trattoGeojson.type === 'MultiLineString') {
            var distance = null;
            for (var i = 0; i < trattoGeojson.coordinates.length; i++) {
                var string = trattoGeojson.coordinates[i];
                var from = turf.lineString(string);
                // const d = this.distance(pointGeojson, from);
                var d = point_to_line_distance.default(pointGeojson, from);
                if (distance === null || distance > d) {
                    distance = d;
                }
            }
            return distance;
        }
        else {
            if (trattoGeojson.type === 'Feature') {
                return this.calculateDistanceFromTratto(trattoGeojson.geometry, pointGeojson);
            }
            else {
                return 0;
            }
        }
    };
    UtilsService.prototype.isOnLine = function (puntoRaw, linea) {
        if (!puntoRaw || !linea) {
            return false;
        }
        var punto = this.getCoords(puntoRaw);
        if (linea.type && linea.type === 'LineString') {
            return (point_to_line_distance.default(punto, linea) < config.DISTANZA_INCROCIO_KM);
        }
        if (!punto || !linea) {
            console.log('ERROR: UtilsService -> isOnLine -> punto || !linea', punto, linea);
        }
        return point_on_line.default(punto, linea);
    };
    UtilsService.prototype.isSamePoint = function (p1, p2) {
        if (p1 && p2) {
            return this.distance(p1, p2) === 0;
        }
        return false;
    };
    UtilsService.prototype.intersezione = function (linea1, linea2) {
        var _this = this;
        var allIntersect = line_intersect.default(linea1, linea2);
        if (allIntersect.features.length < 2) {
            return allIntersect;
        }
        var res = turf.featureCollection([]);
        var first = allIntersect.features[0];
        res.features.push(first);
        var last = allIntersect.features[allIntersect.features.length - 1];
        var precedente = first;
        allIntersect.features.forEach(function (feature) {
            if (_this.distance(precedente, feature) > config.DISTANZA_INCROCIO_KM) {
                res.features.push(feature);
            }
            precedente = feature;
        });
        // ? aggiungere l'ultimo
        if (res.features[res.features.length - 1] !== last) {
            res.features.push(last);
        }
        return res;
    };
    UtilsService.prototype.distance = function (p1, p2) {
        var p1p = this.featuretoPoint(p1);
        var p2p = this.featuretoPoint(p2);
        if (p1p && p2p) {
            return turf_distance.default(p1p, p2p, { units: 'kilometers' });
        }
        return 0;
    };
    UtilsService.prototype.getBuondingBoxClean = function (bounds) {
        var lng1 = bounds._southWest.lng >= -180
            && bounds._southWest.lng < 180 ? bounds._southWest.lng : bounds._southWest.lng > 0 ? 180 : -180;
        var lat1 = bounds._northEast.lat >= -90
            && bounds._northEast.lat < 90 ? bounds._northEast.lat : bounds._northEast.lat > 0 ? 90 : -90;
        var lng2 = bounds._northEast.lng >= -180
            && bounds._northEast.lng < 180 ? bounds._northEast.lng : bounds._northEast.lng > 0 ? 180 : -180;
        var lat2 = bounds._southWest.lat >= -90
            && bounds._southWest.lat < 90 ? bounds._southWest.lat : bounds._southWest.lat > 0 ? 90 : -90;
        return [[lng1, lat1], [lng2, lat2]];
    };
    UtilsService.prototype.getBoundingBoxUniversal = function () {
        return [[3.0, 46.0], [20, 36.0]];
    };
    UtilsService.prototype.BoundingBoxFromGeometries = function (geometryArray) {
        var minX = 20;
        var minY = 46;
        var maxX = 3;
        var maxY = 36;
        geometryArray.forEach(function (geomString) {
            var geom = JSON.parse(geomString);
            if (geom) {
                var boundingbox = bbox.default(geom);
                minX = Math.min(minX, boundingbox[0]);
                minY = Math.min(minY, boundingbox[1]);
                maxX = Math.max(maxX, boundingbox[2]);
                maxY = Math.max(maxY, boundingbox[3]);
            }
            else {
                // console.log("geomString", geomString);
            }
        });
        // console.log('-----------: UtilsService -> BoundingBoxFromGeometries -> [[minX, minY], [maxX, maxY]', geometryArray.length, [[minX, minY], [maxX, maxY]]);
        if (minX && maxX && minY && maxY) {
            return [[minY, minX], [maxY, maxX]];
        }
        else {
            return null;
        }
    };
    ////////////////////// OPERAZIONI SU OST ////////////////////////////
    UtilsService.prototype.isOstTratta = function (ost) {
        return ost.type === 'ost_sentiero_percorso';
    };
    UtilsService.prototype.isOstSequenziale = function (ost1, ost2) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var geoJSONobj1, geoJSONobj2, ost2Start, ost1End, dist;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!ost1 || !ost2) {
                            return [2 /*return*/, true];
                        }
                        return [4 /*yield*/, this.getOstGeometry(ost1)];
                    case 1:
                        geoJSONobj1 = _a.sent();
                        return [4 /*yield*/, this.getOstGeometry(ost2)];
                    case 2:
                        geoJSONobj2 = _a.sent();
                        ost2Start = turf.point(this.getFirstCoords(geoJSONobj2));
                        ost1End = turf.point(this.getLastCoords(geoJSONobj1));
                        dist = this.distance(ost2Start, ost1End);
                        return [2 /*return*/, dist < config.DISTANZA_LIMITE_CONCATENABILI_KM];
                }
            });
        });
    };
    UtilsService.prototype.ostListConcatenabile = function (osts1, osts2) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lastTratto1, firstTratto2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!osts1 || osts1.length === 0 || !osts2 || osts2.length === 0) {
                            return [2 /*return*/, true];
                        }
                        lastTratto1 = this.getLastTratto(osts1);
                        firstTratto2 = this.getFirstTratto(osts2);
                        return [4 /*yield*/, this.isOstSequenziale(lastTratto1, firstTratto2)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UtilsService.prototype.getFirstTratto = function (osts) {
        if (osts.length === 0) {
            return null;
        }
        var firstTratto = osts[0];
        for (var i in osts) {
            if (this.isOstTratta(osts[i])) {
                firstTratto = osts[i];
                break;
            }
        }
        return firstTratto;
    };
    UtilsService.prototype.getLastTratto = function (osts) {
        return this.getFirstTratto(osts.slice().reverse());
    };
    UtilsService.prototype.isEstremiOst = function (ost, p1, p2) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var line;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getOstGeometry(ost)];
                    case 1:
                        line = _a.sent();
                        return [2 /*return*/, this.isEstremo(line, p1) && this.isEstremo(line, p2)];
                }
            });
        });
    };
    UtilsService.prototype.getSegmento = function (ost, startPoint, endPoint, isReverse) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var line;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getOstGeometry(ost)];
                    case 1:
                        line = _a.sent();
                        // if (!isReverse) {
                        return [2 /*return*/, line_slice.default(this.getCoords(startPoint), this.getCoords(endPoint), line)];
                }
            });
        });
    };
    /////////////////  RECUPERO DATI DA STORAGE //////////////////////////
    UtilsService.prototype.getLocal = function (key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.ngfg.getItem(key)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UtilsService.prototype.setLocal = function (key, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.ngfg.setItem(key, value)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    UtilsService.prototype.getOstGeometry = function (ost) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!ost) {
                    return [2 /*return*/, null];
                }
                return [2 /*return*/, this.getGenericGeometry(ost.id)];
            });
        });
    };
    UtilsService.prototype.getGenericGeometry = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cached, geom;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!id) {
                            return [2 /*return*/, null];
                        }
                        cached = this.cache.get(id);
                        if (cached) {
                            return [2 /*return*/, cached];
                        }
                        return [4 /*yield*/, this.ngfg.getItem('' + id)];
                    case 1:
                        geom = _a.sent();
                        if (!!geom) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.downloadOstGeometry(id)];
                    case 2:
                        geom = _a.sent();
                        return [4 /*yield*/, this.setOstGeometry(id, geom)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.cache.set(id, geom);
                        _a.label = 5;
                    case 5: return [2 /*return*/, geom];
                }
            });
        });
    };
    UtilsService.prototype.downloadOstGeometry = function (ostId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, osts, element;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appDataRequest.fecthOst$(ostId).toPromise()];
                    case 1:
                        data = _a.sent();
                        osts = data.data.queryOst.ost;
                        if (osts.length) {
                            element = osts[0];
                            return [2 /*return*/, this.getGeometryFromOstQueryElement(element)];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    UtilsService.prototype.getGeometryFromOstQueryElement = function (ostElement) {
        if (ostElement.id && ostElement.geometry && ostElement.geometry.value) {
            var geoJSon = wellknown.parse(ostElement.geometry.value);
            var trimmed = geojson_precision.parse(geoJSon, 4);
            // patch per anelli:
            if (this.isSamePoint(this.getFirstCoords(trimmed), this.getLastCoords(trimmed))) {
                var c = this.getLastCoords(trimmed);
                c[0] += 0.00001;
                c[1] += 0.00001;
            }
            return trimmed;
        }
        return null;
    };
    UtilsService.prototype.setOstGeometry = function (ostId, geometry) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.cache.invalidate(ostId);
                this.cache.set(ostId, geometry);
                return [2 /*return*/, this.ngfg.setItem('' + ostId, geometry)];
            });
        });
    };
    UtilsService.prototype.setGenericGeometry = function (id, geometry) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.cache.invalidate(id);
                this.cache.set(id, geometry);
                return [2 /*return*/, this.ngfg.setItem('' + id, geometry)];
            });
        });
    };
    UtilsService.prototype.getRegionData = function (regionName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cached, data, center, regionData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cached = this.cache.get(this.regionKey(regionName));
                        if (cached) {
                            return [2 /*return*/, cached];
                        }
                        return [4 /*yield*/, this.ngfg.getItem(this.regionKey(regionName))];
                    case 1:
                        data = _a.sent();
                        center = data['center']['value'].replace('POINT (', '').replace('POINT(', '').replace(')', '').split(' ');
                        regionData = { geometry: data['geometry'], center: center };
                        this.cache.set(this.regionKey(regionName), regionData);
                        return [2 /*return*/, regionData];
                }
            });
        });
    };
    UtilsService.prototype.setRegionData = function (regionName, geometry, center) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.cache.invalidate(this.regionKey(regionName));
                return [2 /*return*/, this.ngfg.setItem(this.regionKey(regionName), { geometry: geometry, center: center })];
            });
        });
    };
    UtilsService.prototype.regionKey = function (regionName) {
        var cleanName = this.cleanString(regionName);
        return 'region-' + cleanName;
    };
    /////////////////  TIPOLOGIA DEGLI ATTRATTORI //////////////////////////
    UtilsService.prototype.ostGetTipologia = function (ost) {
        if (this.isOstTratta(ost)) {
            return 'tratta';
        }
        var tipologia = '';
        if (!ost.tipologia || ost.tipologia.length === 0) {
            if (ost.tematica && ost.tematica.length > 0) {
                tipologia = ost.tematica[0].name.toLocaleLowerCase();
            }
            else {
                return 'ignoto';
            }
        }
        else {
            tipologia = ost.tipologia[0].name.toLocaleLowerCase();
        }
        if (tipologia.indexOf('natur') >= 0) {
            return 'natura';
        }
        if (tipologia.indexOf('montagn') >= 0) {
            return 'montagna';
        }
        if (tipologia.indexOf('forest') >= 0 || tipologia.indexOf('alber') >= 0) {
            return 'foresta';
        }
        if (tipologia.indexOf('panoram') >= 0) {
            return 'panorama';
        }
        if (tipologia.indexOf('lago') >= 0 || tipologia.indexOf('fonte') >= 0 || tipologia.indexOf('cascata') >= 0) {
            return 'acqua';
        }
        if (tipologia.indexOf('edificio') >= 0 || tipologia.indexOf('citt') >= 0) {
            return 'edificio';
        }
        if (tipologia.indexOf('archeolog') >= 0 || tipologia.indexOf('museo') >= 0) {
            return 'cultura';
        }
        if (tipologia.indexOf('scogliera') >= 0 || tipologia.indexOf('spiaggia') >= 0) {
            return 'mare';
        }
        if (tipologia.indexOf('monumento') >= 0) {
            return 'monumento';
        }
        if (tipologia.indexOf('pernottamento') >= 0) {
            return 'alloggio';
        }
        if (tipologia.indexOf('bed and breakfast') >= 0) {
            return 'alloggio';
        }
        if (tipologia.indexOf('autobus') >= 0) {
            return 'trasporto';
        }
        if (tipologia.indexOf('ferrov') >= 0) {
            return 'treno';
        }
        if (tipologia.indexOf('ristorante') >= 0) {
            return 'ristorazione';
        }
    };
    UtilsService.ngInjectableDef = i0.defineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.inject(i1.NgForage), i0.inject(i2.CacheService), i0.inject(i3.AppDataRequests)); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
