import { Action } from "@ngrx/store";

export enum AppDataActionsType {
    FetchAllSchede = '[AppData] FETCH_ALL_SCHEDE',
    FetchAllSchedeSuccess = '[AppData] FETCH_ALL_SCHEDE_SUCCESS',
    FetchAllSchedeFail = '[AppData] FETCH_ALL_SCHEDE_FAIL',
    FetchAllRegioni = '[AppData] FETCH_ALL_REGIONI',
    FetchAllRegioniSuccess = '[AppData] FETCH_ALL_REGIONI_SUCCESS',
    FetchAllRegioniFail = '[AppData] FETCH_ALL_REGIONI_FAIL',
    FetchAllOst = '[AppData] FETCH_ALL_OST',
    FetchAllOstSuccess = '[AppData] FETCH_ALL_OST_SUCCESS',
    FetchAllOstFail = '[AppData] FETCH_ALL_OST_FAIL',
    FetchPeriods = '[AppData] FETCH_PERIODS',
    FetchPeriodsSuccess = '[AppData] FETCH_PERIODS_SUCCESS',
    FetchPeriodsFail = '[AppData] FETCH_PERIODS_FAIL',
}

export class FetchAllSchede implements Action {
    readonly type = AppDataActionsType.FetchAllSchede;
    constructor() {}
}

export class FetchAllSchedeSuccess implements Action {
    readonly type = AppDataActionsType.FetchAllSchedeSuccess;
    constructor(public payload: any) {}
}

export class FetchAllSchedeFail implements Action {
    readonly type = AppDataActionsType.FetchAllSchedeFail;
    constructor(public payload: any) {}
}

export class FetchAllRegioni implements Action {
    readonly type = AppDataActionsType.FetchAllRegioni;
    constructor() {}
}

export class FetchAllRegioniSuccess implements Action {
    readonly type = AppDataActionsType.FetchAllRegioniSuccess;
    constructor(public payload: any) {}
}

export class FetchAllRegioniFail implements Action {
    readonly type = AppDataActionsType.FetchAllRegioniFail;
    constructor(public payload: any) {}
}

export class FetchAllOst implements Action {
    readonly type = AppDataActionsType.FetchAllOst;
    constructor() {}
}

export class FetchAllOstSuccess implements Action {
    readonly type = AppDataActionsType.FetchAllOstSuccess;
    constructor() {}
}

export class FetchAllOstFail implements Action {
    readonly type = AppDataActionsType.FetchAllOstFail;
    constructor(public payload: any) {}
}

export class FetchPeriods implements Action {
    readonly type = AppDataActionsType.FetchPeriods;
    constructor() {}
}

export class FetchPeriodsSuccess implements Action {
    readonly type = AppDataActionsType.FetchPeriodsSuccess;
    constructor(public payload: any) {}
}

export class FetchPeriodsFail implements Action {
    readonly type = AppDataActionsType.FetchPeriodsFail;
    constructor(public payload: any) {}
}

export type AppDataActions =
FetchAllSchede |
FetchAllSchedeSuccess |
FetchAllSchedeFail |
FetchAllRegioni |
FetchAllRegioniSuccess |
FetchAllRegioniFail |
FetchAllOst |
FetchAllOstSuccess |
FetchAllOstFail |
FetchPeriods |
FetchPeriodsSuccess |
FetchPeriodsFail;
