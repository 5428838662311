import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { Intense } from 'src/app/model/model.interfaces';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { PatchCurrentIntense } from 'src/app/logic/search-ost';
import { Gallery, ImageItem } from '@ngx-gallery/core';
import { config } from 'src/environments/config/config';
import { TranslateService } from '@ngx-translate/core';
import { timeout } from 'rxjs/operators';

@Component({
    selector: 'app-edit-intense-file-modal',
    templateUrl: './edit-intense-file-modal.component.html'
})
export class EditIntenseFileModalComponent implements OnInit, OnDestroy {

    // public currentIntese: Intense = { name: '', currentTappa: 0 };
    private destroy$: Subject<void> = new Subject<void>();
    public files: any[];
    public type: string;
    public intense: Intense;
    public error: string;
    private translations = [];
    public extensions = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EditIntenseFileModalComponent>,
        private store: Store<any>,
        private utils: UtilsService,
        public gallery: Gallery,
        private translate: TranslateService
    ) {
        this.type = this.data.type;
        this.intense = this.data.intense;
        if (this.type === 'file') {
            this.files = this.utils.clone(this.data.intense.allegati);
        } else {
            this.files = this.utils.clone(this.data.intense.immagine);
        }
        if (Array.isArray(config.FILES_IN_INTENSE) && config.FILES_IN_INTENSE.length) {
            this.extensions = '.' + config.FILES_IN_INTENSE.join(',.');
        }
    }

    ngOnInit() {
        this.updateLightBox();
        this.translate.get(['modal.fileerror']).subscribe(res => this.translations = res);
    }

    removeFile(idx) {
        this.files.splice(idx, 1);
        this.updateLightBox();
    }

    updateLightBox() {
        if (this.type === 'image') {
            const album = [];
            if (Array.isArray(this.files) && this.files.length) {
                this.files.forEach(file => {
                    album.push(new ImageItem({ src: file.rawfile ? file.rawfile : file.url, thumb: file.rawfile ? file.rawfile : file.url }));
                });
            }
            this.gallery.ref().load(album);
        }
    }

    // openImg(idx) {
    //     this.lightbox.open(idx);
    // }

    onFileChange($event) {
        if (!this.files) { this.files = []; }
        const reader = new FileReader();
        if ($event.target.files && $event.target.files.length > 0) {
            const file = $event.target.files[0];
            if (this.checkFile(file)) {
                reader.readAsDataURL(file);
                reader.onload = (x) => {
                    let res = '';
                    if (typeof reader.result === 'string') {
                        res = reader.result;
                    } else { console.log('FILE NOT STRING -> reader.onload -> reader', reader); }
                    this.files.push({
                        name: file.name,
                        // filetype: file.type,
                        url: null,
                        rawfile: res,
                        id: null
                    });
                    this.updateLightBox();
                };
                this.error = '';
            } else {
                this.error = this.translations['modal.fileerror'];
            }
        }
    }

    checkFile(file) {
        if (this.type === 'file') {
            let hasExt = false;
            config.FILES_IN_INTENSE.forEach(ext => {
                hasExt = hasExt || file.name.indexOf('.' + ext) === (file.name.length - (ext.length + 1));
            });
            return hasExt && file.size <= config.DIMENSIONI_MASSIME_FILE;
        } else {
            return file.size <= config.DIMENSIONI_MASSIME_IMMAGINE &&
                ((file.type as string).indexOf('image') === 0);
        }
    }

    isPdf(file) {
        if (file && file.name && file.name.slice(file.name.length - 4).toLowerCase() === '.pdf') {
            return true;
        }
        return false;
    }

    download(file) {
        // console.log('TCL: download -> file', file);
        if (file && (file.url || file.rawfile)) {
            const url: string = file.url;
            if (url.startsWith('http') || url.startsWith('www')) {
                window.open(url);
            } else {
                // const blob = new Blob([file.url], { type: 'text/plain' });
                // console.log('TCL: download -> blob', blob);
                // const dlurl = window.URL.createObjectURL(blob);
                // console.log('TCL: download -> dlurl', dlurl);
                // window.open(dlurl);

                const downloadLink = document.createElement('a');
                downloadLink.href = file.rawfile;
                downloadLink.download = file.name;
                downloadLink.click();

                // const byteCharacters = atob(file.url.split(',')[1]);
                // const byteNumbers = new Array(byteCharacters.length);
                // for (let i = 0; i < byteCharacters.length; i++) {
                //     byteNumbers[i] = byteCharacters.charCodeAt(i);
                // }
                // const byteArray = new Uint8Array(byteNumbers);
                // const filez = new Blob([byteArray], { type: 'text/plain' });
                // const fileURL = URL.createObjectURL(filez);
                // window.open(fileURL);
            }
        }
    }

    save() {
        if (this.type === 'file') {
            this.store.dispatch(new PatchCurrentIntense({ allegati: this.files }));
        } else if (this.type === 'image') {
            this.store.dispatch(new PatchCurrentIntense({ immagine: this.files }));

        }
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
