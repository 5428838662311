<div class="ras-tooltip" style="max-width: 380px; margin: 0 auto;">
    <div class="ras-tooltip__content">
        <div class="ras-tooltip__track-description" *ngIf="ost?.tipologia?.length">
            <i aria-hidden="true" class="far fa-dot-circle"></i>
            <ng-container *ngFor="let tipologia of ost?.tipologia">
                {{tipologia.name}}
            </ng-container>
        </div>
        <h2 class="ras-tooltip__track-title">{{ost?.name}}</h2>
    </div>

    <div class="ras-tooltip__footer">
        <button class="ras-btn" (click)="onClick()"><i aria-hidden="true" class="far fa-plus-circle"></i> {{'modal.addpoi' | translate}}</button>
    </div>
</div>