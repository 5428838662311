/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./add-poi-popup.component";
import * as i4 from "@ngrx/store";
var styles_AddPoiPopupComponent = [];
var RenderType_AddPoiPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddPoiPopupComponent, data: {} });
export { RenderType_AddPoiPopupComponent as RenderType_AddPoiPopupComponent };
function View_AddPoiPopupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_AddPoiPopupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "ras-tooltip__track-description"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-dot-circle"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddPoiPopupComponent_2)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.ost == null) ? null : _co.ost.tipologia); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AddPoiPopupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "ras-tooltip"], ["style", "max-width: 380px; margin: 0 auto;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "ras-tooltip__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddPoiPopupComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h2", [["class", "ras-tooltip__track-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "ras-tooltip__footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "button", [["class", "ras-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-plus-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.ost == null) ? null : ((_co.ost.tipologia == null) ? null : _co.ost.tipologia.length)); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.ost == null) ? null : _co.ost.name); _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform("modal.addpoi")); _ck(_v, 9, 0, currVal_2); }); }
export function View_AddPoiPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-poi-popup", [], null, null, null, View_AddPoiPopupComponent_0, RenderType_AddPoiPopupComponent)), i0.ɵdid(1, 114688, null, 0, i3.AddPoiPopupComponent, [i4.Store, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddPoiPopupComponentNgFactory = i0.ɵccf("app-add-poi-popup", i3.AddPoiPopupComponent, View_AddPoiPopupComponent_Host_0, { ost: "ost", feature: "feature", index: "index" }, {}, []);
export { AddPoiPopupComponentNgFactory as AddPoiPopupComponentNgFactory };
