import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { State } from './search-ost.interface';
import { Intense, Tappa } from 'src/app/model/model.interfaces';
import { AppState } from '..';
@Injectable({
    providedIn: 'root'
})
export class SearchOstSelectors {

    private searchOST$: Observable<State>;
    private completeState$: Observable<AppState>;

    constructor(private store: Store<any>) {
        this.searchOST$ = this.store.pipe(select((state: any) => state.search_ost));
        this.completeState$ = this.store.pipe(select((state: any) => state));
    }

    getQuery() {
        return this.searchOST$.pipe(
            map(state => state.query)
        );
    }

    getCurrentIntense$() {
        return this.searchOST$.pipe(
            map(state => state.currentIntense)
        );
    }

    getCurrentIntenseOriginal$() {
        return this.searchOST$.pipe(
            map(state => state.currentIntenseOriginal)
        );
    }

    getCurrentIntenseTranslations$() {
        return this.searchOST$.pipe(
            map(state => state.currentIntenseTranslations)
        );
    }

    getResults$() {
        return this.searchOST$.pipe(
            map(state => state.results)
        );
    }

    getResultsAndType$() {
        return this.searchOST$.pipe(
            map(state => ({ results: state.results, update: state.updated }))
        );
    }

    getSelectedOst$() {
        return this.searchOST$.pipe(
            map(state => state.selectOst)
        );
    }

    getType$() {
        return this.searchOST$.pipe(
            map(state => state.type)
        );
    }

    getFacets$() {
        return this.searchOST$.pipe(
            distinctUntilChanged(),
            map(state => {
                let ret = [];
                state.filters.forEach(s => {
                    if (s['name'] === 'regioni') {
                        ret = s['values'];
                    }
                });
                return ret;
            })
        );
    }


    getResultsAndIntense$() {
        return this.searchOST$.pipe(
            map(state => ({ results: state.results, intense: state.currentIntense }))
        );
    }

    getSearchRequest$() {
        return this.completeState$.pipe(
            map(state => {
                const stateOst = state.search_ost;
                return {
                    query: stateOst.query,
                    filters: stateOst.activeFilters,
                    page: stateOst.page,
                    size: stateOst.size,
                    boundingBox: stateOst.boundingBox,
                    type: stateOst.type,
                    lang: state.common.locale,
                    excludedOst: [] // stateOst.currentIntense ? this.recoverOstIds(state.currentIntense) : []
                };
            })
        );
    }

    getFilters$() {
        return this.searchOST$.pipe(
            map(state => state.filters)
        );
    }

    getActiveFilters$() {
        return this.searchOST$.pipe(
            map(state => state.activeFilters)
        );
    }

    getHighlightElement$() {
        return this.searchOST$.pipe(
            map(state => state.HighlightElement)
        );
    }

    private recoverOstIds(intense: Intense) {
        if (!(intense && intense.tappe && Array.isArray(intense.tappe))) {
            return [];
        }
        const ostResult = [];
        intense.tappe.forEach((tappa: Tappa) => {
            if (tappa && tappa.osts) {
                tappa.osts.forEach(ost => {
                    if (ost && ost.id && !ostResult.includes(ost.id)) {
                        ostResult.push(ost.id);
                    }
                });
            }
        });
        return ostResult;
    }

}
