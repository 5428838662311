import { Store, select } from '@ngrx/store';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { searchIntenseSelector } from '..';
import { ApiService } from 'src/app/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../services/api.service";
var SearchIntenseSelectors = /** @class */ (function () {
    function SearchIntenseSelectors(store, api) {
        this.store = store;
        this.api = api;
        // this.searchIntense$ = this.store.pipe(select((state: any) => state.search_intense));
        this.searchIntense$ = this.store.pipe(select(searchIntenseSelector));
        this.completeState$ = this.store.pipe(select(function (state) { return state; }));
    }
    SearchIntenseSelectors.prototype.getQuery$ = function () {
        return this.searchIntense$.pipe(map(function (state) { return state.query; }));
    };
    SearchIntenseSelectors.prototype.getResults$ = function () {
        return this.searchIntense$.pipe(map(function (state) { return state.results; }));
    };
    SearchIntenseSelectors.prototype.getNumResults$ = function () {
        return this.searchIntense$.pipe(map(function (state) { return state.numResults; }));
    };
    SearchIntenseSelectors.prototype.getCurrentPage$ = function () {
        return this.searchIntense$.pipe(map(function (state) { return state.page; }));
    };
    SearchIntenseSelectors.prototype.getPageSize$ = function () {
        return this.searchIntense$.pipe(map(function (state) { return state.size; }));
    };
    SearchIntenseSelectors.prototype.getFilters$ = function () {
        return this.searchIntense$.pipe(map(function (state) { return state.filters; }));
    };
    SearchIntenseSelectors.prototype.getUidFilter$ = function () {
        return this.searchIntense$.pipe(map(function (state) { return state.uid; }));
    };
    SearchIntenseSelectors.prototype.getStatusFilter$ = function () {
        return this.searchIntense$.pipe(map(function (state) { return state.stato; }));
    };
    SearchIntenseSelectors.prototype.getSearchRequest$ = function () {
        return this.completeState$.pipe(map(function (state) {
            var stateIntense = state.search_intense;
            return {
                query: stateIntense.query,
                filters: stateIntense.activeFilters,
                page: stateIntense.page,
                size: stateIntense.size,
                boundingBox: stateIntense.boundingBox,
                lang: state.common.locale,
                uid: stateIntense.uid,
                stato: stateIntense.stato,
                status: stateIntense.status
            };
        }));
    };
    SearchIntenseSelectors.prototype.getActiveFilters$ = function () {
        return this.searchIntense$.pipe(map(function (state) { return state.activeFilters; }));
    };
    SearchIntenseSelectors.prototype.getSelectedResult$ = function () {
        return this.searchIntense$.pipe(distinctUntilChanged(), map(function (state) { return state.selectedResult; }));
    };
    SearchIntenseSelectors.prototype.getFacets$ = function () {
        return this.searchIntense$.pipe(distinctUntilChanged(), map(function (state) {
            var ret = [];
            state.filters.forEach(function (s) {
                if (s['name'] === 'regioni') {
                    ret = s['values'];
                }
            });
            return ret;
        }));
    };
    SearchIntenseSelectors.ngInjectableDef = i0.defineInjectable({ factory: function SearchIntenseSelectors_Factory() { return new SearchIntenseSelectors(i0.inject(i1.Store), i0.inject(i2.ApiService)); }, token: SearchIntenseSelectors, providedIn: "root" });
    return SearchIntenseSelectors;
}());
export { SearchIntenseSelectors };
