import { State } from './common.interface';
import { CommonActions, CommonActionsTypes } from './common.actions';
import { helpers } from 'src/app/helpers';
import { config } from 'src/environments/config/config';
export const initialState: State = {
    isLoading: false,
    modalOpened: false,
    locale: config.LANGUAGES[0] // 'it'
};

export function reducer(state: State = initialState, action: CommonActions): State {
    switch (action.type) {
        case CommonActionsTypes.StartLoading: {
            const nextState = helpers.immutAssign({}, state, { isLoading: true });
            return nextState;
        }
        case CommonActionsTypes.StopLoading: {
            const nextState = helpers.immutAssign({}, state, { isLoading: false });
            return nextState;
        }
        case CommonActionsTypes.DisplayModal: {
            const nextState = helpers.immutAssign({}, state, { isLoading: false, modalOpened: true });
            return nextState;
        }
        case CommonActionsTypes.SetLocale: {
            const nextState = helpers.immutAssign({}, state, { locale: action.payload });
            return nextState;
        }
        case CommonActionsTypes.DisplayModalFail:
        case CommonActionsTypes.DisplayModalSuccess: {
            const nextState = helpers.immutAssign({}, state, { isLoading: false, modalOpened: false });
            return nextState;
        }
        default:
            break;
    }
    return state;
}






