import { State } from './edit-ost.interface';
import { EditOSTActionTypes, EditOSTActions } from './edit-ost.actions';

export const initialState: State = {
    // SET INITIAL SEARCH STATE
    currentOST: null
};

export function reducer( state: State = initialState, action: EditOSTActions): State {
    // HANDLE STATES
    switch (action.type) {
        case EditOSTActionTypes.setName:
            // add logic
        default:
            break;
    }
    return state;
}


