<div mat-dialog-content class="ras-modal">
    <div class="ras-modal__content">
        <header class="ras-modal__header">
            <h1 class="ras-modal__title">
                <div class="ras-track">
                    <div class="ras-track__tappa-image" *ngIf="tipologia(ost)=='tratto'"></div>
                    <div class="ras-track__attrattore-image" [ngClass]="tipologia(ost)" *ngIf="tipologia(ost)!='tratto'"></div>
                    {{tipologiaString(ost) | translate}}:
                    <strong>{{ost.name}}</strong>
                </div>
            </h1>

            <div class="ras-modal__options" *ngIf="tipologia(ost)==='tratto'">

                <div class="ras-modal__options-item" *ngIf="ost.tematica && ost.tematica.length">
                    <div class="ras-modal__options-item-label">{{'modal.theme' | translate}}</div>
                    <ng-container *ngFor="let tematica of ost.tematica">
                        <div class="ras-modal__options-item-value">
                            {{tematica.name}}
                        </div>
                    </ng-container>
                </div>

                <div class="ras-modal__options-item" *ngIf="ost.ambito && ost.ambito.length">
                    <div class="ras-modal__options-item-label">{{'modal.scope' | translate}}</div>
                    <ng-container *ngFor="let ambito of ost.ambito">
                        <div class="ras-modal__options-item-value">
                            {{ambito.name}}
                        </div>
                    </ng-container>
                </div>

                <div class="ras-card__feature">
                    <i aria-hidden="true" class="far fa-route"></i>
                    <span>{{'search.morphology' | translate}}</span>
                </div>
                <div class="ras-card__feature" [hidden]="!ost.distanza">
                    <i aria-hidden="true" class="icon wm-icon-distance"></i>
                    <span>{{(ost.distanza/1000) | number:'1.0-1':'it' }}km</span>
                </div>
                <div class="ras-card__feature" [hidden]="!ost.dislivelloPositivo">
                    <i aria-hidden="true" class="icon wm-icon-ascent"></i>
                    <span>{{ost.dislivelloPositivo | number:'1.0-0':'it' }}m</span>
                </div>
                <div class="ras-card__feature" [hidden]="!ost.dislivelloNegativo">
                    <i aria-hidden="true" class="icon wm-icon-descent"></i>
                    <span>{{ost.dislivelloNegativo | number:'1.0-0':'it' }}m</span>
                </div>
                <div class="ras-card__feature" [hidden]="!ost.altezzaMassima">
                    <i aria-hidden="true" class="far fa-arrow-to-top"></i>
                    <span>{{ost.altezzaMassima | number:'1.0-0':'it' }}m</span>
                </div>
                <div class="ras-card__feature" [hidden]="!ost.altezzaMinima">
                    <i aria-hidden="true" class="far fa-arrow-to-bottom"></i>
                    <span>{{ost.altezzaMinima | number:'1.0-0':'it' }}m</span>
                </div>
                <div class="ras-card__feature" [hidden]="!ost.altezzaStart">
                    <span class="ras-icon-group">
                            <i aria-hidden="true" class="far fa-arrow-to-top"></i>
                            <i aria-hidden="true" class="far fa-play"></i>
                        </span>
                    <span>{{ost.altezzaStart | number:'1.0-0':'it' }}m</span>
                </div>
                <div class="ras-card__feature" [hidden]="!ost.altezzaEnd">
                    <span class="ras-icon-group"><i aria-hidden="true" class="far fa-arrow-to-bottom"></i><i class="far fa-stop"></i></span>
                    <span>{{ost.altezzaEnd | number:'1.0-0':'it' }}m</span>
                </div>

            </div>
        </header>

        <div class="ras-modal__subheader">
            <div class="ras-modal__options-item" *ngIf="ost.gradoDifficolta && ost.gradoDifficolta.length">
                <div class="ras-modal__options-item-label">{{'modal.difficulty' | translate}}</div>
                <ng-container *ngFor="let gradoDifficolta of ost.gradoDifficolta">
                    <div class="ras-modal__options-item-value">
                        <!-- <ul class="ras-difficulty-scale">
                            <li class="ras-difficulty-scale__item is-full"><i aria-hidden="true" class="fas fa-circle"></i></li>
                            <li class="ras-difficulty-scale__item"><i aria-hidden="true" class="fas fa-circle"></i></li>
                            <li class="ras-difficulty-scale__item"><i aria-hidden="true" class="fas fa-circle"></i></li>
                            <li class="ras-difficulty-scale__item"><i aria-hidden="true" class="fas fa-circle"></i></li>
                        </ul> -->

                        <div class="ras-modal__options-item-definition">{{gradoDifficolta.name}}</div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="ras-modal__body">
            <!-- <div class="ras-modal__details-wrapper">

                <div class="ras-modal__detail">
                    <i class="far fa-bicycle" aria-hidden="true"></i>
                    <span class="ras-modal__detail-label">Per Bicicletta da strada</span>
                </div>

                <div class="ras-modal__detail">
                    <i class="far fa-code-commit" aria-hidden="true"></i>
                    <span class="ras-modal__detail-label">Pista ciclabile e/o ciclopedonale in sede propria in affiancamento alla viabilità</span>
                </div>
            </div> -->

            <div class="ras-modal__add-content">
                <form>
                    <div class="ras-form-item ras-form-item__textarea">
                        <label for="description" class="ras-form-item__label">{{'modal.description' | translate}}</label>
                        <!-- <textarea id="description" name="description" [(ngModel)]="ost.descrizione"></textarea> -->
                        <quill-editor [(ngModel)]="ost.descrizione" name="descrizione" [modules]="quillModules"></quill-editor>
                    </div>

                    <div class="ras-form-item ras-form-item__image-upload">
                        <div class="ras-form-item__label">{{'modal.photos' | translate}}</div>
                        <ng-container *ngFor="let img of ost.immagine;index as idx">
                            <div class="ras-image-thumb">
                                <img [src]="img.rawfile? img.rawfile :img.url" [lightbox]="idx">
                                <a [routerLink]="[]" class="ras-btn ras-btn-light ras-form-btn-img-remove" (click)="removeImg(idx)"><i class="far fa-times-circle"></i>
                                    <div class="img-remove-cmd">{{'modal.removeimage' | translate}}</div>
                                </a>

                                <!-- <a [routerLink]="[]" class="ras-form-btn-img-zoom" (click)="openImg(idx)">
                                    <i class="fas fa-search-plus"></i>
                                </a> -->

                            </div>
                        </ng-container>

                        <div class="ras-form-item ras-form-item__file">
                            <input type="file" name="file" id="addImages" class="ras-form-item__label" (change)="onFileChange($event)" accept="image/*">
                            <label for="addImages"><i aria-label="hidden" class="far fa-plus-circle"></i>
                                {{'modal.addimage' | translate}}</label>
                        </div>
                        <div class="ras-errormsg">{{error}}</div>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>

<div mat-dialog-actions class="ras-modal__actions">
    <a class="ras-btn ras-btn-light ras-btn-icon ras-btn-undo ng-star-inserted" (click)="cancel()"><i
            class="fal fa-times-circle"></i> {{'common.cancel' | translate}}</a>
    <button class="ras-btn" (click)="save()">{{'common.save' | translate}}</button>
</div>