import * as tslib_1 from "tslib";
import { ModalService } from 'src/app/services/modal.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { CommonActionsTypes, DisplayModal } from './common.actions';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SearchOstActionTypes } from '../search-ost';
import { SearchIntenseActionTypes } from '../search-intense';
import { AppDataActionsType, FetchAllSchede, FetchAllOst, FetchAllRegioni } from '../app-data';
import { AppDataSelectors } from '../app-data/app-data.selectors';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
var CommonEffects = /** @class */ (function () {
    // private showingSpinner = false;
    function CommonEffects(appDataSelectors, 
    // private overlay: Overlay,
    router, actions$, modalService, translate, spinner) {
        var _this = this;
        this.appDataSelectors = appDataSelectors;
        this.router = router;
        this.actions$ = actions$;
        this.modalService = modalService;
        this.translate = translate;
        this.spinner = spinner;
        // private spinnerRef: OverlayRef = this.cdkSpinnerCreate();
        this.translations = [];
        this.showModal$ = this.actions$.pipe(ofType(CommonActionsTypes.DisplayModal), map(function (action) { return action['payload']; }), tap(function (payload) { return _this.modalService.openDialog(payload.type, payload.data); }));
        this.hideModal$ = this.actions$.pipe(ofType(CommonActionsTypes.DisplayModalSuccess), map(function (action) { return action['payload']; }), tap(function (payload) { return _this.modalService.closeDialog(); }));
        this.spinnerStart$ = this.actions$.pipe(ofType(SearchOstActionTypes.SaveIntense, SearchIntenseActionTypes.DeleteIntense, SearchOstActionTypes.UpdatePercorsoPoint, SearchOstActionTypes.SearchOst, SearchOstActionTypes.SearchOstSuccess, SearchIntenseActionTypes.SearchIntense), tap(function (data) { return _this.showSpinner(); }));
        this.spinnerStop$ = this.actions$.pipe(ofType(SearchOstActionTypes.SaveIntenseSuccess, SearchOstActionTypes.SaveIntenseFail, SearchIntenseActionTypes.DeleteIntenseSuccess, SearchIntenseActionTypes.DeleteIntenseFail, SearchOstActionTypes.UpdatePercorsoSuccess, SearchIntenseActionTypes.SearchIntenseSuccess, SearchOstActionTypes.SearchOstFail, SearchIntenseActionTypes.SearchIntenseFail), tap(function (data) { return _this.stopSpinner(); }));
        this.startLoginSpinner$ = this.actions$.pipe(ofType(AppDataActionsType.FetchAllOst, AppDataActionsType.FetchAllRegioni, AppDataActionsType.FetchAllSchede), tap(function (data) { return _this.showSpinner(); }));
        this.stopLoginSpinner$ = this.actions$.pipe(ofType(
        // AppDataActionsType.FetchAllOstSuccess,
        AppDataActionsType.FetchAllRegioniSuccess, AppDataActionsType.FetchAllSchedeSuccess), withLatestFrom(this.appDataSelectors.dataReady$()), tap(function (_a) {
            var _ = _a[0], check = _a[1];
            if (check) {
                _this.stopSpinner();
                _this.router.navigate(['search']);
            }
        }));
        this.fetchSchedeError$ = this.actions$.pipe(ofType(AppDataActionsType.FetchAllSchedeFail), withLatestFrom(this.appDataSelectors.schedeAttempts$()), map(function (_a) {
            var _ = _a[0], num = _a[1];
            if (num < 0) {
                _this.stopSpinner();
                return new DisplayModal({ type: 'confirm', data: { message: _this.translations['action.loadingerror'] } });
            }
            else {
                return new FetchAllSchede();
            }
        }));
        this.fetchOstError$ = this.actions$.pipe(ofType(AppDataActionsType.FetchAllOstFail), withLatestFrom(this.appDataSelectors.ostAttempts$()), map(function (_a) {
            var _ = _a[0], num = _a[1];
            // console.log(num);
            if (num < 0) {
                _this.stopSpinner();
                return new DisplayModal({ type: 'confirm', data: { message: _this.translations['action.loadingerror'] } });
            }
            else {
                return new FetchAllOst();
            }
        }));
        this.fetchRegionError$ = this.actions$.pipe(ofType(AppDataActionsType.FetchAllRegioniFail), withLatestFrom(this.appDataSelectors.regionAttempts$()), map(function (_a) {
            var _ = _a[0], num = _a[1];
            if (num < 0) {
                _this.stopSpinner();
                return new DisplayModal({ type: 'confirm', data: { message: _this.translations['action.loadingerror'] } });
            }
            else {
                return new FetchAllRegioni();
            }
        }));
        this.ErrorConfirf$ = this.actions$.pipe(ofType(SearchOstActionTypes.SaveIntenseFail), map(function (data) {
            _this.stopSpinner();
            return new DisplayModal({ type: 'confirm', data: { message: _this.translations['action.savingerror'] } });
        }));
        this.deleteIntenseFail$ = this.actions$.pipe(ofType(SearchIntenseActionTypes.DeleteIntenseFail), map(function (data) {
            _this.stopSpinner();
            return new DisplayModal({ type: 'confirm', data: { message: _this.translations['action.deleteerror'] } });
        }));
        this.FeatureNotReady$ = this.actions$.pipe(ofType(CommonActionsTypes.FeatureNotReady), map(function (data) {
            _this.stopSpinner();
            return new DisplayModal({ type: 'confirm', data: { message: _this.translations['action.unavailable'] } });
        }));
        setTimeout(function () {
            translate.get([
                'action.loadingerror',
                'action.savingerror',
                'action.deleteerror',
                'action.unavailable',
            ]).subscribe(function (res) {
                _this.translations = res;
            });
        }, 500);
    }
    // private cdkSpinnerCreate() {
    //     return this.overlay.create({
    //         hasBackdrop: true,
    //         backdropClass: 'dark-backdrop',
    //         positionStrategy: this.overlay.position()
    //             .global()
    //             .centerHorizontally()
    //             .centerVertically()
    //     });
    // }
    CommonEffects.prototype.showSpinner = function () {
        this.spinner.show();
        // if (!this.spinnerRef.hasAttached() && !this.showingSpinner) {
        //     this.showingSpinner = true;
        //     setTimeout(() => {
        //         this.spinnerRef.attach(new ComponentPortal(MatSpinner));
        //     }, 1);
        // }
    };
    CommonEffects.prototype.stopSpinner = function () {
        this.spinner.hide();
        // this.showingSpinner = false;
        // setTimeout(() => {
        //     this.spinnerRef.detach();
        // }, 1);
    };
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "showModal$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "hideModal$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "spinnerStart$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "spinnerStop$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "startLoginSpinner$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "stopLoginSpinner$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "fetchSchedeError$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "fetchOstError$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "fetchRegionError$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "ErrorConfirf$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "deleteIntenseFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], CommonEffects.prototype, "FeatureNotReady$", void 0);
    return CommonEffects;
}());
export { CommonEffects };
