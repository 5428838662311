import { AsrAuthGuard } from './auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "angular-oauth2-oidc";
var Interceptor = /** @class */ (function () {
    function Interceptor(auth, oauthService) {
        this.auth = auth;
        this.oauthService = oauthService;
    }
    Interceptor.prototype.intercept = function (req, next) {
        var authToken = this.auth.getToken();
        if (!authToken) { // && request.header. 
            authToken = this.oauthService.getAccessToken();
        }
        if (authToken) {
            var request = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + authToken
                }
            });
            return next.handle(request);
        }
        return next.handle(req);
    };
    Interceptor.ngInjectableDef = i0.defineInjectable({ factory: function Interceptor_Factory() { return new Interceptor(i0.inject(i1.AsrAuthGuard), i0.inject(i2.OAuthService)); }, token: Interceptor, providedIn: "root" });
    return Interceptor;
}());
export { Interceptor };
