<div mat-dialog-content class="ras-modal">
    <div class="ras-modal__content">
        <header class="ras-modal__header">
            <h1 class="ras-modal__title">
                <div class="ras-track">
                    <div class="ras-track__tappa-image"></div>
                    {{(type==='file'?'modal.attachement':'modal.photos') | translate}}
                    <strong>{{intense.name}}</strong>
                </div>
            </h1>
        </header>

        <div class="ras-modal__body">

            <div class="ras-modal__add-content">
                <form>

                    <div class="ras-form-item ras-form-item__image-upload">
                        <div *ngFor="let file of files;index as idx">
                            <div class="ras-image-thumb ras-file-thumb">
                                <img [src]="file.rawfile? file.rawfile : file.url" [lightbox]="idx" *ngIf="type==='image'">
                                <div *ngIf="type==='file'" (click)="download(file)" class="ras-intense-file">
                                    <i class="fas fa-file-pdf" *ngIf="isPdf(file)"></i>
                                    <i class="fas fa-file" *ngIf="!isPdf(file)"></i>
                                    <div class="ras-intense-filename">{{file.name}}</div>
                                </div>
                                <!-- <a [routerLink]="[]" class="ras-btn ras-btn-light ras-form-btn-img-remove"
                                    (click)="removeFile(idx)"><i class="far fa-times-circle"></i>
                                    <div class="img-remove-cmd">rimuovi</div>
                                </a> -->
                                <div class="ras-image-toolbox ">
                                    <div class="ras-image-tool" (click)="download(file)"><i class="fas fa-download"></i>
                                    </div>
                                    <div class="ras-image-tool" (click)="removeFile(idx)"><i
                                            class="fas fa-times-circle"></i> </div>
                                </div>
                            </div>
                        </div>

                        <div class="ras-form-item ras-form-item__file  ras-form-item__file-inline">
                            <input type="file" name="file" id="addFile" class="ras-form-item__label"
                                (change)="onFileChange($event)" [accept]="type === 'file'?extensions:'image/*'">
                            <label for="addFile"><i aria-label="hidden" class="far fa-plus-circle"></i> {{'modal.addfile' | translate}}
                            </label>
                        </div>
                    </div>


                </form>
                    <div class="ras-errormsg">{{error}}</div>
            </div>

        </div>
    </div>
</div>

<div mat-dialog-actions class="ras-modal__actions">
    <a class="ras-btn ras-btn-light ras-btn-icon ras-btn-undo ng-star-inserted" (click)="cancel()"><i
            class="fal fa-times-circle"></i> {{'common.cancel' | translate}}</a>
    <button class="ras-btn" (click)="save()">{{'common.save' | translate}}</button>
</div>