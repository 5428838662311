<!-- Header -->
<header app-header class="ras-header"></header>

<!-- Main content -->
<main>
    <section class="ras-dashboard-filters">

        <div class="ras-container">
            <!-- Filters -->

            <form class="ras-dashboard-filters__form">
                <!-- Search -->
                <!-- <div class="ras-search-autocomplete">
                    <mat-form-field>
                        <div class="ras-search-bar">
                            <input type="text" placeholder="{{'search.searchintense' | translate}}" matInput [formControl]="searchControl">
                            <i class="far fa-search"></i>
                        </div>
                    </mat-form-field>

                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option (onSelectionChange)="goToEdit(option, true)" *ngFor="let option of (results$ | async)" [value]="option.name">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </div> -->

                <!-- Filtri mobile -->
                <!-- <a id="filters-opener" class="ras-btn-action ras-btn-action--has-label ras-mobile-visible"
                    [routerLink]="[]"><i class="far fa-filter"></i><span class="ras-btn-action__label">mostra
                        filtri</span></a>

                <div class="ras-dashboard-filters__wrapper">
                    <a id="filters-closer" class="ras-btn-action ras-btn-action--has-label ras-mobile-visible"
                        [routerLink]="[]"><i class="far fa-filter"></i><span class="ras-btn-action__label">nascondi
                            filtri</span></a>
                </div>
 -->
                <div class="ras-dashboard-filters__row">
                    <div class="ras-dashboard-filters__wrapper">

                        <ng-container *ngFor="let filter of (filters$ | async)">
                            <!-- Filter - select -->
                            <ng-container>
                                <fieldset class="ras-form-item ras-form-item--select"
                                    (click)="filter.values.length > 0 && toggleFilter(filter)" [ngClass]="{
                                    'is-open': openSelect === filter.name,
                                    'disable': filter.values.length === 0
                                }">
                                    <legend class="ras-form-item__legend">
                                        {{('search.'+(filter.name === 'regioni' ? 'zone':filter.name)) | translate}} <i
                                            class="far fa-chevron-down"></i><i class="far fa-chevron-up"></i>
                                    </legend>
                                    <div class="ras-form-item__options">
                                        <!-- <div class="ras-checkbox">

                                                <input id="{{filter.name + '-all'}}" type="checkbox">
                                                <label for="{{filter.name + '-all'}}">
                                                    Tutte
                                                </label>
                                            </div> -->
                                        <ng-container *ngFor="let option of filter.values">
                                            <div class="ras-checkbox">
                                                <input id="{{filter.name + '-'+ option.key}}" type="checkbox"
                                                    (click)="setFilter(filter.name,option.key)"
                                                    [checked]="isChecked(filter,option)">
                                                <label for="{{filter.name + '-'+ option.key}}">
                                                    {{option.key}}
                                                </label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </fieldset>
                            </ng-container>


                            <!-- <ng-container *ngIf="filter?.type === 'range'">
                                <fieldset class="ras-form-item ras-form-item--slider">
                                    <label class="ras-form-item__label">distanza</label>
                                    <div class="ras-form-item--slider__range">
                                        <span class="ras-form-item--slider__min">
                                        0
                                    </span>
                                        <span class="ras-form-item--slider__max">
                                        120
                                    </span>
                                    </div>

                                    <mat-slider min="1" max="5" step="0.5" value="1.5"></mat-slider>
                                </fieldset>
                            </ng-container> -->

                            <!-- Filter - range -->
                        </ng-container>



                    </div>
                </div>
                <div class="ras-dashboard-filters__action">
                    <a id="ras-filter-toggler" href="" class="ras-dashboard-filters__action-button">
                        <span class="view-more">{{'search.morefilter' | translate}}</span>
                        <span class="view-less">{{'search.lessfilter' | translate}}</span>
                        <i class="view-more fal fa-chevron-circle-down"></i>
                        <i class="view-less fal fa-chevron-circle-up"></i>
                    </a>
                </div>
                <div class="ras-dashboard-filters__row ras-dashboard-filters__row-others">

                    <fieldset class="ras-form-item ras-form-item--select" (click)="toggleFilter({name:'uid'})"
                        [ngClass]="{'is-open': openSelect === 'uid'}">
                        <legend class="ras-form-item__legend">{{'search.user' | translate}} <i
                                class="far fa-chevron-down"></i><i class="far fa-chevron-up"></i></legend>
                        <div class="ras-form-item__options">
                            <div class="ras-checkbox">
                                <input id="user-true" type="checkbox" (click)="setUserFilter(true)"
                                    [checked]="filterUser">
                                <label for="user-true">{{'search.myuser' | translate}}</label>
                            </div>
                            <div class="ras-checkbox">
                                <input id="user-false" type="checkbox" (click)="setUserFilter(false)"
                                    [checked]="!filterUser">
                                <label for="user-false">{{'search.alluser' | translate}}</label>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="ras-form-item ras-form-item--select" (click)="toggleFilter({name:'stato'})"
                        [ngClass]="{'is-open': openSelect === 'stato'}">
                        <legend class="ras-form-item__legend">{{'search.status' | translate}} <i
                                class="far fa-chevron-down"></i><i class="far fa-chevron-up"></i></legend>
                        <div class="ras-form-item__options">
                            <ng-container *ngFor="let stato of statuses">
                                <div class="ras-checkbox">
                                    <input id="{{'status -'+ stato.value}}" type="checkbox"
                                        (click)="setStatusFilter(stato.value,stato.label)"
                                        [checked]="filterStatus === stato.value">
                                    <label for="{{'status -'+ stato.value}}">{{stato.label | translate}}</label>
                                </div>
                            </ng-container>
                        </div>
                    </fieldset>

                    <!-- <fieldset class="ras-form-item ras-form-item--select" (click)="toggleFilter({name:'interruptstatus'})"
                        [ngClass]="{'is-open': openSelect === 'interruptstatus'}">
                        <legend class="ras-form-item__legend">{{'search.interruptstatus' | translate}} <i
                                class="far fa-chevron-down"></i><i class="far fa-chevron-up"></i></legend>
                        <div class="ras-form-item__options">
                            <ng-container *ngFor="let interruptstatus of interruptstatuses">
                                <div class="ras-checkbox">
                                    <input id="{{'interruptstatus -'+ interruptstatus.value}}" type="checkbox"
                                        (click)="setStatusInterruptFilter(interruptstatus.value,interruptstatus.label)"
                                        [checked]="filterInterruptStatus === interruptstatus.value">
                                    <label for="{{'interruptstatus -'+ interruptstatus.value}}">{{interruptstatus.label | translate}}</label>
                                </div>
                            </ng-container>
                        </div>
                    </fieldset> -->

                </div>
            </form>
        </div>
    </section>

    <div class="ras-container">

        <section class="ras-dashboard-main">
            <div app-search-map class="ras-dashboard-map"></div>
            <div class="ras-dashboard-cards">
                <div class="ras-dashboard-cards__informations">

                    <div class="ras-dashboard-results-number">
                        <p>{{'search.intensecards' | translate}} <strong>{{numResults$ | async}}</strong></p>
                        <a id="filters-closer" class="ras-dashboard-filters-close" [routerLink]="[]"
                            (click)="clearFilter()" *ngIf="filtersPresent()">
                            <span>{{'search.cleanfilter' | translate}} </span>
                            <i class="fas fa-times-circle"></i>
                        </a>
                    </div>

                    <!-- Filters pills -->

                    <div class="ras-dashboard-active-filters">
                        <ng-container *ngFor="let filter of activeFilters">
                            <ng-container *ngFor="let value of filter.values">
                                <span class="ras-dashboard-active-filters__item">
                                    <span class="ras-dashboard-active-filters__label">{{value}}</span>
                                    <a (click)="setFilter(filter.name,value)"
                                        class="ras-dashboard-active-filters__item-close">
                                        <i class="far fa-times"></i>
                                    </a>
                                </span>
                            </ng-container>
                        </ng-container>
                        <span class="ras-dashboard-active-filters__item" *ngIf="filterUser">
                            <span class="ras-dashboard-active-filters__label">{{'search.onlyyou' | translate}}</span>
                            <a (click)="setUserFilter()" class="ras-dashboard-active-filters__item-close">
                                <i class="far fa-times"></i>
                            </a>
                        </span>
                        <span class="ras-dashboard-active-filters__item" *ngIf="filterStatus">
                            <span
                                class="ras-dashboard-active-filters__label">{{ getFilterStatusLabel(filterStatus) | translate}}</span>
                            <a (click)="setStatusFilter()" class="ras-dashboard-active-filters__item-close">
                                <i class="far fa-times"></i>
                            </a>
                        </span>
                    </div>

                </div>

                <div class="ras-dashboard-cards__scroller" #resultsScrollList>

                    <!-- Card -->
                    <ng-container *ngFor="let scheda of results$ | async | slice:0:20; ">
                        <div class="ras-card is-selected" *ngIf="scheda.id === (selectedResult$ | async)">
                            <!--<div class="ras-card is-selected">-->
                            <div class="ras-card__header">
                                <div class="ras-card__feature">
                                    <i aria-hidden="true" class="far fa-route"></i>
                                    <span>{{'search.morphology' | translate}}</span></div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled': !scheda.distanza}">
                                    <i aria-hidden="true" class="icon wm-icon-distance"></i>
                                    <span>{{(scheda.distanza/1000) | number:'1.0-1':'it' }}km</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.dislivelloPositivo}">
                                    <i aria-hidden="true" class="icon wm-icon-ascent"></i>
                                    <span>{{scheda.dislivelloPositivo | number:'1.0-0':'it' }}m</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.dislivelloNegativo}">
                                    <i aria-hidden="true" class="icon wm-icon-descent"></i>
                                    <span>{{scheda.dislivelloNegativo | number:'1.0-0':'it' }}m</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.altezzaMassima}">
                                    <i aria-hidden="true" class="far fa-arrow-to-top"></i>
                                    <span>{{scheda.altezzaMassima | number:'1.0-0':'it' }}m</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.altezzaMinima}">
                                    <i aria-hidden="true" class="far fa-arrow-to-bottom"></i>
                                    <span>{{scheda.altezzaMinima | number:'1.0-0':'it' }}m</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.altezzaStart}">
                                    <span class="ras-icon-group">
                                        <i aria-hidden="true" class="far fa-arrow-to-top"></i>
                                        <i aria-hidden="true" class="far fa-play"></i>
                                    </span>
                                    <span>{{scheda.altezzaStart | number:'1.0-0':'it' }}m</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.altezzaEnd}">
                                    <span class="ras-icon-group">
                                        <i aria-hidden="true" class="far fa-arrow-to-bottom"></i>
                                        <i class="far fa-stop"></i>
                                    </span>
                                    <span>{{scheda.altezzaEnd | number:'1.0-0':'it' }}m</span>
                                </div>
                            </div>

                            <div class="ras-card__body">
                                <div class="ras-card__content">
                                    <div class="ras-card__title-container">
                                        <h2 class="ras-card__title"
                                            [matTooltip]="(isDanger(scheda) ? 'search.problemvalidation' : null) | translate"
                                            matTooltipPosition="above">
                                            <i *ngIf="isDanger(scheda)" class="fas fa-exclamation-triangle"></i>
                                            <a (click)="selectItem(scheda)">{{scheda.name}}</a>
                                        </h2>
                                        <div class="ras-card__definition">
                                            <strong>{{scheda.tappe | trattoCounter}}</strong> OST
                                            <strong>{{'search.section' | translate}}</strong> -
                                            <strong>{{scheda.tappe | attrattoriCounter}}</strong> OST
                                            <strong>{{'search.actractor' | translate}}</strong>
                                        </div>
                                    </div>

                                    <div class="ras-card__details">
                                        <span class="ras-card__details-item"><i aria-hidden="true"
                                                class="far fa-calendar-alt"></i>
                                            <ng-container *ngIf="scheda.periodoFruizione">
                                                {{'search.customPeriod' | translate}}</ng-container>
                                            <ng-container *ngIf="!scheda.periodoFruizione">
                                                {{'search.allyear' | translate}}</ng-container>
                                        </span>
                                        <span class="ras-card__details-item"><i aria-hidden="true"
                                                class="far fa-tag"></i> {{'search.theme' | translate}}</span>

                                        <!-- Status: Pubblicato o in revisione-->
                                        <span class="ras-card__details-item ras-card__details-item-status"><i
                                                aria-hidden="true" class="far fa-file-check"></i>
                                            {{'search.published' | translate}}</span>
                                        <!--<span class="ras-card__details-item ras-card__details-item-status"><i aria-hidden="true" class="far fa-clock"></i>In revisione</span>-->
                                    </div>

                                    <div class="ras-card__excerpt">
                                        {{(scheda.descrizione?scheda.descrizione:'') | StringifyHtml | truncate}} <a
                                            class="ras-read-more" [routerLink]="[]"
                                            (click)="goToEdit(scheda,true)">{{'search.readall' | translate}}</a>
                                    </div>
                                </div>

                                <div *ngIf="!scheda.tile_preview?.url || showLeafletMap" app-preview-map
                                    [id]="scheda.id" [geometry]="(scheda.id)" class="ras-card__image"></div>
                                <div class="ras-card__image" *ngIf="scheda.tile_preview?.url && !showLeafletMap">
                                    <img [src]="scheda.tile_preview?.url">
                                </div>


                                <div class="ras-card__actions">
                                    <a [routerLink]="[]" class="ras-card__button ras-card__button-edit"
                                        (click)="goToEdit(scheda,true)"><i class="far fa-pen"></i></a>
                                    <a [routerLink]="[]" class="ras-card__button ras-card__button-copy"
                                        (click)="duplicate(scheda)"><i class="far fa-copy"></i></a>
                                    <a [routerLink]="[]" class="ras-card__button ras-card__button-delete"
                                        (click)="delete(scheda)"><i class="far fa-trash"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let scheda of results$ | async | slice:0:10; ">
                        <div class="ras-card" *ngIf="scheda.id !== (selectedResult$ | async)">
                            <!--<div class="ras-card is-selected">-->
                            <div class="ras-card__header">
                                <div class="ras-card__feature">
                                    <i aria-hidden="true" class="far fa-route"></i>
                                    <span>{{'search.morphology' | translate}}</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled': !scheda.distanza}">
                                    <i aria-hidden="true" class="icon wm-icon-distance"></i>
                                    <span>{{(scheda.distanza/1000) | number:'1.0-1':'it' }}km</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.dislivelloPositivo}">
                                    <i aria-hidden="true" class="icon wm-icon-ascent"></i>
                                    <span>{{scheda.dislivelloPositivo  | number:'1.0-0':'it'}}m</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.dislivelloNegativo}">
                                    <i aria-hidden="true" class="icon wm-icon-descent"></i>
                                    <span>{{scheda.dislivelloNegativo  | number:'1.0-0':'it'}}m</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.altezzaMassima}">
                                    <i aria-hidden="true" class="far fa-arrow-to-top"></i>
                                    <span>{{scheda.altezzaMassima  | number:'1.0-0':'it'}}m</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.altezzaMinima}">
                                    <i aria-hidden="true" class="far fa-arrow-to-bottom"></i>
                                    <span>{{scheda.altezzaMinima  | number:'1.0-0':'it'}}m</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.altezzaStart}">
                                    <span class="ras-icon-group">
                                        <i aria-hidden="true" class="far fa-arrow-to-top"></i>
                                        <i aria-hidden="true" class="far fa-play"></i>
                                    </span>
                                    <span>{{scheda.altezzaStart | number:'1.0-0':'it'}}m</span>
                                </div>
                                <div class="ras-card__feature"
                                    [ngClass]="{'ras-card__feature-disabled':!scheda.altezzaEnd}">
                                    <span class="ras-icon-group">
                                        <i aria-hidden="true" class="far fa-arrow-to-bottom"></i>
                                        <i class="far fa-stop"></i>
                                    </span>
                                    <span>{{scheda.altezzaEnd | number:'1.0-0':'it'}}m</span>
                                </div>
                            </div>

                            <div class="ras-card__body">
                                <div class="ras-card__content">
                                    <div class="ras-card__title-container">
                                        <h2 class="ras-card__title"
                                        [matTooltip]="(isDanger(scheda) ? 'search.problemvalidation' : null) | translate"
                                        matTooltipPosition="above"><i *ngIf="isDanger(scheda)"
                                                class="fas fa-exclamation-triangle"></i><a
                                                (click)="selectItem(scheda)">{{scheda.name}}</a><span
                                                *ngIf="!scheda.geojson">.</span></h2>
                                        <div class="ras-card__definition">
                                            <strong>{{scheda.tappe | trattoCounter}}</strong> OST
                                            <strong>{{'search.section' | translate}}</strong> -
                                            <strong>{{scheda.tappe | attrattoriCounter}}</strong> OST
                                            <strong>{{'search.actractor' | translate}}</strong>
                                        </div>
                                    </div>

                                    <div class="ras-card__details">
                                        <span class="ras-card__details-item"><i aria-hidden="true"
                                                class="far fa-calendar-alt"></i>
                                            <ng-container *ngIf="scheda.periodoFruizione">
                                                {{'search.customPeriod' | translate}}</ng-container>
                                            <ng-container *ngIf="!scheda.periodoFruizione">
                                                {{'search.allyear' | translate}}</ng-container>
                                        </span>
                                        <span class="ras-card__details-item"><i aria-hidden="true"
                                                class="far fa-tag"></i> {{'search.theme' | translate}}</span>

                                        <!-- Pubblicato o in revisione-->
                                        <span class="ras-card__details-item ras-card__details-item-status"><i
                                                aria-hidden="true" class="far fa-file-check"></i>
                                            {{statoString(scheda.stato) | translate}}</span>
                                        <!--<span class="ras-card__details-item ras-card__details-item-status"><i aria-hidden="true" class="far fa-clock"></i>In revisione</span>-->
                                    </div>

                                    <div class="ras-card__excerpt">
                                        {{(scheda.descrizione?scheda.descrizione:'') | StringifyHtml | truncate}} <a
                                            class="ras-read-more" [routerLink]="[]"
                                            (click)="goToEdit(scheda,true)">{{'search.readall' | translate}}</a>
                                    </div>
                                </div>

                                <div *ngIf="!scheda.tile_preview?.url || showLeafletMap" app-preview-map
                                    [id]="scheda.id" [geometry]="(scheda.id)" class="ras-card__image"></div>
                                <div class="ras-card__image" *ngIf="scheda.tile_preview?.url && !showLeafletMap">
                                    <img [src]="scheda.tile_preview?.url">
                                </div>



                                <div class="ras-card__actions">
                                    <a [routerLink]="[]" class="ras-card__button ras-card__button-edit"
                                        (click)="goToEdit(scheda,true)"><i class="far fa-pen"></i></a>
                                    <a [routerLink]="[]" class="ras-card__button ras-card__button-copy"
                                        (click)="duplicate(scheda)"><i class="far fa-copy"></i></a>
                                    <a [routerLink]="[]" class="ras-card__button ras-card__button-delete"
                                        (click)="delete(scheda)"><i class="far fa-trash"></i></a>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>

            </div>


        </section>
    </div>
</main>