import { Injectable, } from '@angular/core';
import { of, zip, Observable as observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Intense, Ost, Facet, GeoQuery, Tappa, Traduzione } from 'src/app/model/model.interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { PercorsoService } from 'src/app/services/percorso.service';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { SearchIntenseRequests } from '../search-intense';
import { map } from 'rxjs/operators';
import { config } from 'src/environments/config/config';

@Injectable({
    providedIn: 'root'
})
export class SearchOstRequests {

    translations = [];

    constructor(private apollo: Apollo,
        private utils: UtilsService,
        private percorso: PercorsoService,
        private api: ApiService,
        private translate: TranslateService,
        private searchIntense: SearchIntenseRequests
    ) {
        setTimeout(() => {
            this.translate.get(['common.defaultstagename']).subscribe(res => this.translations = res);
        }, 500);
    }

    ostInfo = gql`
        fragment OstInfo on OST {
            id
            name
            type
            status
            identificativoCatasto
            identificativo
            lunghezza
            pendenza
            descrizione
            dislivelloSalita
            dislivelloDiscesa
            distanza
            dislivelloPositivo
            dislivelloNegativo
            altezzaMassima
            altezzaMinima
            altezzaStart
            altezzaEnd
            tempoPercorrenza {
               type
               value
            }
            gradoProtezione
            percorrenza
            visitabile
            fruizione {
                name
            }
            tipologia {
                name
            }
            tematica {
                name
            }
            statoFondo {
                name
            }
            tipoFondo {
                name
            }
            presenzaSegnaletica {
                name
            }
            accessibilitaDisabili {
                name
            }
            riconoscimenti {
                name
            }
            rete {
                name
            }
            ambito {
                name
            }
            esposizioneRischi {
                name
            }
            gradoDifficolta {
               name
            }
            contatti {
               type
               value
            }
            allegati {
                name
            }
            immagine {
                name
                url
                id
            }
        }`;

    doSearch$(request) {
        const facets = [
            { name: 'tematica' },
            { name: 'tipologia' },
            { name: 'regioni' },
            { name: 'rete' },
            { name: 'presenza_segnaletica' },
            { name: 'fruizione' },
            { name: 'statoFondo' },
            { name: 'tipoFondo' }
        ];
        interface Variables {
            fulltext: string;
            page: number;
            count: number;
            facets: Facet[];
            geoQuery: GeoQuery;
            filters: Facet[];
            exclude: [];
            type: string | null;
            lang: string | null;
        }
        const querySearch = gql`
        query($fulltext: String, $type: String, $page: Int, $geoQuery: GeoQuery, $filters: [Facet], $count: Int, $facets: [Facet], $exclude: [Int] ) {
            queryOst(fulltext: $fulltext, type: $type, page: $page, count: $count, geoQuery: $geoQuery, facets: $facets, filters: $filters, exclude: $exclude){
                 ost{
                   ...OstInfo
                }
            totalCount
            facets {
                name
                values{
                  key
                  count
                }
              }
            }
        }${this.ostInfo}`;

        return this.apollo.query<any, Variables>({
            query: querySearch,
            variables: {
                fulltext: request.query,
                page: request.page,
                geoQuery: request.boundingBox,
                facets: facets,
                count: request.size,
                filters: this.createFiltersParam(request.filters),
                exclude: request.excludedOst,
                type: request.type,
                lang: request.lang
            }
        });
    }

    private createFiltersParam(filters): (Facet[] | null) {
        return filters;
    }

    saveIntense(intense: Intense, original: Intense, traduzioni, currentLang) {
        return this.api.saveIntense(intense, original, traduzioni, currentLang);
    }

    async createTappa({ indexTappa, indexTratto, currentIntense }) {


        const newIntense: Intense = this.utils.clone(currentIntense);
        // console.log('createTappa: indexTappa, indexTratto,currentIntense', indexTappa, indexTratto, currentIntense);
        const breakTappa = newIntense.tappe[indexTappa];
        if (indexTratto > -1) {


            const newTappa: Tappa = this.utils.clone(breakTappa);
            newTappa.percorso = this.percorso.slicePercorso(breakTappa.percorso, indexTratto);

            // controllo se ci sono punti di interesse da inserire nella tappa precedente
            const toAdd = [];
            for (let i = breakTappa.osts.length - 1; i >= 0; i--) {
                const ost = breakTappa.osts[i];
                const ostGeom = await this.utils.getOstGeometry(ost);
                if (!this.utils.isOstTratta(ost) && this.utils.isNearPercorso(ostGeom, newTappa.percorso)) {
                    toAdd.push(ost);
                    breakTappa.osts.splice(i, 1);
                } else if (this.utils.isOstTratta(ost)) {
                    breakTappa.osts.splice(i, 1);
                }
            }

            newTappa.id = null;
            newTappa.osts = toAdd;
            newTappa.name = this.translations['common.defaultstagename'] + (indexTappa + 2); // nome di default della tappa
            newIntense.tappe.splice(indexTappa + 1, 0, newTappa);
        }
        return { newIntense: newIntense };

    }

    async removeTappa({ tappaIdx, currentIntense }) {
        const newIntense: Intense = this.utils.clone(currentIntense);
        tappaIdx = tappaIdx.indexTappa;
        if (tappaIdx === 0 && newIntense.tappe.length === 1) { return newIntense; }

        if (tappaIdx < newIntense.tappe.length) {
            const addTappa = newIntense.tappe[tappaIdx + 1];
            // controllo se tappa è eliminabile
            const concatenabile = this.percorso.concatenabili(newIntense.tappe[tappaIdx].percorso, addTappa.percorso);
            if (!concatenabile) {
                // console.log('ERRORE DIVISIONE IN TAPPE NON ELIMINABILE');
                throw new Error('tappa unbreakable');
            }

            addTappa.percorso = this.percorso.concat(newIntense.tappe[tappaIdx].percorso, addTappa.percorso);
            // recupero degli attrattori
            addTappa.osts = newIntense.tappe[tappaIdx].osts.concat(addTappa.osts);
        }
        console.log('----------->  removeTappa -> newIntense.tappe', newIntense.tappe.length, newIntense.tappe);
        newIntense.tappe.splice(tappaIdx, 1);
        newIntense.currentTappa = Math.min(tappaIdx, newIntense.tappe.length - 1);
        console.log('----------->  removeTappa -> newIntense.tappe', newIntense.tappe.length, newIntense.tappe);
        // console.log('Rimozione tappa success', tappaIdx, newIntense.tappe.length - 1);
        return { newIntense: newIntense };
    }

    async addStartPoint({ point, direzionePredefinita, osts, currentIntense }) {
        const newIntense: Intense = this.utils.clone(currentIntense);

        newIntense.currentTappa = newIntense.currentTappa ? newIntense.currentTappa : 0;
        if (!newIntense.tappe) {
            newIntense.tappe = [];
            newIntense.tappe.push({});
        }
        if (!newIntense.tappe[0].name) { newIntense.tappe[0].name = this.translations['common.defaultstagename'] + 1; } // default name per la prima tappa
        if (newIntense.tappe.length <= newIntense.currentTappa) {
            // ERRORE
            console.log('ERROR!!! CurrentTappa index maggiore di numero tappe');
        }

        if (!newIntense.tappe[newIntense.currentTappa].percorso) {
            newIntense.tappe[newIntense.currentTappa].percorso = { tratti: [] };
        }
        const percorso = newIntense.tappe[newIntense.currentTappa].percorso;
        const newpoint = { punto: point.punto, ostProvenienza: osts.find(o => o.id === point.ostProvenienza) };
        percorso.inizio = point.punto;
        percorso.direzionePredefinita = direzionePredefinita;
        percorso.ostIniziale = newpoint.ostProvenienza;
        percorso.ultimoPunto = newpoint;
        return { newIntense: newIntense };
    }

    async addPercorsoPoint({ point, currentIntense }) {
        const newIntense: Intense = this.utils.clone(currentIntense);
        newIntense.currentTappa = newIntense.currentTappa ? newIntense.currentTappa : 0;
        newIntense.tappe[newIntense.currentTappa].percorso = await this.percorso.PercorsoWithNewPoint(point, newIntense.tappe[newIntense.currentTappa].percorso);
        return { newIntense: newIntense };
    }

    async modifyIntense({ isAdmin, currentIntense }) {
        const newIntense: Intense = this.utils.clone(currentIntense);
        if (newIntense.stato === this.api.STATO_PUBBLICATA && !isAdmin) {
            newIntense.stato = this.api.STATO_DRAFT;
        }
        return { newIntense: newIntense };
    }

    async removePercorsoPoint({ currentIntense }) {
        const newIntense: Intense = this.utils.clone(currentIntense);
        newIntense.currentTappa = newIntense.currentTappa ? newIntense.currentTappa : 0;
        const percorso = newIntense.tappe[newIntense.currentTappa].percorso;
        this.percorso.removePuntoPercorso(percorso);

        this.checkGhostTrack(percorso);
        this.checkOstProximity(newIntense, percorso);

        return { newIntense: newIntense };
    }

    checkGhostTrack(percorso) {
        if (config.DELETE_GHOST_TRACK) {
            while (percorso.tratti.length > 0 && percorso.tratti[percorso.tratti.length - 1].ost === null) {
                this.percorso.removePuntoPercorso(percorso);
            }
        }
    }

    async checkOstProximity(newIntense, percorso) {
        // rimozione osts che non sono più vicini al percorso rimasto
        const osts = newIntense.tappe[newIntense.currentTappa].osts;
        for (let i = osts.length - 1; i >= 0; i--) {
            const ostGeom = await this.utils.getOstGeometry(osts[i]);
            if (osts.length > (i + 1) && (this.utils.isOstTratta(osts[i]) || !this.utils.isNearPercorso(ostGeom, percorso))) {
                osts.splice(i, 1);
            }
        }
    }

    async removePercorsoTratto({ indexTratto, currentIntense }) {
        console.log('-----------: removePercorsoTratto -> indexTratto, currentIntense', indexTratto, currentIntense);
        const newIntense: Intense = this.utils.clone(currentIntense);

        // rimozione primo punto
        if (indexTratto === 0) {
            newIntense.tappe[newIntense.currentTappa].percorso = null;
            return { newIntense: newIntense };
        }


        const percorso = newIntense.tappe[newIntense.currentTappa].percorso;
        // rimozione dei tratti
        this.percorso.removeIndexPercorso(percorso, indexTratto);

        this.checkGhostTrack(percorso);

        this.checkOstProximity(newIntense, percorso);

        return { newIntense: newIntense };
    }

    async updatePercorsoPoint({ osts, currentIntense }) {
        // console.log('TCL: updatePercorsoPoint -> osts, currentIntense', osts, currentIntense);
        const newIntense: Intense = this.utils.clone(currentIntense);
        if (newIntense) {
            newIntense.currentTappa = newIntense.currentTappa ? newIntense.currentTappa : 0;

            if (newIntense.tappe && newIntense.tappe[newIntense.currentTappa] && newIntense.tappe[newIntense.currentTappa].percorso) {
                const percorso = newIntense.tappe[newIntense.currentTappa].percorso;
                const lastPoint = this.percorso.ultimoPuntoPercorso(percorso);
                newIntense.nextPoints = await this.percorso.getNextPoints(lastPoint, osts, percorso.tratti.length === 0);
            }
        }
        return { newIntense: newIntense };
    }

    getTranslations(intense, request): observable<any> {

        const reqs = [];
        config.LANGUAGES.forEach(lang => {
            if (intense && intense.id) {
                reqs.push(this.searchIntense.doGetIntenseForTranslate$(intense.id, lang));
            }
        });

        if (intense && intense.id) {
            return zip(...reqs).pipe(map(val => {
                const ret = {};
                config.LANGUAGES.forEach((lang, idx) => {
                    ret[lang] = this.getTraduzione(val[idx]);
                });
                return ret;
            })
            );
        } else {
            const ret = {};
            config.LANGUAGES.forEach((lang) => {
                ret[lang] = this.getTraduzione(null);
            });
            return of(ret);
        }
    }

    private getTraduzione(obj: any): Traduzione {
        return {
            name: obj ? obj.data.findIntenseById.name : '',
            descrizione: obj ? obj.data.findIntenseById.descrizione : '',
            osts: obj ? this.utils.getOstsListTrad(obj.data.findIntenseById) : [],
            tappe: obj ? this.utils.getTappeListTrad(obj.data.findIntenseById) : [],
            stato: obj ? obj.data.findIntenseById.stato : this.api.STATO_DRAFT,
            changed: obj ? obj.data.findIntenseById.changed : null,
            modified: obj ? obj.data.findIntenseById.modified : false
        };
    }

    private generateOSTGeoJSON(ost: Ost) {
        return {
            'type': 'Feature',
            'properties': {
                'nid': ost.id
            },
        };
    }

    private geneateAllFeatures(intense: Intense) {
        const data = [
            {
                'type': 'GeometryCollection',
                'properties': {
                    'field_fonte_dati': [
                        'frontend editoriale'
                    ],
                    'type': 'intense',
                    'title': intense.name,
                    'body': intense.descrizione,
                    'nid': intense.id
                }
            }
        ];
        const osts = intense.tappe[0].osts;
        for (let i = 0; i < osts.length; i++) {
            const element: Ost = osts[i];
            const geo = this.generateOSTGeoJSON(element);
            data.push(geo as any);
        }
        return data;
    }
}





