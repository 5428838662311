import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var AppDataSelectors = /** @class */ (function () {
    function AppDataSelectors(store) {
        this.store = store;
        this.appData$ = this.store.pipe(select(function (state) { return state.app_data; }));
    }
    AppDataSelectors.prototype.schedeReady$ = function () {
        return this.appData$.pipe(map(function (state) { return state.schedeReady; }));
    };
    AppDataSelectors.prototype.ostReady$ = function () {
        return this.appData$.pipe(map(function (state) { return state.ostReady; }));
    };
    AppDataSelectors.prototype.regionReady$ = function () {
        return this.appData$.pipe(map(function (state) { return state.regionReady; }));
    };
    AppDataSelectors.prototype.dataReady$ = function () {
        return this.appData$.pipe(map(function (state) { return state.regionReady && state.schedeReady; })
        // TODO valutare se riattivare attesa del download di tutti gli ost
        // map(state => state.ostReady && state.regionReady && state.schedeReady)
        );
    };
    AppDataSelectors.prototype.schedeAttempts$ = function () {
        return this.appData$.pipe(map(function (state) { return state.fetchSchedeNumAttempts; }));
    };
    AppDataSelectors.prototype.regionAttempts$ = function () {
        return this.appData$.pipe(map(function (state) { return state.fetchRegioniNumAttempts; }));
    };
    AppDataSelectors.prototype.ostAttempts$ = function () {
        return this.appData$.pipe(map(function (state) { return state.fetchOstNumAttempts; }));
    };
    AppDataSelectors.prototype.regionArray$ = function () {
        return this.appData$.pipe(map(function (state) { return state.regionArray; }));
    };
    AppDataSelectors.prototype.periodsArray$ = function () {
        return this.appData$.pipe(map(function (state) { return state.periodsArray; }));
    };
    AppDataSelectors.ngInjectableDef = i0.defineInjectable({ factory: function AppDataSelectors_Factory() { return new AppDataSelectors(i0.inject(i1.Store)); }, token: AppDataSelectors, providedIn: "root" });
    return AppDataSelectors;
}());
export { AppDataSelectors };
