<header class="ras-record-sidebar__header">
    <a [routerLink]="['/search']" class="ras-logo" title="Vai alla Homepage">
        <img alt="Logo Intense" src="assets/images/logo.png" srcset="assets/images/logo@2x.png 2x">
    </a>
    <a [routerLink]="[]" id="collapse-filters" (click)="toggleFilters()" class="ras-btn-action ras-btn-action--has-label" *ngIf="!showDetails && showFilters">
        <i class="far fa-filter"></i>
        <span class="ras-btn-action__label ras-btn-action__label-show">{{'leftsidebar.hidefilter'|translate}}</span>
        <span class="ras-btn-action__label ras-btn-action__label-hide">{{'leftsidebar.showfilter'|translate}}</span>
        <i class="far fa-chevron-up"></i>
        <i class="far fa-chevron-down"></i>
    </a>
    <a [routerLink]="[]" (click)="toggleFilters()" class="ras-btn-action ras-btn-action--has-label ras-mobile-hidden" *ngIf="showDetails && !showFilters"><i class="far fa-filter"></i>
        <span class="ras-btn-action__label">{{'leftsidebar.closefilter'|translate}}</span>
        <i class="far fa-chevron-up"></i>
    </a>
</header>

<div class="ras-record-filters" *ngIf="!showDetails && showFilters">
    <a class="ras-btn-action ras-btn-action--has-label ras-mobile-visible" [routerLink]="[]" id="record-filters-closer"><i class="far fa-filter"></i><span
            class="ras-btn-action__label">{{'leftsidebar.hidefilter'|translate}}</span></a>
    <!-- Pills -->
    <div class="ras-record-filter-pills">
        <!-- <div class="ras-record-filter-pills__item">
            <input name="pills" type="radio" id="all" [formControl]="radioControl" value="all">
            <label for="all">{{'leftsidebar.allpill'|translate}}</label>
        </div> -->
        <div class="ras-record-filter-pills__item" >
            <input name="pills" type="radio" id="sentieri" [formControl]="radioControl" value="ost_sentiero_percorso">
            <label for="sentieri">{{'leftsidebar.pathpill'|translate}}</label>
        </div>
        <div class="ras-record-filter-pills__item" (click)="canFilter(viewPoints)" [matTooltip]="(viewPoints ? null : 'leftsidebar.noviewpoints') | translate" matTooltipPosition="right" matTooltipShowDelay="0" matTooltipHideDelay="100">
            <input name="pills" type="radio" id="attrattori" [formControl]="radioControl" value="ost_attrattore" [attr.disabled]="viewPoints ? null : 'disabled'">
            <label for="attrattori">{{'leftsidebar.actractorpill'|translate}}</label>
        </div>
        <div class="ras-record-filter-pills__item" (click)="canFilter(viewPoints)" [matTooltip]="(viewPoints ? null : 'leftsidebar.noviewpoints') | translate" matTooltipPosition="above" matTooltipShowDelay="0" matTooltipHideDelay="100">
            <input name="pills" type="radio" id="servizi" [formControl]="radioControl" value="ost_servizi" [attr.disabled]="viewPoints ? null : 'disabled'">
            <label for="servizi">{{'leftsidebar.servicepill'|translate}}</label>
        </div>
        <div class="ras-record-filter-pills__item" (click)="canFilter(viewPoints)" [matTooltip]="(viewPoints ? null : 'leftsidebar.noviewpoints') | translate" matTooltipPosition="right" matTooltipShowDelay="0" matTooltipHideDelay="100">
            <input name="pills" type="radio" id="sosta" [formControl]="radioControl" value="ost_waypoint" [attr.disabled]="viewPoints ? null : 'disabled'">
            <label for="sosta">{{'leftsidebar.breakpill'|translate}}</label>
        </div>

    </div>

    <!-- Facets -->

    <div class="ras-record-filter-pills selezionati" *ngIf="activeFilters.length">
        <ng-container *ngFor="let filter of activeFilters">
            <div class="ras-record-filter-pills__item" *ngFor="let val of filter.values" (click)="removeFilter(filter,val)">
                <label>
                    {{val}}
                    <i class="fas fa-times-circle"></i>
                </label>
            </div>
        </ng-container>

        <ng-container *ngIf="activeFilters.length > 1 || (activeFilters.length == 1 && activeFilters[0].values.length > 1)">
            <div class="ras-record-filter-pills__item ras-record-filter-pills__clear" (click)="clearFilter()">
                <label>
                    {{'leftsidebar.clearfilter' | translate}}
                    <i class="fas fa-times-circle"></i>
                </label>
            </div>
        </ng-container>

    </div>

    <div class="ras-record-facets">
        <!-- <h2 class="ras-record-facets__title">Ost tratto</h2> -->
        <!-- Accordion facets component -->
        <ng-container *ngFor="let filter of (filters$ | async)">
            <mat-expansion-panel [disabled]="filter.values.length === 0">

                <mat-expansion-panel-header [ngClass]="{'ras-record-facets_activeFilter':isFilterSelected(filter.name)}">
                    {{filter.name | translate}}
                </mat-expansion-panel-header>

                <ng-container *ngFor="let option of filter.values">
                    <a class="ras-record-facets__item" (click)="setFilter(filter.name,option.key)" [ngClass]="{'ras-record-facets_activeFilter':isFilterSelected(filter.name,option.key)}">
                        <span><i class="fas fa-times-circle" *ngIf="isFilterSelected(filter.name,option.key)"></i>{{option.key}}</span> <span class="ras-record-facets__item-count">{{option.count}}</span>

                    </a>
                </ng-container>

            </mat-expansion-panel>

        </ng-container>
    </div>

    <!-- <div class="ras-record-facets">
        <h2 class="ras-record-facets__title">Ost attrattori</h2>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                Tematica
            </mat-expansion-panel-header>

            <a  [routerLink]="[]" class="ras-record-facets__item">
                <span>Trekking</span> <span class="ras-record-facets__item-count">20</span>
            </a>
            <a  [routerLink]="[]" class="ras-record-facets__item">
                <span> Nordic Walk</span> <span class="ras-record-facets__item-count">18</span>
            </a>

        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                Fruizione del tratto
            </mat-expansion-panel-header>

            <a  [routerLink]="[]" class="ras-record-facets__item">
                <span>Trekking</span> <span class="ras-record-facets__item-count">20</span>
            </a>
            <a  [routerLink]="[]" class="ras-record-facets__item">
                <span> Nordic Walk</span> <span class="ras-record-facets__item-count">18</span>
            </a>
        </mat-expansion-panel>
    </div> -->

</div>

<div class="ras-btn-wrapper" *ngIf="!showFilters">
    <a [routerLink]="[]" class="ras-btn-action" (click)="toggleFilters()">
        <i class="far fa-filter"></i>
    </a>

    <a [routerLink]="[]" class="ras-btn-action ras-btn-action--numbered">
        <i aria-hidden="true" class="far fa-route"></i>
        <span class="ras-btn-action__number">{{ results$ | async | ostTrattoCounter}}</span>
    </a>

    <a [routerLink]="[]" class="ras-btn-action ras-btn-action--numbered">
        <i class="far fa-map-marker-alt"></i>
        <span class="ras-btn-action__number">{{ results$ | async | ostAttrattoriCounter}}</span>
    </a>
</div>