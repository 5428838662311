<section class="ras-record "
[ngClass]="{'ras-record--add-track has-sidebar-closed': (!showDetails && !showFilters) ,'ras-record--expanded has-sidebar-hidden': showDetails}"
>
    <aside app-left-sidebar class="ras-record-sidebar">
        <!-- <header class="ras-record-sidebar__header">
            <a  [routerLink]="[]" class="ras-logo" title="Vai alla Homepage">
                <img alt="Logo Intense" src="assets/images/logo.png" srcset="assets/images/logo@2x.png 2x">
            </a>

            <a class="ras-btn-action ras-mobile-show"  [routerLink]="[]"><i class="far fa-filter"></i> <span class="ras-btn-action__label">filtri</span>
            </a>

            <a class="ras-btn-action ras-btn-action--has-label ras-mobile-hidden"  [routerLink]="[]"><i class="far fa-filter"></i> <span class="ras-btn-action__label">mostra in mappa</span>
                <i class="far fa-chevron-up"></i>
            </a>
        </header>

        <div class="ras-record-filters">
            <a [routerLink]="[]" class="ras-filters-btn-mobile">
                chiudi <i class="far fa-times"></i>
            </a>
            <div class="ras-record-filter-pills">
                <div class="ras-record-filter-pills__item">
                    <input name="pills" type="checkbox" id="all">
                    <label for="all">Tutti</label>
                </div>
                <div class="ras-record-filter-pills__item">
                    <input name="pills" type="checkbox" id="sentieri">
                    <label for="sentieri">Sentieri</label>
                </div>
                <div class="ras-record-filter-pills__item">
                    <input name="pills" type="checkbox" id="attrattori">
                    <label for="attrattori">Attrattori</label>
                </div>
                <div class="ras-record-filter-pills__item">
                    <input name="pills" type="checkbox" id="servizi">
                    <label for="servizi">Servizi</label>
                </div>
            </div>


            <div class="ras-record-facets">

                <h2 class="ras-record-facets__title">Ost tratto</h2>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            Tematica
                        </mat-expansion-panel-header>

                        <a [routerLink]="[]" class="ras-record-facets__item">
                            <span>Trekking</span> <span class="ras-record-facets__item-count">20</span>
                        </a>
                        <a [routerLink]="[]" class="ras-record-facets__item">
                            <span> Nordic Walk</span> <span class="ras-record-facets__item-count">18</span>
                        </a>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            Fruizione del tratto
                        </mat-expansion-panel-header>

                        <a [routerLink]="[]" class="ras-record-facets__item">
                            <span>Trekking</span> <span class="ras-record-facets__item-count">20</span>
                        </a>
                        <a [routerLink]="[]" class="ras-record-facets__item">
                            <span> Nordic Walk</span> <span class="ras-record-facets__item-count">18</span>
                        </a>

                    </mat-expansion-panel>
            </div>

            <div class="ras-record-facets">

                <h2 class="ras-record-facets__title">Ost attrattori</h2>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        Tematica
                    </mat-expansion-panel-header>

                    <a [routerLink]="[]" class="ras-record-facets__item">
                        <span>Trekking</span> <span class="ras-record-facets__item-count">20</span>
                    </a>
                    <a [routerLink]="[]" class="ras-record-facets__item">
                        <span> Nordic Walk</span> <span class="ras-record-facets__item-count">18</span>
                    </a>

                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        Fruizione del tratto
                    </mat-expansion-panel-header>

                    <a [routerLink]="[]" class="ras-record-facets__item">
                        <span>Trekking</span> <span class="ras-record-facets__item-count">20</span>
                    </a>
                    <a [routerLink]="[]" class="ras-record-facets__item">
                        <span> Nordic Walk</span> <span class="ras-record-facets__item-count">18</span>
                    </a>

                </mat-expansion-panel>


            </div>

        </div> -->
    </aside>

    <main class="ras-record-map">
        <header app-map-header class="ras-record-map__header">
        </header>

        <div class="ras-record-map__main">
            <div app-edit-map class="ras-record-map__map"></div>
             <div app-right-sidebar  class="ras-record-map__details" *ngIf="showDetails || !showFilters">
             </div>
        </div>
    </main>
</section>