<h2 class="ras-record-map__title" *ngIf="!showDetails">{{'rightsidebar.itinerary'|translate}}</h2>
<div class="ras-record-map__scroller">
    <div class="ras-record-map__infos" *ngIf="showDetails">

        <div class="ras-record-title-wrapper">
            <h1 class="ras-record-title">
                <i *ngIf="isDanger()" class="fas fa-exclamation-triangle" [matTooltip]="(isDanger() ? 'search.problemvalidation' : null) | translate"></i> 
                {{currentItem?.name}}
                <a [routerLink]="[]" (click)="editTitle()" class="ras-btn-action">
                    <i aria-hidden="true" class="far fa-pen"></i>
                </a>
            </h1>
            <ul class="ras-lang-tab">
                <li *ngFor="let lang of languages; let idx = index">
                    <div class="langtab {{statoLingua(lang)}}" (click)="idx?translateModal(lang):null">
                        {{lang.toUpperCase()}} <span class="alertlang" *ngIf="attenzioneLingua(lang) && idx"></span>
                    </div>
                </li>
                <!-- <li>
                    <div class="langtab {{statoLingua('it')}} ">IT</div>
                </li>
                <li>
                    <div class="langtab {{statoLingua('en')}}" (click)="translateModal('en')">EN <span class="alertlang" *ngIf="attenzioneLingua('en')"></span> </div>
                </li>
                <li>
                    <div class="langtab {{statoLingua('fr')}}" (click)="translateModal('fr')">FR <span class="alertlang" *ngIf="attenzioneLingua('fr')"></span></div>
                </li> -->
            </ul>
            <div class="ras-record-features">
                <div class="ras-record-features__item">
                    <i aria-hidden="true" class="icon wm-icon-distance"></i>
                    <span>{{(currentItem?.distanza/1000) | number:'1.0-1':'it' }}km</span>
                </div>
                <div class="ras-record-features__item">
                    <i aria-hidden="true" class="icon wm-icon-ascent"></i>
                    <span>{{currentItem?.dislivelloPositivo | number:'1.0-0':'it' }}m</span>
                </div>
                <div class="ras-record-features__item">
                    <i aria-hidden="true" class="icon wm-icon-descent"></i>
                    <span>{{currentItem?.dislivelloNegativo | number:'1.0-0':'it' }}m</span>
                </div>
                <div class="ras-record-features__item">
                    <i aria-hidden="true" class="far fa-arrow-to-top"></i>
                    <span>{{currentItem?.altezzaMassima | number:'1.0-0':'it' }}m</span>
                </div>
                <div class="ras-record-features__item">
                    <i aria-hidden="true" class="far fa-arrow-to-bottom"></i>
                    <span>{{currentItem?.altezzaMinima | number:'1.0-0':'it' }}m</span>
                </div>
                <div class="ras-record-features__item">
                    <span class="ras-icon-group">
                        <i aria-hidden="true" class="far fa-arrow-to-top"></i>                        
                        <i aria-hidden="true" class="far fa-play"></i>
                        </span>
                    <span>{{currentItem?.altezzaStart | number:'1.0-0':'it' }}m</span>
                </div>
                <div class="ras-record-features__item">
                    <span class="ras-icon-group">
                        <i aria-hidden="true" class="far fa-arrow-to-bottom"></i>
                        <i class="far fa-stop"></i>
                        </span>
                    <span>{{currentItem?.altezzaEnd | number:'1.0-0':'it' }}m</span>
                </div>
            </div>
        </div>

        <div class="ras-record-map__infos-text">
            <div class="ras-record-map__infos-detail">
                <i class="far fa-calendar-alt"></i>
                <ng-container *ngIf="!currentItem?.periodoFruizione"> {{'rightsidebar.allyear'|translate}} </ng-container>
                <ng-container *ngIf="currentItem?.periodoFruizione"> {{'rightsidebar.customperiod'|translate}} </ng-container>
                <a [routerLink]="[]" (click)="editTitle()" class="ras-btn-action"><i aria-hidden="true"
                        class="far fa-pen"></i></a>
            </div>

            <!-- <div class="ras-record-map__infos-detail">
                <i class="far fa-clock"></i> {{currentItem?.durataPercorso || '- ' + 'h'}}
            </div> -->

            <div class="ras-record-map__infos-detail" *ngIf="difficolta(currentItem) && difficolta(currentItem).length >0 ">
                <i class="far fa-hiking"></i> {{'rightsidebar.difficulty'|translate}} 
                <div class="ras-record-map__infos-value"  *ngFor="let diff of difficolta(currentItem)">{{diff}}</div>
            </div>

            <div class="ras-record-map__infos-detail" *ngIf="fruizione(currentItem) && fruizione(currentItem).length >0">
                <i class="far fa-road"></i> {{'rightsidebar.fruibility'|translate}} 
                <div class="ras-record-map__infos-value" *ngFor="let fru of fruizione(currentItem)">{{fru}}</div>
            </div>

            <div class="ras-record-map__infos-definition">
                <strong>{{currentItem?.tappe | trattoCounter}}</strong> OST
                <strong>{{'rightsidebar.tratto'|translate}}</strong> -
                <strong>{{currentItem?.tappe | attrattoriCounter}}</strong> OST
                <strong>{{'rightsidebar.attrattore'|translate}}</strong>
            </div>

            <div class="ras-record-map__infos-description ras-form-item">
                <form>
                    <quill-editor [formControl]="description" [modules]="quillModules"></quill-editor>
                </form>
            </div>

            <!--<a  [routerLink]="[]" class="ras-btn-action">
                            <i aria-hidden="true" class="far fa-pen"></i>
                        </a>-->
        </div>

        <div class="ras-record-map__attachments">
            <a class="ras-btn" (click)="showPhotos()">
                <ng-container *ngIf="currentItem?.immagine?.length>0">
                    <i aria-hidden="true" class="fas fa-images"></i>
                    {{currentItem?.immagine?.length}}
                    {{(currentItem?.immagine?.length <= 1 ? 'rightsidebar.photo1':'rightsidebar.photomulti') | translate}}
                </ng-container>
                <ng-container *ngIf="!currentItem?.immagine || !currentItem?.immagine?.length">
                    <i aria-hidden="true" aria-label="hidden" class="far fa-plus-circle"></i>
                    {{'rightsidebar.addphoto'|translate}}
                </ng-container>
            </a>
            <a class="ras-btn" (click)="showFiles()">
                <ng-container *ngIf="currentItem?.allegati?.length>0">
                    <i aria-hidden="true" class="fas fa-paperclip"></i>
                    {{currentItem?.allegati?.length}}
                    {{(currentItem?.allegati?.length <= 1?'rightsidebar.attachement1':'rightsidebar.attachementmulti') | translate}}
                </ng-container>
                <ng-container *ngIf="!currentItem?.allegati || !currentItem?.allegati?.length">
                    <i aria-hidden="true" aria-label="hidden" class="far fa-plus-circle"></i>
                    {{'rightsidebar.addattachement'|translate}}
                </ng-container>
            </a>
            <!-- <ng-container *ngFor="let lang of languages">
                <a class="ras-btn" (click)="translateModal(lang)" *ngIf="currentLang !== lang">
                    <i aria-hidden="true" class="fas fa-flag"></i> {{'rightsidebar.traduzioni' | translate}} {{'translate.'+lang | translate}}
                </a>
            </ng-container> -->
        </div>
    </div>

    <ng-container *ngIf="(!tappe || !tappe.length) && !showDetails">
        <div class="ras-record-map__final-step">
            {{'rightsidebar.selectstart'|translate}}
        </div>
    </ng-container>

    <ng-container *ngFor="let tappa of tappe; index as tappaIdx; last as isLastTappa">
        <!-- Track -->
        <div class="ras-record-map__tappa" [ngClass]="{'ras-record-map__tappaSelected':isTappaSelected(tappaIdx)}">
            <div class="ras-record-map__final-step" (click)="selectTappa(tappaIdx)" *ngIf="tappe.length > 1">
                <span class="">{{tappa.name}}</span>
                <a [routerLink]="[]" class="ras-btn-action" (click)="editTappaName(tappa,tappaIdx)">
                    <i aria-hidden="true" class="far fa-pen"></i>
                </a>
            </div>
            <ng-container *ngFor="let tratto of tappa.tratti; last as lastTratto; index as trattoNum">
                <div class="ras-track" (click)="selectTappa(tappaIdx)" (mouseover)="highlightElement($event,tratto)" (mouseout)="highlightElement($event,null)">
                    <div class="ras-track__header">
                        <div class="ras-track__tappa-image" *ngIf="showDetails">
                        </div>
                        <div class="ras-track__number">{{ '0'+ (trattoNum + 1) }}</div>
                        <h3 class="ras-track__name" [matTooltip]="(isDanger() ? 'rightsidebar.problemvalidation' : null) | translate">
                            <i *ngIf="tratto?.ost?.status==0" class="fas fa-exclamation-triangle"> </i>
                            {{trattoName(tratto)}}
                        </h3>
                        <!-- <div class="ras-track__difficulty">
                        </div> -->
                        <div class="ras-track__partial" *ngIf="tratto.parziale">
                            {{'rightsidebar.partialost'|translate}}
                            <i aria-hidden="true" class="ras-track__partial-icon fas fa-directions"></i>
                        </div>

                        <div class="ras-track__actions">
                            <a [routerLink]="[]" class="ras-btn-action" (click)="editOst(tratto.ost)" *ngIf="tratto.ost">
                                <i aria-hidden="true" class="far fa-pen"></i>
                            </a>
                            <a [routerLink]="[]" class="ras-btn-action" (click)="removeTratto(trattoNum,tappaIdx)">
                                <i aria-hidden="true" class="far fa-trash-alt"></i>
                            </a>
                        </div>
                    </div>

                    <div class="ras-track__detail" *ngFor="let tipo of tratto?.ost?.tipologia">
                        <span class="ras-track__detail-label">{{tipo.name}}</span>
                    </div>
                    <ng-container *ngFor="let poi of tratto.poi">
                        <div class="ras-track__attrattore" (mouseover)="highlightElement($event,poi)" (mouseout)="highlightElement($event,null)">

                            <div class="ras-track__attrattore-details">
                                <div class="ras-track__attrattore-image" [ngClass]="tipoAttrattore(poi)" *ngIf="showDetails">
                                    <!--<img src="assets/images/card-map.jpg">
                                    {{poi.type}}-->
                                </div>
                                <h3 class="ras-track__attrattore-name">{{poi.name}}</h3>
                                <div class="ras-track__attrattore-category">
                                    <i aria-hidden="true" class="far fa-dot-circle"></i><i aria-hidden="true" class="far " [ngClass]="ostIcon(poi)"></i> <span class="ras-track__attrattore-category-label" *ngFor="let tipologia of poi?.tipologia ">
                                        {{tipologia.name}}
                                    </span>
                                </div>
                                <div class="ras-track__actions">
                                    <a [routerLink]="[]" class="ras-btn-action" (click)="editOst(poi)">
                                        <i aria-hidden="true" class="far fa-pen"></i>
                                    </a>
                                    <a class="ras-btn-action" (click)="removeOst(poi,tappaIdx)">
                                        <i aria-hidden="true" class="far fa-trash-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="ras-record-map__add-step" *ngIf="!(lastTratto && !isLastTappa)">
                    <a [routerLink]="[]" (click)="creaTappa(tappaIdx, trattoNum)" class="ras-record-map__add-step-label"><i class="fas fa-plus-circle"></i>
                        {{'rightsidebar.newcheckpoint'|translate}}</a>
                </div>
            </ng-container>

            <div class="ras-record-map__add-track" *ngIf="isTappaSelected(tappaIdx)">
                <a class="ras-btn" [routerLink]="[]" (click)="aggiungi()">
                    <i class="far fa-plus-circle" aria-hidden="true"></i> {{'rightsidebar.add'|translate}}
                </a>
            </div>

            <div class="ras-record-map__add-step ras-record-map__add-step-day" *ngIf="!isLastTappa">
                <a [routerLink]="[]" (click)="eliminaTappa(tappaIdx)" class="ras-record-map__add-step-label"><i
                        class="fas fa-minus-circle"></i>
                    {{'rightsidebar.deletecheckpoint'|translate}}</a>
            </div>


        </div>
    </ng-container>


</div>

<!-- Buttons -->
<div class="ras-action-wrapper">
    <div class="status">
        <label>{{'status.label' | translate }}:</label>
        <mat-form-field>
            <select matNativeControl required (change)="changeStatus($event)" [(ngModel)]="currentItem.stato">
            <option [value]="apiservice.STATO_DRAFT">{{'status.DRAFT' | translate }}</option>
            <option [value]="apiservice.STATO_VALIDAZIONE">{{'status.VALIDAZIONE' | translate }}</option>
            <option [value]="apiservice.STATO_PUBBLICATA" *ngIf="(isAdmin || currentItem.stato==apiservice.STATO_PUBBLICATA) && !isDanger()">{{'status.PUBBLICATA' | translate }}</option>
        </select>
        </mat-form-field>
    </div>
    <a class="ras-btn ras-btn-light ras-btn-icon ras-btn-undo ng-star-inserted" [routerLink]="['/search']"><i
            class="fal fa-times-circle"></i>{{'common.cancel'|translate}} </a>
    <a class="ras-btn" (click)="save(false)" [ngClass]="{'disabled': !(currentItem | validateIntense)}">{{'common.save'|translate}} </a>
    <a class="ras-btn" (click)="save(true)" [ngClass]="{'disabled': !(currentItem | validateIntense)}">{{'common.saveandclose'|translate}} </a>
</div>