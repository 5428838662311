import * as tslib_1 from "tslib";
import { Ost, PuntoPercorsoSuggerito, Percorso, Geometry } from 'src/app/model/model.interfaces';
import * as turf from '@turf/helpers';
import * as line_slice from '@turf/line-slice';
import * as line_intersect from '@turf/line-intersect';
import { UtilsService } from './utils.service';
import { config } from 'src/environments/config/config';
import * as i0 from "@angular/core";
import * as i1 from "./utils.service";
var PercorsoService = /** @class */ (function () {
    function PercorsoService(utils) {
        this.utils = utils;
    }
    PercorsoService.prototype.getNextPoints = function (puntoIniziale, osts, iniziale) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ostInPoint, nextPoints, isEstremo, _i, osts_1, ost, _a, ostInPoint_1, searchOst;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.ostInQuelPunto(osts, puntoIniziale, iniziale)];
                    case 1:
                        ostInPoint = _b.sent();
                        nextPoints = [];
                        isEstremo = true;
                        _i = 0, osts_1 = osts;
                        _b.label = 2;
                    case 2:
                        if (!(_i < osts_1.length)) return [3 /*break*/, 6];
                        ost = osts_1[_i];
                        if (!this.utils.isOstTratta(ost)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.findIncroci(ost, ostInPoint)];
                    case 3:
                        // if (puntoIniziale.ostProvenienza && ost.id !== puntoIniziale.ostProvenienza.id) {
                        // ricerca delle intersezioni degli ost con gli ost che passano per il punto
                        (_b.sent()).forEach(function (x) { return nextPoints.push(x); });
                        if (!(isEstremo && !iniziale)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.addStartingPointsOtherOst(ost, puntoIniziale)];
                    case 4:
                        (_b.sent()).forEach(function (x) { return nextPoints.push(x); });
                        _b.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 2];
                    case 6:
                        _a = 0, ostInPoint_1 = ostInPoint;
                        _b.label = 7;
                    case 7:
                        if (!(_a < ostInPoint_1.length)) return [3 /*break*/, 10];
                        searchOst = ostInPoint_1[_a];
                        return [4 /*yield*/, this.addStartingPointsSameOst(searchOst, puntoIniziale)];
                    case 8:
                        // aggiunta degli estremi degli ost che passano per il punto (e non sono il punto)
                        (_b.sent()).forEach(function (x) { return nextPoints.push(x); });
                        _b.label = 9;
                    case 9:
                        _a++;
                        return [3 /*break*/, 7];
                    case 10:
                        this.puliziaDoppioni(nextPoints, puntoIniziale);
                        console.debug('NEXTPOINTS', nextPoints);
                        return [2 /*return*/, nextPoints];
                }
            });
        });
    };
    PercorsoService.prototype.puliziaDoppioni = function (punti, puntoIniziale) {
        var _this = this;
        punti.forEach(function (punto) {
            if (!punto['toRemove']) {
                // punto iniziale
                if (puntoIniziale && puntoIniziale.punto && _this.utils.distance(punto.punto, puntoIniziale.punto) === 0) {
                    punto['toRemove'] = true;
                }
                else {
                    // stesso punto
                    punti.forEach(function (p) {
                        // if (this.utils.isSamePoint(p.punto, punto.punto) && !this.utils.compareGeneralObj(punto, p) && !p['toRemove'] && !punto['toRemove']) {
                        if (_this.utils.distance(p.punto, punto.punto) < config.DISTANZA_FILTRO_NODI_KM && !_this.utils.compareGeneralObj(punto, p) && !p['toRemove'] && !punto['toRemove']) {
                            // punto di inizio lontano sovrapposto a punto di inizio connesso
                            if (!p.ostProvenienza && punto.ostProvenienza) {
                                p['toRemove'] = true;
                                // if (!this.utils.compareGeneralObj(punto.ostConnessi, p.ostConnessi)) {
                                //   punto.ostConnessi = punto.ostConnessi.concat(p.ostConnessi);
                                // }
                            }
                            else {
                                // incroci sovrapposti
                                if (p.ostConnessi && p.ostConnessi.length // è un incrocio
                                    && punto.ostConnessi && punto.ostConnessi.length // è un incrocio
                                // && this.utils.compareGeneralObj(p.ostProvenienza, punto.ostProvenienza)
                                ) {
                                    p['toRemove'] = true;
                                    var _loop_1 = function (ost) {
                                        if (punto.ostConnessi.find(function (o) { return o.id === ost.id; })) {
                                            punto.ostConnessi.push(ost);
                                        }
                                    };
                                    for (var _i = 0, _a = p.ostConnessi; _i < _a.length; _i++) {
                                        var ost = _a[_i];
                                        _loop_1(ost);
                                    }
                                }
                                else {
                                    // punto di arrivo sopra punto di incrocio
                                    if (p.ostConnessi && p.ostConnessi.length // incrocio
                                        && (punto.ostProvenienza && (!punto.ostConnessi || punto.ostConnessi.length < 1)) // punto di arrivo/partenza
                                    ) {
                                        p['toRemove'] = true;
                                    }
                                }
                            }
                        }
                    });
                }
                // incroci vicini ??
            }
        });
        // rimozione effettiva
        for (var i = punti.length - 1; i >= 0; i--) {
            if (punti[i]['toRemove']) {
                punti.splice(i, 1);
            }
        }
    };
    PercorsoService.prototype.addStartingPointsSameOst = function (ost, punto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var nextPoints, lineOst, begin, end;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        nextPoints = [];
                        if (!ost) {
                            return [2 /*return*/, nextPoints];
                        }
                        return [4 /*yield*/, this.utils.getOstGeometry(ost)];
                    case 1:
                        lineOst = _a.sent();
                        begin = this.utils.getFirstCoords(lineOst);
                        if (!this.utils.isSamePoint(begin, punto.punto)) {
                            nextPoints.push({ punto: begin, ostProvenienza: ost, ostConnessi: [] });
                            // console.log('-ESTREMO DEL PERCORSO STESSO', begin);
                        }
                        end = this.utils.getLastCoords(lineOst);
                        if (!this.utils.isSamePoint(end, punto.punto)
                            || this.utils.isSamePoint(end, begin) // se è un percorso circolare con partenza/arrivo coincidenti si aggiungono entrambi
                        ) {
                            nextPoints.push({ punto: end, ostProvenienza: ost, ostConnessi: [] }); // TODO: deveno essere differenti in caso di anello
                            // console.log('-ESTREMO DEL PERCORSO STESSO', end);
                        }
                        return [2 /*return*/, nextPoints];
                }
            });
        });
    };
    PercorsoService.prototype.addStartingPointsOtherOst = function (ost, punto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lineOst, nextPoints, dist1, dist2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.utils.getOstGeometry(ost)];
                    case 1:
                        lineOst = _a.sent();
                        nextPoints = [];
                        dist1 = this.utils.distance(this.utils.getFirstCoords(lineOst), punto.punto);
                        if (dist1 < config.DISTANZA_TRA_ESTREMI_PROPOSTA_KM && dist1 !== 0) {
                            nextPoints.push({ punto: this.utils.getFirstCoords(lineOst), ostProvenienza: null, ostConnessi: [ost] });
                        }
                        dist2 = this.utils.distance(this.utils.getLastCoords(lineOst), punto.punto);
                        if (dist2 < config.DISTANZA_TRA_ESTREMI_PROPOSTA_KM && dist2 !== 0) {
                            nextPoints.push({ punto: this.utils.getLastCoords(lineOst), ostProvenienza: null, ostConnessi: [ost] });
                        }
                        return [2 /*return*/, nextPoints];
                }
            });
        });
    };
    PercorsoService.prototype.ostInQuelPunto = function (osts, punto, iniziale) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(punto.ostConnessi && punto.ostConnessi.length)) return [3 /*break*/, 1];
                        // is conoscono già gli ost collegati a quel punto, si aggiunge l'ost stesso
                        return [2 /*return*/, punto.ostConnessi.concat(punto.ostProvenienza)];
                    case 1:
                        if (!(!iniziale || (iniziale && !punto.ostProvenienza))) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.ostCalcolatiInQuelPunto(osts, punto)];
                    case 2: 
                    // si trovano tutti i tratti (escluso quello di provenienza) che passano da quel punto
                    return [2 /*return*/, _a.sent()];
                    case 3: 
                    // punto iniziale, si considera l'ost di cui il punto è la partenza
                    return [2 /*return*/, [punto.ostProvenienza]];
                }
            });
        });
    };
    PercorsoService.prototype.ostCalcolatiInQuelPunto = function (osts, punto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ostInPoint, _i, osts_2, ost, linea;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ostInPoint = [];
                        _i = 0, osts_2 = osts;
                        _a.label = 1;
                    case 1:
                        if (!(_i < osts_2.length)) return [3 /*break*/, 4];
                        ost = osts_2[_i];
                        if (!this.utils.isOstTratta(ost)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.utils.getOstGeometry(ost)];
                    case 2:
                        linea = _a.sent();
                        if (this.utils.isOnLine(punto.punto, linea)) {
                            ostInPoint.push(ost);
                        }
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, ostInPoint];
                }
            });
        });
    };
    PercorsoService.prototype.findIncroci = function (ost, osts) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var linea, res, _i, osts_3, searchOst, lineOstOfPoint, points, _a, _b, p;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.utils.getOstGeometry(ost)];
                    case 1:
                        linea = _c.sent();
                        res = [];
                        _i = 0, osts_3 = osts;
                        _c.label = 2;
                    case 2:
                        if (!(_i < osts_3.length)) return [3 /*break*/, 5];
                        searchOst = osts_3[_i];
                        if (!(ost && ost.id && searchOst && searchOst.id && ost.id !== searchOst.id)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.utils.getOstGeometry(searchOst)];
                    case 3:
                        lineOstOfPoint = _c.sent();
                        points = this.intersezione(linea, lineOstOfPoint);
                        for (_a = 0, _b = points.features; _a < _b.length; _a++) {
                            p = _b[_a];
                            if (p) {
                                // console.log('-INTERSEZIONE', p);
                                res.push({ punto: this.utils.featureToArray(p), ostProvenienza: searchOst, ostConnessi: [ost] });
                            }
                        }
                        _c.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/, res];
                }
            });
        });
    };
    PercorsoService.prototype.intersezione = function (linea1, linea2) {
        var _this = this;
        // ricerca delle intersezioni fra due tratti con filtraggio
        // ricerca di tutti i punti di intersezione
        var res = turf.featureCollection([]);
        if (linea1 && linea2) {
            var allIntersect = line_intersect.default(linea1, linea2);
            // se sono 2 o meno si prendo tutti
            if (allIntersect.features.length < 2) {
                res = allIntersect;
            }
            else {
                res.features.push(allIntersect.features[0]);
                var last = allIntersect.features[allIntersect.features.length - 1];
                var precedente_1 = allIntersect.features[0];
                // si esaminano tutti i punti e si scegono solo quelli che distano più della distanza minima dal precendete
                allIntersect.features.forEach(function (feature) {
                    if (_this.utils.distance(precedente_1, feature) > config.DISTANZA_FILTRO_INCROCI_KM) {
                        res.features.push(feature);
                    }
                    precedente_1 = feature;
                });
                // si inserisce comunque aggiungere l'ultimo
                if (res.features[res.features.length - 1] !== last) {
                    res.features.push(last);
                }
            }
            // punti di vicinanza:
            this.intersezionePerEstremi(linea1, linea2).features.forEach(function (feat) { return res.features.push(feat); });
        }
        return res;
    };
    PercorsoService.prototype.intersezionePerEstremi = function (linea1, linea2) {
        var res = turf.featureCollection([]);
        if (this.utils.calculateDistanceFromTratto(linea2, this.utils.getFirstCoords(linea1)) < config.DISTANZA_LIMITE_CONCATENABILI_KM) {
            res.features.push(this.utils.getFirstCoords(linea1));
        }
        if (this.utils.calculateDistanceFromTratto(linea2, this.utils.getLastCoords(linea1)) < config.DISTANZA_LIMITE_CONCATENABILI_KM) {
            res.features.push(this.utils.getLastCoords(linea1));
        }
        if (this.utils.calculateDistanceFromTratto(linea1, this.utils.getFirstCoords(linea2)) < config.DISTANZA_LIMITE_CONCATENABILI_KM) {
            res.features.push(this.utils.getFirstCoords(linea2));
        }
        if (this.utils.calculateDistanceFromTratto(linea1, this.utils.getLastCoords(linea2)) < config.DISTANZA_LIMITE_CONCATENABILI_KM) {
            res.features.push(this.utils.getLastCoords(linea2));
        }
        return res;
    };
    PercorsoService.prototype.removeOstdaPercorso = function (ost, percorso) {
        // rimuove ost e modifica il percorso eliminando tutte le tratta che dipendono da quell'ost e le successive
        var idx = percorso.tratti.findIndex(function (t) { return t.ost && t.ost.id === ost.id; });
        percorso.tratti.splice(idx);
        percorso.ultimoPunto = null;
        return percorso;
    };
    // inserimento nel percorso di un nuovo punto
    PercorsoService.prototype.PercorsoWithNewPoint = function (puntoscelto, percorsoAttuale) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var percorsofinora, tratto, isParziale, ultimopunto, startP, ultimopunto, partenza, arrivo, ost;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        percorsofinora = this.utils.clone(percorsoAttuale);
                        tratto = null;
                        isParziale = false;
                        if (!!puntoscelto.ostProvenienza) return [3 /*break*/, 1];
                        ultimopunto = this.ultimoPuntoPercorso(percorsofinora);
                        startP = this.utils.getCoords(ultimopunto.punto);
                        tratto = turf.lineString([
                            startP,
                            this.utils.getCoords(puntoscelto.punto)
                        ]);
                        return [3 /*break*/, 4];
                    case 1:
                        ultimopunto = this.ultimoPuntoPercorso(percorsofinora);
                        partenza = ultimopunto.punto;
                        arrivo = puntoscelto.punto;
                        ost = puntoscelto.ostProvenienza;
                        return [4 /*yield*/, this.utils.isEstremiOst(ost, partenza, arrivo)];
                    case 2:
                        isParziale = !(_a.sent());
                        return [4 /*yield*/, this.getSegmento(ost, partenza, arrivo, isParziale)];
                    case 3:
                        tratto = _a.sent();
                        _a.label = 4;
                    case 4:
                        percorsofinora.ultimoPunto = puntoscelto;
                        percorsofinora.tratti.push({ ost: puntoscelto.ostProvenienza, tratto: tratto, ultimoPunto: puntoscelto.punto, parziale: isParziale });
                        return [2 /*return*/, percorsofinora];
                }
            });
        });
    };
    PercorsoService.prototype.ultimoPuntoPercorso = function (percorsofinora) {
        if (!percorsofinora) {
            return null;
        }
        if (percorsofinora.ultimoPunto) {
            return percorsofinora.ultimoPunto;
        }
        if (percorsofinora.tratti && percorsofinora.tratti.length) {
            // ultimo tratto
            var lastTratto = percorsofinora.tratti[percorsofinora.tratti.length - 1];
            // console.log('-----------: PercorsoService -> constructor -> lastTratto', lastTratto);
            // ultimo punto del tratto
            // const lastPoint = this.getLastCoords(lastTratto.tratto);
            var lastPoint = lastTratto.ultimoPunto;
            if (lastPoint) {
                var point = turf.point([lastPoint[0], lastPoint[1]]);
                return { punto: point, ostProvenienza: lastTratto.ost };
            }
            else {
                console.log('ERRORE');
                return { punto: percorsofinora.inizio, ostProvenienza: lastTratto.ost };
            }
        }
        else {
            // console.log('ultimoPuntoPercorso (iniziale)', percorsofinora);
            return { punto: percorsofinora.inizio, ostProvenienza: percorsofinora.ostIniziale };
        }
    };
    PercorsoService.prototype.removePuntoPercorso = function (percorso) {
        // eliminazione ultimo punto percorso e ricalcolo dei punti successivi
        if (percorso.tratti.length) {
            percorso.tratti.pop();
        }
        else {
            percorso.inizio = null;
        }
        percorso.ultimoPunto = null;
        return percorso;
    };
    PercorsoService.prototype.removeIndexPercorso = function (percorso, index) {
        // eliminazione dei punti a partire da index
        if (percorso.tratti.length >= index) {
            percorso.tratti.splice(index);
        }
        else {
            percorso.inizio = null;
        }
        percorso.ultimoPunto = null;
        return percorso;
    };
    PercorsoService.prototype.concatenabili = function (percorso1, percorso2) {
        // controllo se la fine del percorso 1 è sufficientemente vicina all'inizio del percorso 2
        var fine1 = this.ultimoPuntoPercorso(percorso1).punto;
        var inizio2 = percorso2.inizio;
        var distanza = this.utils.distance(fine1, inizio2);
        return distanza < config.DISTANZA_LIMITE_CONCATENABILI_KM;
    };
    PercorsoService.prototype.slicePercorso = function (percorso, index) {
        var newPercorso = { tratti: [] };
        var lastTratto = percorso.tratti[index];
        newPercorso.inizio = lastTratto.ultimoPunto;
        newPercorso.ostIniziale = lastTratto.ost;
        newPercorso.ultimoPunto = percorso.ultimoPunto;
        // percorso.ultimoPunto = null; // lastTratto.ultimoPunto;
        newPercorso.tratti = percorso.tratti.slice(index + 1);
        this.removeIndexPercorso(percorso, index + 1);
        return newPercorso;
    };
    PercorsoService.prototype.concat = function (percorso1, percorso2) {
        var newPercorso = this.utils.clone(percorso1);
        for (var _i = 0, _a = percorso2.tratti; _i < _a.length; _i++) {
            var t2 = _a[_i];
            newPercorso.tratti.push(this.utils.clone(t2));
        }
        newPercorso.ultimoPunto = percorso2.ultimoPunto;
        return newPercorso;
    };
    PercorsoService.prototype.getOstsTratti = function (percorso) {
        var res = [];
        percorso.tratti.forEach(function (tratto) {
            if (tratto.ost) {
                res.push(tratto.ost);
            }
        });
        return res;
    };
    PercorsoService.prototype.getSegmento = function (ost, startPoint, endPoint, isPartial) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var linearetta, line;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        linearetta = turf.lineString([
                            this.utils.featureToArray(startPoint),
                            this.utils.featureToArray(endPoint)
                        ]);
                        if (!ost) {
                            return [2 /*return*/, linearetta];
                        }
                        return [4 /*yield*/, this.utils.getOstGeometry(ost)];
                    case 1:
                        line = _a.sent();
                        if (!isPartial || (!!ost && this.utils.isSamePoint(endPoint, startPoint))) {
                            return [2 /*return*/, line];
                        }
                        //////////// patch BRUTTA per non fare errore con MultiLineString, non dovrebbero esserci MultiLineString ///////////
                        if (line.type === 'MultiLineString') {
                            return [2 /*return*/, linearetta];
                            // return line;
                        }
                        //////////////////////////////////////////////////////////////////////////////////
                        return [2 /*return*/, line_slice.default(this.utils.getCoords(startPoint), this.utils.getCoords(endPoint), line)];
                }
            });
        });
    };
    PercorsoService.ngInjectableDef = i0.defineInjectable({ factory: function PercorsoService_Factory() { return new PercorsoService(i0.inject(i1.UtilsService)); }, token: PercorsoService, providedIn: "root" });
    return PercorsoService;
}());
export { PercorsoService };
