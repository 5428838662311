import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SearchIntenseActionTypes, SearchIntenseSuccess, DeleteIntenseSuccess, DeleteIntenseFail, SearchIntenseFail } from './search-intense.actions';
import { switchMap, map, withLatestFrom, debounceTime, catchError, } from 'rxjs/operators';
import { of } from 'rxjs';
import { SearchIntenseSelectors } from './search-intense.selectors';
import { SearchIntenseRequests } from './search-intense.request';
import { TranslateService } from '@ngx-translate/core';
import { DisplayModal } from 'src/app/logic/common';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "./search-intense.selectors";
import * as i3 from "./search-intense.request";
import * as i4 from "@ngx-translate/core";
var SearchIntenseEffects = /** @class */ (function () {
    function SearchIntenseEffects(actions$, searchSelectors, searchRequests, translate) {
        var _this = this;
        this.actions$ = actions$;
        this.searchSelectors = searchSelectors;
        this.searchRequests = searchRequests;
        this.translate = translate;
        this.translations = [];
        this.doSearch$ = this.actions$.pipe(ofType(SearchIntenseActionTypes.SearchIntense, SearchIntenseActionTypes.SetBoundingBox, SearchIntenseActionTypes.SetFilter, SearchIntenseActionTypes.SetQuery, SearchIntenseActionTypes.ClearFilters, SearchIntenseActionTypes.DeleteIntenseSuccess, SearchIntenseActionTypes.SetStatus, SearchIntenseActionTypes.SetInterruptStatus, SearchIntenseActionTypes.SetUid), map(function (action) { return action['payload']; }), debounceTime(400), withLatestFrom(this.searchSelectors.getSearchRequest$()), switchMap(function (_a) {
            var _ = _a[0], request = _a[1];
            // console.log('-----------: SearchIntenseEffects -> request', request);
            return _this.searchRequests.doSearch$(request).pipe(map(function (data) { return new SearchIntenseSuccess(data); }), catchError(function (error) { return of(new SearchIntenseFail(error)); }));
        }));
        this.deleteSuccess$ = this.actions$.pipe(ofType(SearchIntenseActionTypes.DeleteIntenseSuccess), map(function (data) {
            return new DisplayModal({
                type: 'confirm',
                data: {
                    message: _this.translations['action.deleteintensefinish']
                }
            });
        }));
        this.deleteIntense$ = this.actions$.pipe(ofType(SearchIntenseActionTypes.DeleteIntense), map(function (action) { return action['payload']; }), switchMap(function (action) {
            return _this.searchRequests.doDelete$(action).pipe(map(function (data) { return new DeleteIntenseSuccess(data); }), catchError(function (error) { console.log(error); return of(new DeleteIntenseFail(error)); }));
        }));
        setTimeout(function () {
            _this.translate.get(['action.deleteintensefinish']).subscribe(function (res) { return _this.translations = res; });
        }, 500);
    }
    SearchIntenseEffects.ngInjectableDef = i0.defineInjectable({ factory: function SearchIntenseEffects_Factory() { return new SearchIntenseEffects(i0.inject(i1.Actions), i0.inject(i2.SearchIntenseSelectors), i0.inject(i3.SearchIntenseRequests), i0.inject(i4.TranslateService)); }, token: SearchIntenseEffects, providedIn: "root" });
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchIntenseEffects.prototype, "doSearch$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchIntenseEffects.prototype, "deleteSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchIntenseEffects.prototype, "deleteIntense$", void 0);
    return SearchIntenseEffects;
}());
export { SearchIntenseEffects };
