import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class CacheService {

    private cacheArray = [];

    private MAX_SIZE = 400;

    constructor() { }

    get(key: string): any {

        const foundIdx = this.cacheArray.findIndex(o => o.key === key);
        if (foundIdx >= 0) {
            const value = this.cacheArray[foundIdx].value;
            // rinserimento in testa delle entry appena richieste
            this.cacheArray.splice(foundIdx, 1);
            this.cacheArray.push({ key: key, value: value });
            return value;
        }

    }

    set(key: string, value: any) {
        //  caching circolare in cui gli elementi più vecchi vengono eliminati.

        if (this.cacheArray.length > this.MAX_SIZE) {
            this.cacheArray.shift();
        }
        this.cacheArray.push({ key: key, value: value });


    }

    invalidate(key: string) {
        const foundIdx = this.cacheArray.findIndex(o => o.key === key);
        if (foundIdx >= 0) {
            this.cacheArray.splice(foundIdx, 1);
        }
    }

}
