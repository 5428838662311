import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import * as $ from 'jquery';
import { Store } from '@ngrx/store';
import { SearchIntense, SetQuery, SelectIntense, SetFilter, DeleteIntense, ClearFilters, SetUid, SetStatus, SetInterruptStatus } from 'src/app/logic/search-intense';
import { SearchIntenseSelectors } from 'src/app/logic/search-intense/search-intense.selectors';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SetCurrentIntense } from 'src/app/logic/search-ost';
import { Intense } from 'src/app/model/model.interfaces';
import { SearchIntenseService } from './search-intense.service';
import { ModalService } from 'src/app/services/modal.service';
import { EditIntenseService } from '../edit-intense/edit-intense.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from 'src/app/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { AppDataSelectors } from 'src/app/logic/app-data/app-data.selectors';
import { AsrAuthGuard } from 'src/app/services/auth.service';
import { config } from 'src/environments/config/config';

@Component({
    selector: 'app-search-intense',
    templateUrl: './search-intense.component.html',
    queries: {
        'resultsScrollList': new ViewChild('resultsScrollList')
    }
})
export class SearchIntenseComponent implements OnInit, OnDestroy {

    results$;
    numResults$;
    filters$;
    activeFilters;
    selectedResult$;
    activeSearch;
    // searchControl = new FormControl();
    destroy$: Subject<boolean> = new Subject<boolean>();
    boundingBox = null;
    openSelect;
    translations = [];
    filterUser = false;
    filterStatus: string = null;
    filterInterruptStatus = null;
    userId;

    statuses = [];
    interruptstatuses = [];

    public showLeafletMap = config.FORCE_LEAFLET_PREVIEW;

    public resultsScrollList!: ElementRef;

    constructor(
        private store: Store<any>,
        private router: Router,
        private search: SearchIntenseSelectors,
        private editService: EditIntenseService,
        private searchService: SearchIntenseService,
        private modalService: ModalService,
        private utils: UtilsService,
        private apiService: ApiService,
        private translate: TranslateService,
        private appdata: AppDataSelectors,
        private auth: AsrAuthGuard
    ) {
        // se non c'è stato il caricamento iniziale si fa redirect al login
        appdata.dataReady$().subscribe(ready => {
            if (!ready) {
                router.navigate(['login']);
            }
        });

        this.auth.getUserId().then(id => this.userId = id);

        translate.get(['action.duplicateintense', 'action.deleteintense']).subscribe(res => this.translations = res);

        this.statuses = [
            { label: 'status.ALL', value: null },
            { label: 'status.DRAFT', value: this.apiService.STATO_DRAFT },
            { label: 'status.VALIDAZIONE', value: this.apiService.STATO_VALIDAZIONE },
            { label: 'status.PUBBLICATA', value: this.apiService.STATO_PUBBLICATA },
        ];

        this.interruptstatuses = [
            { label: 'interruptstatus.ALL', value: null },
            { label: 'interruptstatus.INTERRUPT', value: this.apiService.STATUS_INTERRUPT },
            { label: 'interruptstatus.VALID', value: this.apiService.STATUS_VALID },
        ];
    }

    ngOnInit(): void {
        // console.log('start search 2');
        this.store.dispatch(new SearchIntense({ query: '', activeFilters: [], size: 50, uid: null, stato: null, status: null }));
        this.results$ = this.search.getResults$();
        this.filters$ = this.search.getFilters$();
        this.search.getActiveFilters$().pipe(takeUntil(this.destroy$)).
            subscribe((data) => {
                this.activeFilters = this.utils.clone(data);
            });
        // this.searchControl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe(value => {
        //     this.store.dispatch(new SetQuery(value));
        // });
        this.search.getQuery$().pipe(takeUntil(this.destroy$)).subscribe(x => {
            this.activeSearch = x;
        });

        this.search.getUidFilter$().pipe(takeUntil(this.destroy$)).subscribe(x => {
            this.filterUser = !!x;
        });

        this.search.getStatusFilter$().pipe(takeUntil(this.destroy$)).subscribe(x => {
            this.filterStatus = x;
        });

        this.search.getNumResults$().pipe(takeUntil(this.destroy$)).subscribe(x => {
            // Scroll in cima ai risultati quando i risultati cambiano
            if (this.resultsScrollList && this.resultsScrollList.nativeElement) {
                this.resultsScrollList.nativeElement.scrollTo(0, 0);
            }
        });
        this.numResults$ = this.search.getNumResults$();
        this.selectedResult$ = this.search.getSelectedResult$();
        $(document).ready(function () {

            const rasFormSelect = $('.ras-form-item--select');

            // Dashboard filters toggler
            $('#ras-filter-toggler').on('click', function (e) {
                e.preventDefault();
                $(this).toggleClass('is-active');
                $('.ras-dashboard-filters__row-others').toggleClass('is-open');
                $('.ras-dashboard-filters__action-delete').toggleClass('is-open');
            });

            // Selects dropdown
            // rasFormSelect.on('click', function (e) {
            //     rasFormSelect.not($(this)).removeClass('is-open');
            //     $(this).toggleClass('is-open');
            // });

            /* Open dashboard filters on mobile */

            $('#filters-opener').on('click', function (e) {
                e.preventDefault();
                $('.ras-dashboard-filters__wrapper').addClass('is-open');
            });

            $('#filters-closer').on('click', function (e) {
                e.preventDefault();
                $('.ras-dashboard-filters__wrapper').removeClass('is-open');
            });

            /* Close elements on outside click */
            $('body').on('click', function (e) {
                if (!$(e.target).closest('.ras-form-item--select').length && !$(e.target).is('.ras-form-item--select')) {
                    rasFormSelect.removeClass('is-open');
                }
            });
        });
    }

    selectItem(intense: Intense) {
        // let bounds = L.geoJson(wellknown.parse( intense.geometry.value)).getBounds();
        // this.boundingBox = bounds;
        this.store.dispatch(new SelectIntense(intense.id));
    }

    // hasMorfologia(scheda) {
    //     return scheda.distanza || scheda.dislivelloPositivo || scheda.dislivelloNegativo || scheda.dislivelloNegativo || scheda.altezzaMassima || scheda.altezzaMinima || scheda.altezzaStart || scheda.altezzaEnd;
    // }

    selectAllFilters(filter) {
    }

    goToEdit(item, detail) {
        this.editService.toggleDetails$(detail);
        this.editService.hideFilters$();
        // trasformazione dell'intense  per completarlo
        this.apiService.completeIntense(item).then(completeIntense => {
            this.openEditPage(completeIntense);
        });
    }

    goToNew() {
        this.openEditPage(null);
    }

    openEditPage(item) {
        this.store.dispatch(new SetCurrentIntense(item));
        this.store.dispatch(new SelectIntense(null));
        this.router.navigate(['edit']);

    }

    toggleFilter(filter) {
        if (this.openSelect === filter.name) {
            this.openSelect = null;
        } else {
            this.openSelect = filter.name;
        }
    }

    filtersPresent() {
        return !!this.activeFilters.length || !!this.activeSearch || !!this.filterStatus || !!this.filterUser;
    }


    clearFilter() {
        this.store.dispatch(new ClearFilters({}));

        // // pulizia facets
        // this.store.dispatch(new SetFilter([]));

        // // pulizia searchbox
        // this.searchControl.reset();
        this.store.dispatch(new SetQuery(''));
    }

    public setUserFilter(filter = null) {
        this.filterUser = filter;
        // this.openSelect = null;
        this.toggleFilter({ name: 'uid' });
        this.store.dispatch(new SetUid(filter ? this.userId : null));
    }

    public setStatusFilter(filter = null) {
        // console.log('-------: SearchIntenseComponent -> setStatusFilter -> filter', filter);
        this.filterStatus = filter;
        this.store.dispatch(new SetStatus(this.filterStatus));
        this.toggleFilter({ name: 'stato' });
    }

    public setStatusInterruptFilter(filter = null) {
        console.log('-------: SearchIntenseComponent -> setStatusFilter -> filter', filter);
        this.filterInterruptStatus = filter;
        this.store.dispatch(new SetInterruptStatus(this.filterInterruptStatus));
        this.toggleFilter({ name: 'status' });
    }

    public getFilterStatusLabel(status) {
        const state = this.statuses.find(x => x.value === status);
        if (state) {
            return state.label;
        }
    }

    setFilter(filter, option) {
        const index = this.filterIndex(filter);
        if (index === -1) {
            const newFilter = { name: filter, values: [option] };
            this.activeFilters.push(newFilter);
        } else {
            const currentFilter = this.activeFilters[index];
            const optionIndex = this.optionIndex(currentFilter, option);
            if (optionIndex === -1) {
                if (currentFilter.values) {
                } else {
                    currentFilter.values = [];
                }
                currentFilter.values.push(option);
            } else {
                currentFilter.values.splice(optionIndex, 1);
                if (currentFilter.values.length === 0) {
                    this.activeFilters.splice(index, 1);
                }
            }
        }
        this.store.dispatch(new SetFilter(this.activeFilters));
    }

    private filterIndex(filterName: string) {
        let index = -1;
        for (let i = 0; i < this.activeFilters.length; i++) {
            const e = this.activeFilters[i];
            if (e.name === filterName) {
                index = i;
                break;
            }
        }
        return index;
    }

    private optionIndex(filter, value) {
        if (!filter.values) {
            return -1;
        }
        return filter.values.indexOf(value);
    }

    isChecked(filter, option) {
        const filterIndex = this.filterIndex(filter.name);
        if (filterIndex === -1) {
            return false;
        }
        const currentFilter = this.activeFilters[filterIndex];
        const optionIndex = this.optionIndex(currentFilter, option.key);
        return optionIndex > -1;
    }

    delete(scheda) {
        const data = {
            message: this.translations['action.deleteintense'] + scheda.name + '?',
            confirmAction: new DeleteIntense({ id: scheda.id })
        };
        this.modalService.openDialog('delete', data);
    }

    duplicate(scheda) {
        const data = {
            message: this.translations['action.duplicateintense'] + scheda.name + '?',
            confirmFunction: () => {
                const cloned = this.cloneIntense(scheda);
                console.log('-----------: SearchIntenseComponent -> duplicate -> cloned', cloned);
                console.log('-----------: SearchIntenseComponent -> duplicate -> geojson', cloned.geojson.value);
                this.editService.toggleDetails$(true);
                // this.store.dispatch(new SetCurrentIntense(cloned));
                // this.router.navigate(['edit']);

                this.apiService.completeIntense(cloned).then(completeIntense => {
                    this.store.dispatch(new SetCurrentIntense(completeIntense));
                    this.router.navigate(['edit']);
                });
            }
        };
        this.modalService.openDialog('confirm', data);

    }

    private cloneIntense(scheda) {
        const clone: Intense = this.utils.clone(scheda);
        clone.id = null;
        clone.name += ' - copia';
        return clone;
    }

    isDanger(scheda) {
        return this.utils.isIntenseInterrupted(scheda);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    statoString(stato) {
        switch (stato) {
            case this.apiService.STATO_DRAFT: return 'status.DRAFT';
            case this.apiService.STATO_PUBBLICATA: return 'status.PUBBLICATA';
            case this.apiService.STATO_VALIDAZIONE: return 'status.VALIDAZIONE';
        }

    }

}
