import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchIntenseComponent } from './views/search-intentse/search-intense.component';
import { NewIntenseComponent } from './views/new-intense/new-intense.component';
import { EditIntenseComponent } from './views/edit-intense/edit-intense.component';
import { LoginIntenseComponent } from './views/login-intense/login-intense.component';
import { AsrAuthGuard } from './services/auth.service';

const routes: Routes = [
  {
    path: 'search',
    component: SearchIntenseComponent,
    canActivate: [AsrAuthGuard]
  },
  {
    path: 'new',
    component: NewIntenseComponent,
    canActivate: [AsrAuthGuard],
  },
  {
    path: 'edit',
    component: EditIntenseComponent,
    canActivate: [AsrAuthGuard],
  },
  {
    path: 'add-ost',
    component: EditIntenseComponent,
    canActivate: [AsrAuthGuard],
  },
  {
    path: 'search-ost',
    component: EditIntenseComponent,
    canActivate: [AsrAuthGuard]
  },
  {
    path: 'login',
    component: LoginIntenseComponent,
  },
  {
    path: 'sso_return',
    component: LoginIntenseComponent,
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
    canActivate: [AsrAuthGuard]
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})

export class AppRoutingModule { }
