import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Ost, Intense } from 'src/app/model/model.interfaces';
import 'leaflet-clicktolerance';

export const CURRENT_COLOR = 'orange';
export const NEAR_COLOR = 'red';
export const DISTANT_COLOR = 'green';

@Injectable()
export class EditIntenseService {

    private destroy$: Subject<boolean> = new Subject<boolean>();
    private showDetails: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private showFilters: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private data: Ost[] = [];
    constructor(
    ) {
        // this.appDataSelectors.getOst$().subscribe(val => this.data = val);
    }

    public showDetails$(): Observable<boolean> {
        return this.showDetails;
    }

    public toggleDetails$(value): void {
        if (value !== this.showDetails.value) {
            this.showDetails.next(!this.showDetails.value);
        }
    }

    public showFilters$(): Observable<boolean> {
        return this.showFilters;
    }

    public toggleFilters$(): void {
        this.showFilters.next(!this.showFilters.value);
    }
    public hideFilters$(): void {
        this.showFilters.next(false);
    }

}
