import { Component, OnInit, Injector } from '@angular/core';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { createCustomElement } from '@angular/elements';
import { AddPoiPopupComponent, AddTrattoPopupComponent, SelectPoiPopupComponent } from './components/popups';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SetLocale } from './logic/common';
import { config } from 'src/environments/config/config';
import * as moment from 'moment';

// import { FetchAllSchede, FetchAllOst, FetchAllRegioni } from './logic/app-data';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'intense-sin2-frontend';
  lang = 'it';

  constructor(
    private oauthService: OAuthService,
    private http: HttpClient,
    private store: Store<any>,
    injector: Injector,
    translate: TranslateService) {
    // Convert `PopupComponent` to a custom element.
    const addPoiPopup = createCustomElement(AddPoiPopupComponent, { injector });
    const addTrattoPopup = createCustomElement(AddTrattoPopupComponent, { injector });
    const selectPoiPopup = createCustomElement(SelectPoiPopupComponent, { injector });
    // Register the custom element with the browser.
    customElements.define('app-add-poi-popup', addPoiPopup);
    customElements.define('app-add-tratto-popup', addTrattoPopup);
    customElements.define('app-select-poi-popup', selectPoiPopup);
    this.oauthService.clientId = environment.oauth2.clientId;
    this.oauthService.scope = environment.oauth2.scope;
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.dummyClientSecret = environment.oauth2.clientSecret;
    this.oauthService.tokenEndpoint = environment.oauth2.tokenUrl;
    this.oauthService.logoutUrl = environment.oauth2.logoutUrl;
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.requireHttps = false;
    this.oauthService.setupAutomaticSilentRefresh();

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(config.LANGUAGES[0]);

    this.lang = navigator.language || navigator['userLanguage'];
    if (this.lang) { this.lang = this.lang.slice(0, 2); }
    if (!config.LANGUAGES.includes(this.lang)) {
      this.lang = config.LANGUAGES[0];
    }
    translate.use(this.lang);
    moment.locale(this.lang);
  }


  ngOnInit() {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    this.http.get(environment.sessionUrl, { headers, responseType: 'text' }).subscribe((data) =>
      localStorage.setItem('token', data)
    );
    this.store.dispatch(new SetLocale(this.lang));
    // this.store.dispatch(new SetLocale('it')); // TODO: gestione della lingua dei contenuti
  }
}
