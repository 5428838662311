import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { State } from './app-data.interface';
import { of } from 'zen-observable';
@Injectable({
    providedIn: 'root'
})
export class AppDataSelectors {

    private appData$: Observable<State>;
    constructor(private store: Store<any>) {
        this.appData$ = this.store.pipe(select((state: any) => state.app_data));
    }

    schedeReady$() {
        return this.appData$.pipe(
            map(state => state.schedeReady)
        );
    }

    ostReady$() {
        return this.appData$.pipe(
            map(state => state.ostReady)
        );
    }

    regionReady$() {
        return this.appData$.pipe(
            map(state => state.regionReady)
        );
    }

    dataReady$() {
        return this.appData$.pipe(
            map(state => state.regionReady && state.schedeReady)
            // TODO valutare se riattivare attesa del download di tutti gli ost
            // map(state => state.ostReady && state.regionReady && state.schedeReady)
        );
    }

    schedeAttempts$() {
        return this.appData$.pipe(
            map(state => state.fetchSchedeNumAttempts)
        );
    }

    regionAttempts$() {
        return this.appData$.pipe(
            map(state => state.fetchRegioniNumAttempts)
        );
    }

    ostAttempts$() {
        return this.appData$.pipe(
            map(state => state.fetchOstNumAttempts)
        );
    }

    regionArray$() {
        return this.appData$.pipe(
            map(state => state.regionArray)
        );
    }

    periodsArray$() {
        return this.appData$.pipe(
            map(state => state.periodsArray)
        );
    }
}
