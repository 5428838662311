import { Component, OnInit, ViewEncapsulation, AfterViewInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import {  Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SearchOst } from 'src/app/logic/search-ost';
import { SearchOstSelectors } from 'src/app/logic/search-ost/search-ost.selectors';
import { EditIntenseService } from './edit-intense.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as $ from 'jquery';

@Component({
    selector: 'app-edit-intense',
    templateUrl: './edit-intense.component.html'
})
export class EditIntenseComponent implements OnInit, OnDestroy {

    showDetails;
    showFilters;
    searchControl = new FormControl();
    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private editService: EditIntenseService,
        private store: Store<any>,
        private searchOstSelectors: SearchOstSelectors,
        private router: Router) {
    }

    ngOnInit() {
        this.editService.showDetails$().pipe(takeUntil(this.destroy$)).subscribe( value => {
            this.showDetails = value;
        });
        this.editService.showFilters$().pipe(takeUntil(this.destroy$)).subscribe( value => {
            this.showFilters = value;
        });
        this.store.dispatch(new SearchOst({ size: 99, page: 0 }));
        this.searchOstSelectors.getCurrentIntense$().pipe(takeUntil(this.destroy$)).subscribe((item) => {
            if (item === null) {
                console.log("------->>>> EditIntenseComponent -> ngOnInit -> search")
                this.router.navigate(['search']);
            }
        });
        $(document).ready(function () {
            $('.ras-btn-action--inmap').on('click', function (e) {
                e.preventDefault();
                const icon = $(this).find('i');
                $('.ras-record').toggleClass('has-filters-collapsed');

                if (icon.hasClass('fa-chevron-down')) {
                    icon.removeClass('fa-chevron-down').addClass('fa-chevron-up');
                } else if (icon.hasClass('fa-chevron-up')) {
                    icon.removeClass('fa-chevron-up').addClass('fa-chevron-down');
                }

            });
        });
        // $(document).ready(function () {
        //     $('#collapse-filters').on('click',function (e) {
        //         e.preventDefault();
        //         $(this).toggleClass('is-active');
        //         $('.ras-record').toggleClass('has-filters-collapsed');

        //         $('.ras-record-filters').toggleClass('is-open');
        //     });

        //     $('#record-filters-closer').on('click',function (e) {
        //         e.preventDefault();
        //         $('.ras-record-filters').removeClass('is-open');
        //     });
        // });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    // ngAfterViewChecked(): void {
    //     this.openDialog();
    // }
}
