import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { SearchOstSelectors } from 'src/app/logic/search-ost/search-ost.selectors';
import { Tappa, Intense, Periodo } from 'src/app/model/model.interfaces';
import { SetCurrentIntense } from 'src/app/logic/search-ost';
import { EditIntenseService } from '../edit-intense/edit-intense.service';
import { UtilsService } from 'src/app/services/utils.service';
import { config } from 'src/environments/config/config';
import { AppDataSelectors } from 'src/app/logic/app-data/app-data.selectors';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';


@Component({
    selector: 'app-new-intense,',
    templateUrl: './new-intense.component.html',
    encapsulation: ViewEncapsulation.None
})
export class NewIntenseComponent implements OnInit, OnDestroy {

    formGroup: FormGroup;
    title: FormControl;
    description: FormControl;
    radioPeriodo: FormControl;
    periodoFruizione: any[] = null;
    periods = [];
    edit: boolean;
    private currentItem;
    destroy$: Subject<boolean> = new Subject<boolean>();
    quillModules = config.QUILL_MODULES;
    constructor(private router: Router,
        private editService: EditIntenseService,
        private searchOstSelecctors: SearchOstSelectors,
        private store: Store<any>,
        private dataSelectors: AppDataSelectors,
        private utils: UtilsService,
        private api: ApiService) { }
    ngOnInit(): void {
        this.title = new FormControl('', [Validators.required]);
        this.description = new FormControl('');
        this.radioPeriodo = new FormControl('all-year');
        // this.dateFrom = new FormControl('', [Validators.required]);
        // this.dateFrom.disable();
        // this.dateTo = new FormControl('', [Validators.required]);
        // this.dateTo.disable();
        this.formGroup = new FormGroup({
            title: this.title,
            description: this.description,
            radio: this.radioPeriodo
        });
        this.edit = false;
        this.radioPeriodo.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((item) => {
            if (item === 'all-year') {
                // this.dateFrom.disable();
                // this.dateFrom.setValue(null);
                // this.dateTo.disable();
                // this.dateTo.setValue(null);
            } else {
                // this.dateFrom.enable();
                // this.dateTo.enable();
            }
        });
        this.searchOstSelecctors.getCurrentIntense$().pipe(takeUntil(this.destroy$)).subscribe((item) => {
            if (!item) {
                return;
            }
            this.title.setValue(item.name);
            this.description.setValue(item.descrizione);
            // if (item.periodoStart && item.periodoEnd) {
            //     this.dateFrom.setValue(item.periodoStart);
            //     this.dateTo.setValue(item.periodoEnd);
            //     this.radioPeriodo.setValue('period');
            // }
            if (item.periodoFruizione && item.periodoFruizione.length) {
                this.periodoFruizione = [];
                this.radioPeriodo.setValue('period');
                item.periodoFruizione.forEach(per => {
                    const p = this.utils.clone(per);
                    p.holiday_label = p.holiday_label ? p.holiday_label : '0';
                    p['is_not_precise'] = !p.is_precise;
                    if (p.is_precise) {
                        p.fruizione_from = p.fruizione_from_precise;
                        p.fruizione_to = p.fruizione_to_precise;
                    }
                    p.fruizione_from = moment(p.fruizione_from);
                    p.fruizione_to = moment(p.fruizione_to);
                    this.periodoFruizione.push(p);
                });
            }
            this.edit = true;
            this.currentItem = this.utils.clone(item);
        });
        this.dataSelectors.periodsArray$().pipe(takeUntil(this.destroy$)).subscribe(val => { this.periods = val; });
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    goToEdit() {
        const tappe: Tappa[] = [];
        const newPercorso = { tratti: [] };

        // if (this.periodoFruizione) {
        //     this.periodoFruizione.forEach(x => {
        //         if (!x.is_precise) {
        //             x.fruizione_from = x.fruizione_from_precise; // TODO solo gg/MM
        //             x.fruizione_to = x.fruizione_to_precise; // TODO solo gg/MM
        //             delete x.fruizione_from;
        //             delete x.fruizione_to;
        //         }
        //     });
        // }

        if (!this.edit) {
            const new_item: Intense = {
                name: this.title.value,
                descrizione: this.description.value,
                periodoFruizione: this.periodoFruizione,
                currentTappa: 0,
                tappe: [{ osts: [] }],
                stato: this.api.STATO_DRAFT
            };
            this.editService.toggleDetails$(false);
            this.store.dispatch(new SetCurrentIntense(new_item));
        } else {
            this.currentItem.name = this.title.value;
            this.currentItem.descrizione = this.description.value;
            this.currentItem.periodoFruizione = this.periodoFruizione;
            this.editService.toggleDetails$(true);
            this.store.dispatch(new SetCurrentIntense(this.currentItem));

        }
        this.router.navigate(['edit']);
    }

    addPeriod(add, initial = false) {
        if (add) {
            if (!this.periodoFruizione) {
                this.periodoFruizione = [];
            }
            if (this.periodoFruizione.length === 0 || !initial) {
                this.periodoFruizione.push({
                    holiday_label: '0',
                    is_precise: false,
                    is_not_precise: true,
                    fruizione_from: '',
                    fruizione_to: '',
                });
            }

        } else {
            this.periodoFruizione = null;
        }
    }

    removePeriod(idx) {
        this.periodoFruizione.splice(idx, 1);
        if (this.periodoFruizione.length === 0) {
            this.radioPeriodo.setValue('all-year');
        }
    }

    changePrecise($event, period) {
        period.is_precise = !$event.target.checked;
    }

    isValidPeriod(period: Periodo): boolean {
        if (period.holiday_label !== '0') {
            return true;
        }
        if (!period.is_precise && !!period.fruizione_from && !!period.fruizione_to) {
            return true;
        }
        return moment(period.fruizione_from).isBefore(period.fruizione_to);
    }

    isValidAllPeriods() {
        let res = true;
        if (this.periodoFruizione) {
            this.periodoFruizione.forEach(period => {
                res = res && this.isValidPeriod(period);
            });
        }
        return res;
    }

}
