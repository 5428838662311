/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./map-preview.component";
import * as i2 from "../../services/utils.service";
var styles_MapPreviewComponent = [];
var RenderType_MapPreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapPreviewComponent, data: {} });
export { RenderType_MapPreviewComponent as RenderType_MapPreviewComponent };
export function View_MapPreviewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.divId, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_MapPreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-preview-map", [], null, null, null, View_MapPreviewComponent_0, RenderType_MapPreviewComponent)), i0.ɵdid(1, 4440064, null, 0, i1.MapPreviewComponent, [i2.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapPreviewComponentNgFactory = i0.ɵccf("app-preview-map, [app-preview-map]", i1.MapPreviewComponent, View_MapPreviewComponent_Host_0, { geometry: "geometry", id: "id" }, {}, []);
export { MapPreviewComponentNgFactory as MapPreviewComponentNgFactory };
