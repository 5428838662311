import { MatDialog } from '@angular/material';
import { ConfirmModalComponent, EditOstModalComponent, AddOstModalComponent, DeleteModalComponent, EditTappaModalComponent, EditIntenseFileModalComponent, TranslateModalComponent } from '../components/modals';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var ModalService = /** @class */ (function () {
    function ModalService(dialog) {
        this.dialog = dialog;
    }
    ModalService.prototype.openDialog = function (type, data) {
        var component;
        switch (type) {
            case 'confirm':
                component = ConfirmModalComponent;
                break;
            case 'editOst':
                component = EditOstModalComponent;
                break;
            case 'editIntenseFile':
                component = EditIntenseFileModalComponent;
                break;
            case 'editTappa':
                component = EditTappaModalComponent;
                break;
            case 'add':
                component = AddOstModalComponent;
                break;
            case 'delete':
                component = DeleteModalComponent;
                break;
            case 'translate':
                component = TranslateModalComponent;
                break;
            default:
                break;
        }
        var dialogRef = this.dialog.open(component, {
            width: type === 'translate' ? '94%' : '1024px',
            height: type === 'translate' ? '94%' : 'auto',
            hasBackdrop: true,
            data: data
        });
        dialogRef.afterClosed().subscribe(function (result) {
        });
    };
    ModalService.prototype.closeDialog = function () {
        this.dialog.closeAll();
    };
    ModalService.ngInjectableDef = i0.defineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.inject(i1.MatDialog)); }, token: ModalService, providedIn: "root" });
    return ModalService;
}());
export { ModalService };
