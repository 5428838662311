import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { SetCurrentIntense } from 'src/app/logic/search-ost';
import { FeatureNotReady } from 'src/app/logic/common';
import { AsrAuthGuard } from 'src/app/services/auth.service';
import { FormControl } from '@angular/forms';
import { SearchIntenseSelectors } from 'src/app/logic/search-intense/search-intense.selectors';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { SetQuery, SelectIntense } from 'src/app/logic/search-intense/search-intense.actions';
import { EditIntenseService } from 'src/app/views/edit-intense/edit-intense.service';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header,[app-header]',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {

  newPage: boolean;

  public userImg = null;
  results$;
  filters$;
  searchControl = new FormControl();
  activeFilters;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showSearchBox = false;
  constructor(
    private router: Router,
    private store: Store<any>,
    private authService: AsrAuthGuard,
    private search: SearchIntenseSelectors,
    private utils: UtilsService,
    private editService: EditIntenseService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.url[0].path === 'search') {
      this.showSearchBox = true;
    }
    this.newPage = this.router.url === '/new';
    this.userImg = this.authService.getProfileImg();

    this.results$ = this.search.getResults$();
    this.filters$ = this.search.getFilters$();
    this.search.getActiveFilters$().pipe(takeUntil(this.destroy$)).
      subscribe((data) => {
        this.activeFilters = this.utils.clone(data);
      });
    this.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.store.dispatch(new SetQuery(value));
    });
    this.search.getQuery$().pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (x !== this.searchControl.value) { this.searchControl.setValue(x); }
    });
  }

  createNew() {
    this.store.dispatch(new SetCurrentIntense(null));
    this.store.dispatch(new SelectIntense(null));
    this.router.navigate(['new']);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  link(type) {
    if (type === 'help' && environment.helpUrl) {
      window.open(environment.helpUrl, '_blank');
    } else if (type === 'support' && environment.supportUrl) {
      window.open(environment.supportUrl, '_blank');
    } else if (type === 'forum' && environment.forumUrl) {
      window.open(environment.forumUrl, '_blank');
    } else if (type === 'howto_search' && environment.howtoSearchUrl) {
      window.open(environment.howtoSearchUrl, '_blank');
    } else if (type === 'howto_edit' && environment.howtoEditUrl) {
      window.open(environment.howtoEditUrl, '_blank');
    } else if (type === 'howto_publish' && environment.howtoPublishUrl) {
      window.open(environment.howtoPublishUrl, '_blank');
    } else if (type === 'howto_translate' && environment.howtoTranslateUrl) {
      window.open(environment.howtoTranslateUrl, '_blank');
    }
  }

  notificationClick() {
    this.store.dispatch(new FeatureNotReady());
  }


  goToEdit(item, detail) {
    this.editService.toggleDetails$(detail);
    this.editService.hideFilters$();
    // trasformazione dell'intense  per completarlo
    this.apiService.completeIntense(item).then(completeIntense => {
      this.store.dispatch(new SetCurrentIntense(completeIntense));
      this.router.navigate(['edit']);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
