/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-gallery/lightbox";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/forms";
import * as i5 from "./edit-intense-file-modal.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@ngrx/store";
import * as i8 from "../../../services/utils.service";
import * as i9 from "@ngx-gallery/core";
var styles_EditIntenseFileModalComponent = [];
var RenderType_EditIntenseFileModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditIntenseFileModalComponent, data: {} });
export { RenderType_EditIntenseFileModalComponent as RenderType_EditIntenseFileModalComponent };
function View_EditIntenseFileModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "img", [], [[8, "src", 4]], null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.ɵb, [i1.Lightbox, i0.ElementRef, i0.Renderer2], { index: [0, "index"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.index; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.rawfile ? _v.parent.context.$implicit.rawfile : _v.parent.context.$implicit.url); _ck(_v, 0, 0, currVal_0); }); }
function View_EditIntenseFileModalComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-file-pdf"]], null, null, null, null, null))], null, null); }
function View_EditIntenseFileModalComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-file"]], null, null, null, null, null))], null, null); }
function View_EditIntenseFileModalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "ras-intense-file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditIntenseFileModalComponent_4)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditIntenseFileModalComponent_5)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "ras-intense-filename"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isPdf(_v.parent.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isPdf(_v.parent.context.$implicit); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.name; _ck(_v, 6, 0, currVal_2); }); }
function View_EditIntenseFileModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "ras-image-thumb ras-file-thumb"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditIntenseFileModalComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditIntenseFileModalComponent_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "ras-image-toolbox "]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "ras-image-tool"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["class", "fas fa-download"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "ras-image-tool"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "i", [["class", "fas fa-times-circle"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "image"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.type === "file"); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_EditIntenseFileModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 27, "div", [["class", "ras-modal"], ["mat-dialog-content", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 26, "div", [["class", "ras-modal__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "header", [["class", "ras-modal__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "h1", [["class", "ras-modal__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "ras-track"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "ras-track__tappa-image"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 17, "div", [["class", "ras-modal__body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 16, "div", [["class", "ras-modal__add-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 13, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 14).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 16384, null, 0, i4.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(14, 4210688, null, 0, i4.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i4.ControlContainer, null, [i4.NgForm]), i0.ɵdid(16, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i0.ɵeld(17, 0, null, null, 8, "div", [["class", "ras-form-item ras-form-item__image-upload"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditIntenseFileModalComponent_1)), i0.ɵdid(19, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(20, 0, null, null, 5, "div", [["class", "ras-form-item ras-form-item__file  ras-form-item__file-inline"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "input", [["class", "ras-form-item__label"], ["id", "addFile"], ["name", "file"], ["type", "file"]], [[8, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 3, "label", [["for", "addFile"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 0, "i", [["aria-label", "hidden"], ["class", "far fa-plus-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(24, null, [" ", " "])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(26, 0, null, null, 1, "div", [["class", "ras-errormsg"]], null, null, null, null, null)), (_l()(), i0.ɵted(27, null, ["", ""])), (_l()(), i0.ɵeld(28, 0, null, null, 7, "div", [["class", "ras-modal__actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 3, "a", [["class", "ras-btn ras-btn-light ras-btn-icon ras-btn-undo ng-star-inserted"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 0, "i", [["class", "fal fa-times-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(31, null, [" ", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(33, 0, null, null, 2, "button", [["class", "ras-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(34, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.files; _ck(_v, 19, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(((_co.type === "file") ? "modal.attachement" : "modal.photos"))); _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.intense.name; _ck(_v, 9, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 16).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 16).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 16).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 16).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 16).ngClassValid; var currVal_7 = i0.ɵnov(_v, 16).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 16).ngClassPending; _ck(_v, 12, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = ((_co.type === "file") ? _co.extensions : "image/*"); _ck(_v, 21, 0, currVal_10); var currVal_11 = i0.ɵunv(_v, 24, 0, i0.ɵnov(_v, 25).transform("modal.addfile")); _ck(_v, 24, 0, currVal_11); var currVal_12 = _co.error; _ck(_v, 27, 0, currVal_12); var currVal_13 = i0.ɵunv(_v, 31, 0, i0.ɵnov(_v, 32).transform("common.cancel")); _ck(_v, 31, 0, currVal_13); var currVal_14 = i0.ɵunv(_v, 34, 0, i0.ɵnov(_v, 35).transform("common.save")); _ck(_v, 34, 0, currVal_14); }); }
export function View_EditIntenseFileModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-intense-file-modal", [], null, null, null, View_EditIntenseFileModalComponent_0, RenderType_EditIntenseFileModalComponent)), i0.ɵdid(1, 245760, null, 0, i5.EditIntenseFileModalComponent, [i6.MAT_DIALOG_DATA, i6.MatDialogRef, i7.Store, i8.UtilsService, i9.Gallery, i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditIntenseFileModalComponentNgFactory = i0.ɵccf("app-edit-intense-file-modal", i5.EditIntenseFileModalComponent, View_EditIntenseFileModalComponent_Host_0, {}, {}, []);
export { EditIntenseFileModalComponentNgFactory as EditIntenseFileModalComponentNgFactory };
