import { Component, OnInit, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { latLng, tileLayer, Marker } from 'leaflet';
import { Store } from '@ngrx/store';
import { SetBoundingBox, SelectIntense } from 'src/app/logic/search-intense';
import { Intense } from 'src/app/model/model.interfaces';
import { distinctUntilChanged, takeUntil, debounce, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchIntenseSelectors } from 'src/app/logic/search-intense/search-intense.selectors';
// import * as generateColor from 'string-to-color';
import { AppDataSelectors } from 'src/app/logic/app-data/app-data.selectors';
import { LayerMakerService } from 'src/app/services/LayerMaker.service';
import { UtilsService } from 'src/app/services/utils.service';
import { config } from 'src/environments/config/config';
@Component({
    selector: 'app-search-map, [app-search-map]',
    templateUrl: './map-search.component.html'
})
export class MapSearchComponent implements OnInit, OnDestroy {

    private MAX_REGION_ZOOM = 9;
    private OSM = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        minZoom: config.MIN_ZOOM,
        maxZoom: config.MAX_ZOOM,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });
    private SATELLITE = tileLayer.wms('http://213.215.135.196/reflector/open/service?', {
        layers: 'rv1',
        format: 'image/jpeg',
        attribution: '© RealVista1.0 WMS OPEN di e-GEOS SpA - CC BY SA'
    });
    private baseLayers = {
        'OSM': this.OSM,
        'Satellite': this.SATELLITE
    };
    private options = {
        layers: [this.OSM],
        zoom: 8,
        center: this.utils.getMiddlePoint(config.STARTING_BOUNDS)
    };
    private iconDefault = L.icon({
        iconSize: [25, 41],
        iconAnchor: [13, 0],
        iconUrl: 'leaflet/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png'
    });
    private startingBounds = config.STARTING_BOUNDS;
    private map: L.Map;
    private layerMapById = {};
    private selectedLayer = null;
    private regionLayers = [];
    private clusterLayers = [];
    private clusters = [];
    private resultsLayer = null;
    private destroy$: Subject<void> = new Subject<void>();
    // private lastSearchString = '';
    // private lastSearchFilter = null;
    private lastSearchSelector = null;
    // private changeSearchParameters = false;
    private updateSearch = true;
    constructor(
        private store: Store<any>,
        private searchSelectors: SearchIntenseSelectors,
        private appDataSelector: AppDataSelectors,
        private layerMaker: LayerMakerService,
        private utils: UtilsService
    ) { }

    ngOnInit(): void {
        this.map = L.map('search-map', this.options);
        this.setStartingBounds();
        L.control.layers(this.baseLayers).addTo(this.map);
        Marker.prototype.options.icon = this.iconDefault;
        this.resultsLayer = L.geoJSON(null, {
            clickTolerance: 10,
            weight: 7,
            color: 'green',
            onEachFeature: this.onEachFeature
        }).addTo(this.map);
        this.map.on('moveend', () => {
            try {
                if (this.updateSearch) {
                    if (this.isDetailsZoom()) {
                        const bounds = this.utils.getBuondingBoxClean(this.map.getBounds());
                        const newBoudingBox = { 'type': 'envelope', values: bounds };
                        if (this.lastSearchSelector) { this.lastSearchSelector.boundingBox = newBoudingBox; } else { this.lastSearchSelector = { boundingBox: newBoudingBox } }                        
                        this.store.dispatch(new SetBoundingBox(newBoudingBox));
                    } else {
                        if (this.lastSearchSelector) { this.lastSearchSelector.boundingBox = null; }                        
                        if (!this.clusterLayers.length) {                            
                            this.store.dispatch(new SetBoundingBox(null));
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        });
        this.searchSelectors.getSelectedResult$().pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(val =>
            this.handleSelectedLayer(val));
        this.searchSelectors.getResults$().pipe(takeUntil(this.destroy$), debounceTime(10)).subscribe(val => {
            /// console.log('-----------: ngOnInit -> val', val);
            this.handleResults(val)
        });

        this.searchSelectors.getFacets$().pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(1000)).subscribe(val => {
            this.updateClusters(val);
        });

        this.searchSelectors.getSearchRequest$().pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(val => {
            this.lastSearchSelector = val;            
        });
    }

    private setStartingBounds() {
        this.map.fitBounds(this.startingBounds, { maxZoom: 10 });
    }

    private updateClusters = (values) => {

        if (this.isDetailsZoom()) { return; }


        if (values) {
            this.clusters = [];
            const promises = [];
            for (const val of values) {
                const p = this.utils.getRegionData(val.key).then(info => {
                    this.clusters.push({ geometry: info.geometry, center: info.center, count: val.count });
                });
                promises.push(p);
            }

            Promise.all(promises).then(() => {
                this.addClusterLayer();
            });

        }


    }

    private zoomToRegion = (polygon) => {
        this.map.fitBounds(polygon.getBounds());
    }

    private addClusterLayer = () => {

        this.clearCluster();

        for (const cluster of this.clusters) {
            const pol = L.geoJSON(cluster.geometry);

            const markerCluster = this.layerMaker.createRegionClusterMarker(cluster.center, cluster.count);
            this.map.addLayer(markerCluster);
            markerCluster.on('click', a => {
                this.zoomToRegion(pol);
            });
            this.clusterLayers.push(markerCluster);

        }

        this.addRegionLayer();
    }

    private addRegionLayer = () => {

        this.clearRegion();

        this.appDataSelector.regionArray$().subscribe(regs => {
            if (!this.isDetailsZoom() && regs && this.regionLayers.length === 0) {
                for (const regName of regs) {
                    this.utils.getRegionData(regName).then(info => {

                        let clust = this.clusters.find(c => this.utils.compareGeneralObj(c.center, info.center));
                        if (!clust) { clust = { count: 0 }; }

                        const pol = this.layerMaker.createRegionPolygon(info.geometry, clust.count);

                        if (this.map) { // Check map state??
                            pol.addTo(this.map);
                        }
                        this.regionLayers.push(pol);
                        pol.on('click', a => {
                            this.zoomToRegion(pol);
                        });
                    });
                }
            }
        });
    }

    private showRegionAndClusterLayer() {
        // console.log('----> showRegionAndClusterLayer');
        // this.addClusterLayer();
        // if (this.regionLayers.length === 0) {
        //    this.addRegionLayer();
        // }
    }

    private clearRegion = () => {
        this.regionLayers.forEach(layer => {
            this.map.removeLayer(layer);
        });
        this.regionLayers = [];
    }

    private clearRegionAndCluster = () => {
        this.clearRegion();
        this.clearCluster();
    }

    private clearCluster = () => {
        for (let i = this.clusterLayers.length - 1; i >= 0; i--) {
            const layer = this.clusterLayers[i];
            this.map.removeLayer(layer);
            this.clusterLayers.splice(i, 1);
        }
        // this.clusterLayers = [];
    }




    private isDetailsZoom = () => {
        return this.map.getZoom() > this.MAX_REGION_ZOOM;
    }


    private onEachFeature = (feature, layer) => {
        layer.on('click', () => this.store.dispatch(new SelectIntense(feature.properties.id)));
    }

    private handleSelectedLayer(scheda) {
        if (!!this.selectedLayer) {
            this.map.removeLayer(this.selectedLayer);
        }
        if (scheda) {
            this.addSelectedLayer(scheda);
        }
    }

    private async addSelectedLayer(id) {
        const data = await this.utils.getGenericGeometry(id);
        if (data) {
            data['properties'] = { id: id };
            const background = L.geoJSON(data, { color: 'white', weight: 11 });
            const line = L.geoJSON(data, { color: 'red', weight: 7 });
            this.selectedLayer = L.featureGroup([background, line]).addTo(this.map);
            // console.log('-------: addSelectedLayer -> this.map.zoom', this.map.getZoom(), this.MAX_REGION_ZOOM);
            if (this.isDetailsZoom()) {
                this.map.panTo(this.selectedLayer.getBounds().getCenter());
            } else {
                this.map.fitBounds(this.selectedLayer.getBounds()); // fly invece di fit per vitare problemi con lo zoom e le regioni per percorsi molto grandi
            }
        }
    }

    private async addResult(id) {
        const data = await this.utils.getGenericGeometry(id);
        if (data) {
            data['properties'] = { id: id };
            this.layerMapById[id] = this.resultsLayer.addData(data);
        }
    }

    private handleResults(val) {
        if (this.isDetailsZoom()) {
            this.clearRegionAndCluster();
            if (val && Array.isArray(val)) {
                const ids = [];
                const startIds = Object.getOwnPropertyNames(this.layerMapById);
                for (let i = 0; i < val.length; i++) {
                    const scheda: Intense = val[i];

                    if (typeof this.layerMapById[scheda.id] === 'undefined') {
                        this.addResult(scheda.id);
                    }
                    ids.push(scheda.id);
                }
                const previousIds = Object.getOwnPropertyNames(this.layerMapById);
                const idsToRemove = previousIds.filter(x => !ids.includes(parseInt(x, 10)));
                this.resultsLayer.eachLayer(function (layer) {
                    if (idsToRemove.includes('' + layer.feature.geometry.properties.id)) {
                        this.map.removeLayer(layer);
                        delete this.layerMapById[layer.feature.geometry.properties.id];
                    }
                }, this);
            }
            if (!!this.selectedLayer) {
                this.selectedLayer.bringToFront();
            }
        } else {
            this.showRegionAndClusterLayer();
            if (!!this.selectedLayer) {
                this.map.removeLayer(this.selectedLayer);
            }
            this.resultsLayer.eachLayer(function (layer) {
                this.map.removeLayer(layer);
                delete this.layerMapById[layer.feature.geometry.properties.id];
            }, this);
        }
     
        if (!this.lastSearchSelector || !this.lastSearchSelector.boundingBox || !this.lastSearchSelector.boundingBox.type) {
     
            const noSearch = !this.lastSearchSelector || (!this.lastSearchSelector.query && (!this.lastSearchSelector.filter || this.lastSearchSelector.filter.lenght === 0));

            const geometries = val.map(x => x.geojson.value);
            this.updateSearch = false;
            if (noSearch || !geometries || geometries.length === 0) {
                // this.setStartingBounds();
                // console.log('STARTING BOUNDING BOX', val, this.lastSearchSelector);     
            } else {                
                // console.log('CENTER BOUNDING BOX', geometries);
                const bounds = this.utils.BoundingBoxFromGeometries(geometries);
                if (bounds) {                    
                    this.map.fitBounds(bounds);
                }
            }
            this.updateSearch = true;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        if (this.map && this.map.remove) {
            // this.map.remove(this.resultsLayer);
            // this.clearRegionAndCluster();
            this.map.off();
        }
    }
}

