import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'StringifyHtml' })
export class StringifyHtmlPipe implements PipeTransform {
    transform(value: string): string {
        const string = this.extractContent(value);
        return string;
    }
    extractContent(s, ): string {
        return (new DOMParser).parseFromString(s, 'text/html').
            documentElement.textContent;
    }
}
