import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';
import { latLng, tileLayer, Marker } from 'leaflet';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { config } from 'src/environments/config/config';
var MapPreviewComponent = /** @class */ (function () {
    function MapPreviewComponent(utils) {
        this.utils = utils;
        this.divId = '';
        this.iconDefault = L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 0],
            iconUrl: 'leaflet/marker-icon.png',
            shadowUrl: 'leaflet/marker-shadow.png'
        });
        this.OSM = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            minZoom: config.MIN_ZOOM,
            maxZoom: config.MAX_ZOOM,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        });
        this.options = {
            layers: [this.OSM],
            zoom: 7,
            zoomControl: false,
            attributionControl: false,
            zoomAnimation: false,
            center: latLng(40.097262, 9.140745)
        };
    }
    MapPreviewComponent.prototype.ngOnInit = function () {
        Marker.prototype.options.icon = this.iconDefault;
        this.divId = 'preview-map-' + this.id;
    };
    MapPreviewComponent.prototype.ngAfterViewInit = function () {
        if (!this.feature) {
            var id = this.divId;
            if (!this.map) {
                this.map = L.map(id, this.options);
            }
            this.addGeometry(this.geometry);
        }
    };
    MapPreviewComponent.prototype.addGeometry = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var geo;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.utils.getGenericGeometry(id)];
                    case 1:
                        geo = _a.sent();
                        if (geo) {
                            this.feature = L.geoJSON(geo).addTo(this.map);
                            try {
                                this.map.fitBounds(this.feature.getBounds());
                            }
                            catch (error) {
                                console.log(id);
                            }
                            this.map.dragging.disable();
                            this.map.touchZoom.disable();
                            this.map.doubleClickZoom.disable();
                            this.map.scrollWheelZoom.disable();
                            this.map.boxZoom.disable();
                            this.map.keyboard.disable();
                            if (this.map.tap) {
                                this.map.tap.disable();
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MapPreviewComponent.prototype.ngOnDestroy = function () {
        if (this.map) {
            this.map.off();
            this.map.remove();
        }
    };
    return MapPreviewComponent;
}());
export { MapPreviewComponent };
