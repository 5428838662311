import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, tap, take, catchError, delayWhen, concatMap } from 'rxjs/operators';
import {
    AppDataActionsType,
    FetchAllSchedeSuccess,
    FetchAllOstSuccess,
    FetchAllRegioniSuccess,
    FetchAllSchedeFail,
    FetchAllRegioniFail,
    FetchAllOstFail,
    FetchPeriodsSuccess,
    FetchPeriodsFail
} from './app-data.actions';
import { AppDataRequests } from './app-data.request';
import * as wellknown from 'wellknown';
import * as geojson_precision from 'geojson-precision';
import { of } from 'rxjs';
import * as turf_simplify from '@turf/simplify';
import { UtilsService } from 'src/app/services/utils.service';
import { fromPromise } from 'apollo-link';

@Injectable({
    providedIn: 'root'
})
export class AppDataEffects {

    private SIMPLIFY_OPTIONS = { tolerance: 0.5, highQuality: true, mutate: true };


    constructor(
        private actions$: Actions,
        private appDataRequest: AppDataRequests,
        private utils: UtilsService
    ) { }

    @Effect() fetchAllSchedeEffect$ = this.actions$.pipe(
        ofType(AppDataActionsType.FetchAllSchede),
        map(action => action['payload']),
        switchMap(request =>
            this.appDataRequest.fecthAllSchede$().pipe(
                tap(data => this.storeGeometrieSchede(data)),
                map(data => new FetchAllSchedeSuccess(data)),
                catchError(error => of(new FetchAllSchedeFail(error)))
            )),
    );

    @Effect() fetchAllRegioniEffect$ = this.actions$.pipe(
        ofType(AppDataActionsType.FetchAllRegioni),
        map(action => action['payload']),
        switchMap(request =>
            this.appDataRequest.fetchAllRegioni$().pipe(
                concatMap(data => this.storeGeometrieRegioni(data)),
                map(data => new FetchAllRegioniSuccess(data)),
                catchError(error => of(new FetchAllRegioniFail(error)))
            ))
    );

    @Effect() fetchAllOstEffect$ = this.actions$.pipe(
        ofType(AppDataActionsType.FetchAllOst),
        map(action => action['payload']),
        switchMap(request =>
            this.appDataRequest.fecthAllOst$().pipe(
                tap(data => this.storeGeometrieOst(data)),
                map(data => new FetchAllOstSuccess()),
                catchError(error => of(new FetchAllOstFail(error)))
            )),
    );

    @Effect() fetchPeriodsEffect$ = this.actions$.pipe(
        ofType(AppDataActionsType.FetchPeriods),
        map(action => action['payload']),
        switchMap(request =>
            this.appDataRequest.fetchPeriods$().pipe(
                map(data => new FetchPeriodsSuccess(data)),
                catchError(error => of(new FetchPeriodsFail(error)))
            ))
    );

    private async storeGeometrieSchede(data) {
        const schede = data.data.querySchedeIntense.schedeIntense;
        for (let i = 0; i < schede.length; i++) {
            const element = schede[i];
            if (element.id && element.geometry && element.geometry.value) {
                const geoJSon = wellknown.parse(element.geometry.value);
                const trimmed = geojson_precision.parse(geoJSon, 4);
                const rest = await this.utils.setGenericGeometry(element.id, trimmed);
            }
        }
    }

    private async storeGeometrieRegioni(data) {
        const regioni = data.data.queryRegioni.regioni; // check
        if (regioni) {
            for (let i = 0; i < regioni.length; i++) {
                const element = regioni[i];
                if (element.name && element.geometry && element.geometry.value) {
                    const geoJSon = wellknown.parse(element.geometry.value);
                    const trimmed = geojson_precision.parse(geoJSon, 5);
                    const resp = await this.utils.setRegionData(element.name, trimmed, element.centroide);
                }
            }
        }
        return data;
    }

    private async storeGeometrieOst(data) {
        const osts = data.data.queryOst.ost;
        for (let i = 0; i < osts.length; i++) {
            const element = osts[i];
            const trimmed = this.utils.getGeometryFromOstQueryElement(element);
            if (trimmed) { await this.utils.setOstGeometry(element.id, trimmed); }
        }
    }
}
