import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { tileLayer, Marker } from 'leaflet';
import { Store } from '@ngrx/store';
import { SetBoundingBox, SelectIntense } from 'src/app/logic/search-intense';
import { distinctUntilChanged, takeUntil, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchIntenseSelectors } from 'src/app/logic/search-intense/search-intense.selectors';
// import * as generateColor from 'string-to-color';
import { AppDataSelectors } from 'src/app/logic/app-data/app-data.selectors';
import { LayerMakerService } from 'src/app/services/LayerMaker.service';
import { UtilsService } from 'src/app/services/utils.service';
import { config } from 'src/environments/config/config';
var MapSearchComponent = /** @class */ (function () {
    function MapSearchComponent(store, searchSelectors, appDataSelector, layerMaker, utils) {
        var _this = this;
        this.store = store;
        this.searchSelectors = searchSelectors;
        this.appDataSelector = appDataSelector;
        this.layerMaker = layerMaker;
        this.utils = utils;
        this.MAX_REGION_ZOOM = 9;
        this.OSM = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            minZoom: config.MIN_ZOOM,
            maxZoom: config.MAX_ZOOM,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        });
        this.SATELLITE = tileLayer.wms('http://213.215.135.196/reflector/open/service?', {
            layers: 'rv1',
            format: 'image/jpeg',
            attribution: '© RealVista1.0 WMS OPEN di e-GEOS SpA - CC BY SA'
        });
        this.baseLayers = {
            'OSM': this.OSM,
            'Satellite': this.SATELLITE
        };
        this.options = {
            layers: [this.OSM],
            zoom: 8,
            center: this.utils.getMiddlePoint(config.STARTING_BOUNDS)
        };
        this.iconDefault = L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 0],
            iconUrl: 'leaflet/marker-icon.png',
            shadowUrl: 'leaflet/marker-shadow.png'
        });
        this.startingBounds = config.STARTING_BOUNDS;
        this.layerMapById = {};
        this.selectedLayer = null;
        this.regionLayers = [];
        this.clusterLayers = [];
        this.clusters = [];
        this.resultsLayer = null;
        this.destroy$ = new Subject();
        // private lastSearchString = '';
        // private lastSearchFilter = null;
        this.lastSearchSelector = null;
        // private changeSearchParameters = false;
        this.updateSearch = true;
        this.updateClusters = function (values) {
            if (_this.isDetailsZoom()) {
                return;
            }
            if (values) {
                _this.clusters = [];
                var promises = [];
                var _loop_1 = function (val) {
                    var p = _this.utils.getRegionData(val.key).then(function (info) {
                        _this.clusters.push({ geometry: info.geometry, center: info.center, count: val.count });
                    });
                    promises.push(p);
                };
                for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
                    var val = values_1[_i];
                    _loop_1(val);
                }
                Promise.all(promises).then(function () {
                    _this.addClusterLayer();
                });
            }
        };
        this.zoomToRegion = function (polygon) {
            _this.map.fitBounds(polygon.getBounds());
        };
        this.addClusterLayer = function () {
            _this.clearCluster();
            var _loop_2 = function (cluster) {
                var pol = L.geoJSON(cluster.geometry);
                var markerCluster = _this.layerMaker.createRegionClusterMarker(cluster.center, cluster.count);
                _this.map.addLayer(markerCluster);
                markerCluster.on('click', function (a) {
                    _this.zoomToRegion(pol);
                });
                _this.clusterLayers.push(markerCluster);
            };
            for (var _i = 0, _a = _this.clusters; _i < _a.length; _i++) {
                var cluster = _a[_i];
                _loop_2(cluster);
            }
            _this.addRegionLayer();
        };
        this.addRegionLayer = function () {
            _this.clearRegion();
            _this.appDataSelector.regionArray$().subscribe(function (regs) {
                if (!_this.isDetailsZoom() && regs && _this.regionLayers.length === 0) {
                    for (var _i = 0, regs_1 = regs; _i < regs_1.length; _i++) {
                        var regName = regs_1[_i];
                        _this.utils.getRegionData(regName).then(function (info) {
                            var clust = _this.clusters.find(function (c) { return _this.utils.compareGeneralObj(c.center, info.center); });
                            if (!clust) {
                                clust = { count: 0 };
                            }
                            var pol = _this.layerMaker.createRegionPolygon(info.geometry, clust.count);
                            if (_this.map) { // Check map state??
                                pol.addTo(_this.map);
                            }
                            _this.regionLayers.push(pol);
                            pol.on('click', function (a) {
                                _this.zoomToRegion(pol);
                            });
                        });
                    }
                }
            });
        };
        this.clearRegion = function () {
            _this.regionLayers.forEach(function (layer) {
                _this.map.removeLayer(layer);
            });
            _this.regionLayers = [];
        };
        this.clearRegionAndCluster = function () {
            _this.clearRegion();
            _this.clearCluster();
        };
        this.clearCluster = function () {
            for (var i = _this.clusterLayers.length - 1; i >= 0; i--) {
                var layer = _this.clusterLayers[i];
                _this.map.removeLayer(layer);
                _this.clusterLayers.splice(i, 1);
            }
            // this.clusterLayers = [];
        };
        this.isDetailsZoom = function () {
            return _this.map.getZoom() > _this.MAX_REGION_ZOOM;
        };
        this.onEachFeature = function (feature, layer) {
            layer.on('click', function () { return _this.store.dispatch(new SelectIntense(feature.properties.id)); });
        };
    }
    MapSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.map = L.map('search-map', this.options);
        this.setStartingBounds();
        L.control.layers(this.baseLayers).addTo(this.map);
        Marker.prototype.options.icon = this.iconDefault;
        this.resultsLayer = L.geoJSON(null, {
            clickTolerance: 10,
            weight: 7,
            color: 'green',
            onEachFeature: this.onEachFeature
        }).addTo(this.map);
        this.map.on('moveend', function () {
            try {
                if (_this.updateSearch) {
                    if (_this.isDetailsZoom()) {
                        var bounds = _this.utils.getBuondingBoxClean(_this.map.getBounds());
                        var newBoudingBox = { 'type': 'envelope', values: bounds };
                        if (_this.lastSearchSelector) {
                            _this.lastSearchSelector.boundingBox = newBoudingBox;
                        }
                        else {
                            _this.lastSearchSelector = { boundingBox: newBoudingBox };
                        }
                        _this.store.dispatch(new SetBoundingBox(newBoudingBox));
                    }
                    else {
                        if (_this.lastSearchSelector) {
                            _this.lastSearchSelector.boundingBox = null;
                        }
                        if (!_this.clusterLayers.length) {
                            _this.store.dispatch(new SetBoundingBox(null));
                        }
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        });
        this.searchSelectors.getSelectedResult$().pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(function (val) {
            return _this.handleSelectedLayer(val);
        });
        this.searchSelectors.getResults$().pipe(takeUntil(this.destroy$), debounceTime(10)).subscribe(function (val) {
            /// console.log('-----------: ngOnInit -> val', val);
            _this.handleResults(val);
        });
        this.searchSelectors.getFacets$().pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(1000)).subscribe(function (val) {
            _this.updateClusters(val);
        });
        this.searchSelectors.getSearchRequest$().pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(function (val) {
            _this.lastSearchSelector = val;
        });
    };
    MapSearchComponent.prototype.setStartingBounds = function () {
        this.map.fitBounds(this.startingBounds, { maxZoom: 10 });
    };
    MapSearchComponent.prototype.showRegionAndClusterLayer = function () {
        // console.log('----> showRegionAndClusterLayer');
        // this.addClusterLayer();
        // if (this.regionLayers.length === 0) {
        //    this.addRegionLayer();
        // }
    };
    MapSearchComponent.prototype.handleSelectedLayer = function (scheda) {
        if (!!this.selectedLayer) {
            this.map.removeLayer(this.selectedLayer);
        }
        if (scheda) {
            this.addSelectedLayer(scheda);
        }
    };
    MapSearchComponent.prototype.addSelectedLayer = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, background, line;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.utils.getGenericGeometry(id)];
                    case 1:
                        data = _a.sent();
                        if (data) {
                            data['properties'] = { id: id };
                            background = L.geoJSON(data, { color: 'white', weight: 11 });
                            line = L.geoJSON(data, { color: 'red', weight: 7 });
                            this.selectedLayer = L.featureGroup([background, line]).addTo(this.map);
                            // console.log('-------: addSelectedLayer -> this.map.zoom', this.map.getZoom(), this.MAX_REGION_ZOOM);
                            if (this.isDetailsZoom()) {
                                this.map.panTo(this.selectedLayer.getBounds().getCenter());
                            }
                            else {
                                this.map.fitBounds(this.selectedLayer.getBounds()); // fly invece di fit per vitare problemi con lo zoom e le regioni per percorsi molto grandi
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MapSearchComponent.prototype.addResult = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.utils.getGenericGeometry(id)];
                    case 1:
                        data = _a.sent();
                        if (data) {
                            data['properties'] = { id: id };
                            this.layerMapById[id] = this.resultsLayer.addData(data);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MapSearchComponent.prototype.handleResults = function (val) {
        if (this.isDetailsZoom()) {
            this.clearRegionAndCluster();
            if (val && Array.isArray(val)) {
                var ids_1 = [];
                var startIds = Object.getOwnPropertyNames(this.layerMapById);
                for (var i = 0; i < val.length; i++) {
                    var scheda = val[i];
                    if (typeof this.layerMapById[scheda.id] === 'undefined') {
                        this.addResult(scheda.id);
                    }
                    ids_1.push(scheda.id);
                }
                var previousIds = Object.getOwnPropertyNames(this.layerMapById);
                var idsToRemove_1 = previousIds.filter(function (x) { return !ids_1.includes(parseInt(x, 10)); });
                this.resultsLayer.eachLayer(function (layer) {
                    if (idsToRemove_1.includes('' + layer.feature.geometry.properties.id)) {
                        this.map.removeLayer(layer);
                        delete this.layerMapById[layer.feature.geometry.properties.id];
                    }
                }, this);
            }
            if (!!this.selectedLayer) {
                this.selectedLayer.bringToFront();
            }
        }
        else {
            this.showRegionAndClusterLayer();
            if (!!this.selectedLayer) {
                this.map.removeLayer(this.selectedLayer);
            }
            this.resultsLayer.eachLayer(function (layer) {
                this.map.removeLayer(layer);
                delete this.layerMapById[layer.feature.geometry.properties.id];
            }, this);
        }
        if (!this.lastSearchSelector || !this.lastSearchSelector.boundingBox || !this.lastSearchSelector.boundingBox.type) {
            var noSearch = !this.lastSearchSelector || (!this.lastSearchSelector.query && (!this.lastSearchSelector.filter || this.lastSearchSelector.filter.lenght === 0));
            var geometries = val.map(function (x) { return x.geojson.value; });
            this.updateSearch = false;
            if (noSearch || !geometries || geometries.length === 0) {
                // this.setStartingBounds();
                // console.log('STARTING BOUNDING BOX', val, this.lastSearchSelector);     
            }
            else {
                // console.log('CENTER BOUNDING BOX', geometries);
                var bounds = this.utils.BoundingBoxFromGeometries(geometries);
                if (bounds) {
                    this.map.fitBounds(bounds);
                }
            }
            this.updateSearch = true;
        }
    };
    MapSearchComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
        if (this.map && this.map.remove) {
            // this.map.remove(this.resultsLayer);
            // this.clearRegionAndCluster();
            this.map.off();
        }
    };
    return MapSearchComponent;
}());
export { MapSearchComponent };
