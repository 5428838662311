import { Injectable } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { CommonActionsTypes, DisplayModal } from './common.actions';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SearchOstActionTypes } from '../search-ost';
import { SearchIntenseActionTypes } from '../search-intense';
import { AppDataActionsType, FetchAllSchede, FetchAllOst, FetchAllRegioni } from '../app-data';
import { AppDataSelectors } from '../app-data/app-data.selectors';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class CommonEffects {
    // private spinnerRef: OverlayRef = this.cdkSpinnerCreate();
    private translations = [];
    // private showingSpinner = false;
    constructor(
        private appDataSelectors: AppDataSelectors,
        // private overlay: Overlay,
        private router: Router,
        private actions$: Actions,
        private modalService: ModalService,
        private translate: TranslateService,
        private spinner: NgxSpinnerService
    ) {
        setTimeout(() => { // necessario ritardo per caricare le traduzioni
            translate.get([
                'action.loadingerror',
                'action.savingerror',
                'action.deleteerror',
                'action.unavailable',
            ]).subscribe(res => {
                this.translations = res;
            });
        }, 500);
    }

    @Effect({ dispatch: false }) showModal$ = this.actions$.pipe(
        ofType(CommonActionsTypes.DisplayModal),
        map(action => action['payload']),
        tap(payload => this.modalService.openDialog(payload.type, payload.data))
    );

    @Effect({ dispatch: false }) hideModal$ = this.actions$.pipe(
        ofType(CommonActionsTypes.DisplayModalSuccess),
        map(action => action['payload']),
        tap(payload => this.modalService.closeDialog())
    );

    @Effect({ dispatch: false }) spinnerStart$ = this.actions$.pipe(
        ofType(
            SearchOstActionTypes.SaveIntense,
            SearchIntenseActionTypes.DeleteIntense,
            SearchOstActionTypes.UpdatePercorsoPoint,
            SearchOstActionTypes.SearchOst,
            SearchOstActionTypes.SearchOstSuccess,
            SearchIntenseActionTypes.SearchIntense
        ),
        tap(data => this.showSpinner())
    );

    @Effect({ dispatch: false }) spinnerStop$ = this.actions$.pipe(
        ofType(
            SearchOstActionTypes.SaveIntenseSuccess,
            SearchOstActionTypes.SaveIntenseFail,
            SearchIntenseActionTypes.DeleteIntenseSuccess,
            SearchIntenseActionTypes.DeleteIntenseFail,
            SearchOstActionTypes.UpdatePercorsoSuccess,
            SearchIntenseActionTypes.SearchIntenseSuccess,
            SearchOstActionTypes.SearchOstFail,
            SearchIntenseActionTypes.SearchIntenseFail
        ),
        tap(data => this.stopSpinner())
    );

    @Effect({ dispatch: false }) startLoginSpinner$ = this.actions$.pipe(
        ofType(
            AppDataActionsType.FetchAllOst,
            AppDataActionsType.FetchAllRegioni,
            AppDataActionsType.FetchAllSchede
        ),
        tap(data => this.showSpinner())
    );

    @Effect({ dispatch: false }) stopLoginSpinner$ = this.actions$.pipe(
        ofType(
            // AppDataActionsType.FetchAllOstSuccess,
            AppDataActionsType.FetchAllRegioniSuccess,
            AppDataActionsType.FetchAllSchedeSuccess
        ),
        withLatestFrom(this.appDataSelectors.dataReady$()),
        tap(([_, check]) => { if (check) { this.stopSpinner();
            this.router.navigate(['search']); } })
    );

    @Effect() fetchSchedeError$ = this.actions$.pipe(
        ofType(AppDataActionsType.FetchAllSchedeFail),
        withLatestFrom(this.appDataSelectors.schedeAttempts$()),
        map(([_, num]) => {
            if (num < 0) {
                this.stopSpinner();
                return new DisplayModal({ type: 'confirm', data: { message: this.translations['action.loadingerror'] } });
            } else {
                return new FetchAllSchede();
            }
        })
    );

    @Effect() fetchOstError$ = this.actions$.pipe(
        ofType(AppDataActionsType.FetchAllOstFail),
        withLatestFrom(this.appDataSelectors.ostAttempts$()),
        map(([_, num]) => {
            // console.log(num);
            if (num < 0) {
                this.stopSpinner();
                return new DisplayModal({ type: 'confirm', data: { message: this.translations['action.loadingerror'] } });
            } else {
                return new FetchAllOst();
            }
        })
    );

    @Effect() fetchRegionError$ = this.actions$.pipe(
        ofType(AppDataActionsType.FetchAllRegioniFail),
        withLatestFrom(this.appDataSelectors.regionAttempts$()),
        map(([_, num]) => {
            if (num < 0) {
                this.stopSpinner();
                return new DisplayModal({ type: 'confirm', data: { message: this.translations['action.loadingerror'] } });
            } else {
                return new FetchAllRegioni();
            }
        })
    );

    @Effect() ErrorConfirf$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.SaveIntenseFail),
        map(data => {
            this.stopSpinner();
            return new DisplayModal({ type: 'confirm', data: { message: this.translations['action.savingerror'] } });
        })
    );

    @Effect() deleteIntenseFail$ = this.actions$.pipe(
        ofType(SearchIntenseActionTypes.DeleteIntenseFail),
        map(data => {
            this.stopSpinner();
            return new DisplayModal({ type: 'confirm', data: { message: this.translations['action.deleteerror'] } });
        })
    );


    @Effect() FeatureNotReady$ = this.actions$.pipe(
        ofType(CommonActionsTypes.FeatureNotReady),
        map(data => {
            this.stopSpinner();
            return new DisplayModal({ type: 'confirm', data: { message: this.translations['action.unavailable'] } });
        })
    );

    // private cdkSpinnerCreate() {
    //     return this.overlay.create({
    //         hasBackdrop: true,
    //         backdropClass: 'dark-backdrop',
    //         positionStrategy: this.overlay.position()
    //             .global()
    //             .centerHorizontally()
    //             .centerVertically()
    //     });
    // }

    showSpinner() {
        this.spinner.show();
        // if (!this.spinnerRef.hasAttached() && !this.showingSpinner) {
        //     this.showingSpinner = true;
        //     setTimeout(() => {
        //         this.spinnerRef.attach(new ComponentPortal(MatSpinner));
        //     }, 1);
        // }
    }
    stopSpinner() {
        this.spinner.hide();
        // this.showingSpinner = false;

        // setTimeout(() => {
        //     this.spinnerRef.detach();
        // }, 1);
    }
}
