import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { SearchOstSelectors } from 'src/app/logic/search-ost/search-ost.selectors';
import { SelectOst } from 'src/app/logic/search-ost';
import { Router } from '@angular/router';
import { EditIntenseService } from 'src/app/views/edit-intense/edit-intense.service';
import { AsrAuthGuard } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-map-header, [app-map-header]',
    templateUrl: './map-header.component.html'
})
export class MapHeaderComponent implements OnInit, OnDestroy {

    searchControl = new FormControl();
    destroy$: Subject<any> = new Subject<boolean>();
    results = [];
    showDetails;
    showFilters;
    intense;
    userImg;
    constructor(private store: Store<any>,
        private searchSelectors: SearchOstSelectors,
        private router: Router,
        private editService: EditIntenseService,
        private authService: AsrAuthGuard,
        private utils: UtilsService) { }

    ngOnInit(): void {
        // this.searchControl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400)).subscribe(value => {
        //     this.store.dispatch(new SetQuery(value));
        // });
        this.searchSelectors.getCurrentIntense$().pipe(takeUntil(this.destroy$)).subscribe(val => { this.intense = val; });
        this.searchSelectors.getResults$().pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.results = Array.isArray(value) ? value : [];
        });
        this.editService.showDetails$().pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.showDetails = value;
        });
        this.editService.showFilters$().pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.showFilters = value;
        });
        this.userImg = this.authService.getProfileImg();
    }

    onSelect($event) {
        const ost = $event.source.value;

        this.store.dispatch(new SelectOst({ ost: ost }));
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    toggleDetails(value) {
        this.editService.toggleDetails$(value);
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['login']);
    }

    displayFn(obj: object): string {
        return obj && obj['name'] ? obj['name'] : obj;
    }

    isPoi(option) {
        return !this.utils.isOstTratta(option);
    }

    filterAutoComplete(res) {
        if (this.searchControl && this.searchControl.value && !this.searchControl.value.id) {
            return res.filter(x => x.name.toLowerCase().includes(this.searchControl.value.toLowerCase()));
        }
        return res;
    }

    link(type) {
        if (type === 'help' && environment.helpUrl) {
            window.open(environment.helpUrl, '_blank');
        } else if (type === 'support' && environment.supportUrl) {
            window.open(environment.supportUrl, '_blank');
        } else if (type === 'forum' && environment.forumUrl) {
            window.open(environment.forumUrl, '_blank');
        } else if (type === 'howto_search' && environment.howtoSearchUrl){
            window.open(environment.howtoSearchUrl, '_blank');
        } else if(type === 'howto_edit' && environment.howtoEditUrl){
            window.open(environment.howtoEditUrl, '_blank');
        } else if(type === 'howto_publish' && environment.howtoPublishUrl){
            window.open(environment.howtoPublishUrl, '_blank');
        } else if(type === 'howto_translate' && environment.howtoTranslateUrl) {
            window.open(environment.howtoTranslateUrl, '_blank');
        }
    }

}
