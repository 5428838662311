import { Pipe, PipeTransform } from '@angular/core';
import { Intense } from '../model/model.interfaces';
import { tap } from 'rxjs/operators';

@Pipe({ name: 'validateIntense' })
export class ValidateIntensePipe implements PipeTransform {
    transform(data: Intense): boolean {
        if (!data || !data.tappe) {
            return false;
        }

        for (let i = 0; i < data.tappe.length; i++) {
            const tappa = data.tappe[i];
            if (tappa.percorso &&
                ((tappa.percorso.tratti && tappa.percorso.tratti.length) ||
                    (tappa.percorso.inizio && tappa.percorso.ostIniziale))
            ) {
                return true;
            }
        }
        return false;
    }
}
