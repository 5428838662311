import { Component, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilsService } from 'src/app/services/utils.service';
import { Store } from '@ngrx/store';
import { AddOst } from 'src/app/logic/search-ost';

@Component({
    selector: 'app-add-ost-modal',
    templateUrl: './add-ost-modal.component.html'
})
export class AddOstModalComponent implements OnInit {

    public trattoList = [];
    public pointList = [];

    public trattoToAdd = null;
    public pointsToAdd = [];

    public poinum = { value: 0 };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AddOstModalComponent>,
        public utils: UtilsService,
        private store: Store<any>
    ) { }

    ngOnInit() {
        this.data.osts.forEach(ost => {
            if (this.utils.isOstTratta(ost)) {
                this.trattoList.push(ost);
            } else {
                this.pointList.push(ost);
            }
        });
        this.poinum = { value: this.pointList.length };
    }

    cancel() {
        // close
        this.dialogRef.close();
    }

    setPoints(ost, $event) {
        if ($event.target.checked) {
            this.pointsToAdd.push(ost);
        } else {
            const pidx = this.pointsToAdd.indexOf(ost);
            this.pointsToAdd.splice(pidx, 1);
        }
    }

    somethingSelected() {
        return this.trattoToAdd || this.pointsToAdd.length;
    }

    add() {
        // aggiunta elementi selezionati
        if (this.trattoToAdd) { this.pointsToAdd.unshift(this.trattoToAdd); }
        this.pointsToAdd.forEach(ost => {
            this.store.dispatch(new AddOst({ ost: ost, position: 0 }));
        });

        // close
        this.dialogRef.close();
    }
}
