import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { OAuthService } from 'angular-oauth2-oidc';
import { Store } from '@ngrx/store';
import { FetchAllSchede, FetchAllOst, FetchAllRegioni, FetchPeriods } from 'src/app/logic/app-data';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { AsrAuthGuard } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { config } from 'src/environments/config/config';


@Component({
    selector: 'app-login-intense',
    templateUrl: './login-intense.component.html',
})

export class LoginIntenseComponent implements OnInit {
    public hideLogin = false;
    private translations = [];
    public IMD_AUTH = config.IDM_AUTH;
    public PRIVACY_POLICY_URL = config.PRIVACY_POLICY_URL;
    constructor(
        private router: Router,
        private store: Store<any>,
        // private oAuthService: OAuthService,
        private route: ActivatedRoute,
        private api: ApiService,
        private authService: AsrAuthGuard,
        private translate: TranslateService
    ) {
        this.translate.get(['login.error', 'login.missingtoken', 'login.invalidrole']).subscribe(t => { this.translations = t; });
    }


    username: FormControl = new FormControl('', [Validators.required]);
    password: FormControl = new FormControl('', [Validators.required]);
    loginForm: FormGroup = new FormGroup({ 'password': this.password, 'username': this.username }, []);
    public error = '';

    ngOnInit() {
        if (this.authService.isLogged()) {
            console.log('-----------: LoginIntenseComponent -> ngOnInit -> isLogged');
            this.hideLogin = true;
            this.loadData();
        } else {
            // check idm code
            const code = this.route.snapshot.queryParamMap.get('code');
            if (code) {
                this.hideLogin = true;

                this.api.loginIdm(code).subscribe(res => {

                    if (res.token) {
                        const uid = res.user_data.uid[0].value;
                        this.authService.setToken(res.token, uid);
                        this.authService.setUserData(res.user_data);
                        this.loadData();
                    } else {
                        this.authService.logout();
                        this.hideLogin = false;
                        console.log('-----------: LoginIntenseComponent -> ngOnInit -> hideLogin');
                        this.error = this.translations['login.missingtoken'];
                    }
                });
            }
        }
    }
    login(): void {
        this.authService.login(this.username.value, this.password.value).then((resp) => {
            console.log('-----------: LoginIntenseComponent -> login -> resp', resp);
            // Loading data about the user
            this.loadData();
        }, (err) => {
            console.log('-----------: LoginIntenseComponent -> login -> err', err, this.translations);
            this.error = this.translations['login.error'];
        });
    }

    loadData() {
        // load data about the user
        const loadAll = () => {// load intenses data
            this.store.dispatch(new FetchAllSchede());
            this.store.dispatch(new FetchAllOst());
            this.store.dispatch(new FetchAllRegioni());
            this.store.dispatch(new FetchPeriods());
        };

        if (!this.authService.isUserDataSet()) {
            this.authService.getUserId().then(id => {
                this.api.getUserDetails(id).subscribe(x => {
                    let data = x;
                    if (x['userData']) {
                        data = x['userData'];
                    }
                    this.authService.setUserData(data);

                    // check user roles
                    if (!this.authService.isUserWithValidRoles()) {
                        this.hideLogin = false;
                        this.error = this.translations['login.invalidrole'];
                    } else {
                        loadAll();
                    }
                });
            });

        } else {
            if (!this.authService.isUserWithValidRoles()) {
                this.hideLogin = false;
                this.error = this.translations['login.invalidrole'];
            } else {
                loadAll();
            }
        }
        console.log('-----------: LoginIntenseComponent -> loadData -> loadUserData');
    }


    loginIdm() {
        // redirect
        const url = environment.authEndPoint.idmUrl;
        console.log('-----------: LoginIntenseComponent -> loginIdm -> url', url);
        window.location.href = url;
    }
}

