import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PercorsoService } from './percorso.service';
import { UtilsService } from './utils.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./percorso.service";
import * as i3 from "./utils.service";
import * as i4 from "@ngx-translate/core";
var ApiService = /** @class */ (function () {
    function ApiService(http, percorso, utils, translate) {
        var _this = this;
        this.http = http;
        this.percorso = percorso;
        this.utils = utils;
        this.translate = translate;
        this.SAVE_URL = environment.sin2api;
        this.FRUIZIONE_FORMAT = 'YYYY-MM-DD hh:mm:ss';
        this.DATAPRECISA_FORMAT = 'YYYY-MM-DD';
        this.STATO_PUBBLICATA = 'pubblicata';
        this.STATO_VALIDAZIONE = 'scheda_pronta';
        this.STATO_DRAFT = 'draft';
        this.STATUS_INTERRUPT = 0;
        this.STATUS_VALID = 1;
        this.CLASSE_TRADUZIONE_VUOTO = 'vuoto';
        this.CLASSE_TRADUZIONE_NONCOMPLETO = 'noncompleto';
        this.CLASSE_TRADUZIONE_COMPLETO = 'completo';
        this.ACTION_ADD = 'add';
        this.ACTION_REMOVE = 'remove';
        this.ACTION_EDIT = 'edit';
        this.ACTION_PROPERTY = 'action';
        this.ACTION_NOTHING = 'nothing';
        setTimeout(function () {
            translate.get('common.defaultstagename').subscribe(function (res) { return _this.defaultTappaName = res; });
        }, 500);
    }
    ApiService.prototype.loginIdm = function (code) {
        return this.http.get(environment.authEndPoint.responseUrl + code);
    };
    ApiService.prototype.deleteIntense = function (intenseId) {
        var geojson = [{
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'properties': {
                            'class': 'Scheda',
                            'delete': true,
                            'nid': intenseId
                        }
                    }
                ]
            }];
        return this.http.post(this.SAVE_URL, geojson);
    };
    ApiService.prototype.saveIntense = function (intenseScheda, original, traduzioni, currentLang) {
        // console.log('-------: intenseScheda', intenseScheda);
        // console.log('-------: original', original);
        // console.log("-------: traduzioni", currentLang, traduzioni);
        var intense = this.utils.clone(intenseScheda);
        var intenseArray = this.createIntenseArray(intense, original, currentLang, traduzioni);
        console.log('-------: intenseArray', intenseArray);
        // console.log('-------: intenseArray JSON', JSON.stringify(intenseArray));
        // return of(null);
        return this.http.post(this.SAVE_URL, intenseArray);
        // return this.http.post(this.SAVE_URL, intenseArray).pipe(concatMap(res => {
        //     if ((!res['errors'] || !res['errors'].length) && firstSave) {
        //         return this.saveTranslations(intense, original, traduzioni, currentLang, res);
        //     } else {
        //         return of(res);
        //     }
        // })
        // );
    };
    // saveTranslations(intense: Intense, original: Intense, traduzioni: Traduzione[], currentLang: string, res) {
    //     const saves = [];
    //     for (const trad in traduzioni) {
    //         if (traduzioni.hasOwnProperty(trad)) {
    //             if (trad === currentLang || (!traduzioni[trad].modified)) { console.log('ignore trad', trad); continue; }
    //             // if(!traduzioni[trad].modified && firstSave){
    //             // // traduzione vuota di nuova scheda
    //             // }
    //             /// inserimento delle traduzioni nell'intense
    //             this.updateTranslation(intense, traduzioni[trad], res['affected_items']);
    //             const saveArray = this.createIntenseArray(intense, original, trad, false, null);
    //             // chiamate al servizio di salvataggio
    //             saves.push(
    //                 // this.http.post(this.SAVE_URL, saveArray)
    //                 saveArray
    //             );
    //         }
    //     }
    //     console.log('-------: saveArray', saves);
    //     // tutte le chiamate insieme
    //     return from(saves).pipe(concatMap(sa => this.http.post(this.SAVE_URL, sa)), toArray());
    // }
    ApiService.prototype.createIntenseArray = function (intense, intenseOriginal, trad, traduzioni) {
        // creazione geojson con informazioni generali
        var geojson1 = {
            'type': 'FeatureCollection',
            'features': this.geneateAllFeatures(intense, intenseOriginal, trad, traduzioni),
        };
        // creazione geojson con informazioni del percorso
        var geojson2 = {
            'type': 'FeatureCollection',
            'features': this.geneateTrackFeatures(intense)
        };
        var saveArray = [geojson1, geojson2];
        return saveArray;
    };
    ApiService.prototype.updateTranslation = function (intense, translation) {
        var _this = this;
        console.log('-----------: updateTranslation -> translation', translation);
        intense.descrizione = translation.descrizione;
        intense.name = translation.name ? translation.name : intense.name;
        intense.stato = translation.stato;
        if (intense.osts && translation.modified) {
            intense.osts.forEach(function (o) { return _this.updateTranslationOst(o, translation.osts); });
        }
        else {
            delete intense.osts;
        }
        if (intense.tappe && translation.modified) {
            intense.tappe.forEach(function (tappa, idx) {
                // const tappaTrad = translation.tappe.find(t => t.id === tappa.id);
                var tappaTrad = translation.tappe[idx];
                if (tappaTrad) {
                    tappa.name = tappaTrad.name ? tappaTrad.name : tappa.name;
                }
                if (tappa.osts) {
                    tappa.osts.forEach(function (ost) {
                        _this.updateTranslationOst(ost, translation.osts);
                    });
                }
                if (tappa.percorso && tappa.percorso.tratti) {
                    tappa.percorso.tratti.forEach(function (tr) {
                        _this.updateTranslationOst(tr.ost, translation.osts);
                    });
                }
            });
        }
        else {
            delete intense.tappe;
        }
    };
    ApiService.prototype.updateTranslationOst = function (o, osts) {
        var ost = null;
        if (o && o.id) {
            ost = osts.find(function (x) { return x.id === o.id; });
            o.descrizione = ost ? ost.descrizione : o.descrizione;
        }
    };
    ApiService.prototype.geneateAllFeatures = function (intense, intenseOriginal, trad, traduzioni) {
        var firstSave = !intense.id;
        var data = [];
        for (var tradIdx in traduzioni) {
            if (traduzioni.hasOwnProperty(tradIdx)) {
                var traduzione = traduzioni[tradIdx];
                // console.log("-------: geneateAllFeatures -> traduzione", traduzione)
                var isMainLanguage = tradIdx === trad;
                if (isMainLanguage || traduzione.modified || firstSave) {
                    var intenseClone = this.utils.clone(intense);
                    if (!isMainLanguage) {
                        this.updateTranslation(intenseClone, traduzione);
                    }
                    var generateData = this.generateAllFeatureForSingleLanguage(intenseClone, intenseOriginal, tradIdx, isMainLanguage);
                    generateData.forEach(function (gd) { return data.push(gd); });
                }
            }
        }
        return data;
    };
    ApiService.prototype.generateAllFeatureForSingleLanguage = function (intense, intenseOriginal, trad, isMainLanguage) {
        var data = [];
        data.push(this.createIntenseFeature(intense, intenseOriginal, trad, isMainLanguage));
        // console.log('-----------: geneateAllFeatures -> intense', intense);
        if (intense.tappe) {
            var _loop_1 = function (i) {
                var originalTappa = null;
                if (intenseOriginal && intenseOriginal.tappe) {
                    intenseOriginal.tappe.find(function (x) { return x.id === intense.tappe[i].id && !!intense.tappe[i].id; });
                }
                var tappaData = this_1.createTappaFeature(intense.tappe[i], i, trad, originalTappa, isMainLanguage);
                // console.log('-----------: geneateAllFeatures -> tappaData', tappaData);
                if (tappaData) {
                    data.push(tappaData);
                    // const tappaId = tappaData.properties.tappaId; // i
                }
                var osts = this_1.getOstOfTappaWithOriginal(intense, i, intenseOriginal);
                var _loop_2 = function (j) {
                    var element = osts[j];
                    var originalOst = null;
                    if (intenseOriginal) {
                        originalOst = this_1.utils.getOstsList(intenseOriginal).find(function (o) { return o.id === element.id; });
                    }
                    // TODO rimozione ost da tappe in cui non sono più
                    var geoOst = this_1.createOstFeature(element, i, trad, element.action, originalOst, isMainLanguage);
                    data.push(geoOst);
                };
                // console.log('-------: getOstOfTappaWithOriginal -> osts', osts);
                for (var j = 0; j < osts.length; j++) {
                    _loop_2(j);
                }
            };
            var this_1 = this;
            for (var i = 0; i < intense.tappe.length; i++) {
                _loop_1(i);
            }
        }
        return data;
    };
    ApiService.prototype.geneateTrackFeatures = function (intense) {
        var data = [];
        for (var i = 0; i < intense.tappe.length; i++) {
            this.generatePercorsoTrattoJSON(intense.tappe[i].percorso, i).forEach(function (element) {
                data.push(element);
            });
        }
        // console.log('SAVE TRACK INTENSE -> data', data);
        return data;
    };
    ApiService.prototype.getOstOfTappaWithOriginal = function (intense, tappa, intenseOriginal) {
        var listNew = this.getOstOfTappa(intense, tappa);
        var listOriginal = [];
        if (intenseOriginal) {
            this.getOstOfTappa(intenseOriginal, tappa);
        }
        // console.log('-------: getOstOfTappaWithOriginal -> listNew,listOriginal', listNew, listOriginal);
        return this.getDifferencesCollection(listNew, listOriginal);
    };
    ApiService.prototype.getOstOfTappa = function (intense, tappa) {
        // console.log('TCL: ApiService -> getOstOfTappa -> intense', intense);
        var res = [];
        // recupero ost della tappa (controllando se non sia stata modificata)
        if (intense.tappe && intense.tappe[tappa] && intense.tappe[tappa].osts) {
            intense.tappe[tappa].osts.forEach(function (ost) {
                var ins = null;
                if (intense.osts && Array.isArray(intense.osts)) {
                    ins = intense.osts.find(function (o) { return o.id === ost.id; });
                }
                if (!ins) {
                    ins = ost;
                }
                res.push(ins);
            });
        }
        // recupero ost dei percorsi (controllando che non sia stato modificato)
        if (intense.tappe[tappa] && intense.tappe[tappa].percorso) {
            intense.tappe[tappa].percorso.tratti.forEach(function (tratto) {
                if (tratto.ost && !res.find(function (o) { return o.id === tratto.ost.id; })) {
                    var ins = null;
                    if (intense.osts && Array.isArray(intense.osts)) {
                        ins = intense.osts.find(function (o) { return o.id === tratto.ost.id; });
                    }
                    if (!ins) {
                        ins = tratto.ost;
                    }
                    res.push(ins);
                }
            });
        }
        return res;
    };
    ApiService.prototype.fakeGeometry = function () {
        return {
            'type': 'Point',
            'coordinates': [0, 0]
        };
    };
    ApiService.prototype.getDifferencesCollection = function (collectionFinal, collectionOriginal, withRemove) {
        var _this = this;
        if (withRemove === void 0) { withRemove = false; }
        var res = [];
        // console.log("-------: getDifferencesCollection -> original/final", collectionOriginal, collectionFinal);
        if (collectionFinal && collectionFinal.length) {
            var _loop_3 = function (elemF) {
                // if (!collectionOriginal || !collectionOriginal.find(x => this.utils.compareGeneralObj(x, elemF))) {
                var elemClone = this_2.utils.clone(elemF);
                if (collectionOriginal && elemF.id && collectionOriginal.find(function (x) { return x.id === elemF.id; })) {
                    // if (!collectionOriginal.find(x => this.utils.compareGeneralObj(x, elemF))) {
                    elemClone.action = this_2.ACTION_EDIT;
                    // }
                }
                else {
                    elemClone.action = this_2.ACTION_ADD;
                }
                res.push(elemClone);
            };
            var this_2 = this;
            for (var _i = 0, collectionFinal_1 = collectionFinal; _i < collectionFinal_1.length; _i++) {
                var elemF = collectionFinal_1[_i];
                _loop_3(elemF);
            }
        }
        if (withRemove && collectionOriginal && collectionOriginal.length) {
            var _loop_4 = function (elemO) {
                if (!collectionFinal || !collectionFinal.find(function (x) { return _this.utils.compareGeneralObj(x, elemO) || x.id === elemO.id; })) {
                    var elemClone = this_3.utils.clone(elemO);
                    elemClone.action = this_3.ACTION_REMOVE;
                    res.push(elemClone);
                }
            };
            var this_3 = this;
            for (var _a = 0, collectionOriginal_1 = collectionOriginal; _a < collectionOriginal_1.length; _a++) {
                var elemO = collectionOriginal_1[_a];
                _loop_4(elemO);
            }
        }
        // console.log('geDifferencesColl', collectionOriginal, collectionFinal, res);
        return res;
    };
    ApiService.prototype.createIntenseFeature = function (intense, originalIntense, trad, isMainLanguage) {
        // console.log('-----------: ApiService -> createIntenseFeature -> intense', intense);
        var intenseFeature = {
            'type': 'Feature',
            'properties': {
                'field_fonte_dati': [
                    'Frontend editoriale'
                ],
                'class': 'Scheda',
                'title': intense.name,
                'body': intense.descrizione,
                'nid': !!intense.id ? intense.id : undefined,
                'tipoFondo': [],
                'tipologia': [],
                'ambito': [],
                'fruizione': [],
                'galleria': [],
                'immaginePrincipale': [],
                'mezziTrasporto': intense.mezziTrasporto,
                'stato': intense.stato,
                'locale': trad,
                'action': !!intense.id ? this.ACTION_EDIT : this.ACTION_EDIT,
                'is_new_with_translation': !intense.id && isMainLanguage
            }
        };
        if (isMainLanguage) {
            console.log('-----------: ApiService -> createIntenseFeature -> intense, originalIntense', intense, originalIntense);
            if (intense && originalIntense) {
                intenseFeature.properties.allegati = this.getDifferencesCollection(intense.allegati, originalIntense.allegati);
                intenseFeature.properties.immagini = this.getDifferencesCollection(intense.immagine, originalIntense.immagine);
                intenseFeature.properties.periodoFruizione = this.creaPeriodiFruizioneDifferential(intense, originalIntense);
            }
            else {
                // intenseFeature.properties.allegati = this.getDifferencesCollection(intense.allegati, originalIntense.allegati);
                // intenseFeature.properties.immagini = this.getDifferencesCollection(intense.immagine, originalIntense.immagine);
                // intenseFeature.properties.periodoFruizione = this.creaPeriodiFruizioneDifferential(intense, originalIntense);
            }
        }
        return intenseFeature;
    };
    ApiService.prototype.creaPeriodiFruizioneDifferential = function (intenseNew, intenseOriginal) {
        if (this.utils.compareGeneralObj(intenseNew, intenseOriginal)) {
            return null;
        }
        var periodoNew = this.creaPeriodiFruizione(intenseNew.periodoFruizione);
        var periodoOriginal = this.creaPeriodiFruizione(intenseOriginal.periodoFruizione);
        return this.getDifferencesCollection(periodoNew, periodoOriginal);
    };
    ApiService.prototype.creaPeriodiFruizione = function (fruizione) {
        var _this = this;
        if (!fruizione) {
            return null;
        }
        var res = [];
        fruizione.forEach(function (periodo) {
            var x = {};
            if (periodo.holiday_label && periodo.holiday_label !== '0') {
                x['festivita'] = periodo.holiday_label;
                x['dataEsatta'] = 0;
            }
            else if (periodo.is_precise) {
                x['dataEsatta'] = 1;
                x['dataEsattaDa'] = moment(periodo.fruizione_from ? periodo.fruizione_from : periodo.fruizione_from_precise).format(_this.DATAPRECISA_FORMAT);
                x['dataEsattaA'] = moment(periodo.fruizione_to ? periodo.fruizione_to : periodo.fruizione_to_precise).format(_this.DATAPRECISA_FORMAT);
            }
            else {
                x['dataEsatta'] = 0;
                x['periodoFruizioneDa'] = moment(periodo.fruizione_from).format(_this.FRUIZIONE_FORMAT);
                x['periodoFruizioneA'] = moment(periodo.fruizione_to).format(_this.FRUIZIONE_FORMAT);
            }
            res.push(x);
        });
        return res;
    };
    ApiService.prototype.createTappaFeature = function (tappa, index, trad, originalTappa, isMainLanguage) {
        var action = this.ACTION_EDIT;
        if (this.utils.compareGeneralObj(tappa, originalTappa)) {
            action = null;
        }
        return {
            'type': 'Feature',
            'properties': {
                'class': 'Tappa',
                'title': tappa.name,
                'body': tappa.descrizione,
                'tappaId': index,
                'nid': tappa.id,
                'locale': trad,
                'action': originalTappa || !isMainLanguage ? action : this.ACTION_ADD,
                'is_new_with_translation': !originalTappa && isMainLanguage
            },
        };
    };
    ApiService.prototype.createOstFeature = function (ost, tappa_id, trad, action, originalOst, isMainLanguage) {
        // console.log('-----------: createOstFeature -> ost', ost);
        var ostObj = {
            'type': 'Feature',
            'properties': {
                'field_fonte_dati': [
                    'Frontend editoriale'
                ],
                'nid': ost.id,
                'class': 'Ost',
                'title': ost.name,
                'body': ost.descrizione,
                'tappaId': tappa_id,
                'disponibilita': 1,
                // 'ultimaVerifica': '2012-04-23T18:25:43.511Z',
                // 'dataImportazione': '2012-04-23T18:25:43.511Z',
                // 'dataScadenza': null,
                // 'fineIndisponibilita': null,
                // 'inizioIndisponibilita': null,
                'tipologia': !!ost.tipologia ? ost.tipologia : [],
                'riconoscimenti': [],
                'visitabile': ost.visitabile,
                'allegati': [],
                'idCastasto': ost.identificativoCatasto,
                'subclass': ost.type,
                'accessibilita': !!ost.accessibilitaDisabili ? ost.accessibilitaDisabili : [],
                'contatti': [],
                'field_id_ext': ost.identificativo,
                'immaginePrincipale': [],
                'locale': trad,
                'action': this.ACTION_EDIT
            }
        };
        if (isMainLanguage) {
            if (!!originalOst && (ost.descrizione === originalOst.descrizione) && this.hasSameImages(ost.immagine, originalOst.immagine)) {
                ostObj.properties.action = this.ACTION_NOTHING;
            }
            ostObj.properties.immagini = originalOst ? this.getDifferencesCollection(ost.immagine, originalOst.immagine) : ost.immagine;
        }
        return ostObj;
    };
    ApiService.prototype.hasSameImages = function (immagini, originalImmagini) {
        if ((!immagini && !originalImmagini)) {
            return true;
        }
        if ((!!immagini != !!originalImmagini)) {
            if ((Array.isArray(immagini) && immagini.length === 0 && originalImmagini === null) ||
                (Array.isArray(originalImmagini) && originalImmagini.length === 0 && immagini === null)) {
                return true;
            }
            return false;
        }
        if (immagini.length != originalImmagini.length) {
            return false;
        }
        var check = true;
        for (var i = 0; i < immagini.length; i++) {
            var immagine = immagini[i];
            if (!immagine.id || immagine.action === this.ACTION_REMOVE) {
                check = false;
                break;
            }
        }
        return check;
    };
    ApiService.prototype.generateTrattoGeoJSON = function (tratto, tappa_idx) {
        return {
            'type': 'Feature',
            'properties': {
                'nid': tratto['id'],
                'ostid': tratto.ost ? tratto.ost.id : 0,
                'tappaId': tappa_idx,
                'parziale': tratto.parziale
            },
            'geometry': this.utils.GetGeometry(tratto.tratto)
        };
    };
    ApiService.prototype.generatePuntoFinaleTrattoGeoJSON = function (tratto, tappa_idx) {
        return {
            'type': 'Feature',
            'properties': {
                'nid': tratto['id'],
                'ostid': tratto.ost ? tratto.ost.id : 0,
                'tappaId': tappa_idx,
                'parziale': tratto.parziale
            },
            'geometry': this.utils.convertToGeoJSONpoint(tratto.ultimoPunto)
        };
    };
    ApiService.prototype.generatePuntoInizialeTrattoGeoJSON = function (perc, tappa_idx) {
        return {
            'type': 'Feature',
            'properties': {
                'ostid': perc.ostIniziale && perc.ostIniziale.id ? perc.ostIniziale.id : 0,
                'tappaId': tappa_idx
            },
            'geometry': this.utils.convertToGeoJSONpoint(perc.inizio),
        };
    };
    ApiService.prototype.generatePercorsoTrattoJSON = function (percorso, tappa_idx) {
        var _this = this;
        var res = [];
        if (percorso) {
            res.push(this.generatePuntoInizialeTrattoGeoJSON(percorso, tappa_idx));
            percorso.tratti.forEach(function (tratto) {
                res.push(_this.generateTrattoGeoJSON(tratto, tappa_idx));
                res.push(_this.generatePuntoFinaleTrattoGeoJSON(tratto, tappa_idx));
            });
        }
        return res;
    };
    /// Funzione che completa i dati di una scheda intense. In caso di una scheda 'vecchia' la trasforma in una nuova, mentre per le nuove ricostruisce il percorso a partire dal geojson
    ApiService.prototype.completeIntense = function (incompleteIntense) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (incompleteIntense && incompleteIntense.geojson && incompleteIntense.geojson.value) {
                    console.log(' NEW format Intense ----- Parse', incompleteIntense);
                    return [2 /*return*/, this.parseIntese(incompleteIntense)];
                }
                if (incompleteIntense && (!incompleteIntense.geojson || !incompleteIntense.geojson.value)) {
                    console.log(' OLD format Intense ----- Import', incompleteIntense);
                    return [2 /*return*/, this.importFromOld(incompleteIntense)];
                }
                return [2 /*return*/, incompleteIntense];
            });
        });
    };
    ApiService.prototype.importFromOld = function (incompleteIntense) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var intense, t, tappa, i, ost, tratto;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        intense = this.utils.clone(incompleteIntense);
                        t = 0;
                        _a.label = 1;
                    case 1:
                        if (!(t < intense.tappe.length)) return [3 /*break*/, 8];
                        tappa = intense.tappe[t];
                        if (!tappa.osts) return [3 /*break*/, 6];
                        i = tappa.osts.length - 1;
                        _a.label = 2;
                    case 2:
                        if (!(i >= 0)) return [3 /*break*/, 5];
                        ost = tappa.osts[i];
                        if (!this.utils.isOstTratta(ost)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.utils.getOstGeometry(ost)];
                    case 3:
                        tratto = _a.sent();
                        if (!tappa.percorso) {
                            tappa.percorso = { tratti: [] };
                            tappa.percorso.inizio = this.utils.getFirstCoords(tratto);
                            tappa.percorso.ostIniziale = ost;
                        }
                        tappa.percorso.tratti.push({
                            ost: ost,
                            tratto: tratto,
                            ultimoPunto: this.utils.getLastCoords(tratto),
                            parziale: false // nei vecchi gli ost sono sempre completi
                        });
                        tappa.osts.splice(i, 1);
                        _a.label = 4;
                    case 4:
                        i--;
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        tappa.osts = [];
                        _a.label = 7;
                    case 7:
                        t++;
                        return [3 /*break*/, 1];
                    case 8: return [2 /*return*/, intense];
                }
            });
        });
    };
    ApiService.prototype.parseIntese = function (incompleteIntense) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentIntense, getOst, percorsi;
            return tslib_1.__generator(this, function (_a) {
                currentIntense = this.utils.clone(incompleteIntense);
                getOst = function (id, tappa) {
                    return tappa.osts ? tappa.osts.find(function (x) { return x.id === id; }) : [];
                };
                percorsi = JSON.parse(currentIntense.geojson.value);
                if (percorsi && percorsi.features) {
                    percorsi.features.forEach(function (feature) {
                        // nell'intense esistono già le tappe a cui ogni feature è collegata
                        var tappa = currentIntense.tappe[feature.properties.tappaId];
                        if (!tappa) {
                            tappa = {};
                        }
                        if (!tappa.percorso) { // non esiste un percorso, viene creato
                            tappa.percorso = { tratti: [] };
                            // prima feature dovrebbe essere un punto che corrisponde al punto iniziale dell'intense
                            if (feature.geometry.type === 'Point') {
                                tappa.percorso.inizio = feature.geometry.coordinates;
                                tappa.percorso.ostIniziale = getOst(feature.properties.ostid, tappa);
                            }
                        }
                        // ogni tratto del percorso
                        if (feature.geometry.type === 'LineString') {
                            tappa.percorso.tratti.push({
                                tratto: feature.geometry,
                                ost: getOst(feature.properties.ostid, tappa),
                                parziale: feature.properties.parziale,
                                ultimoPunto: {}
                            });
                        }
                        // punti di fine tratto associati all'ultimo tratto inserito
                        if (feature.geometry.type === 'Point' && tappa.percorso.tratti.length > 0) {
                            tappa.percorso.tratti[tappa.percorso.tratti.length - 1].ultimoPunto = feature.geometry.coordinates;
                        }
                    });
                }
                return [2 /*return*/, currentIntense];
            });
        });
    };
    ApiService.prototype.getUserDetails = function (userID) {
        var url = environment.userDetailUrl.replace('${userID}', userID);
        return this.http.get(url);
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient), i0.inject(i2.PercorsoService), i0.inject(i3.UtilsService), i0.inject(i4.TranslateService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
