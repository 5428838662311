import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.awesome-markers';
import 'leaflet-clicktolerance';
import { tileLayer, Marker } from 'leaflet';
import { Store } from '@ngrx/store';
import { SetBoundingBox } from 'src/app/logic/search-ost';
import { EditIntenseService } from 'src/app/views/edit-intense/edit-intense.service';
import { takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchOstSelectors } from 'src/app/logic/search-ost/search-ost.selectors';
import { UtilsService } from 'src/app/services/utils.service';
import { LayerMakerService } from 'src/app/services/LayerMaker.service';
import { PercorsoService } from 'src/app/services/percorso.service';
import { config } from 'src/environments/config/config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppDataSelectors } from 'src/app/logic/app-data/app-data.selectors';
import { SnackbarComponent } from '../modals/snackbar/snackbar.component';
var MapEditComponent = /** @class */ (function () {
    function MapEditComponent(searchOstSelectors, editService, store, utils, layerMaker, percorso, matSnack, appDataSelector) {
        var _this = this;
        this.searchOstSelectors = searchOstSelectors;
        this.editService = editService;
        this.store = store;
        this.utils = utils;
        this.layerMaker = layerMaker;
        this.percorso = percorso;
        this.matSnack = matSnack;
        this.appDataSelector = appDataSelector;
        this.iconDefault = L.icon({
            iconSize: [25, 41],
            iconAnchor: [13, 0],
            iconUrl: 'leaflet/marker-icon.png',
            shadowUrl: 'leaflet/marker-shadow.png'
        });
        this.startingBounds = config.STARTING_BOUNDS;
        this.OSM = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            minZoom: config.MIN_ZOOM,
            maxZoom: config.MAX_ZOOM,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        });
        this.SATELLITE = tileLayer.wms('http://213.215.135.196/reflector/open/service?', {
            layers: 'rv1',
            format: 'image/jpeg',
            attribution: '© RealVista1.0 WMS OPEN di e-GEOS SpA - CC BY SA'
        });
        this.baseLayers = {
            'OSM': this.OSM,
            'Satellite': this.SATELLITE
        };
        this.options = {
            layers: [this.OSM],
            zoom: 7,
            center: this.utils.getMiddlePoint(config.STARTING_BOUNDS)
        };
        this.resultsOst = [];
        this.destroy$ = new Subject();
        this.layerMapById = {};
        this.currentIntenseLayer = null;
        this.currentIntenseTappaLayer = null;
        this.currentIntense = null;
        this.regionLayers = [];
        this.clusterLayers = [];
        this.clusters = [];
        this.lastHilighted = null;
        this.updateClusters = function (values) {
            if (_this.isDetailsZoom()) {
                return;
            }
            if (values) {
                _this.clusters = [];
                var promises = [];
                var _loop_1 = function (val) {
                    var p = _this.utils.getRegionData(val.key).then(function (info) {
                        _this.clusters.push({ geometry: info.geometry, center: info.center, count: val.count });
                    });
                    promises.push(p);
                };
                for (var _i = 0, values_1 = values; _i < values_1.length; _i++) {
                    var val = values_1[_i];
                    _loop_1(val);
                }
                Promise.all(promises).then(function () {
                    _this.addClusterLayer();
                });
            }
        };
        this.zoomToRegion = function (polygon) {
            _this.map.fitBounds(polygon.getBounds());
        };
        this.addClusterLayer = function () {
            _this.clearCluster();
            var _loop_2 = function (cluster) {
                var pol = L.geoJSON(cluster.geometry);
                var markerCluster = _this.layerMaker.createRegionClusterMarker(cluster.center, cluster.count);
                _this.regionContainerLayer.addLayer(markerCluster);
                markerCluster.on('click', function (a) {
                    _this.zoomToRegion(pol);
                });
                _this.clusterLayers.push(markerCluster);
            };
            for (var _i = 0, _a = _this.clusters; _i < _a.length; _i++) {
                var cluster = _a[_i];
                _loop_2(cluster);
            }
            _this.addRegionLayer();
        };
        this.clearRegion = function () {
            _this.regionLayers.forEach(function (layer) {
                _this.regionContainerLayer.removeLayer(layer);
            });
            _this.regionLayers = [];
        };
        this.clearRegionAndCluster = function () {
            _this.clearRegion();
            _this.clearCluster();
        };
        this.clearCluster = function () {
            for (var i = _this.clusterLayers.length - 1; i >= 0; i--) {
                var layer = _this.clusterLayers[i];
                _this.regionContainerLayer.removeLayer(layer);
                _this.clusterLayers.splice(i, 1);
            }
            // this.clusterLayers = [];
        };
        this.isDetailsZoom = function () {
            return _this.map.getZoom() > 9;
        };
    }
    MapEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        Marker.prototype.options.icon = this.iconDefault;
        this.map = L.map('edit-map', this.options);
        this.map.fitBounds(this.startingBounds, { maxZoom: 9 });
        L.control.layers(this.baseLayers).addTo(this.map);
        Marker.prototype.options.icon = this.iconDefault;
        this.regionContainerLayer = L.featureGroup().addTo(this.map);
        this.normalContainerLayer = L.featureGroup().addTo(this.map);
        this.previewLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.percorsoLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.resultsLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.startPointsLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.nextPointsLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.highlightLayer = L.featureGroup().addTo(this.normalContainerLayer);
        this.map.on('moveend', function () {
            try {
                if (_this.isDetailsZoom()) {
                    var bounds = _this.map.getBounds();
                    _this.store.dispatch(new SetBoundingBox({ 'type': 'envelope', values: _this.utils.getBuondingBoxClean(bounds) }));
                }
                else if (!_this.clusterLayers.length) {
                    _this.store.dispatch(new SetBoundingBox(null));
                }
            }
            catch (error) {
                console.log(error);
            }
        });
        this.searchOstSelectors.getResultsAndType$().pipe(takeUntil(this.destroy$), distinctUntilChanged(this.compareResults), debounceTime(100)).subscribe(function (val) { return _this.handleResults(val.results, val.update); });
        this.searchOstSelectors.getCurrentIntense$().pipe(takeUntil(this.destroy$), distinctUntilChanged(this.utils.compareGeneralObj), debounceTime(100)).subscribe(function (val) { return _this.handleCurrentIntense(val); });
        // this.editService.getState$().pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(
        //     val => {console.log(val),this.map.invalidateSize(true)});
        this.searchOstSelectors.getSelectedOst$().pipe(takeUntil(this.destroy$), distinctUntilChanged(this.utils.compareGeneralObj)).subscribe(function (val) { if (val) {
            _this.openPop(val);
        } });
        this.searchOstSelectors.getHighlightElement$().pipe(takeUntil(this.destroy$), distinctUntilChanged(this.utils.compareGeneralObj)).subscribe(function (val) { _this.highlight(val); });
        // evento visualizzazione dettagli
        this.editService.showDetails$().pipe(takeUntil(this.destroy$)).subscribe(function (value) { return _this.detailsView(value); });
        this.searchOstSelectors.getFacets$().pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(1000)).subscribe(function (val) {
            _this.clearRegionAndCluster();
            _this.updateClusters(val);
        });
    };
    MapEditComponent.prototype.compareResults = function (prev, current) {
        var prevIds = [];
        var currentIds = [];
        if (!(Array.isArray(prev) && Array.isArray(current))) {
            return false;
        }
        for (var i = 0; i < prev.length; i++) {
            var element = prev[i];
            prevIds.push(element.id);
        }
        for (var i = 0; i < current.length; i++) {
            var element = current[i];
            currentIds.push(element.id);
        }
        if (prevIds.length !== currentIds.length) {
            return false;
        }
        var ids = prevIds.filter(function (x) { return currentIds.includes(x); });
        if (ids.length === prevIds.length) {
            return true;
        }
        return false;
    };
    MapEditComponent.prototype.onResized = function (event) {
        this.map.invalidateSize(true);
    };
    MapEditComponent.prototype.handleCurrentIntense = function (val) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var firstRun, osts_1, promises, i, ostItem, inCurrentTappa;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        firstRun = !this.currentIntense;
                        if (val) { // caching
                            this.currentIntense = val;
                        }
                        if (!(this.currentIntense && this.currentIntense.tappe && Array.isArray(this.currentIntense.tappe)
                            && this.currentIntense.tappe[0] && this.currentIntense.tappe[0].osts && Array.isArray(this.currentIntense.tappe[0].osts))) return [3 /*break*/, 2];
                        osts_1 = [];
                        this.currentIntense.tappe.forEach(function (element, $idx) {
                            if (element.osts && element.osts.length) {
                                element.osts.forEach(function (ost) {
                                    osts_1.push({ ost: ost, inCurrentTappa: $idx === _this.currentIntense.currentTappa });
                                });
                            }
                        });
                        if (!!this.currentIntenseLayer) {
                            this.map.removeLayer(this.currentIntenseLayer);
                        }
                        if (!!this.currentIntenseTappaLayer) {
                            this.map.removeLayer(this.currentIntenseTappaLayer);
                        }
                        this.currentIntenseLayer = L.featureGroup().addTo(this.map);
                        this.currentIntenseTappaLayer = L.featureGroup().addTo(this.map);
                        promises = [];
                        for (i = 0; i < osts_1.length; i++) {
                            ostItem = osts_1[i].ost;
                            inCurrentTappa = osts_1[i].inCurrentTappa;
                            if (!this.utils.isOstTratta(ostItem)) {
                                promises.push(this.addElementToLayer(ostItem, inCurrentTappa));
                            }
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        if (this.currentIntenseTappaLayer.getLayers().length > 0) {
                            // this.map.fitBounds(this.currentIntenseTappaLayer.getBounds());
                        }
                        this.updateNextPoints(this.currentIntense.nextPoints);
                        this.drawTappePercorso(this.currentIntense.tappe);
                        return [3 /*break*/, 3];
                    case 2:
                        this.updateNextPoints([]);
                        _a.label = 3;
                    case 3:
                        if (firstRun) {
                            window.setTimeout(function () { return _this.zoomPercorso(); }, 300);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MapEditComponent.prototype.patchOldIntense = function (currentIntense) {
        // rimozione dei vechi ost dall'intense, patch da rimuovere
        // if (currentIntense.tappe && currentIntense.tappe.length && currentIntense.tappe[0].osts.length && !currentIntense.tappe[0].percorso) {
        //     this.store.dispatch(new RemovePercorsoTratto({ indexTratto: 0, indexTappa: 0 }));
        // }
    };
    MapEditComponent.prototype.currentPercorso = function () {
        return this.currentIntense.tappe[this.currentIntense.currentTappa].percorso;
    };
    MapEditComponent.prototype.esistePercorso = function () {
        return this.currentIntense
            && Array.isArray(this.currentIntense.tappe)
            && this.currentIntense.currentTappa != null
            && this.currentIntense.tappe.length > this.currentIntense.currentTappa
            && this.currentPercorso();
    };
    MapEditComponent.prototype.updateNextPoints = function (punti) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ultimopuntoSuggerito, markerClusterLayer, _i, punti_1, punto, isFar, p;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.nextPointsLayer.clearLayers();
                        ultimopuntoSuggerito = null;
                        if (this.esistePercorso()) {
                            ultimopuntoSuggerito = this.percorso.ultimoPuntoPercorso(this.currentPercorso());
                        }
                        else {
                            return [2 /*return*/];
                        }
                        if (!this.firstChoose()) {
                            this.removeSTartPoints(null);
                        }
                        markerClusterLayer = L.markerClusterGroup({
                            showCoverageOnHover: false,
                            animateAddingMarkers: true,
                            iconCreateFunction: function (cluster) {
                                var divHtml = "<div class=\"map-cluster-icon\" >" + cluster.getChildCount() + "<div>";
                                return L.divIcon({ html: divHtml });
                            }
                        });
                        if (!(punti && Array.isArray(punti) && ultimopuntoSuggerito.punto)) return [3 /*break*/, 4];
                        _i = 0, punti_1 = punti;
                        _a.label = 1;
                    case 1:
                        if (!(_i < punti_1.length)) return [3 /*break*/, 4];
                        punto = punti_1[_i];
                        isFar = !!ultimopuntoSuggerito && (this.utils.distance(ultimopuntoSuggerito.punto, punto.punto) > config.DISTANZA_MASSIMA_AGGIUNTA_NONSEGNALATA_KM);
                        return [4 /*yield*/, this.layerMaker.createNextPointMarker(punto, isFar, function (nxt) { _this.mousEnterNextPoint(nxt); }, function () { return _this.mouseLeaveNextPoint(); })];
                    case 2:
                        p = _a.sent();
                        if (this.layerMaker.isPointCross(punto)) {
                            markerClusterLayer.addLayer(p);
                        }
                        else {
                            this.nextPointsLayer.addLayer(p);
                        }
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.nextPointsLayer.addLayer(markerClusterLayer);
                        if (ultimopuntoSuggerito && ultimopuntoSuggerito.punto) {
                            this.drawEndPercorso(ultimopuntoSuggerito.punto);
                        }
                        this.nextPointsLayer.bringToFront();
                        return [2 /*return*/];
                }
            });
        });
    };
    MapEditComponent.prototype.mouseClickStartPoint = function (ost) {
        // zoom to ost
        this.zoomToOst(ost);
    };
    MapEditComponent.prototype.zoomToOst = function (ost) {
        var _this = this;
        this.utils.getOstGeometry(ost).then(function (geom) {
            var gl = L.geoJSON(geom);
            _this.map.fitBounds(gl.getBounds());
        });
    };
    MapEditComponent.prototype.mouseEnterStartPoint = function (ost, feature) {
        this.matSnack.openFromComponent(SnackbarComponent, { data: ost });
        this.highlight(ost);
        // this.highlightFeature(feature);
    };
    MapEditComponent.prototype.mouseLeaveStartPoint = function (feature, color) {
        //     this.matSnack.dismiss();
        //     this.highlight(null);
        //     // this.highlightFeature(feature, color);
    };
    // private highlightFeature(feature, color = null) {
    //     const colorLine = color ? color : config.HIGHLIGHT_COLOR;
    //     feature.setStyle({ color: colorLine });
    // }
    MapEditComponent.prototype.mousEnterNextPoint = function (p) {
        var _this = this;
        this.mouseLeaveNextPoint();
        var ultimopunto = this.percorso.ultimoPuntoPercorso(this.currentPercorso());
        this.percorso.getSegmento(p.ostProvenienza, ultimopunto.punto, p.punto, true).then(function (geometry) {
            var preview = L.geoJSON(geometry, {
                style: function () {
                    return {
                        'color': config.PREVIEW_COLOR,
                        'weight': config.PREVIEW_WEIGHT,
                        'dashArray': config.PREVIEW_DASH_ARRAY
                    };
                }
            });
            _this.previewLayer.addLayer(preview);
        });
        if (p.ostConnessi && p.ostConnessi.length) {
            this.matSnack.openFromComponent(SnackbarComponent, { data: p.ostConnessi });
        }
        else if (p.ostProvenienza) {
            this.matSnack.openFromComponent(SnackbarComponent, { data: p.ostProvenienza });
        }
    };
    MapEditComponent.prototype.mouseLeaveNextPoint = function () {
        var _this = this;
        this.previewLayer.eachLayer(function (l) {
            _this.previewLayer.removeLayer(l);
        });
        this.matSnack.dismiss();
    };
    MapEditComponent.prototype.detailsView = function (isDetail) {
        // if(isDetail){
        this.normalContainerLayer.removeLayer(this.resultsLayer);
        this.normalContainerLayer.removeLayer(this.nextPointsLayer);
        // }
        if (!isDetail) {
            this.resultsLayer.addTo(this.normalContainerLayer);
            this.nextPointsLayer.addTo(this.normalContainerLayer);
        }
        else {
            // zoom su percorso
            this.zoomPercorso();
        }
        // this.percorsoLayer; //sempre visibile
        // this.highlightLayer;//sempre visibile
    };
    MapEditComponent.prototype.zoomPercorso = function () {
        if (this.percorsoLayer.getLayers().length) {
            this.map.fitBounds(this.percorsoLayer.getBounds());
        }
    };
    MapEditComponent.prototype.highlight = function (elem, addStartMarker) {
        if (addStartMarker === void 0) { addStartMarker = null; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var geom, geom, highlight, e2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (elem && this.lastHilighted === elem) {
                            return [2 /*return*/];
                        }
                        if (!(elem && elem.type && this.utils.isOstTratta(elem))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.utils.getOstGeometry(elem)];
                    case 1:
                        geom = _a.sent();
                        elem = { tratto: geom };
                        _a.label = 2;
                    case 2:
                        // console.log('-----------: highlight -> elem', elem);
                        if (elem && elem.tratto) {
                            geom = elem.tratto.geometry ? elem.tratto.geometry : elem.tratto;
                            highlight = L.geoJSON(geom, {
                                style: function () {
                                    return {
                                        color: config.HIGHLIGHT_COLOR,
                                        weight: config.HIGHLIGHT_WEIGHT,
                                        dashArray: config.HIGHLIGHT_DASH_ARRAY,
                                        opacity: config.HIGHLIGHT_OPACITY,
                                    };
                                }
                            });
                            highlight.on('mouseout', function ($event) {
                                _this.matSnack.dismiss();
                                _this.highlight(null);
                                // this.highlightFeature(feature, colorLine);
                            });
                            if (addStartMarker) {
                                highlight.on('click', function ($event) {
                                    _this.addStartMarker(addStartMarker.ostGeoJSON, addStartMarker.ost, addStartMarker.feature, addStartMarker.colorLine);
                                });
                            }
                            this.highlightLayer.clearLayers();
                            this.highlightLayer.addLayer(highlight);
                            // this.lastHilighted = elem;
                            // setTimeout(() => {
                            //     if (this.lastHilighted === elem) {
                            //         this.matSnack.dismiss();
                            //         this.highlight(null);
                            //     }
                            // }, 1000);
                        }
                        else if (elem && elem.type) { // Controllo debole per il tipo OST, da migliorare
                            e2 = this.utils.clone(elem);
                            this.utils.getOstGeometry(e2).then(function (geom) {
                                var center = _this.utils.getCoords(geom);
                                var highlight = L.circleMarker([center[1], center[0]], {
                                    radius: config.HIGHLIGHT_RADIUS,
                                    color: config.HIGHLIGHT_COLOR,
                                    fillOpacity: config.HIGHLIGHT_OPACITY,
                                    stroke: 0
                                });
                                _this.highlightLayer.clearLayers();
                                _this.highlightLayer.addLayer(highlight);
                            });
                        }
                        else {
                            this.highlightLayer.clearLayers();
                            this.matSnack.dismiss();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MapEditComponent.prototype.drawTappePercorso = function (tappe) {
        var _this = this;
        this.percorsoLayer.eachLayer(function (l) {
            _this.percorsoLayer.removeLayer(l);
        });
        for (var _i = 0, tappe_1 = tappe; _i < tappe_1.length; _i++) {
            var tappa = tappe_1[_i];
            this.drawPercorso(tappa.percorso, tappe[this.currentIntense.currentTappa] === tappa);
        }
    };
    MapEditComponent.prototype.drawPercorso = function (percorso, isSelected) {
        var _this = this;
        // console.log('TCL: drawPercorso -> percorso', percorso, isSelected);
        if (percorso && percorso.tratti) {
            this.drawPointPercorso(percorso.inizio);
            percorso.tratti.forEach(function (tratto, idx) {
                _this.drawTrattoPercorso(tratto, isSelected);
                if (idx !== percorso.tratti.length - 1) {
                    _this.drawPointPercorso(tratto.ultimoPunto);
                }
            });
        }
        this.percorsoLayer.bringToBack();
        if (!isSelected) {
            this.percorsoLayer.bringToBack();
        }
    };
    MapEditComponent.prototype.drawPointPercorso = function (punto) {
        var mrk = this.layerMaker.createPercorsotPointMarker(this.utils.featureToArray(punto));
        if (mrk) {
            this.percorsoLayer.addLayer(mrk);
        }
    };
    MapEditComponent.prototype.drawTrattoPercorso = function (tratto, isSelected) {
        var l = L.geoJSON(tratto.tratto, {
            style: function () {
                return {
                    'color': isSelected ? config.PERCORSO_INTAPPA_COLOR : config.PERCORSO_FUORITAPPA_COLOR,
                    'weight': tratto.ost ? config.PERCORSO_INOST_WEIGHT : config.PERCORSO_FUORIOST_WEIGHT,
                    'dashArray': tratto.ost ? config.PERCORSO_INOST_DASHARRAY : config.PERCORSO_FUORIOST_DASHARRAY
                };
            }
        });
        this.percorsoLayer.addLayer(l);
    };
    MapEditComponent.prototype.drawEndPercorso = function (pointFeature) {
        var point = this.utils.featureToArray(pointFeature);
        var p = this.layerMaker.createLastPointMarker(point);
        this.nextPointsLayer.addLayer(p);
    };
    MapEditComponent.prototype.addElementToLayer = function (ost, inCurrentTappa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var markerColor, geoJSONobj, layer, toAddLayer;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        markerColor = inCurrentTappa ? config.PERCORSO_MARKER_INTAPPA_COLOR : config.PERCORSO_MARKER_FUORITAPPA_COLOR;
                        return [4 /*yield*/, this.utils.getOstGeometry(ost)];
                    case 1:
                        geoJSONobj = _a.sent();
                        layer = this.layerMaker.createOstMarker(geoJSONobj, markerColor, ost);
                        toAddLayer = inCurrentTappa ? this.currentIntenseTappaLayer : this.currentIntenseLayer;
                        toAddLayer.addLayer(layer);
                        return [2 /*return*/];
                }
            });
        });
    };
    MapEditComponent.prototype.createEndOfLinePoli = function (geoJSONobj, color) {
        var center = this.utils.getLastCoords(geoJSONobj);
        var direction = this.utils.calculateDirection(geoJSONobj, false);
        var circle = L.semiCircle([center[1], center[0]], { radius: 10, fillOpacity: 1, fillColor: color, color: 'black', weight: '1' }).setDirection(direction + 180, 180);
        return circle;
    };
    MapEditComponent.prototype.firstChoose = function () {
        var tappaAttuale = this.currentIntense.tappe[this.currentIntense.currentTappa];
        if (tappaAttuale && tappaAttuale.percorso && tappaAttuale.percorso.inizio != null) {
            return false;
        }
        else {
            return true;
        }
    };
    MapEditComponent.prototype.handleResults = function (val, hardreset) {
        var _this = this;
        this.showOnZoomLevel();
        if (val && Array.isArray(val)) {
            var ids = [];
            var startIds = Object.getOwnPropertyNames(this.layerMapById);
            var ostList_1 = [];
            ostList_1 = this.utils.clone(val);
            this.resultsLayer.eachLayer(function (layer) {
                var valIdx = ostList_1.findIndex(function (o) { return o.id === layer.id; });
                if (valIdx === -1 || hardreset) {
                    _this.resultsLayer.removeLayer(layer);
                    _this.removeSTartPoints(layer.id);
                }
                else {
                    ostList_1.splice(valIdx, 1);
                }
            }, this);
            if (ostList_1.length) {
                for (var i = 0; i < ostList_1.length; i++) {
                    var ost = ostList_1[i];
                    this.addOst(ost);
                }
            }
        }
    };
    MapEditComponent.prototype.removeSTartPoints = function (ostId) {
        var _this = this;
        this.startPointsLayer.eachLayer(function (layer) {
            if (ostId === layer.ostid || !ostId) {
                _this.startPointsLayer.removeLayer(layer);
            }
        });
    };
    MapEditComponent.prototype.addOst = function (ost) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ostGeoJSON, index, features, group;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.utils.getOstGeometry(ost)];
                    case 1:
                        ostGeoJSON = _a.sent();
                        index = -1;
                        if (!this.utils.isOstTratta(ost)) {
                            if (this.esistePercorso()) {
                                index = this.utils.isNearPercorso(ostGeoJSON, this.currentPercorso()) ? 1 : -1;
                            }
                        }
                        features = this.createFeatures(ost, ostGeoJSON, index);
                        group = L.featureGroup(features);
                        group['id'] = ost.id;
                        this.resultsLayer.addLayer(group);
                        return [2 /*return*/];
                }
            });
        });
    };
    MapEditComponent.prototype.createFeatures = function (ost, ostGeoJSON, index) {
        var _this = this;
        // non vengono mostrati gli attrattori troppo lontani
        if (index < 0 && !this.utils.isOstTratta(ost) && this.firstChoose()) {
            return;
        }
        var colorMarker = index > -1 ? config.MARKER_NEAR_COLOR : config.MARKER_DISTANT_COLOR;
        var colorLine = this.layerMaker.colorForOst(ost);
        var marker = L.AwesomeMarkers.icon({
            icon: this.layerMaker.getAwesomeIcon(ost),
            prefix: 'fa',
            markerColor: colorMarker,
            extraClasses: index > -1 ? '' : 'map-icon-far',
            zIndexOffset: index > -1 ? 1500 : null,
        });
        var options = {
            'weight': 4,
            clickTolerance: 100,
            color: colorLine,
            pointToLayer: function (_, latlng) {
                return L.marker(latlng, { icon: marker });
            }
        };
        var features = [];
        // ostGeoJSON['properties'] = { id: ost.id };
        var feature = L.geoJson(ostGeoJSON, options);
        // popup solo per gli attrattori
        if (!this.utils.isOstTratta(ost)) {
            feature.bindPopup(function (fl) { return _this.createPopup(ost, feature, index); }, { maxWidth: 400 });
        }
        feature.on('mouseover', function ($event) {
            console.log('-----------: createFeatures -> mouseover');
            _this.matSnack.openFromComponent(SnackbarComponent, { data: ost });
            _this.highlight(ost, { ostGeoJSON: ostGeoJSON, ost: ost, feature: feature, colorLine: colorLine });
            // this.highlightFeature(feature);
        });
        // feature.on('mouseout', ($event) => {
        //     this.matSnack.dismiss();
        //     this.highlight(null);
        //     // this.highlightFeature(feature, colorLine);
        // });
        // feature.on('click', ($event) => {
        //     this.addStartMarker();
        // });
        features.push(feature);
        if (this.utils.isOstTratta(ost)) {
            if (config.SHOW_OST_HEADS) {
                var start = this.layerMaker.createStartOfLinePoli(ostGeoJSON, colorLine);
                start.id = ost.id;
                var end = this.layerMaker.createEndOfLinePoli(ostGeoJSON, colorLine);
                end.id = ost.id;
                features.push(start);
                features.push(end);
            }
            // if (this.firstChoose()) {
            //     const startMKR = this.layerMaker.createStartMarker(
            //         ostGeoJSON, true, ost,
            //         clickOst => { this.mouseClickStartPoint(clickOst); },
            //         startOst => { this.mouseEnterStartPoint(startOst, feature); },
            //         () => { this.mouseLeaveStartPoint(feature, colorLine); });
            //     const endMKR = this.layerMaker.createStartMarker(
            //         ostGeoJSON, false, ost,
            //         clickOst => { this.mouseClickStartPoint(clickOst); },
            //         startOst => { this.mouseEnterStartPoint(startOst, feature); },
            //         () => { this.mouseLeaveStartPoint(feature, colorLine); });
            //     this.startPointsLayer.addLayer(startMKR);
            //     this.startPointsLayer.addLayer(endMKR);
            // }
        }
        return features;
    };
    MapEditComponent.prototype.addStartMarker = function (ostGeoJSON, ost, feature, colorLine) {
        var _this = this;
        this.startPointsLayer.clearLayers();
        if (this.firstChoose()) {
            var startMKR = this.layerMaker.createStartMarker(ostGeoJSON, true, ost, function (clickOst) { _this.mouseClickStartPoint(clickOst); }, function (startOst) { _this.mouseEnterStartPoint(startOst, feature); }, function () { _this.mouseLeaveStartPoint(feature, colorLine); });
            var endMKR = this.layerMaker.createStartMarker(ostGeoJSON, false, ost, function (clickOst) { _this.mouseClickStartPoint(clickOst); }, function (startOst) { _this.mouseEnterStartPoint(startOst, feature); }, function () { _this.mouseLeaveStartPoint(feature, colorLine); });
            this.startPointsLayer.addLayer(startMKR);
            this.startPointsLayer.addLayer(endMKR);
        }
    };
    MapEditComponent.prototype.createPopup = function (ost, feature, index) {
        if (!this.utils.isOstTratta(ost)) {
            var popupEl_1 = document.createElement('app-add-poi-popup');
            popupEl_1.addEventListener('closed', function () { document.body.removeChild(popupEl_1); });
            document.body.appendChild(popupEl_1);
            popupEl_1.ost = ost;
            popupEl_1.feature = feature;
            popupEl_1.index = index;
            return popupEl_1;
        }
        else {
            var popupEl_2 = document.createElement('app-add-tratto-popup');
            popupEl_2.addEventListener('closed', function () { return document.body.removeChild(popupEl_2); });
            document.body.appendChild(popupEl_2);
            popupEl_2.ost = ost;
            popupEl_2.index = index;
            popupEl_2.feature = feature;
            return popupEl_2;
        }
    };
    MapEditComponent.prototype.openPop = function (ost) {
        if (this.utils.isOstTratta(ost)) {
            this.zoomToOst(ost);
        }
        else {
            this.resultsLayer.eachLayer(function (layer) {
                if (layer['id'] === ost.id) {
                    // console.log('---Got layer', layer, ost);
                    layer.eachLayer(function (sublayer) {
                        sublayer.openPopup();
                    });
                }
            }, this);
        }
    };
    // ********************************************************************
    // ******************* REGION AND CLUSTERS ****************************
    // ********************************************************************
    MapEditComponent.prototype.showOnZoomLevel = function () {
        if (this.isDetailsZoom() || this.esistePercorso()) {
            this.map.removeLayer(this.regionContainerLayer);
            this.map.addLayer(this.normalContainerLayer);
        }
        else {
            this.map.removeLayer(this.normalContainerLayer);
            this.map.addLayer(this.regionContainerLayer);
        }
    };
    MapEditComponent.prototype.addRegionLayer = function () {
        var _this = this;
        this.appDataSelector.regionArray$().subscribe(function (regs) {
            if (!_this.isDetailsZoom() && regs && _this.regionLayers.length === 0) {
                for (var _i = 0, regs_1 = regs; _i < regs_1.length; _i++) {
                    var regName = regs_1[_i];
                    _this.utils.getRegionData(regName).then(function (info) {
                        var clust = _this.clusters.find(function (c) { return _this.utils.compareGeneralObj(c.center, info.center); });
                        if (!clust) {
                            clust = { count: 0 };
                        }
                        var pol = _this.layerMaker.createRegionPolygon(info.geometry, clust.count);
                        if (_this.regionContainerLayer) {
                            pol.addTo(_this.regionContainerLayer);
                        }
                        _this.regionLayers.push(pol);
                        pol.on('click', function (a) {
                            _this.zoomToRegion(pol);
                        });
                    });
                }
            }
        });
    };
    MapEditComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
        // this.map.remove(this.baseLayers);
        // this.map.remove(this.regionContainerLayer);
        // this.map.remove(this.normalContainerLayer);
        // this.map.remove(this.currentIntenseLayer);
        // this.map.remove(this.currentIntenseTappaLayer);
        // this.map.remove(this.regionContainerLayer);
        // this.map.remove(this.normalContainerLayer);
        this.map.off();
    };
    return MapEditComponent;
}());
export { MapEditComponent };
