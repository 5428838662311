/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "angular-resize-event";
import * as i2 from "./map-edit.component";
import * as i3 from "../../logic/search-ost/search-ost.selectors";
import * as i4 from "../../views/edit-intense/edit-intense.service";
import * as i5 from "@ngrx/store";
import * as i6 from "../../services/utils.service";
import * as i7 from "../../services/LayerMaker.service";
import * as i8 from "../../services/percorso.service";
import * as i9 from "@angular/material/snack-bar";
import * as i10 from "../../logic/app-data/app-data.selectors";
var styles_MapEditComponent = [];
var RenderType_MapEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapEditComponent, data: {} });
export { RenderType_MapEditComponent as RenderType_MapEditComponent };
export function View_MapEditComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["id", "edit-map"]], null, [[null, "resized"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resized" === en)) {
        var pd_0 = (_co.onResized($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 212992, null, 0, i1.ResizedDirective, [i0.ElementRef], null, { resized: "resized" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_MapEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-map", [], null, null, null, View_MapEditComponent_0, RenderType_MapEditComponent)), i0.ɵdid(1, 245760, null, 0, i2.MapEditComponent, [i3.SearchOstSelectors, i4.EditIntenseService, i5.Store, i6.UtilsService, i7.LayerMakerService, i8.PercorsoService, i9.MatSnackBar, i10.AppDataSelectors], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapEditComponentNgFactory = i0.ɵccf("app-edit-map, [app-edit-map]", i2.MapEditComponent, View_MapEditComponent_Host_0, {}, {}, []);
export { MapEditComponentNgFactory as MapEditComponentNgFactory };
