<header app-header class="ras-header"></header>
<main>
    <div class="ras-container-medium">
        <section class="ras-start">
            <h1 class="ras-main-title">{{(edit ? 'newintense.modify' : 'newintense.create') | translate}} </h1>
            <form>
                <div class="ras-start__form">
                    <div class="ras-form-item">
                        <label for="title" class="ras-form-item__label">{{'newintense.title' | translate}}</label>
                        <input type="text" placeholder="inserisci titolo" id="title" [formControl]="title">
                    </div>

                    <div class="ras-form-item">
                        <label for="description" class="ras-form-item__label">{{'newintense.description' | translate}}</label>
                        <quill-editor [formControl]="description" [modules]="quillModules"></quill-editor>
                    </div>

                    <div class="ras-form-item ras-form-item--inline">
                        <h2 class="ras-form-item__label">{{'newintense.usetime' | translate}}</h2>
                        <div class="ras-form-item__wrapper">
                            <div class="ras-form-item__wrapper">
                                <div class="ras-radio">
                                    <input type="radio" name="period" id="all-year" value="all-year" [formControl]="radioPeriodo" (click)="addPeriod(false)">
                                    <label for="all-year">{{'newintense.allyear' | translate}}</label>
                                </div>
                                <div class="ras-radio">
                                    <input type="radio" name="period" id="period-define" value="period" [formControl]="radioPeriodo" (click)="addPeriod(true,true)">
                                    <label for="period-define">{{'newintense.timedefine' | translate}}</label>
                                </div>
                            </div>

                            <div class="ras-form-item__wrapper ras-form-item__wrapper-hidden" [ngClass]="{'is-visible': radioPeriodo?.value === 'period'}">



                                <!-- <div class="ras-form-item ras-datepicker">
                                    <label class="ras-form-item__label">{{'newintense.startdate' | translate}}</label>
                                    <mat-form-field>
                                        <input id="date-from" matInput [matDatepicker]="pickerFrom" [max]="dateTo.value"
                                            [formControl]="dateFrom" placeholder="GG/MM/AAAA" disabled>
                                        <mat-datepicker-toggle matSuffix [for]="pickerFrom">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerFrom></mat-datepicker>
                                    </mat-form-field>
                                </div> -->

                            </div>
                        </div>

                    </div>


                    <div class="ras-form-item__period" *ngFor="let period of periodoFruizione; let idx = index">
                        <select [(ngModel)]="period.holiday_label" [ngModelOptions]="{standalone: true}">
                            <option [value]="0">{{'newintense.openperiod' | translate}}</option>
                            <option *ngFor="let period of periods" [value]="period.name">{{period.name}}</option>
                        </select>
                        <div *ngIf="period.holiday_label === '0'">

                            <div class="fullyear">
                                {{'newintense.validity' | translate}}
                                <input type="checkbox" [(ngModel)]="period.is_not_precise" (change)="changePrecise($event,period)" [ngModelOptions]="{standalone: true}">
                            </div>
                            <div class="date-inizio-fine">
                                <mat-form-field>
                                    <label>{{'newintense.selectdatestart' | translate}}</label>
                                    <input matInput [matDatepicker]="pickerFrom" [(ngModel)]="period.fruizione_from" [ngModelOptions]="{standalone: true}" disabled hidden=true [readonly]="true" (click)="pickerFrom.open()">
                                    <input matInput [ngModel]="period.fruizione_from | moment : period.is_precise?'DD/MM/YYYY':'DD MMMM'" [ngModelOptions]="{standalone: true}" placeholder="{{'newintense.selectdatestart' | translate}}" (click)="pickerFrom.open()" [readonly]="true">
                                    <mat-datepicker-toggle class="ras-form-datepicker" matSuffix [for]="pickerFrom">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerFrom disabled="false"></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field>
                                    <label>{{'newintense.selectdateend' | translate}}</label>
                                    <input matInput [matDatepicker]="pickerTo" [(ngModel)]="period.fruizione_to" [ngModelOptions]="{standalone: true}" disabled hidden=true [readonly]="true" (click)="pickerTo.open()">
                                    <input matInput [ngModel]="period.fruizione_to | moment : period.is_precise?'DD/MM/YYYY':'DD MMMM'" [ngModelOptions]="{standalone: true}" placeholder="{{'newintense.selectdateend' | translate}}" (click)="pickerTo.open()" [readonly]="true">
                                    <mat-datepicker-toggle class="ras-form-datepicker" matSuffix [for]="pickerTo">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerTo disabled="false"></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="btnaddremove">
                            <button class="ras-btn btn-addremove-date" (click)="addPeriod(true)" [disabled]="!isValidPeriod(period)"> <i class="far fa-plus-circle"></i> </button>
                            <button class="ras-btn btn-addremove-date" style="display: inline-block;" (click)="removePeriod(idx)"> <i class="far fa-trash"></i> </button>
                        </div>
                    </div>


                </div>

                <div class="ras-start__form-action">
                    <button [routerLink]="['/search']" class="ras-btn ras-btn-light ras-btn-icon ras-btn-undo" *ngIf="!edit"><i class="fal fa-times-circle"></i> {{'common.cancel' | translate}}</button>
                    <button [routerLink]="['/edit']" class="ras-btn ras-btn-light ras-btn-icon ras-btn-undo" *ngIf="edit"><i class="fal fa-times-circle"></i> {{'common.cancel' | translate}}</button>
                    <button class="ras-btn" (click)="goToEdit()" [disabled]="formGroup.invalid || !isValidAllPeriods()">{{ (edit ? 'newintense.modifybtn' :'newintense.createbtn') | translate}}</button>
                </div>
            </form>
        </section>
    </div>
</main>