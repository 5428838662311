/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./map-search.component";
import * as i2 from "@ngrx/store";
import * as i3 from "../../logic/search-intense/search-intense.selectors";
import * as i4 from "../../logic/app-data/app-data.selectors";
import * as i5 from "../../services/LayerMaker.service";
import * as i6 from "../../services/utils.service";
var styles_MapSearchComponent = [];
var RenderType_MapSearchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapSearchComponent, data: {} });
export { RenderType_MapSearchComponent as RenderType_MapSearchComponent };
export function View_MapSearchComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["id", "search-map"]], null, null, null, null, null))], null, null); }
export function View_MapSearchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-search-map", [], null, null, null, View_MapSearchComponent_0, RenderType_MapSearchComponent)), i0.ɵdid(1, 245760, null, 0, i1.MapSearchComponent, [i2.Store, i3.SearchIntenseSelectors, i4.AppDataSelectors, i5.LayerMakerService, i6.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapSearchComponentNgFactory = i0.ɵccf("app-search-map, [app-search-map]", i1.MapSearchComponent, View_MapSearchComponent_Host_0, {}, {}, []);
export { MapSearchComponentNgFactory as MapSearchComponentNgFactory };
