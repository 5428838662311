
export const helpers = {
  core_hasOwn: Object.prototype.hasOwnProperty,

  snapshot(store) {
    let state;
    store.take(1).subscribe(s => state = s);
    return state;
  },

  // NOTE:
  //   immutAssign({}, {a: 2, b: 3}, {a: 1}) => {a: 1, b: 3}
  //   immutAssign({}, {a: 2, '<b': null}, {a: 1}) => {a: 1}
  //   immutAssign({}, {a: {a1: 1, a2: 2}, b: 3}, {a: {a1: 2}}) => {a: {a1: 2, a2: 2}, b: 3}
  //   immutAssign({}, {a: {a1: 1, a2: 2}, b: 3}, {'>a': {a1: 2}}) => {a: {a1: 2}, b: 3}
  immutAssign(dest, source, value) {
    let index, doOverwrite;
    const result = Object.assign(dest, source);

    for (const i in value) {
      if (value.hasOwnProperty(i)) {
        if (i.substring(-1, 1) === '>') {
          doOverwrite = true;
          index = i.substr(1);
        } else {
          index = i;
        }

        if (i.substring(-1, 1) === '<') {
          delete result[i.substr(1)];
          continue;
        }

        if (helpers.isObject(value[i]) &&
          typeof result[index] !== 'undefined' &&
          !doOverwrite) {
          result[index] = helpers.immutAssign(helpers.isArray(value[i]) ?
            [] : {}, source[index], value[i]);
        } else {
          result[index] = value[i];
        }
      }
    }

    return result;
  },

  deepAssign(...args: any[]) {
    let options, name, src, copy, copyIsArray, clone;
    let target = arguments[0] || {}, i = 1;
    const length = arguments.length;

    if (typeof target !== 'object' && !helpers.isFunction(target)) {
      target = {};
    }

    if (length === i) {
      target = this;
      --i;
    }

    for (; i < length; i++) {
      options = arguments[i];
      if (options !== null) {
        for (name in options) {
          if (options.hasOwnProperty(name)) {
            src = target[name];
            copy = options[name];

            if (target === copy) {
              continue;
            }

            copyIsArray = helpers.isArray(copy);
            if (copy && (helpers.isPlainObject(copy) || copyIsArray)) {
              if (copyIsArray) {
                copyIsArray = false;
                clone = src && helpers.isArray(src) ? src : [];

              } else {
                clone = src && helpers.isPlainObject(src) ? src : {};
              }

              target[name] = helpers.deepAssign(clone, copy);

            } else if (copy !== undefined) {
              target[name] = copy;
            }
          }
        }
      }
    }

    return target;
  },

  disabledEventPropagation(e: Event) {
    if (e) {
      if (e.stopPropagation) {
        e.stopPropagation();
      } else if (window.event) {
        window.event.cancelBubble = true;
      }
    }
  },

  isEmptyObject(obj) {
    return Object.keys(obj).length === 0 &&
      obj.constructor === Object;
  },

  getObjectsByKey(key, values) {
    if (!helpers.isObject(values)) {
      return;
    }

    const valuesKeys = Object.keys(values);

    const currents = valuesKeys.reduce((res, k) => {
      if (values[k][key]) {
        res.push(k);
      }
      return res;
    }, []);

    if (currents.length > 0) {
      return currents;
    }
  },

  getNewObjectInDeepCamelCase(oldObj, isArray?) {
    let key, camelKey, newObj;
    const keys = Object.keys(oldObj);
    let n = keys.length;

    if (Array.isArray(oldObj) && !isArray) {
      return helpers.getNewObjectInDeepCamelCase(oldObj, true);
    }

    if (!isArray) {
      newObj = {};
      while (n--) {
        key = keys[n];
        camelKey = key.replace(/_\w/g, (m) => m[1].toUpperCase());
        if (camelKey === 'idRicetta') {
          camelKey = 'id';
        }
        if (typeof oldObj[key] === 'object') {
          newObj[camelKey] = helpers.getNewObjectInDeepCamelCase(
            oldObj[key], Array.isArray(oldObj[key]));
        } else {
          newObj[camelKey] = oldObj[key];
        }
      }
    } else {
      newObj = [];
      for (let i = 0; i < n; i++) {
        if (typeof oldObj[i] === 'object') {
          newObj.push(helpers.getNewObjectInDeepCamelCase(
            oldObj[i], Array.isArray(oldObj[i])));
        } else {
          newObj.push(oldObj[i]);
        }
      }
    }

    return newObj;
  },

  getNewObjectInCamelCase(oldObj) {
    let key, camelKey;
    const keys = Object.keys(oldObj);
    let n = keys.length;
    const newObj = {};

    while (n--) {
      key = keys[n];
      camelKey = key.replace(/_\w/g, (m) => m[1].toUpperCase());
      newObj[camelKey] = oldObj[key];
    }

    return newObj;
  },

  isFunction(value) {
    return typeof value === 'function';
  },

  isObject(value) {
    // http://jsperf.com/isobject4
    return value !== null && typeof value === 'object';
  },

  isArray(value) {
    return Array.isArray(value);
  },

  isWindow(obj) {
    // In IE8 window.window !== window.window, so we allow == here.
    return obj !== null && obj === obj.window;
  },

  isPlainObject(obj) {
    if (!obj || typeof obj !== 'object' ||
      obj.nodeType || helpers.isWindow(obj)) {
      return false;
    }
    try {
      if (obj.constructor &&
        !helpers.core_hasOwn.call(obj, 'constructor') &&
        !helpers.core_hasOwn.call(obj.constructor.prototype, 'isPrototypeOf')) {
        return false;
      }
    } catch (e) {
      return false;
    }

    let key;
    for (key in obj) { if (key) { } }

    return key === undefined || helpers.core_hasOwn.call(obj, key);
  },

  pascalCaseToCamelCase(str) {
    return str.charAt(0).toLowerCase() + str.substring(1);
  },

  camelCaseToPascalCase(str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
  },

  camelCaseToSpaces(str) {
    return str
      .replace(/([A-Z])/g, ' $1')
      .split(' ')
      .map(word => word.charAt(0).toLowerCase() + word.substring(1))
      .join(' ');
  },

  dashCaseToCamelCase(str) {
    return str.replace(/-([a-z])/ig, (all, letter) => {
      return letter.toUpperCase();
    });
  },

  snakeCaseToCamelCase(str) {
    return str.replace(/_([a-z])/ig, (all, letter) => {
      return letter.toUpperCase();
    });
  },

  dashCaseToSpaces(str) {
    return this.camelCaseToSpaces(this.dashCaseToCamelCase(str));
  },

  generateShortUID() {
    return new Date().getTime() + Math.floor(Math.random() * 100000);
  },

  generateUID(separator?) {
    separator = separator || '-';

    function S4() {
      return (((1 + Math.random()) * 0x10000) || 0).toString(16).substring(1);
    }
    return (
      S4() + S4() +
      separator + S4() +
      separator + S4() +
      separator + S4() +
      separator + S4() +
      S4() + S4()
    );
  },

  differenceInSecondsBetweensDates(date1: Date, date2: Date): number {
    return Math.abs(
      Math.round(
        (date1.getTime() - date2.getTime()) / 1000
      )
    );
  },

  mmssfromSecs(secs) {
    const hours = Math.floor(secs / 3600)
      , minutes = Math.floor((secs - (hours * 3600)) / 60);
    let seconds = secs - (hours * 3600) - (minutes * 60);

    seconds = Math.round(seconds * 100) / 100;

    // return (hours < 10 ? '0' + hours : hours) + ':' +
    return (minutes < 10 ? '0' + minutes : minutes) +
      ':' + (seconds < 10 ? '0' + seconds : seconds);
  },

  /**
   * Pads text
   *
   * @param text
   *   Text to pad.
   *
   * @param length
   *   Total lenght of output string(s).
   *
   * @param position
   *   Where to add fill char:
   *     - 'start'
   *     - 'end'
   *
   * @param method
   *   Padding method:
   *     - 'multiline'
   *     - 'truncate'
   *     - 'ellipsis' (implies 'truncate')
   * @param fillChar
   *   Fill character
   */
  padExtra(text, maxLength, position?, method?, fillChar?) {
    if (!text || !text.length) {
      return '';
    }
    let parts
      , returnEmpty = false
      , _pad;
    const length = Math.min(text.length, maxLength)
      , output = [];
    position = typeof position === 'undefined' ? 'start' : position;
    method = typeof method === 'undefined' ? 'truncate' : method;
    fillChar = typeof fillChar === 'undefined' ? ' ' : fillChar;

    _pad = (stringPart) => {
      const pad = Array(Math.max(0, maxLength - stringPart.length) + 1).join(fillChar)
        , resultString = stringPart;
      return position === 'start' ? pad + resultString : resultString + pad;
    };

    parts = text.match(new RegExp('.{1,' + length + '}', 'g'));

    switch (method) {
      case 'multiline':
        parts.forEach((part) => output.push(_pad(part)));
        break;
      case 'truncate':
        if (parts.length) {
          output.push(_pad(parts[0]));
        }
        break;
      case 'elipsis':
        output.push(_pad(parts[0]).substr(0, length - 3) + '...');
        break;
      default:
        returnEmpty = true;
    }

    if (returnEmpty) {
      return [];
    }

    return output;
  },

  isEquivalentObj(a, b) {
    if (typeof a === 'undefined' && typeof b === 'undefined') {
      return true;
    }
    if ((typeof a === 'undefined' && typeof b !== 'undefined') || (typeof b === 'undefined' && typeof a !== 'undefined')) {
      return false;
    }

    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;
  },

  getNestedProp(keys, obj) {
    if (keys.length === 0 || typeof obj === 'undefined') {
      return undefined;
    }
    const key = keys.shift();
    if (keys.length === 0) {
      if (typeof obj[key] !== 'undefined') {
        return obj[key];
      }
      return undefined;
    }
    if (typeof obj[key] !== 'undefined') {
      return this.getNestedProp(keys, obj[key]);
    }
    return undefined;
  },

  getIdArray(elements) {
    if (!elements) {
      return;
    }
    const res = [];
    elements.forEach(element => {
      if (element.id) {
        res.push(element.id);
      }
    });
    return res;
  }
};
