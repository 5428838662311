import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Intense, Ost, Percorso, TrattoPercorso, Tappa, Periodo, Traduzione } from 'src/app/model/model.interfaces';
import { Observable, from, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PercorsoService } from './percorso.service';
import { UtilsService } from './utils.service';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, map, last, toArray } from 'rxjs/operators';
import * as moment from 'moment';


@Injectable({ providedIn: 'root' })
export class ApiService {
    defaultTappaName: '';

    SAVE_URL = environment.sin2api;
    FRUIZIONE_FORMAT = 'YYYY-MM-DD hh:mm:ss';
    DATAPRECISA_FORMAT = 'YYYY-MM-DD';

    STATO_PUBBLICATA = 'pubblicata';
    STATO_VALIDAZIONE = 'scheda_pronta';
    STATO_DRAFT = 'draft';

    STATUS_INTERRUPT = 0;
    STATUS_VALID = 1;

    CLASSE_TRADUZIONE_VUOTO = 'vuoto';
    CLASSE_TRADUZIONE_NONCOMPLETO = 'noncompleto';
    CLASSE_TRADUZIONE_COMPLETO = 'completo';

    ACTION_ADD = 'add';
    ACTION_REMOVE = 'remove';
    ACTION_EDIT = 'edit';
    ACTION_PROPERTY = 'action';
    ACTION_NOTHING = 'nothing';

    constructor(
        private http: HttpClient,
        private percorso: PercorsoService,
        private utils: UtilsService,
        private translate: TranslateService
    ) {
        setTimeout(() => {
            translate.get('common.defaultstagename').subscribe(res => this.defaultTappaName = res);
        }, 500);
    }

    public loginIdm(code): Observable<any> {
        return this.http.get(environment.authEndPoint.responseUrl + code);
    }

    public deleteIntense(intenseId: number): Observable<any> {

        const geojson = [{
            'type': 'FeatureCollection',
            'features': [
                {
                    'type': 'Feature',
                    'properties': {
                        'class': 'Scheda',
                        'delete': true,
                        'nid': intenseId
                    }
                }
            ]
        }];
        return this.http.post(this.SAVE_URL, geojson);
    }


    saveIntense(intenseScheda: Intense, original: Intense, traduzioni: Traduzione[], currentLang: string): Observable<any> {

        // console.log('-------: intenseScheda', intenseScheda);
        // console.log('-------: original', original);
        // console.log("-------: traduzioni", currentLang, traduzioni);

        const intense: Intense = this.utils.clone(intenseScheda);

        const intenseArray = this.createIntenseArray(intense, original, currentLang, traduzioni);
        console.log('-------: intenseArray', intenseArray);
        // console.log('-------: intenseArray JSON', JSON.stringify(intenseArray));
        // return of(null);

        return this.http.post(this.SAVE_URL, intenseArray);

        // return this.http.post(this.SAVE_URL, intenseArray).pipe(concatMap(res => {
        //     if ((!res['errors'] || !res['errors'].length) && firstSave) {

        //         return this.saveTranslations(intense, original, traduzioni, currentLang, res);
        //     } else {
        //         return of(res);
        //     }
        // })
        // );
    }

    // saveTranslations(intense: Intense, original: Intense, traduzioni: Traduzione[], currentLang: string, res) {

    //     const saves = [];
    //     for (const trad in traduzioni) {
    //         if (traduzioni.hasOwnProperty(trad)) {
    //             if (trad === currentLang || (!traduzioni[trad].modified)) { console.log('ignore trad', trad); continue; }

    //             // if(!traduzioni[trad].modified && firstSave){
    //             // // traduzione vuota di nuova scheda
    //             // }

    //             /// inserimento delle traduzioni nell'intense
    //             this.updateTranslation(intense, traduzioni[trad], res['affected_items']);
    //             const saveArray = this.createIntenseArray(intense, original, trad, false, null);

    //             // chiamate al servizio di salvataggio
    //             saves.push(
    //                 // this.http.post(this.SAVE_URL, saveArray)
    //                 saveArray
    //             );
    //         }
    //     }
    //     console.log('-------: saveArray', saves);
    //     // tutte le chiamate insieme
    //     return from(saves).pipe(concatMap(sa => this.http.post(this.SAVE_URL, sa)), toArray());
    // }

    createIntenseArray(intense, intenseOriginal, trad, traduzioni) {
        // creazione geojson con informazioni generali
        const geojson1 = {
            'type': 'FeatureCollection',
            'features': this.geneateAllFeatures(intense, intenseOriginal, trad, traduzioni),
            // 'properties': { 'locale': trad, 'stato': intense.stato }
        };
        // creazione geojson con informazioni del percorso
        const geojson2 = {
            'type': 'FeatureCollection',
            'features': this.geneateTrackFeatures(intense)
        };
        const saveArray = [geojson1, geojson2];

        return saveArray;
    }

    updateTranslation(intense: Intense, translation: Traduzione) {
        console.log('-----------: updateTranslation -> translation', translation);
        intense.descrizione = translation.descrizione;
        intense.name = translation.name ? translation.name : intense.name;
        intense.stato = translation.stato;
        if (intense.osts && translation.modified) {
            intense.osts.forEach(o => this.updateTranslationOst(o, translation.osts));
        } else {
            delete intense.osts;
        }

        if (intense.tappe && translation.modified) {
            intense.tappe.forEach((tappa, idx) => {

                // const tappaTrad = translation.tappe.find(t => t.id === tappa.id);
                const tappaTrad = translation.tappe[idx];
                if (tappaTrad) {
                    tappa.name = tappaTrad.name ? tappaTrad.name : tappa.name;
                }
                if (tappa.osts) {
                    tappa.osts.forEach(ost => {
                        this.updateTranslationOst(ost, translation.osts);
                    });
                }
                if (tappa.percorso && tappa.percorso.tratti) {
                    tappa.percorso.tratti.forEach(tr => {
                        this.updateTranslationOst(tr.ost, translation.osts);
                    });
                }
            });
        } else {
            delete intense.tappe;
        }
    }

    updateTranslationOst(o: any, osts: any) {
        let ost = null;
        if (o && o.id) {
            ost = osts.find(x => x.id === o.id);
            o.descrizione = ost ? ost.descrizione : o.descrizione;
        }
    }

    private geneateAllFeatures(intense: Intense, intenseOriginal: Intense, trad: string, traduzioni) {

        const firstSave = !intense.id;

        const data = [];
        for (const tradIdx in traduzioni) {
            if (traduzioni.hasOwnProperty(tradIdx)) {
                const traduzione = traduzioni[tradIdx];
                // console.log("-------: geneateAllFeatures -> traduzione", traduzione)
                const isMainLanguage = tradIdx === trad;
                if (isMainLanguage || traduzione.modified || firstSave) {
                    const intenseClone = this.utils.clone(intense);
                    if (!isMainLanguage) { this.updateTranslation(intenseClone, traduzione); }
                    const generateData = this.generateAllFeatureForSingleLanguage(intenseClone, intenseOriginal, tradIdx, isMainLanguage);
                    generateData.forEach(gd => data.push(gd));
                }
            }
        }
        return data;
    }

    generateAllFeatureForSingleLanguage(intense, intenseOriginal, trad, isMainLanguage) {
        const data = [];

        data.push(this.createIntenseFeature(intense, intenseOriginal, trad, isMainLanguage));
        // console.log('-----------: geneateAllFeatures -> intense', intense);

        if (intense.tappe) {
            for (let i = 0; i < intense.tappe.length; i++) {
                const originalTappa = null;
                if (intenseOriginal && intenseOriginal.tappe) { intenseOriginal.tappe.find(x => x.id === intense.tappe[i].id && !!intense.tappe[i].id); }
                const tappaData = this.createTappaFeature(intense.tappe[i], i, trad, originalTappa, isMainLanguage);
                // console.log('-----------: geneateAllFeatures -> tappaData', tappaData);
                if (tappaData) {
                    data.push(tappaData);
                    // const tappaId = tappaData.properties.tappaId; // i
                }
                const osts = this.getOstOfTappaWithOriginal(intense, i, intenseOriginal);
                // console.log('-------: getOstOfTappaWithOriginal -> osts', osts);
                for (let j = 0; j < osts.length; j++) {
                    const element: Ost = osts[j];
                    let originalOst = null;
                    if (intenseOriginal) { originalOst = this.utils.getOstsList(intenseOriginal).find(o => o.id === element.id); }
                    // TODO rimozione ost da tappe in cui non sono più
                    const geoOst = this.createOstFeature(element, i, trad, element.action, originalOst, isMainLanguage);
                    data.push(geoOst as any);
                }
            }
        }

        return data;
    }

    private geneateTrackFeatures(intense: Intense) {
        const data = [];
        for (let i = 0; i < intense.tappe.length; i++) {
            this.generatePercorsoTrattoJSON(intense.tappe[i].percorso, i).forEach(element => {
                data.push(element as any);
            });
        }
        // console.log('SAVE TRACK INTENSE -> data', data);
        return data;
    }

    private getOstOfTappaWithOriginal(intense: Intense, tappa, intenseOriginal: Intense) {
        const listNew = this.getOstOfTappa(intense, tappa);
        const listOriginal = [];
        if (intenseOriginal) { this.getOstOfTappa(intenseOriginal, tappa); }
        // console.log('-------: getOstOfTappaWithOriginal -> listNew,listOriginal', listNew, listOriginal);
        return this.getDifferencesCollection(listNew, listOriginal);
    }

    private getOstOfTappa(intense: Intense, tappa) {
        // console.log('TCL: ApiService -> getOstOfTappa -> intense', intense);
        const res = [];

        // recupero ost della tappa (controllando se non sia stata modificata)
        if (intense.tappe && intense.tappe[tappa] && intense.tappe[tappa].osts) {
            intense.tappe[tappa].osts.forEach(ost => {
                let ins = null;
                if (intense.osts && Array.isArray(intense.osts)) {
                    ins = intense.osts.find(o => o.id === ost.id);
                }
                if (!ins) { ins = ost; }
                res.push(ins);
            });
        }

        // recupero ost dei percorsi (controllando che non sia stato modificato)
        if (intense.tappe[tappa] && intense.tappe[tappa].percorso) {
            intense.tappe[tappa].percorso.tratti.forEach(
                tratto => {
                    if (tratto.ost && !res.find(o => o.id === tratto.ost.id)) {
                        let ins = null;
                        if (intense.osts && Array.isArray(intense.osts)) {
                            ins = intense.osts.find(o => o.id === tratto.ost.id);
                        }
                        if (!ins) { ins = tratto.ost; }
                        res.push(ins);
                    }
                });
        }

        return res;
    }
    private fakeGeometry() {
        return {
            'type': 'Point',
            'coordinates': [0, 0]
        };
    }

    private getDifferencesCollection(collectionFinal: any[], collectionOriginal: any[], withRemove: boolean = false) {
        const res = [];
        // console.log("-------: getDifferencesCollection -> original/final", collectionOriginal, collectionFinal);

        if (collectionFinal && collectionFinal.length) {
            for (const elemF of collectionFinal) {
                // if (!collectionOriginal || !collectionOriginal.find(x => this.utils.compareGeneralObj(x, elemF))) {
                const elemClone = this.utils.clone(elemF);
                if (collectionOriginal && elemF.id && collectionOriginal.find(x => x.id === elemF.id)) {
                    // if (!collectionOriginal.find(x => this.utils.compareGeneralObj(x, elemF))) {
                    elemClone.action = this.ACTION_EDIT;
                    // }

                } else {
                    elemClone.action = this.ACTION_ADD;
                }
                res.push(elemClone);
                // }
            }
        }
        if (withRemove && collectionOriginal && collectionOriginal.length) {
            for (const elemO of collectionOriginal) {
                if (!collectionFinal || !collectionFinal.find(x => this.utils.compareGeneralObj(x, elemO) || x.id === elemO.id)) {
                    const elemClone = this.utils.clone(elemO);
                    elemClone.action = this.ACTION_REMOVE;
                    res.push(elemClone);
                }
            }
        }
        // console.log('geDifferencesColl', collectionOriginal, collectionFinal, res);
        return res;
    }

    private createIntenseFeature(intense: Intense, originalIntense: Intense, trad: string, isMainLanguage: boolean) {
        // console.log('-----------: ApiService -> createIntenseFeature -> intense', intense);
        const intenseFeature: any = {
            'type': 'Feature',
            'properties': {
                'field_fonte_dati': [
                    'Frontend editoriale'
                ],
                'class': 'Scheda',
                'title': intense.name,
                'body': intense.descrizione,
                'nid': !!intense.id ? intense.id : undefined,
                'tipoFondo': [],
                'tipologia': [],
                'ambito': [],
                'fruizione': [],
                'galleria': [],
                'immaginePrincipale': [],
                'mezziTrasporto': intense.mezziTrasporto,
                'stato': intense.stato,
                'locale': trad,
                'action': !!intense.id ? this.ACTION_EDIT : this.ACTION_EDIT,
                'is_new_with_translation': !intense.id && isMainLanguage
            }
        };

        if (isMainLanguage) {
            console.log('-----------: ApiService -> createIntenseFeature -> intense, originalIntense', intense, originalIntense);
            if (intense && originalIntense) {
                intenseFeature.properties.allegati = this.getDifferencesCollection(intense.allegati, originalIntense.allegati);
                intenseFeature.properties.immagini = this.getDifferencesCollection(intense.immagine, originalIntense.immagine);
                intenseFeature.properties.periodoFruizione = this.creaPeriodiFruizioneDifferential(intense, originalIntense);
            } else {
                // intenseFeature.properties.allegati = this.getDifferencesCollection(intense.allegati, originalIntense.allegati);
                // intenseFeature.properties.immagini = this.getDifferencesCollection(intense.immagine, originalIntense.immagine);
                // intenseFeature.properties.periodoFruizione = this.creaPeriodiFruizioneDifferential(intense, originalIntense);
            }
        }

        return intenseFeature;
    }


    private creaPeriodiFruizioneDifferential(intenseNew: Intense, intenseOriginal: Intense) {
        if (this.utils.compareGeneralObj(intenseNew, intenseOriginal)) {
            return null;
        }
        const periodoNew = this.creaPeriodiFruizione(intenseNew.periodoFruizione);
        const periodoOriginal = this.creaPeriodiFruizione(intenseOriginal.periodoFruizione);
        return this.getDifferencesCollection(periodoNew, periodoOriginal);
    }

    private creaPeriodiFruizione(fruizione: Periodo[]) {
        if (!fruizione) {
            return null;
        }
        const res = [];
        fruizione.forEach(periodo => {
            const x = {};
            if (periodo.holiday_label && periodo.holiday_label !== '0') {
                x['festivita'] = periodo.holiday_label;
                x['dataEsatta'] = 0;
            } else if (periodo.is_precise) {
                x['dataEsatta'] = 1;
                x['dataEsattaDa'] = moment(periodo.fruizione_from ? periodo.fruizione_from : periodo.fruizione_from_precise).format(this.DATAPRECISA_FORMAT);
                x['dataEsattaA'] = moment(periodo.fruizione_to ? periodo.fruizione_to : periodo.fruizione_to_precise).format(this.DATAPRECISA_FORMAT);
            } else {
                x['dataEsatta'] = 0;
                x['periodoFruizioneDa'] = moment(periodo.fruizione_from).format(this.FRUIZIONE_FORMAT);
                x['periodoFruizioneA'] = moment(periodo.fruizione_to).format(this.FRUIZIONE_FORMAT);
            }
            res.push(x);
        });

        return res;
    }

    private createTappaFeature(tappa, index, trad: string, originalTappa: Tappa, isMainLanguage: boolean) {
        let action = this.ACTION_EDIT;
        if (this.utils.compareGeneralObj(tappa, originalTappa)) {
            action = null;
        }
        return {
            'type': 'Feature',
            'properties': {
                'class': 'Tappa',
                'title': tappa.name, // ? tappa.name : (this.defaultTappaName + (index + 1)),
                'body': tappa.descrizione,
                'tappaId': index,
                'nid': tappa.id, // index,
                'locale': trad,
                'action': originalTappa || !isMainLanguage ? action : this.ACTION_ADD,
                'is_new_with_translation': !originalTappa && isMainLanguage
            },
            // 'geometry': this.fakeGeometry()
        };
    }

    private createOstFeature(ost: Ost, tappa_id, trad: string, action: string, originalOst, isMainLanguage: boolean) {
        // console.log('-----------: createOstFeature -> ost', ost);
        const ostObj: any = {
            'type': 'Feature',
            'properties': {
                'field_fonte_dati': [
                    'Frontend editoriale'
                ],
                'nid': ost.id,
                'class': 'Ost',
                'title': ost.name,
                'body': ost.descrizione,
                'tappaId': tappa_id,
                'disponibilita': 1,
                // 'ultimaVerifica': '2012-04-23T18:25:43.511Z',
                // 'dataImportazione': '2012-04-23T18:25:43.511Z',
                // 'dataScadenza': null,
                // 'fineIndisponibilita': null,
                // 'inizioIndisponibilita': null,
                'tipologia': !!ost.tipologia ? ost.tipologia : [],
                'riconoscimenti': [],
                'visitabile': ost.visitabile,
                'allegati': [],
                'idCastasto': ost.identificativoCatasto,
                'subclass': ost.type,
                'accessibilita': !!ost.accessibilitaDisabili ? ost.accessibilitaDisabili : [],
                'contatti': [],
                'field_id_ext': ost.identificativo,
                'immaginePrincipale': [],
                'locale': trad,
                'action': this.ACTION_EDIT
            }
        };
        if (isMainLanguage) {
            if(!!originalOst && (ost.descrizione === originalOst.descrizione) && this.hasSameImages(ost.immagine, originalOst.immagine)){
                ostObj.properties.action = this.ACTION_NOTHING;
            }
            ostObj.properties.immagini = originalOst ? this.getDifferencesCollection(ost.immagine, originalOst.immagine) : ost.immagine;
        }
        return ostObj;
    }

    private hasSameImages(immagini, originalImmagini): boolean{

        if((!immagini && !originalImmagini)){
            return true;
        }

        if( (!!immagini != !!originalImmagini)){
            if((Array.isArray(immagini) && immagini.length === 0 && originalImmagini === null) || 
            (Array.isArray(originalImmagini) && originalImmagini.length === 0 && immagini === null)){
                return true;
            }
            return false;
        }

        if(immagini.length != originalImmagini.length){
            return false;
        }

        let check = true;
        for (let i = 0; i < immagini.length; i++) {
            const immagine = immagini[i];
            if(!immagine.id || immagine.action === this.ACTION_REMOVE){
                check = false;
                break;
            }
        }

        return check;
    }


    private generateTrattoGeoJSON(tratto: TrattoPercorso, tappa_idx) {
        return {
            'type': 'Feature',
            'properties': {
                'nid': tratto['id'],
                'ostid': tratto.ost ? tratto.ost.id : 0,
                'tappaId': tappa_idx,
                'parziale': tratto.parziale
            },
            'geometry': this.utils.GetGeometry(tratto.tratto)
        };
    }

    private generatePuntoFinaleTrattoGeoJSON(tratto: TrattoPercorso, tappa_idx) {
        return {
            'type': 'Feature',
            'properties': {
                'nid': tratto['id'],
                'ostid': tratto.ost ? tratto.ost.id : 0,
                'tappaId': tappa_idx,
                'parziale': tratto.parziale
            },
            'geometry': this.utils.convertToGeoJSONpoint(tratto.ultimoPunto)
        };
    }

    private generatePuntoInizialeTrattoGeoJSON(perc: Percorso, tappa_idx) {
        return {
            'type': 'Feature',
            'properties': {
                'ostid': perc.ostIniziale && perc.ostIniziale.id ? perc.ostIniziale.id : 0,
                'tappaId': tappa_idx
            },
            'geometry': this.utils.convertToGeoJSONpoint(perc.inizio),
        };
    }

    private generatePercorsoTrattoJSON(percorso: Percorso, tappa_idx) {
        const res = [];
        if (percorso) {
            res.push(this.generatePuntoInizialeTrattoGeoJSON(percorso, tappa_idx));
            percorso.tratti.forEach(tratto => {
                res.push(this.generateTrattoGeoJSON(tratto, tappa_idx));
                res.push(this.generatePuntoFinaleTrattoGeoJSON(tratto, tappa_idx));
            }
            );
        }
        return res;
    }

    /// Funzione che completa i dati di una scheda intense. In caso di una scheda 'vecchia' la trasforma in una nuova, mentre per le nuove ricostruisce il percorso a partire dal geojson
    public async completeIntense(incompleteIntense: Intense): Promise<Intense> {

        if (incompleteIntense && incompleteIntense.geojson && incompleteIntense.geojson.value) {
            console.log(' NEW format Intense ----- Parse', incompleteIntense);
            return this.parseIntese(incompleteIntense);
        }

        if (incompleteIntense && (!incompleteIntense.geojson || !incompleteIntense.geojson.value)) {
            console.log(' OLD format Intense ----- Import', incompleteIntense);
            return this.importFromOld(incompleteIntense);
        }

        return incompleteIntense;
    }

    public async importFromOld(incompleteIntense: Intense): Promise<Intense> {
        const intense = this.utils.clone(incompleteIntense);
        for (let t = 0; t < intense.tappe.length; t++) {
            const tappa = intense.tappe[t];
            if (tappa.osts) {
                for (let i = tappa.osts.length - 1; i >= 0; i--) {
                    const ost = tappa.osts[i];
                    if (this.utils.isOstTratta(ost)) {
                        const tratto = await this.utils.getOstGeometry(ost);
                        if (!tappa.percorso) {
                            tappa.percorso = { tratti: [] };
                            tappa.percorso.inizio = this.utils.getFirstCoords(tratto);
                            tappa.percorso.ostIniziale = ost;
                        }
                        tappa.percorso.tratti.push({
                            ost: ost,
                            tratto: tratto,
                            ultimoPunto: this.utils.getLastCoords(tratto),
                            parziale: false // nei vecchi gli ost sono sempre completi
                        });

                        tappa.osts.splice(i, 1);
                    }
                }
            } else { tappa.osts = []; }
        }

        return intense;
    }

    public async parseIntese(incompleteIntense: Intense): Promise<Intense> {

        const currentIntense = this.utils.clone(incompleteIntense);
        // funzione per trovare un ost dentro una tappa utilizzate in seguito
        const getOst = (id, tappa: Tappa) => {
            return tappa.osts ? tappa.osts.find(x => x.id === id) : [];
        };

        // ricostruzione dell'oggetto percorso a partire dal geoJson dell'intense
        const percorsi = JSON.parse(currentIntense.geojson.value);
        if (percorsi && percorsi.features) {
            percorsi.features.forEach(feature => {
                // nell'intense esistono già le tappe a cui ogni feature è collegata
                let tappa = currentIntense.tappe[feature.properties.tappaId];
                if (!tappa) {
                    tappa = {};
                }
                if (!tappa.percorso) { // non esiste un percorso, viene creato
                    tappa.percorso = { tratti: [] };
                    // prima feature dovrebbe essere un punto che corrisponde al punto iniziale dell'intense
                    if (feature.geometry.type === 'Point') {
                        tappa.percorso.inizio = feature.geometry.coordinates;
                        tappa.percorso.ostIniziale = getOst(feature.properties.ostid, tappa);
                    }
                }
                // ogni tratto del percorso
                if (feature.geometry.type === 'LineString') {
                    tappa.percorso.tratti.push({
                        tratto: feature.geometry,
                        ost: getOst(feature.properties.ostid, tappa),
                        parziale: feature.properties.parziale,
                        ultimoPunto: {}
                    });
                }
                // punti di fine tratto associati all'ultimo tratto inserito
                if (feature.geometry.type === 'Point' && tappa.percorso.tratti.length > 0) {
                    tappa.percorso.tratti[tappa.percorso.tratti.length - 1].ultimoPunto = feature.geometry.coordinates;
                }
            });
        }

        return currentIntense;
    }

    getUserDetails(userID) {
        const url = environment.userDetailUrl.replace('${userID}', userID);
        return this.http.get(url);

    }

}
