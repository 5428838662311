import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
// import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Store } from '@ngrx/store';
import { RemovePercorsoTratto, PatchCurrentIntense, SaveIntense, CreateTappa, RemoveTappa, SelectTappa, RemoveOst, HighlightElement, SetType } from 'src/app/logic/search-ost';
import { Subject } from 'rxjs';
import { first, skip } from 'rxjs/operators';
import { Router } from '@angular/router';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DisplayModal } from 'src/app/logic/common';
import { ValidateIntensePipe } from 'src/app/pipes';
import { EditIntenseService } from 'src/app/views/edit-intense/edit-intense.service';
import { SearchOstSelectors } from 'src/app/logic/search-ost/search-ost.selectors';
import { UtilsService } from 'src/app/services/utils.service';
import { LayerMakerService } from 'src/app/services/LayerMaker.service';
import { config } from 'src/environments/config/config';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { AsrAuthGuard } from 'src/app/services/auth.service';
var RightSideBarComponent = /** @class */ (function () {
    function RightSideBarComponent(modalService, searchOstSelectors, editService, utils, router, store, layerMaker, translate, apiservice, auth) {
        this.modalService = modalService;
        this.searchOstSelectors = searchOstSelectors;
        this.editService = editService;
        this.utils = utils;
        this.router = router;
        this.store = store;
        this.layerMaker = layerMaker;
        this.translate = translate;
        this.apiservice = apiservice;
        this.auth = auth;
        this.currentIntense = null;
        this.destroy$ = new Subject();
        this.quillModules = config.QUILL_MODULES;
        this.languages = config.LANGUAGES;
        this.translations = [];
        this.intenseTranslations = [];
        this.currentLang = config.LANGUAGES[0];
        this.isAdmin = false;
    }
    RightSideBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchOstSelectors.getCurrentIntense$().pipe(takeUntil(this.destroy$), distinctUntilChanged(this.utils.compareGeneralObj)).subscribe(function (value) {
            _this.currentItem = value, _this.handleTappe(_this.currentItem).then(function (taps) { _this.tappe = taps; });
        });
        this.description = new FormControl(this.currentItem && this.currentItem.descrizione);
        this.editService.showDetails$().pipe(takeUntil(this.destroy$)).subscribe(function (value) { return _this.showDetails = value; });
        this.editService.showFilters$().pipe(takeUntil(this.destroy$)).subscribe(function (value) { return _this.showFilters = value; });
        this.description.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400)).subscribe(function (data) {
            _this.store.dispatch(new PatchCurrentIntense({ descrizione: data }));
        });
        this.isAdmin = this.auth.isUserValidator();
        this.searchOstSelectors.getCurrentIntenseTranslations$().pipe(takeUntil(this.destroy$)).subscribe(function (transl) { _this.intenseTranslations = transl; });
        // inizializzazione tappa selezionata (su prima tappa)
        this.store.dispatch(new SelectTappa(0));
        this.translate.get([
            'common.defaultlinkname',
            'rightsidebar.confirmremovepoi',
            'rightsidebar.confirmremovepath',
            'rightsidebar.pathmissing'
        ]).subscribe(function (res) {
            _this.translations = res;
        });
    };
    RightSideBarComponent.prototype.handleTappe = function (intense) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newTappe, _i, _a, tappa, osts, tratti, newTappa, i, newTratto, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.currentIntense = intense;
                        newTappe = [];
                        if (!intense || !intense.tappe) {
                            return [2 /*return*/, newTappe];
                        }
                        _i = 0, _a = intense.tappe;
                        _c.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 7];
                        tappa = _a[_i];
                        if (!(tappa.percorso && tappa.percorso.tratti)) return [3 /*break*/, 6];
                        osts = this.utils.clone(tappa.osts);
                        tratti = tappa.percorso.tratti;
                        newTappa = { tappa: {}, tratti: [], name: tappa.name };
                        i = 0;
                        _c.label = 2;
                    case 2:
                        if (!(i < tratti.length)) return [3 /*break*/, 5];
                        newTratto = this.utils.clone(tratti[i]);
                        _b = newTratto;
                        return [4 /*yield*/, this.getPoiOfTratto(osts, newTratto)];
                    case 3:
                        _b.poi = _c.sent();
                        newTappa.tratti.push(newTratto);
                        _c.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5:
                        newTappe.push(newTappa);
                        _c.label = 6;
                    case 6:
                        _i++;
                        return [3 /*break*/, 1];
                    case 7: return [2 /*return*/, newTappe];
                }
            });
        });
    };
    RightSideBarComponent.prototype.getPoiOfTratto = function (osts, tratto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pois, i;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pois = [];
                        if (!(osts && osts.length)) return [3 /*break*/, 4];
                        i = osts.length - 1;
                        _a.label = 1;
                    case 1:
                        if (!(i >= 0)) return [3 /*break*/, 4];
                        if (!!this.utils.isOstTratta(osts[i])) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.utils.isNearTratto(tratto, osts[i])];
                    case 2:
                        if (_a.sent()) {
                            pois.push(osts[i]);
                            osts.splice(i, 1);
                        }
                        _a.label = 3;
                    case 3:
                        i--;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, pois];
                }
            });
        });
    };
    RightSideBarComponent.prototype.trattoName = function (tratto) {
        if (tratto.ost && tratto.ost.name) {
            return tratto.ost.name;
        }
        else {
            return this.translations['common.defaultlinkname'];
        }
    };
    RightSideBarComponent.prototype.updateTappaSelected = function (idx) {
        if (!this.currentIntense) {
            return;
        }
        if (idx === -1 || idx == null) {
            this.currentIntense.currentTappa = this.tappe.length - 1;
        }
        else {
            this.currentIntense.currentTappa = idx;
        }
    };
    RightSideBarComponent.prototype.ostIcon = function (poi) {
        return 'fa-' + this.layerMaker.getAwesomeIcon(poi);
    };
    RightSideBarComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    RightSideBarComponent.prototype.editOst = function (ost) {
        var modOst = ost;
        // controllo se non esite già una versione modificata dell'ost
        if (this.currentIntense.osts) {
            var found = this.currentIntense.osts.find(function (o) { return o.id === ost.id; });
            if (found) {
                modOst = found;
            }
        }
        this.modalService.openDialog('editOst', modOst);
    };
    RightSideBarComponent.prototype.editTappaName = function (tappa, tappaIdx) {
        this.modalService.openDialog('editTappa', { tappa: tappa, tappaIdx: tappaIdx });
    };
    RightSideBarComponent.prototype.addOst = function (ost) {
        this.modalService.openDialog('add', null);
    };
    RightSideBarComponent.prototype.confirm = function (ost) {
        this.modalService.openDialog('confirm', ost);
    };
    RightSideBarComponent.prototype.creaTappa = function (i, trattoIdx) {
        // this.store.dispatch(new FeatureNotReady());
        this.store.dispatch(new CreateTappa({ indexTappa: i, indexTratto: trattoIdx }));
    };
    RightSideBarComponent.prototype.eliminaTappa = function (i) {
        this.store.dispatch(new RemoveTappa({ indexTappa: i }));
    };
    // dropTappa(event: CdkDragDrop<string[]>) {
    //     if (event.previousContainer === event.container) {
    //         moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    //     } else {
    //         transferArrayItem(event.previousContainer.data,
    //             event.container.data,
    //             event.previousIndex,
    //             event.currentIndex);
    //     }
    // }
    RightSideBarComponent.prototype.removeOst = function (ost, tappaIdx) {
        var data = {
            message: this.translations['rightsidebar.confirmremovepoi'],
            confirmAction: new RemoveOst({ ost: ost, indexTappa: tappaIdx })
        };
        this.store.dispatch(new DisplayModal({ type: 'delete', data: data }));
    };
    RightSideBarComponent.prototype.removeTratto = function (index, tappaIdx) {
        var data = {
            message: this.translations['rightsidebar.confirmremovepath'],
            confirmAction: new RemovePercorsoTratto({ indexTratto: index, indexTappa: tappaIdx })
        };
        this.store.dispatch(new DisplayModal({ type: 'delete', data: data }));
    };
    RightSideBarComponent.prototype.save = function (exit) {
        var validator = new ValidateIntensePipe();
        if (validator.transform(this.currentItem)) {
            this.store.dispatch(new SaveIntense({ item: this.currentItem, close: exit }));
        }
        else {
            this.store.dispatch(new DisplayModal({
                type: 'confirm',
                data: { message: this.translations['rightsidebar.pathmissing'] }
            }));
        }
    };
    RightSideBarComponent.prototype.editTitle = function () {
        this.store.dispatch(new PatchCurrentIntense({ descrizione: this.description.value }));
        this.router.navigate(['new']);
    };
    RightSideBarComponent.prototype.isTappaSelected = function (tappaIdx) {
        if (!this.currentIntense) {
            return false;
        }
        return this.currentIntense.currentTappa === tappaIdx;
    };
    RightSideBarComponent.prototype.selectTappa = function (tappaIdx) {
        // this.selectedTappa = tappa;
        this.store.dispatch(new SelectTappa(tappaIdx));
    };
    RightSideBarComponent.prototype.aggiungi = function () {
        var _this = this;
        this.searchOstSelectors.getType$()
            .pipe(first()).subscribe(function (type) {
            _this.store.dispatch(new SetType('ost_attrattore'));
            _this.searchOstSelectors.getResults$()
                // .pipe(skip(1), first()   => salta il primo risultato (vecchio) e prende quello dato dalla setType
                .pipe(skip(1), first()).subscribe(function (allOsts) {
                // filtrare solo ost che possono essere aggiunti
                var osts = [];
                var proms = [];
                allOsts.forEach(function (o) {
                    if (!_this.utils.isOstTratta(o)) {
                        var prom = _this.utils.getOstGeometry(o);
                        prom.then(function (geom) {
                            if (_this.utils.isNearPercorso(geom, _this.currentIntense.tappe[_this.currentIntense.currentTappa].percorso)) {
                                osts.push(o);
                            }
                        });
                        proms.push(prom);
                    }
                });
                Promise.all(proms).then(function () {
                    _this.store.dispatch(new SetType(type)); // rimposta tipo di filtro
                    _this.store.dispatch(new DisplayModal({ type: 'add', data: { osts: osts } }));
                });
            });
        });
    };
    RightSideBarComponent.prototype.tipoAttrattore = function (ost) {
        switch (ost.type) {
            case 'ost_attrattore': return 'attrattore';
            case 'ost_servizio':
            case 'ost_servizi': return 'servizio';
            case 'ost_waypoint': return 'sosta';
            default: return '';
        }
    };
    RightSideBarComponent.prototype.highlightElement = function ($event, obj) {
        if ($event) {
            $event.stopPropagation();
        }
        this.store.dispatch(new HighlightElement(obj));
    };
    RightSideBarComponent.prototype.showPhotos = function () {
        this.modalService.openDialog('editIntenseFile', { type: 'image', intense: this.currentIntense });
    };
    RightSideBarComponent.prototype.showFiles = function () {
        this.modalService.openDialog('editIntenseFile', { type: 'file', intense: this.currentIntense });
    };
    RightSideBarComponent.prototype.translateModal = function (lang) {
        this.modalService.openDialog('translate', { intense: this.currentIntense, lang: lang });
    };
    RightSideBarComponent.prototype.isDanger = function () {
        return this.utils.isIntenseInterrupted(this.currentIntense);
    };
    RightSideBarComponent.prototype.statoLingua = function (lang) {
        if (this.intenseTranslations && this.intenseTranslations[lang]) {
            return !this.intenseTranslations[lang].stato || this.intenseTranslations[lang].stato === this.apiservice.STATO_DRAFT ? this.apiservice.CLASSE_TRADUZIONE_VUOTO :
                this.intenseTranslations[lang].stato === this.apiservice.STATO_PUBBLICATA ? this.apiservice.CLASSE_TRADUZIONE_COMPLETO :
                    this.apiservice.CLASSE_TRADUZIONE_NONCOMPLETO;
        }
        return this.apiservice.CLASSE_TRADUZIONE_VUOTO; // stato della traduzione
    };
    RightSideBarComponent.prototype.attenzioneLingua = function (lang) {
        if (this.intenseTranslations && this.intenseTranslations[lang]) {
            // return this.currentIntense.changed > this.intenseTranslations[lang].changed;
            return this.intenseTranslations[lang].outdated;
        }
        return false; // TODO lingua non aggiornata
    };
    RightSideBarComponent.prototype.changeStatus = function ($event) {
        this.store.dispatch(new PatchCurrentIntense({ stato: $event.currentTarget.value }));
    };
    RightSideBarComponent.prototype.difficolta = function (currentItem) {
        var res = [];
        if (currentItem && currentItem.difficolta) {
            currentItem.difficolta.forEach(function (diff) {
                if (diff.name && !res.includes(diff.name)) {
                    res.push(diff.name);
                }
            });
        }
        this.utils.getOstsList(currentItem).forEach(function (ost) {
            if (ost.gradoDifficolta) {
                ost.gradoDifficolta.forEach(function (diff) {
                    if (diff.name && !res.includes(diff.name)) {
                        res.push(diff.name);
                    }
                });
            }
        });
        return res;
    };
    RightSideBarComponent.prototype.fruizione = function (currentItem) {
        var res = [];
        if (currentItem && currentItem.fruizione) {
            currentItem.fruizione.forEach(function (fru) {
                if (fru.name && !res.includes(fru.name)) {
                    res.push(fru.name);
                }
            });
        }
        this.utils.getOstsList(currentItem).forEach(function (ost) {
            if (ost.fruizione) {
                ost.fruizione.forEach(function (fru) {
                    if (fru.name && !res.includes(fru.name)) {
                        res.push(fru.name);
                    }
                });
            }
        });
        return res;
    };
    return RightSideBarComponent;
}());
export { RightSideBarComponent };
