import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { DisplayModalSuccess } from 'src/app/logic/common';

@Component({
    selector: 'app-delete-modal',
    templateUrl: './delete-modal.component.html'
})
export class DeleteModalComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private store: Store<any>) {
    }

    ngOnInit() {
    }

    confirm() {
        if (!!this.data && !!this.data.confirmAction) {
            this.store.dispatch(this.data.confirmAction);
        }
        this.store.dispatch(new DisplayModalSuccess({}));
    }

    close() {
        if (!!this.data && !!this.data.failAction) {
            this.store.dispatch(this.data.failAction);
        }
        this.store.dispatch(new DisplayModalSuccess({}));
    }
}
