/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./confirm-modal.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@ngrx/store";
var styles_ConfirmModalComponent = [];
var RenderType_ConfirmModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmModalComponent, data: {} });
export { RenderType_ConfirmModalComponent as RenderType_ConfirmModalComponent };
export function View_ConfirmModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "ras-modal ras-modal--confirm"], ["mat-dialog-content", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "ras-modal__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "ras-modal__message"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "ras-modal__actions ras-modal__actions-justified"], ["mat-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "button", [["class", "ras-btn ras-btn-light ras-btn-icon ras-btn-undo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-times-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["class", "ras-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.message; _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("common.cancel")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("common.ok")); _ck(_v, 10, 0, currVal_2); }); }
export function View_ConfirmModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-modal", [], null, null, null, View_ConfirmModalComponent_0, RenderType_ConfirmModalComponent)), i0.ɵdid(1, 114688, null, 0, i2.ConfirmModalComponent, [i3.MAT_DIALOG_DATA, i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmModalComponentNgFactory = i0.ɵccf("app-confirm-modal", i2.ConfirmModalComponent, View_ConfirmModalComponent_Host_0, {}, {}, []);
export { ConfirmModalComponentNgFactory as ConfirmModalComponentNgFactory };
