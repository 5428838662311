import { MatSnackBar } from '@angular/material';
import { Inject, Component } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar-component',
    templateUrl: './snackbar.component.html'
})
export class SnackbarComponent {
    public osts = [];
    private _isHorse = null;
    private _isBike = null;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
        public snackBar: MatSnackBar) {
        if (!Array.isArray(data)) {
            this.osts.push(data);
        } else { this.osts = data; }
    }

    public classForFruizione(fruizione) {
        console.log('Fruizione', fruizione);
        switch (fruizione) {
            case 'bicicletta': return 'fas fa-bicycle';
            case 'cavallo': return 'fas fa-horse';
            case 'trekking': return 'fas fa-hiking';
            default: return 'hide';
        }
    }
}
