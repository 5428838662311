import { State } from './search-ost.interface';
import { SearchOstActionTypes, SearchOstActions, AddOst, RemoveTappa } from './search-ost.actions';
import { helpers } from 'src/app/helpers';
import { Ost, Intense, Tappa } from 'src/app/model/model.interfaces';

export const DEFAULT_OST_NUM_PER_PAGE = 99;
export const DEFAULT_OST_START_PAGE = 0;
export const initialState: State = {
    query: null,
    filters: [],
    activeFilters: [],
    page: 0,
    numResults: 0,
    boundingBox: null,
    results: [],
    type: null,
    previousState: null,
    size: DEFAULT_OST_NUM_PER_PAGE,
    currentIntenseOriginal: null,
    currentIntense: null,
    currentIntenseTranslations: [],
    selectOst: null,
    updated: true,
    HighlightElement: null
};

export function reducer(state: State = initialState, action: SearchOstActions): State {
    let nextState;
    switch (action.type) {
        case SearchOstActionTypes.SearchOst: {
            // nextState = helpers.immutAssign({}, state, {
            //     query: action.payload.query || null,
            //     activeFilters: action.payload.filters || [],
            //     filters: [],
            //     // '>results': [],
            //     numResults: 0,
            //     page: action.payload.page || DEFAULT_OST_START_PAGE,
            //     boundingBox: action.payload.boundingBox || null,
            //     excludedOst: action.payload.excludedOst || [] ,
            //     size: action.payload.size || state.size  ,
            //     previousState: state,
            //     currentIntense: action.payload.intense || state.currentIntense || null
            // });
            nextState = {
                query: action.payload.query || null,
                filters: [],
                results: state.results.map(x => Object.assign({}, x)),
                activeFilters: action.payload.filters || [],
                page: action.payload.page || 0,
                numResults: state.numResults,
                type: action.payload.type ? action.payload.type : null,
                boundingBox: action.payload.boundingBox ? action.payload.boundingBox : null,
                previousState: JSON.parse(JSON.stringify(state)),
                size: action.payload.size || 0,
                currentIntenseOriginal: state.currentIntenseOriginal,
                currentIntense: state.currentIntense,
                currentIntenseTranslations: state.currentIntenseTranslations,
                updated: false
            };
            return nextState;
        }

        case SearchOstActionTypes.SetFilter: {
            // nextState = helpers.immutAssign({}, state, {
            //     activeFilters: action.payload,
            //     filters: [],
            //     // '>results': [],
            //     numResults: 0,
            //     previousState: state
            // });
            nextState = {
                query: state.query,
                filters: [],
                results: state.results.map(x => Object.assign({}, x)),
                activeFilters: action.payload,
                page: state.page,
                type: state.type,
                numResults: state.numResults,
                boundingBox: Object.assign({}, state.boundingBox),
                previousState: Object.assign({}, state),
                size: state.size,
                currentIntenseOriginal: state.currentIntenseOriginal,
                currentIntense: Object.assign({}, state.currentIntense),
                currentIntenseTranslations: Object.assign({}, state.currentIntenseTranslations),
                updated: false
            };
            return nextState;
        }

        case SearchOstActionTypes.SetQuery: {
            // nextState = helpers.immutAssign({}, state, {
            //     query: action.payload,
            //     filters: [],
            //     // '>results': [],
            //     numResults: 0,
            //     previousState: state
            // });
            nextState = {
                query: action.payload,
                filters: [],
                results: state.results.map(x => Object.assign({}, x)),
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                type: state.type,
                numResults: state.numResults,
                boundingBox: Object.assign({}, state.boundingBox),
                previousState: Object.assign({}, state),
                size: state.size,
                currentIntenseOriginal: state.currentIntenseOriginal,
                currentIntense: Object.assign({}, state.currentIntense),
                currentIntenseTranslations: Object.assign({}, state.currentIntenseTranslations),
                updated: false
            };
            return nextState;
        }
        case SearchOstActionTypes.SetType: {
            // nextState = helpers.immutAssign({}, state, {
            //     size: action.payload,
            //     filters: [],
            //     // '>results': [],
            //     numResults: 0,
            //     previousState: state
            // });
            nextState = {
                query: state.query,
                filters: [],
                results: state.results.map(x => Object.assign({}, x)),
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                type: action.payload || null,
                numResults: state.numResults,
                boundingBox: Object.assign({}, state.boundingBox),
                previousState: Object.assign({}, state),
                size: state.size,
                currentIntenseOriginal: state.currentIntenseOriginal,
                currentIntense: Object.assign({}, state.currentIntense),
                currentIntenseTranslations: Object.assign({}, state.currentIntenseTranslations),
                updated: false
            };
            return nextState;
        }

        case SearchOstActionTypes.SetSize: {
            // nextState = helpers.immutAssign({}, state, {
            //     size: action.payload,
            //     filters: [],
            //     // '>results': [],
            //     numResults: 0,
            //     previousState: state
            // });
            nextState = {
                query: state.query,
                filters: [],
                results: state.results.map(x => Object.assign({}, x)),
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                type: state.type,
                numResults: state.numResults,
                boundingBox: Object.assign({}, state.boundingBox),
                previousState: Object.assign({}, state),
                size: action.payload,
                currentIntenseOriginal: state.currentIntenseOriginal,
                currentIntense: Object.assign({}, state.currentIntense),
                currentIntenseTranslations: Object.assign({}, state.currentIntenseTranslations),
                updated: false
            };
            return nextState;
        }

        case SearchOstActionTypes.SetBoundingBox: {
            // nextState = helpers.immutAssign({}, state, {
            //     boundingBox: action.payload,
            //     filters: [],
            //     // '>results': [],
            //     numResults: 0,
            //     previousState: state
            // });
            nextState = {
                query: state.query,
                filters: [],
                results: state.results.map(x => Object.assign({}, x)),
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                type: state.type,
                numResults: state.numResults,
                boundingBox: action.payload,
                previousState: Object.assign({}, state),
                size: state.size,
                currentIntenseOriginal: state.currentIntenseOriginal,
                currentIntense: Object.assign({}, state.currentIntense),
                currentIntenseTranslations: Object.assign({}, state.currentIntenseTranslations),
                selectOst: state.selectOst,
                updated: false
            };
            return nextState;
        }

        case SearchOstActionTypes.ClearFilters: {
            // nextState = helpers.immutAssign({}, state, {
            //     activeFilters: [],
            //     filters: [],
            //     // '>results': [],
            //     numResults: 0,
            //     size: DEFAULT_OST_NUM_PER_PAGE,
            //     boundingBox: null,
            //     previousState: state
            // });
            nextState = {
                query: state.query,
                filters: [],
                results: state.results.map(x => Object.assign({}, x)),
                activeFilters: [],
                page: 0,
                type: state.type,
                numResults: state.numResults,
                boundingBox: null,
                previousState: Object.assign({}, state),
                size: DEFAULT_OST_NUM_PER_PAGE,
                currentIntenseOriginal: state.currentIntenseOriginal,
                currentIntense: Object.assign({}, state.currentIntense),
                currentIntenseTranslations: Object.assign({}, state.currentIntenseTranslations),
                updated: false
            };
            return nextState;
        }

        case SearchOstActionTypes.SetPage: {
            // nextState = helpers.immutAssign({}, state, {
            //     page : action.payload,
            //     // '>results': [],
            //     previousState: state
            // });
            nextState = {
                query: state.query,
                filters: [],
                results: state.results.map(x => Object.assign({}, x)),
                activeFilters: [],
                page: action.payload,
                type: state.type,
                numResults: state.numResults,
                boundingBox: Object.assign({}, state.boundingBox),
                previousState: Object.assign({}, state),
                size: DEFAULT_OST_NUM_PER_PAGE,
                currentIntenseOriginal: state.currentIntenseOriginal,
                currentIntense: Object.assign({}, state.currentIntense),
                currentIntenseTranslations: Object.assign({}, state.currentIntenseTranslations),
                updated: false
            };
            return nextState;
        }

        case SearchOstActionTypes.SearchOstFail: {
            if (state.previousState == null) {
                return state;
            }
            nextState = Object.assign({}, state.previousState);
            nextState.previousState = null;
            return nextState;
        }

        case SearchOstActionTypes.SearchOstSuccess: {
            // nextState = helpers.immutAssign({}, state, {
            //     '>filters': action.payload.meta ? action.payload.meta : [],
            //     '>results': action.payload.data && action.payload.data.queryOst ? action.payload.data.queryOst.ost : [],
            //     page: 0,
            //     numResults: action.payload.data.queryOst.totalCount || 0
            // });
            nextState = {
                query: state.query,
                filters: action.payload.data.queryOst.facets || [],
                results: action.payload.data.queryOst.ost,
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: 0,
                type: state.type,
                numResults: action.payload.data.queryOst.totalCount,
                boundingBox: JSON.parse(JSON.stringify(state.boundingBox)),
                previousState: Object.assign({}, state),
                size: state.size,
                currentIntenseOriginal: state.currentIntenseOriginal,
                currentIntense: Object.assign({}, state.currentIntense),
                currentIntenseTranslations: Object.assign({}, state.currentIntenseTranslations),
                selectOst: state.selectOst,
                updated: false
            };
            return nextState;
        }

        case SearchOstActionTypes.SetPageSuccess: {
            nextState = helpers.immutAssign({}, state, {
                '>results': action.payload.data,
                'updated': false
            });
            return nextState;
        }

        case SearchOstActionTypes.SetCurrentIntense: {
            nextState = {
                query: state.query,
                filters: [],
                results: [],
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                numResults: 0,
                type: state.type,
                boundingBox: Object.assign({}, state.boundingBox),
                previousState: Object.assign({}, state),
                size: state.size,
                currentIntenseOriginal: state.currentIntenseOriginal ? state.currentIntenseOriginal : JSON.parse(JSON.stringify(action.payload)),
                currentIntense: JSON.parse(JSON.stringify(action.payload)),
                currentIntenseTranslations: Object.assign({}, state.currentIntenseTranslations),
                'updated': false
            };
            return nextState;
        }

        case SearchOstActionTypes.UpdateCurrentIntense: {
            nextState = {
                query: state.query,
                filters: [],
                results: [],
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                numResults: 0,
                type: state.type,
                boundingBox: Object.assign({}, state.boundingBox),
                previousState: Object.assign({}, state),
                size: state.size,
                currentIntenseOriginal: JSON.parse(JSON.stringify(action.payload)),
                currentIntense: JSON.parse(JSON.stringify(action.payload)),
                currentIntenseTranslations: Object.assign({}, state.currentIntenseTranslations),
                'updated': false
            };
            return nextState;
        }

        case SearchOstActionTypes.PatchCurrentIntense: {
            const newIntese = JSON.parse(JSON.stringify(state.currentIntense));
            for (const val in action.payload) {
                if (action.payload.hasOwnProperty(val)) {
                    newIntese[val] = action.payload[val];
                }
            }
            nextState = helpers.immutAssign({}, state, {
                currentIntense: newIntese, // action.payload,
                'updated': false
            });
            nextState.currentIntense = newIntese;
            return nextState;
        }

        case SearchOstActionTypes.ModifiedCurrentIntense: {
            const newIntese = JSON.parse(JSON.stringify(state.currentIntense));
            for (const val in action.payload.newIntense) {
                if (action.payload.newIntense.hasOwnProperty(val)) {
                    newIntese[val] = action.payload.newIntense[val];
                }
            }
            nextState = helpers.immutAssign({}, state, {
                currentIntense: newIntese, // action.payload,
                'updated': false
            });
            nextState.currentIntense = newIntese;
            return nextState;
        }

        case SearchOstActionTypes.UpdateTappa: {
            nextState = helpers.immutAssign({}, state, {
                '>currentIntense': updateTappa(action.payload, state.currentIntense)

            });
            return nextState;
        }

        case SearchOstActionTypes.UpdateOst: {
            nextState = helpers.immutAssign({}, state, {
                '>currentIntense': updateOst(action.payload, state.currentIntense)

            });
            return nextState;
        }

        case SearchOstActionTypes.AddOst: {
            nextState = helpers.immutAssign({}, state, {
                '>currentIntense': addOst(action.payload, state.currentIntense),
                'updated': true

            });
            return nextState;
        }

        case SearchOstActionTypes.RemoveOst: {
            nextState = helpers.immutAssign({}, state, {
                '>currentIntense': removeOst(action.payload, state.currentIntense),
                'updated': true
            });
            return nextState;
        }

        case SearchOstActionTypes.RemovePercorsoTratto: {
            nextState = helpers.immutAssign({}, state, {
                'updated': true
            });
            return nextState;
        }

        case SearchOstActionTypes.AddStartPoint: {
            nextState = helpers.immutAssign({}, state, {
                // '>currentIntense': addStartPoint(action.payload, state.currentIntense),
                'updated': true
            });
            return nextState;
        }

        case SearchOstActionTypes.AddPercorsoPoint: {
            nextState = helpers.immutAssign({}, state, {
                // '>currentIntense': addPercorsoPoint(action.payload, state.currentIntense),
                'updated': true
            });
            return nextState;
        }

        case SearchOstActionTypes.RemovePercorsoPoint: {
            nextState = helpers.immutAssign({}, state, {
                // '>currentIntense': removePercorsoPoint(action.payload, state.currentIntense),
                'updated': true
            });
            return nextState;
        }

        case SearchOstActionTypes.UpdatePercorsoPoint: {
            nextState = helpers.immutAssign({}, state, {
                '>currentIntense': updateIntense(action.payload, state.currentIntense)
            });
            return nextState;
        }

        case SearchOstActionTypes.UpdatePercorsoSuccess: {
            nextState = helpers.immutAssign({}, state, {
                '>currentIntense': updateIntense(action.payload, state.currentIntense)
            });
            return nextState;
        }


        case SearchOstActionTypes.SelectOst: {
            nextState = helpers.immutAssign({}, state, {
                '>selectOst': action.payload.ost,
                'updated': false
            });
            return nextState;
        }

        case SearchOstActionTypes.CreateTappa: {
            nextState = helpers.immutAssign({}, state, {
                // '>currentIntense': createTappa(action.payload, state.currentIntense),
            });
            return nextState;
        }

        case SearchOstActionTypes.CreateTappaSuccess: {
            nextState = helpers.immutAssign({}, state, {
                '>currentIntense': action.payload.newIntense,
                'updated': true
            });
            return nextState;
        }

        case SearchOstActionTypes.RemoveTappa: {
            nextState = helpers.immutAssign({}, state, {
                // '>currentIntense': removeTappa(action.payload, state.currentIntense)
            });
            return nextState;
        }

        case SearchOstActionTypes.RemoveTappaSuccess: {
            console.log('-----------: action.payload.newIntense', action.payload.newIntense);
            nextState = helpers.immutAssign({}, state, {
                '>currentIntense': action.payload.newIntense,
                'updated': true
            });
            console.log('-----------: nextState', nextState);
            return nextState;
        }

        case SearchOstActionTypes.RemoveTappaFail: {
            nextState = helpers.immutAssign({}, state, {
                // '>currentIntense':
            });
            return nextState;
        }

        case SearchOstActionTypes.SelectTappa: {
            nextState = helpers.immutAssign({}, state, {
                '>currentIntense': selectTappa(action.payload, state.currentIntense),
                'updated': true
            });
            return nextState;
        }

        case SearchOstActionTypes.SaveIntenseSuccess: {
            if (action.payload.close) {
                nextState = helpers.immutAssign({}, state, {
                    // currentIntense: null,
                    'currentIntenseOriginal': null,
                    'updated': false
                });
            } else {
                const newIntese = JSON.parse(JSON.stringify(state.currentIntense));
                let newId = state.currentIntense.id;
                console.log('-----------: newId', newId);
                console.log('-----------: action.payload.affected_items', action.payload, action.payload.resp.affected_items);
                if (action.payload.resp.affected_items && action.payload.resp.affected_items.intense) {
                    for (const prop in action.payload.resp.affected_items.intense) {
                        console.log('-----------: prop', prop);
                        if (prop) {
                            newId = parseInt(prop, 10);
                        }
                    }
                }
                console.log('-----------: newId', newId);

                newIntese.id = newId;
                const newInteseOriginal = JSON.parse(JSON.stringify(newIntese));
                nextState = helpers.immutAssign({}, state, {
                    currentIntense: newIntese,
                    'currentIntenseOriginal': newInteseOriginal,
                    'updated': false
                });
            }
            console.log('-----------SaveIntenseSuccess-> nextState', nextState);
            return nextState;
        }

        case SearchOstActionTypes.HighlightElement: {
            nextState = helpers.immutAssign({}, state, {
                '>HighlightElement': action.payload,
                'updated': false
            });
            return nextState;
        }

        case SearchOstActionTypes.GetIntenseTranslations: {
            nextState = helpers.immutAssign({}, state, {
                'currentIntenseTranslations': action.payload
            });


            nextState.currentIntenseTranslations = JSON.parse(JSON.stringify(nextState.currentIntenseTranslations));
            for (const t in nextState.currentIntenseTranslations) {
                if (nextState.currentIntenseTranslations.hasOwnProperty(t)) {
                    const translation = nextState.currentIntenseTranslations[t];
                    if (!translation.stato) { translation.stato = 'draft'; }
                }
            }

            return nextState;
        }

        case SearchOstActionTypes.UpdateIntenseTranslations: {
            nextState = helpers.immutAssign({}, state, {
                'currentIntenseTranslations': updateTranslation(action.payload.lang, action.payload.translations, state.currentIntenseTranslations)
            });
            return nextState;
        }
    }
    return state;
}

export function addOst(data: { ost: Ost, position: number }, currentIntense: Intense): Intense {
    const copy: Intense = JSON.parse(JSON.stringify(currentIntense));
    if (copy) {
        copy.currentTappa = copy.currentTappa ? copy.currentTappa : 0;
        const lastTappa: Tappa = copy.tappe[copy.currentTappa];
        lastTappa.osts.push(data.ost);
    }
    return copy;
}

export function updateTappa(data: { tappaIdx: number, tappa: Tappa }, currentIntense: Intense): Intense {
    const copy: Intense = JSON.parse(JSON.stringify(currentIntense));
    console.log('-----------: currentIntense', currentIntense);
    if (copy) {
        // console.log('TCL: copy', copy, data, copy.tappe[data.tappaIdx].name, data.tappa.name);
        copy.tappe[data.tappaIdx].name = data.tappa.name;
    }
    return copy;
}

export function updateOst(ost, currentIntense: Intense): Intense {
    const copy: Intense = JSON.parse(JSON.stringify(currentIntense));
    if (copy) {
        if (!copy.osts) { copy.osts = []; }
        const idx = copy.osts.findIndex(o => o.id === ost.id);
        if (idx >= 0) {
            copy.osts.splice(idx, 1);
        }
        copy.osts.push(ost);
    }
    return copy;
}

export function selectTappa(tappaIdx: number, currentIntense: Intense): Intense {
    const newIntense: Intense = JSON.parse(JSON.stringify(currentIntense));
    if (newIntense) { newIntense.currentTappa = tappaIdx; }
    return newIntense;
}

export function updateTranslation(lang: string, newTranslation: any, oldTranslations: any): Intense {
    const newTranslations: Intense = JSON.parse(JSON.stringify(oldTranslations));
    if (newTranslations) { newTranslations[lang] = newTranslation; }
    return newTranslations;
}

export function removeOst(item: any, currentIntense: Intense): Intense {
    const ost: Ost = item.ost;
    const copy: Intense = JSON.parse(JSON.stringify(currentIntense));
    const copyTappa: Tappa = copy.tappe[item.indexTappa];

    const ostIdx = copyTappa.osts.findIndex(o => o.id === ost.id);
    if (ost.type === 'ost_sentiero_percorso') {
        // rimozione ost e tutti i seguenti
        copyTappa.osts.splice(ostIdx);
    } else {
        // rimozione singolo ost non sentiero
        copyTappa.osts.splice(ostIdx, 1);

    }

    // una tappa vuota (non l'ultima) viene automaticamente eliminata
    if (ostIdx === 0 && copyTappa.osts.length === 0 && item.indexTappa !== (copy.tappe.length - 1)) {
        copy.tappe.splice(item.indexTappa, 1);
    }


    return copy;
}

export function updateIntense(item: any, currentIntense: Intense): Intense {
    const nextIntense: any = item.newIntense;
    return nextIntense;
}
