import { State } from './search-intense.interface';
import { SearchIntenseActionTypes, SearchIntenseActions } from './search-intense.actions';
import { helpers } from 'src/app/helpers';
import { Intense } from 'src/app/model/model.interfaces';

export const DEFAULT_SIZE = 10;
export const START_PAGE = 0;
export const initialState: State = {
    query: null,
    filters: [],
    activeFilters: [],
    page: 0,
    numResults: 0,
    boundingBox: null,
    results: [],
    previousState: null,
    size: DEFAULT_SIZE,
    selectedResult: null,
    uid: null,
    stato: null,
    status: null
};

export function reducer(state: State = initialState, action: SearchIntenseActions): State {
    let nextState;
    switch (action.type) {

        case SearchIntenseActionTypes.SearchIntense: {
            // nextState = helpers.immutAssign({}, state, {
            //     query: action.payload.query || null,
            //     activeFilters: action.payload.filters || [],
            //     filters: [],
            //     // results: [],
            //     numResults: 0,
            //     page: action.payload.page || START_PAGE,
            //     boundingBox: action.payload.boundingBox || null,
            //     size: action.payload.size || state.size || DEFAULT_SIZE,
            //     // previousState: state
            // });
            nextState = {
                query: action.payload.query || null,
                activeFilters: action.payload.filters || [],
                filters: [],
                results: [],
                numResults: 0,
                page: action.payload.page || START_PAGE,
                boundingBox: action.payload.boundingBox || null,
                size: action.payload.size || state.size || DEFAULT_SIZE,
                previousState: Object.assign({}, state),
                selectedResult: state.selectedResult,
                uid: action.payload.uid,
                stato: action.payload.stato,
                status: action.payload.status

            };
            console.log('-----------: SearchIntense -> nextState', nextState);
            return nextState;
        }

        case SearchIntenseActionTypes.SetFilter: {
            // nextState = helpers.immutAssign({}, state, {
            //     '>activeFilters': Object.assign({},action.payload)),
            //     filters: [],
            //     // results: [],
            //     numResults: 0,
            //     // previousState: state,
            // });

            nextState = {
                query: state.query,
                filters: [],
                activeFilters: action.payload.map(x => Object.assign({}, x)),
                page: state.page,
                numResults: 0,
                boundingBox: Object.assign({}, state.boundingBox),
                results: [],
                previousState: Object.assign({}, state),
                size: state.size,
                selectedResult: state.selectedResult,
                uid: state.uid,
                stato: state.stato,
                status: state.status
            };
            return nextState;
        }

        case SearchIntenseActionTypes.SetQuery: {
            // nextState = helpers.immutAssign({}, state, {
            //     query: action.payload,
            //     filters: [],
            //     // results: [],
            //     numResults: 0,
            //     // previousState: state,
            // });
            nextState = {
                query: action.payload,
                filters: [],
                // activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                activeFilters: [],
                page: state.page,
                numResults: 0,
                // boundingBox: Object.assign({}, state.boundingBox),
                boundingBox: null,
                results: [],
                previousState: Object.assign({}, state),
                size: state.size,
                selectedResult: state.selectedResult,
                uid: state.uid,
                stato: state.stato,
                status: state.status,
            };
            return nextState;
        }

        case SearchIntenseActionTypes.SetSize: {
            // nextState = helpers.immutAssign({}, state, {
            //     size: action.payload,
            //     filters: [],
            //     // results: [],
            //     numResults: 0,
            //     // previousState: state,
            // });
            nextState = {
                query: state.query,
                filters: [],
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                numResults: 0,
                boundingBox: Object.assign({}, state.boundingBox),
                results: [],
                previousState: Object.assign({}, state),
                size: action.payload,
                selectedResult: state.selectedResult,
                uid: state.uid,
                stato: state.stato,
                status: state.status,
            };
            return nextState;
        }

        case SearchIntenseActionTypes.SetBoundingBox: {
            // nextState = helpers.immutAssign({}, state, {
            //     boundingBox: action.payload,
            //     filters: [],
            //     // results: [],
            //     numResults: 0,
            //     // previousState: state,
            // });
            nextState = {
                query: state.query,
                filters: [],
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                numResults: state.numResults,
                boundingBox: action.payload,
                results: JSON.parse(JSON.stringify(state.results)),
                previousState: Object.assign({}, state),
                size: state.size,
                selectedResult: state.selectedResult,
                uid: state.uid,
                stato: state.stato,
                status: state.status,
            };
            return nextState;
        }

        case SearchIntenseActionTypes.ClearFilters: {
            // nextState = helpers.immutAssign({}, state, {
            //     activeFilters: [],
            //     filters: [],
            //     // results: [],
            //     numResults: 0,
            //     size: DEFAULT_SIZE,
            //     boundingBox: null,
            //     // previousState: state,
            //     selectedResult: null
            // });
            nextState = {
                query: null,
                filters: [],
                activeFilters: [],
                page: 0,
                numResults: 0,
                boundingBox: null,
                results: [],
                previousState: Object.assign({}, state),
                size: DEFAULT_SIZE,
                selectedResult: null,
                uid: null,
                stato: null,
                status: null,
            };
            return nextState;
        }

        case SearchIntenseActionTypes.SetPage: {
            // nextState = helpers.immutAssign({}, state, {
            //     page: action.payload,
            //     // results: [],
            //     // previousState: state,
            //     selectedResult: null
            // });
            nextState = {
                query: state.query,
                filters: [],
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: action.payload,
                numResults: 0,
                boundingBox: Object.assign({}, state.boundingBox),
                results: [],
                previousState: Object.assign({}, state),
                size: state.size,
                selectedResult: state.selectedResult,
                uid: state.uid,
                stato: state.stato,
                status: state.status,
            };
            return nextState;
        }

        case SearchIntenseActionTypes.SetUid: {
            nextState = {
                query: state.query,
                filters: [],
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                numResults: 0,
                boundingBox: Object.assign({}, state.boundingBox),
                results: [],
                previousState: Object.assign({}, state),
                size: state.size,
                selectedResult: state.selectedResult,
                uid: action.payload,
                stato: state.stato,
                status: state.status,
            };
            return nextState;
        }

        case SearchIntenseActionTypes.SetStatus: {
            nextState = {
                query: state.query,
                filters: [],
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                numResults: 0,
                boundingBox: Object.assign({}, state.boundingBox),
                results: [],
                previousState: Object.assign({}, state),
                size: state.size,
                selectedResult: state.selectedResult,
                uid: state.uid,
                stato: action.payload,
                status: state.status
            };
            return nextState;
        }

        case SearchIntenseActionTypes.SetInterruptStatus: {
            nextState = {
                query: state.query,
                filters: [],
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                numResults: 0,
                boundingBox: Object.assign({}, state.boundingBox),
                results: [],
                previousState: Object.assign({}, state),
                size: state.size,
                selectedResult: state.selectedResult,
                uid: state.uid,
                stato: state.stato,
                status: action.payload

            };
            return nextState;
        }

        case SearchIntenseActionTypes.SearchIntenseFail: {
            if (state.previousState == null) {
                return state;
            }
            nextState = state.previousState;
            // nextState.previousState = null;
            return nextState;
        }

        case SearchIntenseActionTypes.SearchIntenseSuccess: {
            // nextState = helpers.immutAssign({}, state, {
            //     '>filters': action.payload.data.querySchedeIntense.facets || [],
            //     '>results': action.payload.data.querySchedeIntense.schedeIntense,
            //     page: 0,
            //     numResults: action.payload.data.querySchedeIntense.totalCount || 0,
            //     selectedResult: findIntenseById(state.selectedResult, action.payload.data.querySchedeIntense.schedeIntense)
            // });
            nextState = {
                query: state.query,
                filters: action.payload.data.querySchedeIntense.facets || [],
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: 0,
                numResults: action.payload.data.querySchedeIntense.totalCount || 0,
                boundingBox: Object.assign({}, state.boundingBox),
                results: action.payload.data.querySchedeIntense.schedeIntense,
                previousState: Object.assign({}, state),
                size: state.size,
                selectedResult: findIntenseById(state.selectedResult, action.payload.data.querySchedeIntense.schedeIntense),
                uid: state.uid,
                stato: state.stato,
                status: state.status,
            };

            //////// TODO: valutare se serve patch valore stato di default /////////
            nextState.results = JSON.parse(JSON.stringify(nextState.results));
            nextState.results.forEach(element => {
                // console.log(element);
                if (!element.stato) { element.stato = 'draft'; }
            }); // default value
            //////////////////////////////////////////////////////////////////////////


            return nextState;
        }

        case SearchIntenseActionTypes.SetPageSuccess: {
            // nextState = helpers.immutAssign({}, state, {
            //     results: action.payload.data
            // });
            nextState = {
                query: state.query,
                filters: state.filters.map(x => Object.assign({}, x)),
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                numResults: state.numResults,
                boundingBox: Object.assign({}, state.boundingBox),
                results: action.payload.data.querySchedeIntense.schedeIntense,
                previousState: Object.assign({}, state),
                size: state.size,
                selectedResult: state.selectedResult,
                uid: state.uid,
                stato: state.stato,
                status: state.status,
            };
            return nextState;
        }

        case SearchIntenseActionTypes.SelectIntense: {
            // nextState = helpers.immutAssign({}, state, {
            //     selectedResult:  action.payload
            // });
            nextState = {
                query: state.query,
                filters: state.filters.map(x => Object.assign({}, x)),
                activeFilters: state.activeFilters.map(x => Object.assign({}, x)),
                page: state.page,
                numResults: state.numResults,
                boundingBox: Object.assign({}, state.boundingBox),
                results: state.results.map(x => Object.assign({}, x)),
                previousState: Object.assign({}, state),
                size: state.size,
                selectedResult: action.payload,
                uid: state.uid,
                stato: state.stato,
                status: state.status,
            };
            return nextState;
        }

        case SearchIntenseActionTypes.DeleteIntense: {
            return state;
        }

        case SearchIntenseActionTypes.DeleteIntenseSuccess: {
            return state;
        }
    }
    return state;
}

export function findIntenseById(id, data: Intense[]) {
    let found = null;
    for (let i = 0; i < data.length; i++) {
        const intense = data[i];
        if (intense.id === id) {
            found = id;
            break;
        }
    }
    return found;
}
