import {
  ActionReducerMap,
  ActionReducer,
  MetaReducer,
  createFeatureSelector,
} from '@ngrx/store';
import { environment } from '../../environments/environment';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
import { storeFreeze } from 'ngrx-store-freeze';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromSearchIntense from './search-intense';
import * as fromSearchOST from './search-ost';
import * as fromCommon from './common';
import * as fromEditOST from './edit-ost';
import * as fromAppData from './app-data';
// import .... TODO

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface AppState {
  search_intense: fromSearchIntense.State;
  search_ost: fromSearchOST.State;
  common: fromCommon.State;
  edit_ost: fromEditOST.State;
  app_data: fromAppData.State;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<AppState> = {
  search_intense: fromSearchIntense.reducer,
  search_ost: fromSearchOST.reducer,
  edit_ost: fromEditOST.reducer,
  common: fromCommon.reducer,
  app_data: fromAppData.reducer
};

// console.log all actions
export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function(state: AppState, action: any): AppState {
    console.debug('state', state);
    console.debug('action', action);
    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger, storeFreeze] : [];

/**
 * Feature state selectors
 */
export const searchIntenseSelector = createFeatureSelector<AppState, fromSearchIntense.State>('search_intense');
export const searchOstSelector = createFeatureSelector<AppState, fromSearchOST.State>('search_ost');
export const editOstSelector = createFeatureSelector<AppState, fromEditOST.State>('edit_ost');
export const commonSelector = createFeatureSelector<AppState, fromCommon.State>('common');
export const appDataSelector = createFeatureSelector<AppState, fromAppData.State>('app_data');
