import * as i0 from "@angular/core";
var CacheService = /** @class */ (function () {
    function CacheService() {
        this.cacheArray = [];
        this.MAX_SIZE = 400;
    }
    CacheService.prototype.get = function (key) {
        var foundIdx = this.cacheArray.findIndex(function (o) { return o.key === key; });
        if (foundIdx >= 0) {
            var value = this.cacheArray[foundIdx].value;
            // rinserimento in testa delle entry appena richieste
            this.cacheArray.splice(foundIdx, 1);
            this.cacheArray.push({ key: key, value: value });
            return value;
        }
    };
    CacheService.prototype.set = function (key, value) {
        //  caching circolare in cui gli elementi più vecchi vengono eliminati.
        if (this.cacheArray.length > this.MAX_SIZE) {
            this.cacheArray.shift();
        }
        this.cacheArray.push({ key: key, value: value });
    };
    CacheService.prototype.invalidate = function (key) {
        var foundIdx = this.cacheArray.findIndex(function (o) { return o.key === key; });
        if (foundIdx >= 0) {
            this.cacheArray.splice(foundIdx, 1);
        }
    };
    CacheService.ngInjectableDef = i0.defineInjectable({ factory: function CacheService_Factory() { return new CacheService(); }, token: CacheService, providedIn: "root" });
    return CacheService;
}());
export { CacheService };
