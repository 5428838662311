import { Action } from '@ngrx/store';

export enum CommonActionsTypes {
    StartLoading = '[Common] START_LOADING',
    StopLoading = '[Common] STOP_LOADING',
    DisplayModal = '[Common] DISPLAY_MODAL',
    DisplayModalSuccess = '[Common] DISPLAY_MODAL_SUCCESS',
    DisplayModalFail = '[Common] DISPLAY_MODAL_FAIL',
    FeatureNotReady = '[Common] FEATURE_NOT_READY',
    SetLocale = '[Common] SET_LOCALE'
}

export class StartLoading implements Action {
    readonly type = CommonActionsTypes.StartLoading;
}

export class StopLoading implements Action {
    readonly type = CommonActionsTypes.StopLoading;
}

export class DisplayModal implements Action {
    readonly type = CommonActionsTypes.DisplayModal;
    constructor(public payload?: any) { }
}

export class DisplayModalSuccess implements Action {
    readonly type = CommonActionsTypes.DisplayModalSuccess;
    constructor(public payload: any) { }
}
export class DisplayModalFail implements Action {
    readonly type = CommonActionsTypes.DisplayModalFail;
}

export class FeatureNotReady implements Action {
    readonly type = CommonActionsTypes.FeatureNotReady;
}

export class SetLocale implements Action {
    readonly type = CommonActionsTypes.SetLocale;
    constructor(public payload?: any) { }
}


export type CommonActions = StartLoading | StopLoading | DisplayModal | DisplayModalSuccess | DisplayModalFail | FeatureNotReady | SetLocale;
