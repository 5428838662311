import { Pipe, PipeTransform } from '@angular/core';
import { Tappa, Ost } from '../model/model.interfaces';

@Pipe({ name: 'attrattoriCounter' })
export class AttrattoriCounterPipe implements PipeTransform {
    transform(data: Tappa[]): number {
        if (!data) {
            return 0;
        }
        let counter = 0;
        data.forEach(tappa => {
            if (tappa.osts) {
                tappa.osts.forEach(ost => {
                    if (ost.type !== 'ost_sentiero_percorso') {
                        counter++;
                    }
                });
            }
        });
        return counter;
    }
}

@Pipe({ name: 'trattoCounter' })
export class TrattoCounterPipe implements PipeTransform {
    transform(data: Tappa[]): number {
        if (!data) {
            return 0;
        }
        let counter = 0;
        data.forEach(tappa => {
            if (tappa.osts) {
                tappa.osts.forEach(ost => {
                    if (ost.type === 'ost_sentiero_percorso') {
                        counter++;
                    }
                });
            }
        });
        return counter;
    }
}

@Pipe({ name: 'ostAttrattoriCounter' })
export class OstAttrattoriCounterPipe implements PipeTransform {
    transform(data: Ost[]): number {
        if (!data) {
            return 0;
        }
        let counter = 0;
        data.forEach(ost => {
            if (ost.type !== 'ost_sentiero_percorso') {
                counter++;
            }
        });
        return counter;
    }
}

@Pipe({ name: 'ostTrattoCounter' })
export class OstTrattoCounterPipe implements PipeTransform {
    transform(data: Ost[]): number {
        if (!data) {
            return 0;
        }
        let counter = 0;
        data.forEach(ost => {
            if (ost.type === 'ost_sentiero_percorso') {
                counter++;
            }
        });
        return counter;
    }
}

@Pipe({ name: 'trattoIndex' })
export class TrattoIndexPipe implements PipeTransform {
    transform(data: Ost[], ost: Ost): string {
        if (!data) {
            return '';
        }
        let counter = 0;
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.type === 'ost_sentiero_percorso') {
                counter++;
            }
            if (element.id === ost.id) {
                break;
            }
        }
        return counter > 9 ? '' + counter : '0' + counter;
    }
}

