import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DisplayModalSuccess } from 'src/app/logic/common';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormControl } from '@angular/forms';
import { config } from 'src/environments/config/config';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { CommonSelectors } from 'src/app/logic/common/common.selectors';
import { SearchOstSelectors } from 'src/app/logic/search-ost/search-ost.selectors';
import { Intense, Traduzione, TappaTraduzione } from 'src/app/model/model.interfaces';
import { UpdateIntenseTranslations } from 'src/app/logic/search-ost';
import { AsrAuthGuard } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-translate-modal',
    templateUrl: './translate-modal.component.html'
})
export class TranslateModalComponent implements OnInit, OnDestroy {

    private destroy$: Subject<void> = new Subject<void>();
    public intense: Intense;
    public traduzione: Traduzione;
    public descrizione: FormControl;
    public quillModules = config.QUILL_MODULES;

    public titoloTrad: string;
    public descrizioneTrad: string;
    public stato;
    public openedOst: any = null;
    public osts = null;
    public lang = config.LANGUAGES[0]; // 'it';
    public currentLang = config.LANGUAGES[0]; // 'it';
    public languages = config.LANGUAGES;


    public tappe: TappaTraduzione[] = [];
    isAdmin = false;
    modified = false;

    // public titleOst: string[] = [];
    // public descrizioneOst: FormControl[] = [];
    public descrizioneOst: any[] = [];
    public descrizioneOstOriginal: any[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        private store: Store<any>,
        private utils: UtilsService,
        private common: CommonSelectors,
        private searchOst: SearchOstSelectors,
        private auth: AsrAuthGuard,
        public apiservice: ApiService
    ) {

        this.isAdmin = auth.isUserValidator();
        this.traduzione = { name: '', descrizione: '', osts: [], tappe: [], stato: apiservice.STATO_DRAFT, modified: false };
        this.stato = apiservice.STATO_DRAFT;

    }

    ngOnInit() {
        this.intense = this.data.intense;
        this.lang = this.data.lang;

        this.common.locale$().pipe(takeUntil(this.destroy$)).subscribe(value => this.currentLang = value);

        this.searchOst.getCurrentIntenseTranslations$().pipe(takeUntil(this.destroy$)).subscribe(transl => {

            // recupero scheda tradotta nella lingua in cui tradurre
            this.traduzione = transl[this.lang];
            // console.log('-----------: TranslateModalComponent -> ngOnInit -> this.intense', this.data, this.intense);

            // assegnamento valori traduzioni
            this.descrizioneTrad = this.traduzione.descrizione;
            this.titoloTrad = this.traduzione.name;
            this.descrizione = new FormControl(this.descrizioneTrad);
            this.descrizione.valueChanges.subscribe(data => { this.edited(); });
            this.stato = this.traduzione.stato;

            // recupero traduzioni ost
            const osts = this.utils.getOstsList(this.intense);
            osts.forEach(o => {
                const oTrans = this.traduzione.osts.find(x => x.id === o.id);
                this.descrizioneOst.push({ id: o.id, name: o.name, descrizione: oTrans ? oTrans.descrizione : '' });
                this.descrizioneOstOriginal.push({ id: o.id, name: o.name, descrizione: oTrans ? oTrans.descrizione : '' });
            });
            this.osts = osts;

            // recupero traduzioni tappe
            this.tappe = [];
            this.intense.tappe.forEach((tappa, idx) => {
                const tappaTrans = this.traduzione.tappe[idx]; // this.traduzione.tappe.find(x => x.id === tappa.id);
                // console.log('-------: TranslateModalComponent -> ngOnInit -> this.traduzione', this.traduzione)
                this.tappe.push({ id: tappa.id, name: tappaTrans ? tappaTrans.name : '' });
            });
        });

        this.descrizione.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(400)).subscribe((data) => { this.descrizioneTrad = data; });
    }

    close() {
        this.destroy$.next();
        if (!!this.data && !!this.data.failAction) {
            this.store.dispatch(this.data.failAction);
        }
        this.store.dispatch(new DisplayModalSuccess({}));
    }

    save() {
        this.destroy$.next();
        if (!this.utils.compareGeneralObj(this.descrizioneOstOriginal, this.descrizioneOst)) {
            this.edited();
        }
        this.store.dispatch(new UpdateIntenseTranslations(
            {
                lang: this.lang, translations:
                {
                    name: this.titoloTrad,
                    descrizione: this.descrizioneTrad,
                    osts: this.descrizioneOst,
                    tappe: this.tappe,
                    stato: this.stato,
                    modified: this.modified
                }
            }));
        this.store.dispatch(new DisplayModalSuccess({}));
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openOst(ost) {
        if (ost !== this.openedOst) {
            this.openedOst = ost;
        } else {
            this.openedOst = null;
        }
    }

    changeStatus($event) {
        this.stato = $event.currentTarget.value;
        this.edited();
    }

    edited() {
        console.log('-----------: TranslateModalComponent -> edited -> this.modified', this.modified);
        this.modified = true;
        if (!this.isAdmin && this.stato === this.apiservice.STATO_PUBBLICATA) {
            this.stato = this.apiservice.STATO_DRAFT;
        }
    }

    onBlurMethod(idx, val) {
        console.log('-----------: TranslateModalComponent -> onBlurMethod -> idx, val', idx, val);
        const original = this.descrizioneOstOriginal[idx];
        if (original !== val) {
            this.edited();
        }

    }
}
