import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { SearchOstSelectors } from 'src/app/logic/search-ost/search-ost.selectors';
import { Ost } from 'src/app/model/model.interfaces';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { UpdateOst } from 'src/app/logic/search-ost';
import { Gallery, GalleryItem, ImageItem } from '@ngx-gallery/core';
import { TranslateService } from '@ngx-translate/core';
import { config } from 'src/environments/config/config';

@Component({
    selector: 'app-edit-ost-modal',
    templateUrl: './edit-ost-modal.component.html'
})
export class EditOstModalComponent implements OnInit, OnDestroy {


    // public currentIntese: Intense = { name: '', currentTappa: 0 };
    private destroy$: Subject<void> = new Subject<void>();
    public ost: Ost;
    public error: string;
    private translations = [];
    public quillModules = config.QUILL_MODULES;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EditOstModalComponent>,
        private store: Store<any>,
        private searchOstSelectors: SearchOstSelectors,
        private utils: UtilsService,
        public gallery: Gallery,
        private translate: TranslateService
    ) {
        this.ost = this.utils.clone(this.data);
        if (!this.ost.descrizione) { this.ost.descrizione = ''; }
        setTimeout(() => {
            this.translate.get(['modal.fileerror']).subscribe(res => this.translations = res);
        }, 500);
    }

    ngOnInit() {
        this.updateLightBox();
    }

    tipologia(ost) {
        switch (ost.type) {
            case 'ost_attrattore': return 'attrattore';
            case 'ost_servizio': return 'servizio';
            case 'ost_waypoint': return 'sosta';
            case 'ost_sentiero_percorso': return 'tratto';
            default: return '';
        }
    }

    tipologiaString(ost) {
        switch (ost.type) {
            case 'ost_attrattore': return 'common.actractor';
            case 'ost_servizio': return 'common.service';
            case 'ost_waypoint': return 'common.waypoint';
            case 'ost_sentiero_percorso': return 'common.section';
            default: return '';
        }
    }

    removeImg(idx) {
        this.ost.immagine.splice(idx, 1);
        this.updateLightBox();
    }

    updateLightBox() {
        const album = [];
        if (this.ost && Array.isArray(this.ost.immagine) && this.ost.immagine.length) {
            this.ost.immagine.forEach(img => {
                album.push(new ImageItem({ src: img.rawfile ? img.rawfile : img.url, thumb: img.rawfile ? img.rawfile : img.url }));
            });
        }
        this.gallery.ref().load(album);
    }

    // openImg(idx) {
    //     this.lightbox.open(idx);
    // }

    onFileChange($event) {
        if (!this.ost.immagine) { this.ost.immagine = []; }
        const reader = new FileReader();
        if ($event.target.files && $event.target.files.length > 0) {
            const file = $event.target.files[0];
            if (this.checkFile(file)) {
                reader.readAsDataURL(file);
                reader.onload = (x) => {
                    let res = '';
                    if (typeof reader.result === 'string') { res = reader.result; }
                    this.ost.immagine.push({
                        name: file.name,
                        // filetype: file.type,
                        url: null,
                        rawfile: res,
                        id: null
                    });
                    this.updateLightBox();
                };
                this.error = '';
            } else {
                this.error = this.translations['modal.fileerror'];
            }
        }
    }

    checkFile(file) {
        return file.size <= config.DIMENSIONI_MASSIME_IMMAGINE &&
            ((file.type as string).indexOf('image') === 0);

    }

    save() {
        this.store.dispatch(new UpdateOst(this.ost));
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
