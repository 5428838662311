import { Injectable } from '@angular/core';
import { Ost, PuntoPercorsoSuggerito } from 'src/app/model/model.interfaces';
import * as generateColor from 'string-to-color';
import { AddPercorsoPoint, RemovePercorsoPoint, AddStartPoint } from 'src/app/logic/search-ost';
import { UtilsService } from 'src/app/services/utils.service';
import { Store } from '@ngrx/store';
import * as L from 'leaflet';
import { ModalService } from './modal.service';
import { config } from 'src/environments/config/config';
import * as chroma from 'chroma-js';
import { TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: 'root' })
export class LayerMakerService {
    private translations = [];
    constructor(
        private store: Store<any>,
        private utils: UtilsService,
        private modalService: ModalService,
        private translate: TranslateService) {
        setTimeout(() => {
            this.translate.get(['action.removestageerror', 'action.farost']).subscribe(res => this.translations = res);
        }, 500);

    }


    public createStartMarker(geoJSONobj, isStart, ost, mouseClick: Function, mouseEnter: Function = null, mouseLeave: Function = null) {
        const point = isStart ? this.utils.getFirstCoords(geoJSONobj) : this.utils.getLastCoords(geoJSONobj);
        const direction = this.utils.calculateDirection(geoJSONobj, isStart);
        const color = this.colorForOst(ost);


        const dataContent = ost.name;

        const divHtml = `<div class="map-startpath-icon map-path-icon"` +
            // `style="transform: rotate(` + direction + `deg) translateY(-10px); color: ` + color + `"` +
            `data-content="` + dataContent + `"><div>`;

        const myIcon = L.divIcon({ html: divHtml });
        // you can set .map-startpath-icon styles in CSS
        const marker = L.marker([point[1], point[0]], { icon: myIcon });
        marker.ostid = ost.id;
        marker.on('click', ev => {
            if (mouseLeave) { mouseLeave(); }
            mouseClick(ost);
            this.store.dispatch(new AddStartPoint(
                {
                    inizio: { punto: point, ostProvenienza: ost.id },
                    direzionePredefinita: isStart
                }
            ));
        });
        if (mouseEnter) { marker.on('mouseover', ev => { mouseEnter(ost); }); }
        if (mouseLeave) { marker.on('mouseout', ev => { mouseLeave(); }); }
        return marker;
    }



    public createLastPointMarker(point) {
        const divHtml = `<div class="map-lastpath-icon map-path-icon"> <div>`;
        const myIcon = L.divIcon({ html: divHtml });

        const marker = L.marker([point[1], point[0]], { icon: myIcon });
        marker.on('click', ev => {
            this.store.dispatch(new RemovePercorsoPoint({}));
        });
        return marker;
    }

    public createEndOfLinePoli(geoJSONobj, color) {
        const center = this.utils.getLastCoords(geoJSONobj);
        const direction = this.utils.calculateDirection(geoJSONobj, false);
        const circle = L.semiCircle([center[1], center[0]],
            { radius: 10, fillOpacity: 1, fillColor: color, color: 'black', weight: '1' }).setDirection(direction + 180, 180);
        return circle;
    }

    public createStartOfLinePoli(geoJSONobj, color) {
        const center = this.utils.getFirstCoords(geoJSONobj);
        const direction = this.utils.calculateDirection(geoJSONobj, true);
        const circle = L.semiCircle([center[1], center[0]],
            { radius: 10, fillOpacity: 1, fillColor: color, color: 'black', weight: '1' }).setDirection(direction + 180, 180);
        return circle;
    }

    public createOstMarker(geoJSONobj, markerColor, ost) {
        return L.geoJSON(geoJSONobj, {
            // clickTolerance: 100, style: function () {
            //     return {
            //         'color': color,
            //         'weight': 3,
            //     };
            // },

            pointToLayer: (feature, latlng) => {
                const marker = L.AwesomeMarkers.icon({
                    icon: this.getAwesomeIcon(ost),
                    prefix: 'fa',
                    markerColor: markerColor,
                });
                return L.marker(latlng, {
                    icon: marker
                });
            }
        });
    }

    public createPercorsotPointMarker(punto: number[]) {
        if (!punto || punto.length < 2) { return null; }
        const divHtml = `<div class="map-path-icon map-middle-icon"> <div>`;
        const myIcon = L.divIcon({ html: divHtml });
        const marker = L.marker([punto[1], punto[0]], { icon: myIcon });
        return marker;
    }

    
    public isPointOst(p: PuntoPercorsoSuggerito){
        return p.ostProvenienza && p.ostProvenienza.id;
    }

    public isPointCross(p: PuntoPercorsoSuggerito){
        return p.ostProvenienza && p.ostProvenienza.id && p.ostConnessi && p.ostConnessi.length > 0;
    }

    public async createNextPointMarker(p: PuntoPercorsoSuggerito, isFar: boolean, mouseEnter: Function = null, mouseLeave: Function = null) {
        let classes = 'map-nextpath-icon map-path-icon';
        let style = '';
        if (this.isPointOst(p)) {
            classes += ` map-ost-icon`;
            if (this.isPointCross(p)) {
                classes += ` map-cross-icon`;
                // style += ` color: ` + this.colorForOst(p.ostConnessi[0]) + `;`;
            }
        }


        if (p.ostProvenienza && p.ostProvenienza.id && (!p.ostConnessi || p.ostConnessi.length === 0)) {
            // spostamento dei fine percorso
            const jsonObj = await this.utils.getOstGeometry(p.ostProvenienza);
            const startPoint = this.utils.getFirstCoords(jsonObj);
            const isStart = this.utils.isSamePoint(startPoint, p.punto);
            const dir = this.utils.calculateDirection(jsonObj, isStart);

            const y = Math.cos(-dir * Math.PI / 180) * 20;
            const x = Math.sin(-dir * Math.PI / 180) * 20;
            style += ' transform: translate(' + x + 'px,' + y + 'px); ';
        }

        if (p.ostConnessi && p.ostConnessi.length > 0) {
            style += ` color: ` + this.colorForOst(p.ostConnessi[0]) + `;`;
        }

        let dataContent = '';
        if (p.ostConnessi && p.ostConnessi.length > 0) {
            p.ostConnessi.forEach(ost => { dataContent += ost.name + '\n'; });
        } else {
            dataContent += p.ostProvenienza.name + '\n';
        }

        const divHtml = `<div class="` + classes + `" style="` + style + `" data-content="` + dataContent + `"> <div>`;
        const myIcon = L.divIcon({ html: divHtml });

        const marker = L.marker([p.punto[1], p.punto[0]], { icon: myIcon });
        marker.on('click', ev => {
            mouseLeave();
            const action = new AddPercorsoPoint({ punto: p });
            if (isFar && (!p.ostProvenienza || !p.ostProvenienza.id)) {
                const data = {
                    message: this.translations['action.farost'],
                    confirmAction: action
                };
                this.modalService.openDialog('confirm', data);
            } else { this.store.dispatch(action); }

        });
        marker.on('mouseover', ev => { mouseEnter(p); });
        marker.on('mouseout', ev => { mouseLeave(); });
        return marker;
    }

    public createRegionClusterMarker(center, number) {

        const divHtml = `<div class="map-cluster-icon" >` + number + `<div>`;
        const myIcon = L.divIcon({ html: divHtml });

        const marker = L.marker([center[1], center[0]], { icon: myIcon });
        return marker;
    }

    public createRegionPolygon(geometry, count) {
        const pol = L.geoJSON(geometry, {
            fillColor: this.colorForRegion(count),
            fillOpacity: config.REGION_FILL_OPACITY,
            stroke: true,
            color: config.REGION_STROKE_COLOR,
            weight: config.REGION_STROKE_WEIGHT,
        });
        return pol;
    }

    public colorForRegion(number) {
        if (number < 1) {
            return config.REGION_0_COLOR;
        }
        if (number <= 10) {
            const scale = chroma.scale([config.REGION_10_COLOR, config.REGION_100_COLOR]).mode('lab');
            return scale(number / 10).hex();
        }
        if (number < 100) {
            const scale = chroma.scale([config.REGION_100_COLOR, config.REGION_MAX_COLOR]).mode('lab');
            return scale((number - 10) / 90).hex();
        }
        return config.REGION_MAX_COLOR;
    }

    public colorForOst(ost) {
        // return 'red';
        return generateColor('gray-' + ost.name + ost.id);
    }

    public getAwesomeIcon(ost: Ost) {
        switch (this.utils.ostGetTipologia(ost)) {
            case 'natura': return 'leaf';
            case 'montagna': return 'mountain';
            case 'foresta': return 'tree';
            case 'panorama': return 'binoculars';
            case 'acqua': return 'water';
            case 'edificio': return 'landmark';
            case 'mare': return 'umbrella-beach';
            case 'monumento': return 'monument';
            case 'cultura': return 'book';
            case 'alloggio': return 'bed';
            case 'trasporto': return 'bus';
            case 'treno': return 'train';
            case 'ristorazione': return 'utensils';
            default: return 'map-marker';
        }
    }

}
