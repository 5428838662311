import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Facet, GeoQuery } from 'src/app/model/model.interfaces';
import { ApiService } from 'src/app/services/api.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SearchIntenseRequests {

    constructor(
        private apollo: Apollo,
        private api: ApiService
    ) { }

    ostInfo = gql`
        fragment OstInfo on OST {
            id
            name
            type
            identificativoCatasto
            identificativo
            lunghezza
            pendenza
            descrizione
            dislivelloSalita
            dislivelloDiscesa
            status
            distanza
            dislivelloPositivo
            dislivelloNegativo
            altezzaMassima
            altezzaMinima
            altezzaStart
            altezzaEnd
            tempoPercorrenza {
               type
               value
            }
            gradoProtezione
            percorrenza
            visitabile
            fruizione {
                name
            }
            tipologia {
                name
            }
            tematica {
                name
            }
            statoFondo {
                name
            }
            tipoFondo {
                name
            }
            presenzaSegnaletica {
                name
            }
            accessibilitaDisabili {
                name
            }
            riconoscimenti {
                name
            }
            rete {
                name
            }
            ambito {
                name
            }
            esposizioneRischi {
                name
            }
            gradoDifficolta {
               name
            }
            contatti {
               type
               value
            }
            allegati {
                name
            }
            immagine {
                name
                url
                id
            }
        }`;
    tappaInfo = gql`
        fragment TappaInfo on Tappa {
            name
            id
            osts { ...OstInfo }
        }
            ${this.ostInfo}
        `;
    schedaInfoFragment = gql`
        fragment IntenseInfo on Intense {
            id
            name
            descrizione
            stato
            tappe {    ...TappaInfo  }
            distanza
            dislivelloPositivo
            dislivelloNegativo
            altezzaMassima
            altezzaMinima
            altezzaStart
            altezzaEnd
            changed
            fruizione{
                name
            }
            difficolta{
                name
            }
            mezziTrasporto {
                name
            }
            durataPercorso
            pendenzaMax
            allegati {
                name
                url
                id
            }
            immagine {
                name
                url
                id
            }
            geojson {
                value
            }
            periodoFruizione {
                holiday_label
                is_precise
                fruizione_from_precise
                fruizione_to_precise
                fruizione_from
                fruizione_to
            }
            tile_preview{
                url
            }
        }
        ${this.tappaInfo}`;

    doSearch$(request) {
        const facets = [{ name: 'tematica' }, { name: 'tipologia' }, { name: 'fruizione' }, { name: 'regioni' }, { name: 'gradoDifficolta' }];
        interface Variables {

            fulltext: string | null;
            page: number;
            count: number;
            facets: Facet[];
            geoQuery: GeoQuery;
            filters: Facet[];
            locale: string | null;
            uid: number | null;
            stato: string | null;
            status: number | null;
        }
        const querySearch = gql`
        query($fulltext: String, $page: Int, $geoQuery: GeoQuery, $filters: [Facet], $count: Int, $facets: [Facet], $locale: String, $uid: Int, $stato: String $status: Int ) {
            querySchedeIntense(fulltext: $fulltext, page: $page, count: $count, geoQuery: $geoQuery, facets: $facets, filters: $filters, locale : $locale, uid: $uid, stato: $stato, status: $status )  {
                 schedeIntense{
                    ...IntenseInfo
                }
            totalCount
            facets {
                name
                values{
                  key
                  count
                }
              }
            }
        }${this.schedaInfoFragment}`;

        return this.apollo.watchQuery<any, Variables>({
            query: querySearch,
            variables: {
                fulltext: request.query,
                page: request.page,
                geoQuery: request.boundingBox,
                facets: facets,
                count: request.size,
                filters: this.createFiltersParam(request.filters),
                locale: request.lang,
                uid: request.uid,
                stato: request.stato,
                status: request.status
            }
        }).valueChanges;
    }

    doGetIntense$(idIntense, language) {
        interface Variables {
            id: number;
            locale: string | null;
        }
        const querySearch = gql`
        query($id: Int, $locale: String ) {
            findIntenseById(id: $id, locale: $locale )  {
                ...IntenseInfo
            }
        }${this.schedaInfoFragment}`;

        return this.apollo.watchQuery<any, Variables>({
            query: querySearch,
            variables: {
                id: idIntense,
                locale: language,
            }
        }).valueChanges;
    }

    doGetIntenseForTranslate$(idIntense, language) {
        interface Variables {
            id: number;
            locale: string | null;
        }
        const querySearch = gql`
        query($id: Int, $locale: String ) {
            findIntenseById(id: $id, locale: $locale )  {
                name
                descrizione
                tappe {    ...TappaInfo  }
                changed
                stato
            }
        }${this.tappaInfo}`;

        return this.apollo.watchQuery<any, Variables>({
            query: querySearch,
            variables: {
                id: idIntense,
                locale: language,
            }
        }).valueChanges;
    }

    doDelete$(payload) {
        return this.api.deleteIntense(payload.id).pipe(map(data => {
            if (data.error) {
                throw (data.error);
            }
            return data;
        }));
    }

    private createFiltersParam(filters): (Facet[] | null) {
        return filters;
    }

}
