
<div class="ras-tooltip" style="max-width: 380px; margin: 0 auto;">
    <div class="ras-tooltip__content">
        <fieldset class="ras-component-column">
            <legend class="ras-component-column-title">Ci sono <strong>3 attrattori</strong> collegati al tratto</legend>
            <div class="ras-form-item ras-checkbox">
                <input name="attrattori" id="attrattore1" type="checkbox">
                <label for="attrattore1"> Attrattore 1 </label>
            </div>
            <div class="ras-form-item ras-checkbox">
                <input name="attrattori" id="attrattore2" type="checkbox">
                <label for="attrattore2"> Attrattore 2</label>
            </div>

            <div class="ras-form-item ras-checkbox">
                <input name="attrattori" id="attrattore3" type="checkbox">
                <label for="attrattore3"> Attrattore 3</label>
            </div>
        </fieldset>
    </div>

    <div class="ras-tooltip__footer">
        <button class="ras-btn ras-btn-light ras-btn-icon"><i aria-hidden="true" class="far fa-plus-circle"></i> Aggiungi OST</button>
        <button class="ras-btn ras-btn-light ras-btn-icon ras-btn-undo"><i aria-hidden="true" class="fal fa-times-circle"></i> non adesso
        </button>
    </div>
</div>