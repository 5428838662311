import { State } from './app-data.interface';
import { AppDataActionsType, AppDataActions } from './app-data.actions';
import { helpers } from 'src/app/helpers';

export const DEFAULT_SIZE = 10;
export const START_PAGE = 0;

export const initialState: State = {
    schedeReady: false,
    ostReady: false,
    regionReady: false,
    regionArray: [],
    ostError: false,
    schedeError: false,
    regionError: false,
    fetchSchedeNumAttempts: 2,
    fetchOstNumAttempts: 2,
    fetchRegioniNumAttempts: 2,
    periodsArray: [],
};

export function reducer(state: State = initialState, action: AppDataActions): State {
    let nextState;
    switch (action.type) {
        case AppDataActionsType.FetchAllSchede: {
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: state.ostReady,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: state.ostError,
                schedeError: false,
                regionError: state.regionError,
                fetchSchedeNumAttempts: state.fetchSchedeNumAttempts,
                fetchOstNumAttempts: state.fetchOstNumAttempts,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchAllSchedeSuccess: {
            nextState = {
                schedeReady: true,
                ostReady: state.ostReady,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: state.ostError,
                schedeError: false,
                regionError: state.regionError,
                fetchSchedeNumAttempts: 2,
                fetchOstNumAttempts: state.fetchOstNumAttempts,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchAllSchedeFail: {
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: state.ostReady,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: state.ostError,
                schedeError: true,
                regionError: state.regionError,
                fetchSchedeNumAttempts: state.fetchSchedeNumAttempts - 1,
                fetchOstNumAttempts: state.fetchOstNumAttempts,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchAllRegioni: {
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: state.ostReady,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: state.ostError,
                schedeError: state.schedeError,
                regionError: false,
                fetchSchedeNumAttempts: state.fetchSchedeNumAttempts,
                fetchOstNumAttempts: state.fetchOstNumAttempts,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchAllRegioniSuccess: {
            const regArray = [];
            action.payload.data.queryRegioni.regioni.forEach(element => {
                regArray.push(element.name);
            });
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: state.ostReady,
                regionReady: true,
                regionArray: regArray,
                ostError: state.ostError,
                schedeError: state.schedeError,
                regionError: false,
                fetchSchedeNumAttempts: state.fetchRegioniNumAttempts,
                fetchOstNumAttempts: state.fetchOstNumAttempts,
                fetchRegioniNumAttempts: 2,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchAllRegioniFail: {
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: state.ostReady,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: state.ostError,
                schedeError: state.schedeError,
                regionError: true,
                fetchSchedeNumAttempts: state.fetchSchedeNumAttempts,
                fetchOstNumAttempts: state.fetchOstNumAttempts,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts - 1,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchAllOst: {
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: state.ostReady,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: false,
                schedeError: state.schedeError,
                regionError: state.regionError,
                fetchSchedeNumAttempts: state.fetchSchedeNumAttempts,
                fetchOstNumAttempts: state.fetchOstNumAttempts,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchAllOstSuccess: {
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: true,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: false,
                schedeError: state.schedeError,
                regionError: state.regionError,
                fetchSchedeNumAttempts: state.fetchSchedeNumAttempts,
                fetchOstNumAttempts: 2,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchAllOstFail: {
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: state.ostReady,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: true,
                schedeError: state.schedeError,
                regionError: state.regionError,
                fetchSchedeNumAttempts: state.fetchSchedeNumAttempts,
                fetchOstNumAttempts: state.fetchOstNumAttempts - 1,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchPeriods: {
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: state.ostReady,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: state.ostError,
                schedeError: state.schedeError,
                regionError: state.regionError,
                fetchSchedeNumAttempts: state.fetchSchedeNumAttempts,
                fetchOstNumAttempts: state.fetchOstNumAttempts,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchPeriodsSuccess: {
            // TODO: valori periodi presia da servizi
            const periodsArray = [];
            action.payload.data.queryFestivita.festivita.forEach(p => {
                periodsArray.push({ name: p.name, value: p.name });
            });
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: state.ostReady,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: state.ostError,
                schedeError: state.schedeError,
                regionError: state.regionError,
                fetchSchedeNumAttempts: state.fetchSchedeNumAttempts,
                fetchOstNumAttempts: state.fetchOstNumAttempts,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts,
                periodsArray: periodsArray
            };
            return nextState;
        }
        case AppDataActionsType.FetchPeriodsFail: {
            nextState = {
                schedeReady: state.schedeReady,
                ostReady: state.ostReady,
                regionReady: state.regionReady,
                regionArray: state.regionArray,
                ostError: state.ostError,
                schedeError: state.schedeError,
                regionError: state.regionError,
                fetchSchedeNumAttempts: state.fetchSchedeNumAttempts,
                fetchOstNumAttempts: state.fetchOstNumAttempts,
                fetchRegioniNumAttempts: state.fetchRegioniNumAttempts,
                periodsArray: state.periodsArray
            };
            return nextState;
        }
    }
    return state;
}
