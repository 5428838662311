<div class="ras-wrapper-centered ras-wrapper-centered-login">
    <div class="ras-logo">
        <img alt="Logo Intense" src="assets/images/logo.png" srcset="assets/images/logo@2x.png 2x">
    </div>
    <section class="ras-login">
        <div class="ras-login-panel" *ngIf="!hideLogin">
            <h1>Login</h1>
            <form [formGroup]="loginForm">
                <div class="ras-form-item ras-form-item--small">
                    <label for="username">{{'login.username' | translate}}</label>
                    <input type="text" formControlName="username" class="form-control" />

                </div>
                <div class="ras-form-item ras-form-item--small">
                    <label for="password">{{'login.password' | translate}}</label>
                    <input type="password" formControlName="password" class="form-control" />

                </div>
                <div class="ras-form-item ras-form-item--small" *ngIf="error">
                    <p class="error">{{error}}</p>
                </div>
                <div class="ras-form-item ras-form-item--action">
                    <button [disabled]="loginForm.invalid" type="button" (click)="login()"
                        class="ras-btn">{{'login.login' | translate}}</button>
                </div>
                <div class="ras-form-item ras-form-item--action" *ngIf="IMD_AUTH">
                    <button type="button" (click)="loginIdm()"
                        class="ras-btn ras-btn-secondary">{{'login.idm' | translate}}</button>
                </div>
            </form>
        </div>
    </section>
    <div class="ras-logo-interreg">
        <img alt="Logo Intense" src="assets/images/interreg.png" srcset="assets/images/interreg@2x.png 2x">
    </div>
    <div class="ras-logo-interreg">
        <a href="{{PRIVACY_POLICY_URL}}" target="_blank">Privacy policy</a>
    </div>
</div>