import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { DisplayModalSuccess } from 'src/app/logic/common';
import { SearchOstSelectors} from 'src/app/logic/search-ost/search-ost.selectors';
import { UpdateTappa } from 'src/app/logic/search-ost/search-ost.actions';
import { Intense, Tappa } from 'src/app/model/model.interfaces';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
    selector: 'app-edit-tappa-modal',
    templateUrl: './edit-tappa-modal.component.html'
})
export class EditTappaModalComponent implements OnInit, OnDestroy {

    public currentIntese: Intense = { name: '', currentTappa: 0 };
    private destroy$: Subject<void> = new Subject<void>();
    public tappa: Tappa;
    public tappaIdx: number;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EditTappaModalComponent>,
        private store: Store<any>,
        private searchOstSelectors: SearchOstSelectors,
        private utils: UtilsService
    ) {
        this.tappa = this.utils.clone(this.data.tappa);
        this.tappaIdx = this.data.tappaIdx;
        this.searchOstSelectors.getCurrentIntense$().pipe(takeUntil(this.destroy$), distinctUntilChanged(this.utils.compareGeneralObj)).subscribe(
            val => { this.currentIntese = val; });
    }

    ngOnInit() {

    }

    save() {
        this.store.dispatch(new UpdateTappa({tappaIdx : this.tappaIdx, tappa : this.tappa}));
        this.dialogRef.close();
        // this.store.dispatch(new DisplayModalSuccess({}));
    }

    cancel() {
        this.dialogRef.close();
        // this.store.dispatch(new DisplayModalSuccess({}));
    }


    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
