/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./delete-modal.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@ngrx/store";
var styles_DeleteModalComponent = [];
var RenderType_DeleteModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeleteModalComponent, data: {} });
export { RenderType_DeleteModalComponent as RenderType_DeleteModalComponent };
export function View_DeleteModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ras-modal ras-modal--has-columns"], ["mat-dialog-content", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "ras-modal__content"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 8, "div", [["class", "ras-modal__actions ras-modal__actions-justified"], ["mat-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "button", [["class", "ras-btn ras-btn-light ras-btn-icon ras-btn-undo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-times-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 3, "button", [["class", "ras-btn ras-btn-light ras-btn-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-trash"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.message; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("common.cancel")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("common.delete")); _ck(_v, 10, 0, currVal_2); }); }
export function View_DeleteModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-delete-modal", [], null, null, null, View_DeleteModalComponent_0, RenderType_DeleteModalComponent)), i0.ɵdid(1, 114688, null, 0, i2.DeleteModalComponent, [i3.MAT_DIALOG_DATA, i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteModalComponentNgFactory = i0.ɵccf("app-delete-modal", i2.DeleteModalComponent, View_DeleteModalComponent_Host_0, {}, {}, []);
export { DeleteModalComponentNgFactory as DeleteModalComponentNgFactory };
