<a [routerLink]="['/search/']" class="ras-logo" title="Vai alla Homepage"><img alt="Logo Intense"
        src="assets/images/logo.png"></a>

<!-- SEARCH HEADER-->
<div class="ras-search-autocomplete" [hidden]="!showSearchBox">
    <form class="ras-dashboard-filters__form">
        <!-- Search -->
        <div class="ras-search-autocomplete" >
            <mat-form-field>
                <div class="ras-search-bar">
                    <!-- <input type="text" placeholder="{{'search.searchintense' | translate}}" matInput [formControl]="searchControl" [matAutocomplete]="auto"> -->
                    <input type="text" placeholder="{{'search.searchintense' | translate}}" matInput
                        [formControl]="searchControl">
                    <i class="far fa-search"></i>
                </div>
            </mat-form-field>

            <mat-autocomplete #auto="matAutocomplete">
                <mat-option (onSelectionChange)="goToEdit(option, true)" *ngFor="let option of (results$ | async)"
                    [value]="option.name">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </div>
    </form>
</div>
<!-- END SEARCH HEADER-->

<a (click)="createNew()" class="ras-btn" *ngIf="!newPage">{{'header.create'|translate}} </a>
<ul class="ras-header__user-area">
    <li>
        <!-- <a [routerLink]="[]" class="ras-header__user-notify" (click)="notificationClick()"><i class="fal fa-bell"
                aria-hidden="true"></i></a> -->
    </li>

    <li>
        <a mat-icon-button [matMenuTriggerFor]="appMenu" class="ras-header__user-profile">
            <img [src]="userImg" *ngIf="userImg">
            <i class="fas fa-user-tie ras-header__user-img" *ngIf="!userImg"></i>
        </a>
        <mat-menu #appMenu="matMenu">
            <!-- <button mat-menu-item>Settings</button> -->
            <button mat-menu-item (click)="link('help')">{{'header.help'|translate}}</button>
            <button mat-menu-item (click)="link('support')">{{'header.support'|translate}}</button>
            <button mat-menu-item (click)="link('forum')">{{'header.forum'|translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="howto">Howto</button>
            <button mat-menu-item (click)="logout()">{{'header.logout'|translate}}</button>
        </mat-menu>

        <mat-menu #howto="matMenu">
            <button mat-menu-item (click)="link('howto_search')">{{'header.howto_search'|translate}}</button>
            <button mat-menu-item (click)="link('howto_edit')">{{'header.howto_edit'|translate}}</button>
            <button mat-menu-item (click)="link('howto_publish')">{{'header.howto_publish'|translate}}</button>
            <button mat-menu-item (click)="link('howto_translate')">{{'header.howto_translate'|translate}}</button>
        </mat-menu>
    </li>
</ul>