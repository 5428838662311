import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SearchOstRequests } from './search-ost.request';
import { map, switchMap, withLatestFrom, tap, catchError, filter } from 'rxjs/operators';
import { SearchOstActionTypes, SearchOstSuccess, SearchOstFail, SaveIntenseSuccess, UpdatePercorsoSuccess, GetIntenseTranslations, ModifiedCurrentIntense, UpdateCurrentIntense } from './search-ost.actions';
import { UpdatePercorsoPoint, SaveIntenseFail, RemoveTappaSuccess, RemoveTappaFail, CreateTappaSuccess } from './search-ost.actions';
import { SearchOstSelectors } from './search-ost.selectors';
import { Router } from '@angular/router';
import { of, from } from 'rxjs';
import { FetchAllSchede } from '../app-data';
import { DisplayModal } from 'src/app/logic/common';
import { TranslateService } from '@ngx-translate/core';
import { CommonSelectors } from '../common/common.selectors';
import { AsrAuthGuard } from 'src/app/services/auth.service';
import { Store } from '@ngrx/store';
import { SearchIntenseRequests } from '../search-intense';
import { ApiService } from 'src/app/services/api.service';
var SearchOstEffects = /** @class */ (function () {
    function SearchOstEffects(actions$, router, searchSelectors, commonSelectors, searchRequests, searchIntenseRequest, translate, auth, apiService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.router = router;
        this.searchSelectors = searchSelectors;
        this.commonSelectors = commonSelectors;
        this.searchRequests = searchRequests;
        this.searchIntenseRequest = searchIntenseRequest;
        this.translate = translate;
        this.auth = auth;
        this.apiService = apiService;
        this.store = store;
        this.translations = [];
        this.doSearchOst$ = this.actions$.pipe(ofType(SearchOstActionTypes.SearchOst, SearchOstActionTypes.SetBoundingBox, SearchOstActionTypes.SetFilter, SearchOstActionTypes.SetType, SearchOstActionTypes.AddOst, SearchOstActionTypes.RemoveOst, SearchOstActionTypes.RemovePercorsoTratto, SearchOstActionTypes.SetQuery), map(function (action) { return action['payload']; }), 
        // debounceTime(500),
        withLatestFrom(this.searchSelectors.getSearchRequest$()), switchMap(function (_a) {
            var _ = _a[0], request = _a[1];
            return _this.searchRequests.doSearch$(request).pipe(map(function (data) { return new SearchOstSuccess(data); }), catchError(function (error) { console.log('BOUNDING BOX ERROR', error); return of(new SearchOstFail(error)); }));
        }));
        this.saveIntense$ = this.actions$.pipe(ofType(SearchOstActionTypes.SaveIntense), map(function (action) { return action['payload']; }), withLatestFrom(this.searchSelectors.getCurrentIntense$(), this.searchSelectors.getCurrentIntenseOriginal$(), this.searchSelectors.getCurrentIntenseTranslations$(), this.commonSelectors.locale$()), switchMap(function (_a) {
            var p = _a[0], intense = _a[1], original = _a[2], traduzioni = _a[3], currentLang = _a[4];
            return _this.searchRequests.saveIntense(intense, original, traduzioni, currentLang).pipe(map(function (response) {
                console.log("saveIntense done", response);
                return new SaveIntenseSuccess({ resp: response, close: p.close });
            }), catchError(function (error) { console.log(error); return of(new SaveIntenseFail(error)); }));
        }));
        this.getIntenseTranslations$ = this.actions$.pipe(ofType(SearchOstActionTypes.SetCurrentIntense, SearchOstActionTypes.UpdateCurrentIntense), map(function (action) { return action['payload']; }), withLatestFrom(this.searchSelectors.getSearchRequest$()), switchMap(function (_a) {
            var payload = _a[0], request = _a[1];
            return _this.searchRequests.getTranslations(payload, request).pipe(map(function (response) { return new GetIntenseTranslations(response); }), catchError(function (error) { console.log('get translations error ', error); return of((error)); }));
        }));
        this.CreateTappa$ = this.actions$.pipe(ofType(SearchOstActionTypes.CreateTappa), map(function (action) { return action['payload']; }), withLatestFrom(this.searchSelectors.getCurrentIntense$()), switchMap(function (_a) {
            var payl = _a[0], request = _a[1];
            return from(_this.searchRequests.createTappa({ indexTratto: payl.indexTratto, indexTappa: payl.indexTappa, currentIntense: request })).pipe(map(function (payload, t) {
                return new CreateTappaSuccess(payload);
            }), catchError(function (error) { console.log('create tappa error ', error); return of((error)); }));
        }));
        this.RemoveTappa$ = this.actions$.pipe(ofType(SearchOstActionTypes.RemoveTappa), map(function (action) { return action['payload']; }), 
        // tap(data => console.log("effect remove tappa", data)),
        withLatestFrom(this.searchSelectors.getCurrentIntense$()), switchMap(function (_a) {
            var tappaIdx = _a[0], request = _a[1];
            return from(_this.searchRequests.removeTappa({ tappaIdx: tappaIdx, currentIntense: request })).pipe(map(function (payload, t) {
                console.log('-----------: SearchOstEffects -> payload', payload);
                return new RemoveTappaSuccess(payload);
            }), catchError(function (error) { console.log('remove tappa error ', error); return of(new RemoveTappaFail(error)); }));
        }));
        this.AddStartPoint$ = this.actions$.pipe(ofType(SearchOstActionTypes.AddStartPoint), map(function (action) { return action['payload']; }), withLatestFrom(this.searchSelectors.getCurrentIntense$()), withLatestFrom(this.searchSelectors.getResults$()), switchMap(function (_a) {
            var _b = _a[0], payl = _b[0], intense = _b[1], osts = _a[1];
            return from(_this.searchRequests.addStartPoint({ point: payl.inizio, direzionePredefinita: payl.direzionePredefinita, currentIntense: intense, osts: osts })).pipe(map(function (payload) {
                // console.log('TCL: SearchOstEffects -> payload', payload);
                return new UpdatePercorsoPoint(payload);
            }), catchError(function (error) { console.log('AddStartPoint error ', error); return null; }));
        }));
        this.AddPercorsoPoint$ = this.actions$.pipe(ofType(SearchOstActionTypes.AddPercorsoPoint), map(function (action) { return action['payload']; }), withLatestFrom(this.searchSelectors.getCurrentIntense$()), switchMap(function (_a) {
            var payl = _a[0], intense = _a[1];
            return from(_this.searchRequests.addPercorsoPoint({ point: payl.punto, currentIntense: intense })).pipe(map(function (payload) {
                return new UpdatePercorsoPoint(payload);
            }), catchError(function (error) { console.log('AddPercorsoPoint error ', error); return null; }));
        }));
        this.RemovePercorsoPoint$ = this.actions$.pipe(ofType(SearchOstActionTypes.RemovePercorsoPoint), map(function (action) { return action['payload']; }), withLatestFrom(this.searchSelectors.getCurrentIntense$()), switchMap(function (_a) {
            var payl = _a[0], intense = _a[1];
            return from(_this.searchRequests.removePercorsoPoint({ currentIntense: intense })).pipe(map(function (payload) {
                return new UpdatePercorsoPoint(payload);
            }), catchError(function (error) { console.log('RemovePercorsoPoint error ', error); return null; }));
        }));
        this.RemovePercorsoTratto$ = this.actions$.pipe(ofType(SearchOstActionTypes.RemovePercorsoTratto), map(function (action) { return action['payload']; }), withLatestFrom(this.searchSelectors.getCurrentIntense$()), switchMap(function (_a) {
            var payl = _a[0], intense = _a[1];
            // console.log('Payload', payl);
            return from(_this.searchRequests.removePercorsoTratto({ indexTratto: payl.indexTratto, currentIntense: intense })).pipe(map(function (payload) {
                return new UpdatePercorsoPoint(payload);
            }), catchError(function (error) { console.log('RemovePercorsoTratto error ', error); return null; }));
        }));
        this.UpdatePercorsoPoint$ = this.actions$.pipe(ofType(SearchOstActionTypes.UpdatePercorsoPoint, SearchOstActionTypes.SearchOstSuccess, SearchOstActionTypes.RemoveTappaSuccess, SearchOstActionTypes.CreateTappaSuccess, SearchOstActionTypes.SelectTappa), map(function (action) { return action['payload']; }), withLatestFrom(this.searchSelectors.getCurrentIntense$()), withLatestFrom(this.searchSelectors.getResults$()), switchMap(function (_a) {
            var _b = _a[0], payl = _b[0], intense = _b[1], osts = _a[1];
            return from(_this.searchRequests.updatePercorsoPoint({ osts: osts, currentIntense: intense })).pipe(map(function (payload) {
                return new UpdatePercorsoSuccess(payload);
            }), catchError(function (error) { console.log('updatePercorsoPoint error ', error); return null; }));
        }));
        this.ModifiedCurrentIntense$ = this.actions$.pipe(ofType(SearchOstActionTypes.AddOst, SearchOstActionTypes.RemoveOst, SearchOstActionTypes.UpdateOst, SearchOstActionTypes.AddPercorsoPoint, SearchOstActionTypes.RemovePercorsoPoint, SearchOstActionTypes.UpdatePercorsoPoint, SearchOstActionTypes.RemovePercorsoTratto, SearchOstActionTypes.AddStartPoint, SearchOstActionTypes.RemoveTappaSuccess, SearchOstActionTypes.CreateTappaSuccess, SearchOstActionTypes.UpdateTappa, SearchOstActionTypes.PatchCurrentIntense), withLatestFrom(this.searchSelectors.getCurrentIntense$()), switchMap(function (_a) {
            var payl = _a[0], intense = _a[1];
            return from(_this.searchRequests.modifyIntense({ isAdmin: _this.auth.isUserValidator(), currentIntense: intense })).pipe(map(function (payload) {
                return new ModifiedCurrentIntense(payload);
            }), catchError(function (error) { console.log('Modified intense error ', error); return null; }));
        }));
        this.RemoveTappaFail$ = this.actions$.pipe(ofType(SearchOstActionTypes.RemoveTappaFail), map(function (data) {
            return new DisplayModal({
                type: 'confirm',
                data: {
                    message: _this.translations['action.removestageerror']
                }
            });
        }));
        this.saveSuccess$ = this.actions$.pipe(ofType(SearchOstActionTypes.SaveIntenseSuccess), map(function (data) { return data['payload']; }), filter(function (payload) { return !payload.close; }), map(function (payload) { return payload.resp; }), switchMap(function (response) {
            var id = Object.keys(response.affected_items.intense)[0];
            return _this.searchIntenseRequest.doGetIntense$(id, null);
        }), switchMap(function (intenseResp) { return from(_this.apiService.completeIntense(intenseResp.data.findIntenseById)); }), switchMap(function (intense) {
            var displayModal = new DisplayModal({
                type: 'confirm',
                data: {
                    message: _this.translations['action.saveintensefinish']
                }
            });
            console.log('current Intense', intense);
            return [new UpdateCurrentIntense(intense), displayModal];
        }), catchError(function (error) { console.log('Save intense error ', error); return null; }));
        this.saveSuccessAndExit$ = this.actions$.pipe(ofType(SearchOstActionTypes.SaveIntenseSuccess), map(function (data) { return data['payload']; }), filter(function (payload) { return !!payload.close; }), tap(function (payload) {
            _this.router.navigate(['search']);
        }), switchMap(function (payload) {
            var displayModal = new DisplayModal({
                type: 'confirm',
                data: {
                    message: _this.translations['action.saveintensefinish']
                }
            });
            return [new FetchAllSchede(), displayModal];
        }));
        setTimeout(function () {
            _this.translate.get(['action.removestageerror', 'action.saveintensefinish']).subscribe(function (res) { return _this.translations = res; });
        }, 500);
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "doSearchOst$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "saveIntense$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "getIntenseTranslations$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "CreateTappa$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "RemoveTappa$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "AddStartPoint$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "AddPercorsoPoint$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "RemovePercorsoPoint$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "RemovePercorsoTratto$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "UpdatePercorsoPoint$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "ModifiedCurrentIntense$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "RemoveTappaFail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "saveSuccess$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], SearchOstEffects.prototype, "saveSuccessAndExit$", void 0);
    return SearchOstEffects;
}());
export { SearchOstEffects };
