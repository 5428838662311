import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SearchOstRequests } from './search-ost.request';
import { map, switchMap, withLatestFrom, tap, catchError, debounceTime, filter } from 'rxjs/operators';
import { SearchOstActionTypes, SearchOstSuccess, SearchOstFail, SaveIntenseSuccess, UpdatePercorsoSuccess, GetIntenseTranslations, ModifiedCurrentIntense, SetCurrentIntense, UpdateCurrentIntense } from './search-ost.actions';
import { UpdatePercorsoPoint, SaveIntenseFail, RemoveTappaSuccess, RemoveTappaFail, CreateTappaSuccess } from './search-ost.actions';
import { SearchOstSelectors } from './search-ost.selectors';
import { Router } from '@angular/router';
import { of, from } from 'rxjs';
import { FetchAllSchede } from '../app-data';
import { DisplayModal } from 'src/app/logic/common';
import { TranslateService } from '@ngx-translate/core';
import { CommonSelectors } from '../common/common.selectors';
import { AsrAuthGuard } from 'src/app/services/auth.service';
import { Store } from '@ngrx/store';
import { SearchIntenseRequests } from '../search-intense';
import { ApiService } from 'src/app/services/api.service';

@Injectable()
export class SearchOstEffects {
    private translations = [];
    constructor(
        private actions$: Actions,
        private router: Router,
        private searchSelectors: SearchOstSelectors,
        private commonSelectors: CommonSelectors,
        private searchRequests: SearchOstRequests,
        private searchIntenseRequest: SearchIntenseRequests,
        private translate: TranslateService,
        private auth: AsrAuthGuard,    
        private apiService: ApiService,     
        private store: Store<any>
    ) {
        setTimeout(() => {
            this.translate.get(['action.removestageerror', 'action.saveintensefinish']).subscribe(res => this.translations = res);
        }, 500);
    }

    @Effect() doSearchOst$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.SearchOst,
            SearchOstActionTypes.SetBoundingBox,
            SearchOstActionTypes.SetFilter,
            SearchOstActionTypes.SetType,
            SearchOstActionTypes.AddOst,
            SearchOstActionTypes.RemoveOst,
            SearchOstActionTypes.RemovePercorsoTratto,
            SearchOstActionTypes.SetQuery),
        map(action => action['payload']),
        // debounceTime(500),
        withLatestFrom(this.searchSelectors.getSearchRequest$()),
        switchMap(([_, request]) =>
            this.searchRequests.doSearch$(request).pipe(
                map(data => new SearchOstSuccess(data)),
                catchError(error => { console.log('BOUNDING BOX ERROR', error); return of(new SearchOstFail(error)); })
            ))
    );

    @Effect() saveIntense$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.SaveIntense),
        map(action => action['payload']),
        withLatestFrom(
            this.searchSelectors.getCurrentIntense$(),
            this.searchSelectors.getCurrentIntenseOriginal$(),
            this.searchSelectors.getCurrentIntenseTranslations$(),
            this.commonSelectors.locale$()),
        switchMap(([p, intense, original, traduzioni, currentLang]) => {
            return this.searchRequests.saveIntense(intense, original, traduzioni, currentLang).pipe(
                map(response => {
                    console.log("saveIntense done", response);
                    return new SaveIntenseSuccess({ resp: response, close: p.close })
                }),
                catchError(error => { console.log(error); return of(new SaveIntenseFail(error)); })
            )
        }
        )
    );

    @Effect() getIntenseTranslations$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.SetCurrentIntense, SearchOstActionTypes.UpdateCurrentIntense),
        map(action => action['payload']),
        withLatestFrom(this.searchSelectors.getSearchRequest$()),
        switchMap(([payload, request]) =>
            this.searchRequests.getTranslations(payload, request).pipe(
                map(response => new GetIntenseTranslations(response)),
                catchError(error => { console.log('get translations error ', error); return of((error)); })
            ))
    );

    @Effect() CreateTappa$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.CreateTappa),
        map(action => action['payload']),
        withLatestFrom(this.searchSelectors.getCurrentIntense$()),
        switchMap(
            ([payl, request]) =>
                from(this.searchRequests.createTappa({ indexTratto: payl.indexTratto, indexTappa: payl.indexTappa, currentIntense: request })).pipe(
                    map((payload, t) => {
                        return new CreateTappaSuccess(payload);
                    }),
                    catchError(error => { console.log('create tappa error ', error); return of((error)); })
                )
        ),
    );

    @Effect() RemoveTappa$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.RemoveTappa),
        map(action => action['payload']),
        // tap(data => console.log("effect remove tappa", data)),
        withLatestFrom(this.searchSelectors.getCurrentIntense$()),
        switchMap(
            ([tappaIdx, request]) =>
                from(this.searchRequests.removeTappa({ tappaIdx, currentIntense: request })).pipe(
                    map((payload, t) => {
                        console.log('-----------: SearchOstEffects -> payload', payload);
                        return new RemoveTappaSuccess(payload);
                    }),
                    catchError(error => { console.log('remove tappa error ', error); return of(new RemoveTappaFail(error)); })
                )
        ),
    );

    @Effect() AddStartPoint$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.AddStartPoint),
        map(action => action['payload']),
        withLatestFrom(this.searchSelectors.getCurrentIntense$()),
        withLatestFrom(this.searchSelectors.getResults$()),
        switchMap(
            ([[payl, intense], osts]) =>
                from(this.searchRequests.addStartPoint(
                    { point: payl.inizio, direzionePredefinita: payl.direzionePredefinita, currentIntense: intense, osts: osts }
                )).pipe(
                    map((payload) => {
                        // console.log('TCL: SearchOstEffects -> payload', payload);
                        return new UpdatePercorsoPoint(payload);
                    }),
                    catchError(error => { console.log('AddStartPoint error ', error); return null; })
                )
        ),
    );
    @Effect() AddPercorsoPoint$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.AddPercorsoPoint),
        map(action => action['payload']),
        withLatestFrom(this.searchSelectors.getCurrentIntense$()),
        switchMap(
            ([payl, intense]) =>
                from(this.searchRequests.addPercorsoPoint({ point: payl.punto, currentIntense: intense })).pipe(
                    map((payload) => {
                        return new UpdatePercorsoPoint(payload);
                    }),
                    catchError(error => { console.log('AddPercorsoPoint error ', error); return null; })
                )
        ),
    );
    @Effect() RemovePercorsoPoint$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.RemovePercorsoPoint),
        map(action => action['payload']),
        withLatestFrom(this.searchSelectors.getCurrentIntense$()),
        switchMap(
            ([payl, intense]) =>
                from(this.searchRequests.removePercorsoPoint({ currentIntense: intense })).pipe(
                    map((payload) => {
                        return new UpdatePercorsoPoint(payload);
                    }),
                    catchError(error => { console.log('RemovePercorsoPoint error ', error); return null; })
                )
        ),
    );
    @Effect() RemovePercorsoTratto$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.RemovePercorsoTratto),
        map(action => action['payload']),
        withLatestFrom(this.searchSelectors.getCurrentIntense$()),
        switchMap(
            ([payl, intense]) => {
                // console.log('Payload', payl);
                return from(this.searchRequests.removePercorsoTratto({ indexTratto: payl.indexTratto, currentIntense: intense })).pipe(
                    map((payload) => {
                        return new UpdatePercorsoPoint(payload);
                    }),
                    catchError(error => { console.log('RemovePercorsoTratto error ', error); return null; })
                );
            }
        ),
    );
    @Effect() UpdatePercorsoPoint$ = this.actions$.pipe(
        ofType(
            SearchOstActionTypes.UpdatePercorsoPoint,
            SearchOstActionTypes.SearchOstSuccess,
            SearchOstActionTypes.RemoveTappaSuccess,
            SearchOstActionTypes.CreateTappaSuccess,
            SearchOstActionTypes.SelectTappa
        ),
        map(action => action['payload']),
        withLatestFrom(this.searchSelectors.getCurrentIntense$()),
        withLatestFrom(this.searchSelectors.getResults$()),
        switchMap(
            ([[payl, intense], osts]) =>
                from(this.searchRequests.updatePercorsoPoint({ osts: osts, currentIntense: intense })).pipe(
                    map((payload) => {
                        return new UpdatePercorsoSuccess(payload);
                    }),
                    catchError(error => { console.log('updatePercorsoPoint error ', error); return null; })
                )
        ),
    );

    @Effect() ModifiedCurrentIntense$ = this.actions$.pipe(
        ofType(
            SearchOstActionTypes.AddOst,
            SearchOstActionTypes.RemoveOst,
            SearchOstActionTypes.UpdateOst,
            SearchOstActionTypes.AddPercorsoPoint,
            SearchOstActionTypes.RemovePercorsoPoint,
            SearchOstActionTypes.UpdatePercorsoPoint,
            SearchOstActionTypes.RemovePercorsoTratto,
            SearchOstActionTypes.AddStartPoint,
            SearchOstActionTypes.RemoveTappaSuccess,
            SearchOstActionTypes.CreateTappaSuccess,
            SearchOstActionTypes.UpdateTappa,
            SearchOstActionTypes.PatchCurrentIntense,
        ),
        withLatestFrom(this.searchSelectors.getCurrentIntense$()),
        switchMap(
            ([payl, intense]) => {
                return from(this.searchRequests.modifyIntense({ isAdmin: this.auth.isUserValidator(), currentIntense: intense })).pipe(
                    map((payload) => {
                        return new ModifiedCurrentIntense(payload);
                    }),
                    catchError(error => { console.log('Modified intense error ', error); return null; })
                );
            })
    );

    @Effect() RemoveTappaFail$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.RemoveTappaFail),
        map(data => {
            return new DisplayModal(
                {
                    type: 'confirm',
                    data: {
                        message: this.translations['action.removestageerror']
                    }
                }
            );
        })
    );
    
    @Effect() saveSuccess$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.SaveIntenseSuccess),
        map(data => data['payload']),
        filter(payload => !payload.close),
        map(payload => payload.resp),
        switchMap(response => {
            const id = Object.keys(response.affected_items.intense)[0];
            return this.searchIntenseRequest.doGetIntense$(id, null);
        }),
        switchMap(intenseResp => from(this.apiService.completeIntense(intenseResp.data.findIntenseById))),
        switchMap((intense) => {
            const displayModal = new DisplayModal(
                {
                    type: 'confirm',
                    data: {
                        message: this.translations['action.saveintensefinish']
                    }
                });
                console.log('current Intense', intense);
            return [new UpdateCurrentIntense(intense), displayModal];
        }),
        catchError(error => { console.log('Save intense error ', error); return null; })
    );

    @Effect() saveSuccessAndExit$ = this.actions$.pipe(
        ofType(SearchOstActionTypes.SaveIntenseSuccess),
        map(data => data['payload']),
        filter(payload => !!payload.close),
        tap(payload => {
          this.router.navigate(['search'])
        }),
        switchMap(payload => {
            const displayModal = new DisplayModal(
                {
                    type: 'confirm',
                    data: {
                        message: this.translations['action.saveintensefinish']
                    }
                });
            return [new FetchAllSchede(), displayModal];     
        })
    );
}
