import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AsrAuthGuard } from './auth.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class Interceptor implements HttpInterceptor {


    constructor(
        private auth: AsrAuthGuard,
        private oauthService: OAuthService
    ) {


    }


    intercept(req: HttpRequest<any>, next: HttpHandler)
        : Observable<HttpEvent<any>> {
        let authToken = this.auth.getToken();
            if (!authToken){// && request.header. 
              authToken = this.oauthService.getAccessToken();
            }
        if (authToken) {
            const request = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + authToken
                }
            });
            return next.handle(request);
        }

        return next.handle(req);
    }

}
