import { Store, select } from '@ngrx/store';
import { map, distinctUntilChanged } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var SearchOstSelectors = /** @class */ (function () {
    function SearchOstSelectors(store) {
        this.store = store;
        this.searchOST$ = this.store.pipe(select(function (state) { return state.search_ost; }));
        this.completeState$ = this.store.pipe(select(function (state) { return state; }));
    }
    SearchOstSelectors.prototype.getQuery = function () {
        return this.searchOST$.pipe(map(function (state) { return state.query; }));
    };
    SearchOstSelectors.prototype.getCurrentIntense$ = function () {
        return this.searchOST$.pipe(map(function (state) { return state.currentIntense; }));
    };
    SearchOstSelectors.prototype.getCurrentIntenseOriginal$ = function () {
        return this.searchOST$.pipe(map(function (state) { return state.currentIntenseOriginal; }));
    };
    SearchOstSelectors.prototype.getCurrentIntenseTranslations$ = function () {
        return this.searchOST$.pipe(map(function (state) { return state.currentIntenseTranslations; }));
    };
    SearchOstSelectors.prototype.getResults$ = function () {
        return this.searchOST$.pipe(map(function (state) { return state.results; }));
    };
    SearchOstSelectors.prototype.getResultsAndType$ = function () {
        return this.searchOST$.pipe(map(function (state) { return ({ results: state.results, update: state.updated }); }));
    };
    SearchOstSelectors.prototype.getSelectedOst$ = function () {
        return this.searchOST$.pipe(map(function (state) { return state.selectOst; }));
    };
    SearchOstSelectors.prototype.getType$ = function () {
        return this.searchOST$.pipe(map(function (state) { return state.type; }));
    };
    SearchOstSelectors.prototype.getFacets$ = function () {
        return this.searchOST$.pipe(distinctUntilChanged(), map(function (state) {
            var ret = [];
            state.filters.forEach(function (s) {
                if (s['name'] === 'regioni') {
                    ret = s['values'];
                }
            });
            return ret;
        }));
    };
    SearchOstSelectors.prototype.getResultsAndIntense$ = function () {
        return this.searchOST$.pipe(map(function (state) { return ({ results: state.results, intense: state.currentIntense }); }));
    };
    SearchOstSelectors.prototype.getSearchRequest$ = function () {
        return this.completeState$.pipe(map(function (state) {
            var stateOst = state.search_ost;
            return {
                query: stateOst.query,
                filters: stateOst.activeFilters,
                page: stateOst.page,
                size: stateOst.size,
                boundingBox: stateOst.boundingBox,
                type: stateOst.type,
                lang: state.common.locale,
                excludedOst: [] // stateOst.currentIntense ? this.recoverOstIds(state.currentIntense) : []
            };
        }));
    };
    SearchOstSelectors.prototype.getFilters$ = function () {
        return this.searchOST$.pipe(map(function (state) { return state.filters; }));
    };
    SearchOstSelectors.prototype.getActiveFilters$ = function () {
        return this.searchOST$.pipe(map(function (state) { return state.activeFilters; }));
    };
    SearchOstSelectors.prototype.getHighlightElement$ = function () {
        return this.searchOST$.pipe(map(function (state) { return state.HighlightElement; }));
    };
    SearchOstSelectors.prototype.recoverOstIds = function (intense) {
        if (!(intense && intense.tappe && Array.isArray(intense.tappe))) {
            return [];
        }
        var ostResult = [];
        intense.tappe.forEach(function (tappa) {
            if (tappa && tappa.osts) {
                tappa.osts.forEach(function (ost) {
                    if (ost && ost.id && !ostResult.includes(ost.id)) {
                        ostResult.push(ost.id);
                    }
                });
            }
        });
        return ostResult;
    };
    SearchOstSelectors.ngInjectableDef = i0.defineInjectable({ factory: function SearchOstSelectors_Factory() { return new SearchOstSelectors(i0.inject(i1.Store)); }, token: SearchOstSelectors, providedIn: "root" });
    return SearchOstSelectors;
}());
export { SearchOstSelectors };
