import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './logic';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatSliderModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatOptionModule,
  MatAutocompleteModule,
  MatInputModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatProgressSpinnerModule,
  // MatSpinner,
  MatMenuModule,
  MatSnackBarModule,
  MatTooltipModule
} from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SearchIntenseEffects } from './logic/search-intense/search-intense.effects';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { SearchOstEffects } from './logic/search-ost/search-ost.effects';
import { QuillModule } from 'ngx-quill';
import { OAuthModule } from 'angular-oauth2-oidc';
import {
  AddOstModalComponent,
  EditOstModalComponent,
  ConfirmModalComponent,
  DeleteModalComponent,
  SnackbarComponent,
  EditIntenseFileModalComponent,
  TranslateModalComponent
} from './components/modals';
import {
  LeftSideBarComponent,
  RightSideBarComponent,
  HeaderComponent,
  MapHeaderComponent,
  MapSearchComponent,
  MapEditComponent,
  EditTappaModalComponent
} from './components';
import { SearchIntenseComponent, EditIntenseComponent, NewIntenseComponent } from './views';
import {
  StringifyHtmlPipe,
  TruncantePipe,
  TrattoCounterPipe,
  AttrattoriCounterPipe,
  TrattoIndexPipe,
  ValidateIntensePipe,
  ExtractAttribute,
  OstAttrattoriCounterPipe,
  OstTrattoCounterPipe,
  MomentPipe
} from './pipes';
import { AddPoiPopupComponent, AddTrattoPopupComponent, SelectPoiPopupComponent } from './components/popups';
// import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonEffects } from './logic/common/common.effetcs';
import { MapPreviewComponent } from './components/map-preview-component';
import { LoginIntenseComponent } from './views/login-intense/login-intense.component';
// import { OverlayModule } from '@angular/cdk/overlay';
import { EditIntenseService } from './views/edit-intense/edit-intense.service';
import { SearchIntenseService } from './views/search-intentse/search-intense.service';
import { AppDataEffects } from './logic/app-data/app-data.effects';
import { Driver, NgForageConfig } from 'ngforage';
import { AngularResizedEventModule } from 'angular-resize-event';
import 'hammerjs';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from 'src/app/services/interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    StringifyHtmlPipe,
    TruncantePipe,
    TrattoCounterPipe,
    OstAttrattoriCounterPipe,
    OstTrattoCounterPipe,
    TrattoIndexPipe,
    ValidateIntensePipe,
    AttrattoriCounterPipe,
    MomentPipe,
    ExtractAttribute,
    LeftSideBarComponent,
    RightSideBarComponent,
    HeaderComponent,
    LoginIntenseComponent,
    MapHeaderComponent,
    MapSearchComponent,
    MapEditComponent,
    MapPreviewComponent,
    SearchIntenseComponent,
    EditIntenseComponent,
    NewIntenseComponent,
    AddOstModalComponent,
    EditOstModalComponent,
    EditTappaModalComponent,
    ConfirmModalComponent,
    DeleteModalComponent,
    AddPoiPopupComponent,
    AddTrattoPopupComponent,
    SelectPoiPopupComponent,
    SnackbarComponent,
    EditIntenseFileModalComponent,
    TranslateModalComponent
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatOptionModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      name: 'Store',
      logOnly: environment.production,
      maxAge: 10
    }),
    EffectsModule.forRoot([SearchIntenseEffects, SearchOstEffects, CommonEffects, AppDataEffects]),
    HttpClientModule,
    AppRoutingModule,
    MatNativeDateModule,
    MatSliderModule,
    MatExpansionModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTooltipModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    QuillModule.forRoot(),
    NgxSpinnerModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.sin2api, environment.graphqlUrl],
        sendAccessToken: true
      }
    }),
    // DragDropModule,
    MatProgressSpinnerModule,
    // OverlayModule,
    AngularResizedEventModule,
    GalleryModule.withConfig({
      loadingMode: 'indeterminate'
    }),
    LightboxModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    EditIntenseService,
    SearchIntenseService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphqlUrl
          }),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'none',
            },
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'none',
            },
          }
        };
      },
      deps: [HttpLink]
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    // MatSpinner,
    AddPoiPopupComponent,
    AddTrattoPopupComponent,
    SelectPoiPopupComponent,
    ConfirmModalComponent,
    AddOstModalComponent,
    EditOstModalComponent,
    EditTappaModalComponent,
    DeleteModalComponent,
    TranslateModalComponent,
    SnackbarComponent,
    EditIntenseFileModalComponent
  ]
})
export class AppModule {
  public constructor(ngfConfig: NgForageConfig) {
    registerLocaleData(localeIt, 'it');
    ngfConfig.configure({
      name: 'MyApp',
      driver: [ // defaults to indexedDB -> webSQL -> localStorage
        Driver.INDEXED_DB,
        Driver.LOCAL_STORAGE
      ]
    });
  }
}
