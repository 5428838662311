import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var CommonSelectors = /** @class */ (function () {
    function CommonSelectors(store) {
        this.store = store;
        this.commonState$ = this.store.pipe(select(function (state) { return state.common; }));
    }
    CommonSelectors.prototype.isLoading$ = function () {
        return this.commonState$.pipe(map(function (state) { return state.isLoading; }));
    };
    CommonSelectors.prototype.locale$ = function () {
        return this.commonState$.pipe(map(function (state) { return state.locale; }));
    };
    CommonSelectors.ngInjectableDef = i0.defineInjectable({ factory: function CommonSelectors_Factory() { return new CommonSelectors(i0.inject(i1.Store)); }, token: CommonSelectors, providedIn: "root" });
    return CommonSelectors;
}());
export { CommonSelectors };
