import * as tslib_1 from "tslib";
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var AppDataRequests = /** @class */ (function () {
    function AppDataRequests(apollo) {
        this.apollo = apollo;
        this.ostInfo = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n    fragment OstInfo on OST {\n        id\n        geometry  {\n           type\n           value\n              }\n    }"], ["\n    fragment OstInfo on OST {\n        id\n        geometry  {\n           type\n           value\n              }\n    }"])));
        this.schedaInfoFragment = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n        fragment IntenseInfo on Intense {\n            id\n            geometry {\n               type\n               value\n            }\n        }"], ["\n        fragment IntenseInfo on Intense {\n            id\n            geometry {\n               type\n               value\n            }\n        }"])));
    }
    AppDataRequests.prototype.fetchAllRegioni$ = function () {
        var querySearch = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n            query {\n                queryRegioni(page: 0, count: 100){\n                  regioni{\n                    name\n                    geometry{\n                      value\n                    }\n                    centroide{\n                      value\n                    }\n                  }\n                }\n               }\n            "], ["\n            query {\n                queryRegioni(page: 0, count: 100){\n                  regioni{\n                    name\n                    geometry{\n                      value\n                    }\n                    centroide{\n                      value\n                    }\n                  }\n                }\n               }\n            "])));
        return this.apollo.watchQuery({
            query: querySearch,
        }).valueChanges;
    };
    AppDataRequests.prototype.fecthAllSchede$ = function () {
        var querySearch = gql(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n        query {\n            querySchedeIntense(count:9998) {\n                 schedeIntense{\n                ...IntenseInfo\n                }\n            }\n        }", ""], ["\n        query {\n            querySchedeIntense(count:9998) {\n                 schedeIntense{\n                ...IntenseInfo\n                }\n            }\n        }", ""])), this.schedaInfoFragment);
        return this.apollo.watchQuery({
            query: querySearch,
        }).valueChanges;
    };
    AppDataRequests.prototype.fecthAllOst$ = function () {
        var querySearch = gql(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n        query {\n            queryOst(count: 9999) {\n                 ost{\n                    ...OstInfo\n                }\n            }\n        }", ""], ["\n        query {\n            queryOst(count: 9999) {\n                 ost{\n                    ...OstInfo\n                }\n            }\n        }", ""])), this.ostInfo);
        return this.apollo.query({
            query: querySearch,
        });
    };
    AppDataRequests.prototype.fecthOst$ = function (id) {
        var querySearch = gql(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n        query($include: [Int]) {\n            queryOst(count: 9999,include:$include) {\n                 ost{\n                    ...OstInfo\n                }\n            }\n        }", ""], ["\n        query($include: [Int]) {\n            queryOst(count: 9999,include:$include) {\n                 ost{\n                    ...OstInfo\n                }\n            }\n        }", ""])), this.ostInfo);
        return this.apollo.query({
            query: querySearch,
            variables: {
                include: [id]
            }
        });
    };
    AppDataRequests.prototype.fetchPeriods$ = function () {
        var querySearch = gql(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n            query {\n                queryFestivita{\n                  festivita{\n                    name\n                    data_festivita\n                  }\n                }\n            }\n            "], ["\n            query {\n                queryFestivita{\n                  festivita{\n                    name\n                    data_festivita\n                  }\n                }\n            }\n            "])));
        return this.apollo.watchQuery({
            query: querySearch,
        }).valueChanges;
    };
    AppDataRequests.ngInjectableDef = i0.defineInjectable({ factory: function AppDataRequests_Factory() { return new AppDataRequests(i0.inject(i1.Apollo)); }, token: AppDataRequests, providedIn: "root" });
    return AppDataRequests;
}());
export { AppDataRequests };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
