import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmModalComponent, EditOstModalComponent, AddOstModalComponent, DeleteModalComponent, EditTappaModalComponent, EditIntenseFileModalComponent, TranslateModalComponent } from '../components/modals';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(private dialog: MatDialog) {

    }


    openDialog(type: string, data: any): void {
        let component;
        switch (type) {
            case 'confirm':
                component = ConfirmModalComponent;
                break;
            case 'editOst':
                component = EditOstModalComponent;
                break;
            case 'editIntenseFile':
                component = EditIntenseFileModalComponent;
                break;
            case 'editTappa':
                component = EditTappaModalComponent;
                break;
            case 'add':
                component = AddOstModalComponent;
                break;
            case 'delete':
                component = DeleteModalComponent;
                break;
            case 'translate':
                component = TranslateModalComponent;
                break;
            default:
                break;
        }
        const dialogRef = this.dialog.open(component, {
            width: type === 'translate' ? '94%' : '1024px',
            height: type === 'translate' ? '94%' : 'auto',
            hasBackdrop: true,
            data: data
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    closeDialog() {
        this.dialog.closeAll();
    }
}
