import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-poi-poup',
  templateUrl: './select-poi-popup.component.html',
})
export class SelectPoiPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
