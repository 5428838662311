<div mat-dialog-content class="ras-modal ras-modal--has-columns">
    <div class="ras-modal__content">
        {{data.message}}
    </div>
</div>

<div mat-dialog-actions class="ras-modal__actions ras-modal__actions-justified">
    <button class="ras-btn ras-btn-light ras-btn-icon ras-btn-undo" (click)="close()"><i aria-hidden="true" class="fal fa-times-circle"></i> {{'common.cancel' | translate}}
    </button>
    <button class="ras-btn ras-btn-light ras-btn-icon" (click)="confirm()"><i aria-hidden="true" class="far fa-trash"></i> {{'common.delete' | translate}}</button>
</div>
