import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, tap, catchError, concatMap } from 'rxjs/operators';
import { AppDataActionsType, FetchAllSchedeSuccess, FetchAllOstSuccess, FetchAllRegioniSuccess, FetchAllSchedeFail, FetchAllRegioniFail, FetchAllOstFail, FetchPeriodsSuccess, FetchPeriodsFail } from './app-data.actions';
import { AppDataRequests } from './app-data.request';
import * as wellknown from 'wellknown';
import * as geojson_precision from 'geojson-precision';
import { of } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "./app-data.request";
import * as i3 from "../../services/utils.service";
var AppDataEffects = /** @class */ (function () {
    function AppDataEffects(actions$, appDataRequest, utils) {
        var _this = this;
        this.actions$ = actions$;
        this.appDataRequest = appDataRequest;
        this.utils = utils;
        this.SIMPLIFY_OPTIONS = { tolerance: 0.5, highQuality: true, mutate: true };
        this.fetchAllSchedeEffect$ = this.actions$.pipe(ofType(AppDataActionsType.FetchAllSchede), map(function (action) { return action['payload']; }), switchMap(function (request) {
            return _this.appDataRequest.fecthAllSchede$().pipe(tap(function (data) { return _this.storeGeometrieSchede(data); }), map(function (data) { return new FetchAllSchedeSuccess(data); }), catchError(function (error) { return of(new FetchAllSchedeFail(error)); }));
        }));
        this.fetchAllRegioniEffect$ = this.actions$.pipe(ofType(AppDataActionsType.FetchAllRegioni), map(function (action) { return action['payload']; }), switchMap(function (request) {
            return _this.appDataRequest.fetchAllRegioni$().pipe(concatMap(function (data) { return _this.storeGeometrieRegioni(data); }), map(function (data) { return new FetchAllRegioniSuccess(data); }), catchError(function (error) { return of(new FetchAllRegioniFail(error)); }));
        }));
        this.fetchAllOstEffect$ = this.actions$.pipe(ofType(AppDataActionsType.FetchAllOst), map(function (action) { return action['payload']; }), switchMap(function (request) {
            return _this.appDataRequest.fecthAllOst$().pipe(tap(function (data) { return _this.storeGeometrieOst(data); }), map(function (data) { return new FetchAllOstSuccess(); }), catchError(function (error) { return of(new FetchAllOstFail(error)); }));
        }));
        this.fetchPeriodsEffect$ = this.actions$.pipe(ofType(AppDataActionsType.FetchPeriods), map(function (action) { return action['payload']; }), switchMap(function (request) {
            return _this.appDataRequest.fetchPeriods$().pipe(map(function (data) { return new FetchPeriodsSuccess(data); }), catchError(function (error) { return of(new FetchPeriodsFail(error)); }));
        }));
    }
    AppDataEffects.prototype.storeGeometrieSchede = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var schede, i, element, geoJSon, trimmed, rest;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        schede = data.data.querySchedeIntense.schedeIntense;
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < schede.length)) return [3 /*break*/, 4];
                        element = schede[i];
                        if (!(element.id && element.geometry && element.geometry.value)) return [3 /*break*/, 3];
                        geoJSon = wellknown.parse(element.geometry.value);
                        trimmed = geojson_precision.parse(geoJSon, 4);
                        return [4 /*yield*/, this.utils.setGenericGeometry(element.id, trimmed)];
                    case 2:
                        rest = _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AppDataEffects.prototype.storeGeometrieRegioni = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var regioni, i, element, geoJSon, trimmed, resp;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        regioni = data.data.queryRegioni.regioni;
                        if (!regioni) return [3 /*break*/, 4];
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < regioni.length)) return [3 /*break*/, 4];
                        element = regioni[i];
                        if (!(element.name && element.geometry && element.geometry.value)) return [3 /*break*/, 3];
                        geoJSon = wellknown.parse(element.geometry.value);
                        trimmed = geojson_precision.parse(geoJSon, 5);
                        return [4 /*yield*/, this.utils.setRegionData(element.name, trimmed, element.centroide)];
                    case 2:
                        resp = _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, data];
                }
            });
        });
    };
    AppDataEffects.prototype.storeGeometrieOst = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var osts, i, element, trimmed;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        osts = data.data.queryOst.ost;
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < osts.length)) return [3 /*break*/, 4];
                        element = osts[i];
                        trimmed = this.utils.getGeometryFromOstQueryElement(element);
                        if (!trimmed) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.utils.setOstGeometry(element.id, trimmed)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AppDataEffects.ngInjectableDef = i0.defineInjectable({ factory: function AppDataEffects_Factory() { return new AppDataEffects(i0.inject(i1.Actions), i0.inject(i2.AppDataRequests), i0.inject(i3.UtilsService)); }, token: AppDataEffects, providedIn: "root" });
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppDataEffects.prototype, "fetchAllSchedeEffect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppDataEffects.prototype, "fetchAllRegioniEffect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppDataEffects.prototype, "fetchAllOstEffect$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppDataEffects.prototype, "fetchPeriodsEffect$", void 0);
    return AppDataEffects;
}());
export { AppDataEffects };
