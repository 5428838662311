import * as tslib_1 from "tslib";
import * as generateColor from 'string-to-color';
import { AddPercorsoPoint, RemovePercorsoPoint, AddStartPoint } from 'src/app/logic/search-ost';
import { UtilsService } from 'src/app/services/utils.service';
import { Store } from '@ngrx/store';
import * as L from 'leaflet';
import { ModalService } from './modal.service';
import { config } from 'src/environments/config/config';
import * as chroma from 'chroma-js';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./utils.service";
import * as i3 from "./modal.service";
import * as i4 from "@ngx-translate/core";
var LayerMakerService = /** @class */ (function () {
    function LayerMakerService(store, utils, modalService, translate) {
        var _this = this;
        this.store = store;
        this.utils = utils;
        this.modalService = modalService;
        this.translate = translate;
        this.translations = [];
        setTimeout(function () {
            _this.translate.get(['action.removestageerror', 'action.farost']).subscribe(function (res) { return _this.translations = res; });
        }, 500);
    }
    LayerMakerService.prototype.createStartMarker = function (geoJSONobj, isStart, ost, mouseClick, mouseEnter, mouseLeave) {
        var _this = this;
        if (mouseEnter === void 0) { mouseEnter = null; }
        if (mouseLeave === void 0) { mouseLeave = null; }
        var point = isStart ? this.utils.getFirstCoords(geoJSONobj) : this.utils.getLastCoords(geoJSONobj);
        var direction = this.utils.calculateDirection(geoJSONobj, isStart);
        var color = this.colorForOst(ost);
        var dataContent = ost.name;
        var divHtml = "<div class=\"map-startpath-icon map-path-icon\"" +
            // `style="transform: rotate(` + direction + `deg) translateY(-10px); color: ` + color + `"` +
            "data-content=\"" + dataContent + "\"><div>";
        var myIcon = L.divIcon({ html: divHtml });
        // you can set .map-startpath-icon styles in CSS
        var marker = L.marker([point[1], point[0]], { icon: myIcon });
        marker.ostid = ost.id;
        marker.on('click', function (ev) {
            if (mouseLeave) {
                mouseLeave();
            }
            mouseClick(ost);
            _this.store.dispatch(new AddStartPoint({
                inizio: { punto: point, ostProvenienza: ost.id },
                direzionePredefinita: isStart
            }));
        });
        if (mouseEnter) {
            marker.on('mouseover', function (ev) { mouseEnter(ost); });
        }
        if (mouseLeave) {
            marker.on('mouseout', function (ev) { mouseLeave(); });
        }
        return marker;
    };
    LayerMakerService.prototype.createLastPointMarker = function (point) {
        var _this = this;
        var divHtml = "<div class=\"map-lastpath-icon map-path-icon\"> <div>";
        var myIcon = L.divIcon({ html: divHtml });
        var marker = L.marker([point[1], point[0]], { icon: myIcon });
        marker.on('click', function (ev) {
            _this.store.dispatch(new RemovePercorsoPoint({}));
        });
        return marker;
    };
    LayerMakerService.prototype.createEndOfLinePoli = function (geoJSONobj, color) {
        var center = this.utils.getLastCoords(geoJSONobj);
        var direction = this.utils.calculateDirection(geoJSONobj, false);
        var circle = L.semiCircle([center[1], center[0]], { radius: 10, fillOpacity: 1, fillColor: color, color: 'black', weight: '1' }).setDirection(direction + 180, 180);
        return circle;
    };
    LayerMakerService.prototype.createStartOfLinePoli = function (geoJSONobj, color) {
        var center = this.utils.getFirstCoords(geoJSONobj);
        var direction = this.utils.calculateDirection(geoJSONobj, true);
        var circle = L.semiCircle([center[1], center[0]], { radius: 10, fillOpacity: 1, fillColor: color, color: 'black', weight: '1' }).setDirection(direction + 180, 180);
        return circle;
    };
    LayerMakerService.prototype.createOstMarker = function (geoJSONobj, markerColor, ost) {
        var _this = this;
        return L.geoJSON(geoJSONobj, {
            // clickTolerance: 100, style: function () {
            //     return {
            //         'color': color,
            //         'weight': 3,
            //     };
            // },
            pointToLayer: function (feature, latlng) {
                var marker = L.AwesomeMarkers.icon({
                    icon: _this.getAwesomeIcon(ost),
                    prefix: 'fa',
                    markerColor: markerColor,
                });
                return L.marker(latlng, {
                    icon: marker
                });
            }
        });
    };
    LayerMakerService.prototype.createPercorsotPointMarker = function (punto) {
        if (!punto || punto.length < 2) {
            return null;
        }
        var divHtml = "<div class=\"map-path-icon map-middle-icon\"> <div>";
        var myIcon = L.divIcon({ html: divHtml });
        var marker = L.marker([punto[1], punto[0]], { icon: myIcon });
        return marker;
    };
    LayerMakerService.prototype.isPointOst = function (p) {
        return p.ostProvenienza && p.ostProvenienza.id;
    };
    LayerMakerService.prototype.isPointCross = function (p) {
        return p.ostProvenienza && p.ostProvenienza.id && p.ostConnessi && p.ostConnessi.length > 0;
    };
    LayerMakerService.prototype.createNextPointMarker = function (p, isFar, mouseEnter, mouseLeave) {
        if (mouseEnter === void 0) { mouseEnter = null; }
        if (mouseLeave === void 0) { mouseLeave = null; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var classes, style, jsonObj, startPoint, isStart, dir, y, x, dataContent, divHtml, myIcon, marker;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        classes = 'map-nextpath-icon map-path-icon';
                        style = '';
                        if (this.isPointOst(p)) {
                            classes += " map-ost-icon";
                            if (this.isPointCross(p)) {
                                classes += " map-cross-icon";
                                // style += ` color: ` + this.colorForOst(p.ostConnessi[0]) + `;`;
                            }
                        }
                        if (!(p.ostProvenienza && p.ostProvenienza.id && (!p.ostConnessi || p.ostConnessi.length === 0))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.utils.getOstGeometry(p.ostProvenienza)];
                    case 1:
                        jsonObj = _a.sent();
                        startPoint = this.utils.getFirstCoords(jsonObj);
                        isStart = this.utils.isSamePoint(startPoint, p.punto);
                        dir = this.utils.calculateDirection(jsonObj, isStart);
                        y = Math.cos(-dir * Math.PI / 180) * 20;
                        x = Math.sin(-dir * Math.PI / 180) * 20;
                        style += ' transform: translate(' + x + 'px,' + y + 'px); ';
                        _a.label = 2;
                    case 2:
                        if (p.ostConnessi && p.ostConnessi.length > 0) {
                            style += " color: " + this.colorForOst(p.ostConnessi[0]) + ";";
                        }
                        dataContent = '';
                        if (p.ostConnessi && p.ostConnessi.length > 0) {
                            p.ostConnessi.forEach(function (ost) { dataContent += ost.name + '\n'; });
                        }
                        else {
                            dataContent += p.ostProvenienza.name + '\n';
                        }
                        divHtml = "<div class=\"" + classes + "\" style=\"" + style + "\" data-content=\"" + dataContent + "\"> <div>";
                        myIcon = L.divIcon({ html: divHtml });
                        marker = L.marker([p.punto[1], p.punto[0]], { icon: myIcon });
                        marker.on('click', function (ev) {
                            mouseLeave();
                            var action = new AddPercorsoPoint({ punto: p });
                            if (isFar && (!p.ostProvenienza || !p.ostProvenienza.id)) {
                                var data = {
                                    message: _this.translations['action.farost'],
                                    confirmAction: action
                                };
                                _this.modalService.openDialog('confirm', data);
                            }
                            else {
                                _this.store.dispatch(action);
                            }
                        });
                        marker.on('mouseover', function (ev) { mouseEnter(p); });
                        marker.on('mouseout', function (ev) { mouseLeave(); });
                        return [2 /*return*/, marker];
                }
            });
        });
    };
    LayerMakerService.prototype.createRegionClusterMarker = function (center, number) {
        var divHtml = "<div class=\"map-cluster-icon\" >" + number + "<div>";
        var myIcon = L.divIcon({ html: divHtml });
        var marker = L.marker([center[1], center[0]], { icon: myIcon });
        return marker;
    };
    LayerMakerService.prototype.createRegionPolygon = function (geometry, count) {
        var pol = L.geoJSON(geometry, {
            fillColor: this.colorForRegion(count),
            fillOpacity: config.REGION_FILL_OPACITY,
            stroke: true,
            color: config.REGION_STROKE_COLOR,
            weight: config.REGION_STROKE_WEIGHT,
        });
        return pol;
    };
    LayerMakerService.prototype.colorForRegion = function (number) {
        if (number < 1) {
            return config.REGION_0_COLOR;
        }
        if (number <= 10) {
            var scale = chroma.scale([config.REGION_10_COLOR, config.REGION_100_COLOR]).mode('lab');
            return scale(number / 10).hex();
        }
        if (number < 100) {
            var scale = chroma.scale([config.REGION_100_COLOR, config.REGION_MAX_COLOR]).mode('lab');
            return scale((number - 10) / 90).hex();
        }
        return config.REGION_MAX_COLOR;
    };
    LayerMakerService.prototype.colorForOst = function (ost) {
        // return 'red';
        return generateColor('gray-' + ost.name + ost.id);
    };
    LayerMakerService.prototype.getAwesomeIcon = function (ost) {
        switch (this.utils.ostGetTipologia(ost)) {
            case 'natura': return 'leaf';
            case 'montagna': return 'mountain';
            case 'foresta': return 'tree';
            case 'panorama': return 'binoculars';
            case 'acqua': return 'water';
            case 'edificio': return 'landmark';
            case 'mare': return 'umbrella-beach';
            case 'monumento': return 'monument';
            case 'cultura': return 'book';
            case 'alloggio': return 'bed';
            case 'trasporto': return 'bus';
            case 'treno': return 'train';
            case 'ristorazione': return 'utensils';
            default: return 'map-marker';
        }
    };
    LayerMakerService.ngInjectableDef = i0.defineInjectable({ factory: function LayerMakerService_Factory() { return new LayerMakerService(i0.inject(i1.Store), i0.inject(i2.UtilsService), i0.inject(i3.ModalService), i0.inject(i4.TranslateService)); }, token: LayerMakerService, providedIn: "root" });
    return LayerMakerService;
}());
export { LayerMakerService };
