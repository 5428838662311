<div mat-dialog-content class="ras-modal ras-modal&#45;&#45;confirm">
    <div class="ras-modal__content">
        <div class="ras-modal__message">
            {{data.message}}
        </div>
    </div>
</div>

<div mat-dialog-actions class="ras-modal__actions ras-modal__actions-justified">
        <button class="ras-btn ras-btn-light ras-btn-icon ras-btn-undo" (click)="close()"><i aria-hidden="true" class="fal fa-times-circle"></i> {{'common.cancel' | translate}}
        </button>
        <button (click)="confirm()" class="ras-btn">{{'common.ok' | translate}}</button>
    </div>
    