import { Action } from '@ngrx/store';
import { Intense } from 'src/app/model/model.interfaces';

export enum SearchOstActionTypes {
    SearchOst = '[SearchOst] SEARCH',
    SearchOstSuccess = '[SearchOst] SEARCH_SUCCESS',
    SearchOstFail = '[SearchOst] SEARCH_FAIL',
    SetQuery = '[SearchOst] SET_QUERY',
    SetBoundingBox = '[SearchOst] SET_BOUNDINGBOX',
    SetFilter = '[SearchOst] SET_FILTER',
    SetSize = '[SearchOst] SET_SIZE',
    SetType = '[SearchOst] SET_TYPE',
    SetPage = '[SearchOst] SET_PAGE',
    SetPageSuccess = '[SearchOst] SET_PAGE_SUCCESS',
    ClearFilters = '[SearchOst] CLEAR_FILTERS',
    SetCurrentIntense = '[SearchOst] SetCurrentIntense',
    AddOst = '[SearchOst] AddOst',
    RemoveOst = '[SearchOst] RemoveOst',
    RemovePercorsoTratto = '[SearchOst] RemovePercorsoTratto',
    SelectOst = '[SearchOst] SelectOst',
    SelectTappa = '[SearchOst] SelectTappa',
    CreateTappa = '[SearchOst] CreateTappa',
    CreateTappaSuccess = '[SearchOst] CreateTappaSuccess',
    RemoveTappa = '[SearchOst] RemoveTappa',
    RemoveTappaSuccess = '[SearchOst] RemoveTappaSuccess',
    RemoveTappaFail = '[SearchOst] RemoveTappaFail',
    PatchCurrentIntense = '[SearchOst] PatchCurrentIntense',
    SaveIntense = '[SearchOst] SaveIntense',
    SaveIntenseSuccess = '[SearchOst] SaveIntenseSuccess',
    SaveIntenseFail = '[SearchOst] SaveIntenseFail',
    AddStartPoint = '[SearchOst] AddStartPoint',
    AddPercorsoPoint = '[SearchOst] AddPercorsoPoint',
    RemovePercorsoPoint = '[SearchOst] RemovePercorsoPoint',
    UpdatePercorsoSuccess = '[SearchOst] UpdatePercorsoSuccess',
    UpdatePercorsoPoint = '[SearchOst] UpdatePercorsoPoint',
    HighlightElement = '[SearchOst] HighlightElement',
    UpdateTappa = '[SearchOst] UpdateTappa',
    UpdateOst = '[SearchOst] UpdateOst',
    GetIntenseTranslations = '[SearchOst] GetIntenseTranslations',
    UpdateIntenseTranslations = '[SearchOst] UpdateIntenseTranslations',
    ModifiedCurrentIntense = '[SearchOst] ModifiedCurrentIntense',
    UpdateCurrentIntense = '[SearchOst] UpdateCurrentIntense'
}

export class HighlightElement implements Action {
    readonly type = SearchOstActionTypes.HighlightElement;
    constructor(public payload: any) {}
}

export class SearchOst implements Action {
    readonly type = SearchOstActionTypes.SearchOst;
    constructor(public payload: any) {}
}

export class SearchOstSuccess implements Action {
    readonly type = SearchOstActionTypes.SearchOstSuccess;
    constructor(public payload: any) {}
}

export class SearchOstFail implements Action {
    readonly type = SearchOstActionTypes.SearchOstFail;
    constructor(public payload?: any) {}
}

export class SetFilter implements Action {
    readonly type = SearchOstActionTypes.SetFilter;
    constructor(public payload?: any) {}
}

export class SetQuery implements Action {
    readonly type = SearchOstActionTypes.SetQuery;
    constructor(public payload?: any) {}
}

export class SetSize implements Action {
    readonly type = SearchOstActionTypes.SetSize;
    constructor(public payload?: any) {}
}

export class SetBoundingBox implements Action {
    readonly type = SearchOstActionTypes.SetBoundingBox;
    constructor(public payload?: any) {}
}

export class SetType implements Action {
    readonly type = SearchOstActionTypes.SetType;
    constructor(public payload?: any) {}
}

export class ClearFilters implements Action {
    readonly type = SearchOstActionTypes.ClearFilters;
    constructor(public payload?: any) {}
}

export class SetPage implements Action {
    readonly type = SearchOstActionTypes.SetPage;
    constructor(public payload?: any) {}
}

export class SetPageSuccess implements Action {
    readonly type = SearchOstActionTypes.SetPageSuccess;
    constructor(public payload?: any) {}
}

export class SetCurrentIntense implements Action {
    readonly type = SearchOstActionTypes.SetCurrentIntense;
    constructor(public payload: Intense | null) {}
}

export class SelectOst implements Action {
    readonly type = SearchOstActionTypes.SelectOst;
    constructor(public payload: any) {}
}

export class AddOst implements Action {
    readonly type = SearchOstActionTypes.AddOst;
    constructor(public payload: any) {}
}

export class RemoveOst implements Action {
    readonly type = SearchOstActionTypes.RemoveOst;
    constructor(public payload: any) {}
}

export class RemovePercorsoTratto implements Action {
    readonly type = SearchOstActionTypes.RemovePercorsoTratto;
    constructor(public payload: any) {}
}

export class AddStartPoint implements Action {
    readonly type = SearchOstActionTypes.AddStartPoint;
    constructor(public payload: any) {}
}

export class AddPercorsoPoint implements Action {
    readonly type = SearchOstActionTypes.AddPercorsoPoint;
    constructor(public payload: any) {}
}

export class RemovePercorsoPoint implements Action {
    readonly type = SearchOstActionTypes.RemovePercorsoPoint;
    constructor(public payload: any) {}
}


export class UpdatePercorsoPoint implements Action {
    readonly type = SearchOstActionTypes.UpdatePercorsoPoint;
    constructor(public payload: any) {}
}

export class UpdatePercorsoSuccess implements Action {
    readonly type = SearchOstActionTypes.UpdatePercorsoSuccess;
    constructor(public payload: any) {}
}

export class CreateTappa implements Action {
    readonly type = SearchOstActionTypes.CreateTappa;
    constructor(public payload: any) {}
}

export class RemoveTappa implements Action {
    readonly type = SearchOstActionTypes.RemoveTappa;
    constructor(public payload: any) {}
}

export class SelectTappa implements Action {
    readonly type = SearchOstActionTypes.SelectTappa;
    constructor(public payload: any) {}
}

export class CreateTappaSuccess implements Action {
    readonly type = SearchOstActionTypes.CreateTappaSuccess;
    constructor(public payload: any) {}
}

export class RemoveTappaSuccess implements Action {
    readonly type = SearchOstActionTypes.RemoveTappaSuccess;
    constructor(public payload: any) {}
}

export class RemoveTappaFail implements Action {
    readonly type = SearchOstActionTypes.RemoveTappaFail;
    constructor(public payload: any) {}
}

export class PatchCurrentIntense implements Action {
    readonly type = SearchOstActionTypes.PatchCurrentIntense;
    constructor(public payload: any) {}
}

export class ModifiedCurrentIntense implements Action {
    readonly type = SearchOstActionTypes.ModifiedCurrentIntense;
    constructor(public payload: any) {}
}

export class SaveIntense implements Action {
    readonly type = SearchOstActionTypes.SaveIntense;
    constructor(public payload: any) {}
}

export class SaveIntenseSuccess implements Action {
    readonly type = SearchOstActionTypes.SaveIntenseSuccess;
    constructor(public payload: any) {}
}

export class SaveIntenseFail implements Action {
    readonly type = SearchOstActionTypes.SaveIntenseFail;
    constructor(public payload: any) {}
}

export class UpdateTappa implements Action {
    readonly type = SearchOstActionTypes.UpdateTappa;
    constructor(public payload: any) {}
}

export class UpdateOst implements Action {
    readonly type = SearchOstActionTypes.UpdateOst;
    constructor(public payload: any) {}
}

export class GetIntenseTranslations implements Action {
    readonly type = SearchOstActionTypes.GetIntenseTranslations;
    constructor(public payload: any) {}
}

export class UpdateIntenseTranslations implements Action {
    readonly type = SearchOstActionTypes.UpdateIntenseTranslations;
    constructor(public payload: any) {}
}

export class UpdateCurrentIntense implements Action {
    readonly type = SearchOstActionTypes.UpdateCurrentIntense;
    constructor(public payload: any) {}
}


export type SearchOstActions =
SearchOst |
SearchOstSuccess |
SearchOstFail |
SetFilter |
SetQuery |
SetBoundingBox |
SetSize |
SetPage |
SetType |
SetPageSuccess |
ClearFilters |
SetCurrentIntense |
PatchCurrentIntense |
AddOst |
RemoveOst |
SelectOst |
RemovePercorsoTratto |
AddStartPoint |
AddPercorsoPoint |
RemovePercorsoPoint |
UpdatePercorsoPoint |
UpdatePercorsoSuccess |
CreateTappa |
CreateTappaSuccess |
RemoveTappa |
SelectTappa |
RemoveTappaSuccess |
RemoveTappaFail |
SaveIntense |
SaveIntenseSuccess |
SaveIntenseFail |
HighlightElement |
UpdateTappa|
UpdateOst |
GetIntenseTranslations |
UpdateIntenseTranslations |
ModifiedCurrentIntense |
UpdateCurrentIntense
;
