import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { State } from './common.interface';

@Injectable({
    providedIn: 'root'
})
export class CommonSelectors {

    private commonState$: Observable<State>;

    constructor(private store: Store<any>) {
        this.commonState$ = this.store.pipe(select((state: any) => state.common));
    }

    isLoading$() {
        return this.commonState$.pipe(
            map(state => state.isLoading)
        );
    }

    locale$() {
        return this.commonState$.pipe(
            map(state => state.locale)
        );
    }
}
