import { environment } from '../environments/environment';
import { HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Driver, NgForageConfig } from 'ngforage';
import 'hammerjs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
var ɵ0 = { hasBackdrop: false }, ɵ1 = function (httpLink) {
    return {
        cache: new InMemoryCache(),
        link: httpLink.create({
            uri: environment.graphqlUrl
        }),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'none',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'none',
            },
        }
    };
};
var AppModule = /** @class */ (function () {
    function AppModule(ngfConfig) {
        registerLocaleData(localeIt, 'it');
        ngfConfig.configure({
            name: 'MyApp',
            driver: [
                Driver.INDEXED_DB,
                Driver.LOCAL_STORAGE
            ]
        });
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
