<div mat-dialog-content class="ras-modal ras-modal-translate">
    <div class="ras-modal__content">
        <div class="ras-modal__body">
            <div class="title-lang rowlang">
                <div class="origin">
                    <ng-container *ngFor="let la of languages">
                        <h4 class="ras-main-title" *ngIf="currentLang==la">{{'translate.'+la | translate}}</h4>
                    </ng-container>
                </div>
                <div class="translate">
                    <ng-container *ngFor="let la of languages">
                        <h4 class="ras-main-title" *ngIf="lang==la">{{'translate.'+la | translate}}</h4>
                    </ng-container>
                </div>
            </div>
            <div class="title-scheda rowlang">
                <div class="origin">
                    <div class="ras-form-item"><label class="ras-form-item__label" for="title">{{'translate.title' | translate}}</label>
                        <h4 class="ras-main-title">{{intense?.name}}</h4>
                    </div>
                </div>
                <div class="translate">
                    <div class="ras-form-item"><label class="ras-form-item__label" for="title">{{'translate.title' | translate}}</label>
                        <input id="title" placeholder="inserisci titolo" type="text" [(ngModel)]="titoloTrad" (change)="edited()" class="ng-pristine ng-invalid ng-touched">
                    </div>
                </div>
            </div>

            <div class="descrizione-scheda rowlang">
                <div class="origin">
                    <div class="ras-form-item"><label class="ras-form-item__label" for="title">{{'translate.description' | translate}}</label>
                        <p [innerHtml]="intense?.descrizione"></p>
                    </div>
                </div>
                <div class="translate">
                    <div class="ras-form-item"><label class="ras-form-item__label" for="descrizione">{{'translate.description' | translate}}</label>
                        <quill-editor [formControl]="descrizione" [modules]="quillModules" (text-change)="edited()"></quill-editor>
                    </div>
                </div>
            </div>
        </div>

        <div class="ost-scheda rowlang" *ngFor="let tappa of intense.tappe; let idx = index">
            <div class="origin">
                <div class="ras-list-ost">
                    <div class="ras-list-ost-item">
                        <h4>{{'translate.stage' | translate}} {{idx+1}}</h4>
                        <p>{{tappa.name}}</p>
                    </div>
                </div>
            </div>
            <div class="translate">
                <div class="ras-list-ost">
                    <div class="ras-list-ost-item">
                        <div class="ras-form-item">
                            <label class="ras-form-item__label" for="tappa"></label>
                            <input type="text" name="tappa" [(ngModel)]="tappe[idx].name" (change)="edited()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ost-scheda rowlang" *ngFor="let ost of osts; let idx = index">
            <div class="origin">
                <div class="ras-list-ost">
                    <div class="ras-list-ost-item">
                        <h4>{{ost.name}}</h4>
                        <p *ngIf="ost == openedOst" [innerHTML]="ost.descrizione"></p>
                    </div>
                </div>
            </div>
            <div class="translate">
                <div class="ras-list-ost">
                    <div class="ras-list-ost-item">
                        <h4>
                            <!--{{titleOst[idx]}} --><i aria-hidden="true" class="far fa-pen" (click)="openOst(ost)"></i></h4>
                        <ng-container *ngIf="ost == openedOst">
                            <!-- <div class="ras-form-item">
                                <label class="ras-form-item__label" for="title">{{'translate.ostTitle' | translate}}</label>
                                <input type="text" [(ngModel)]="titleOst[idx]">
                            </div> -->
                            <div class="ras-form-item">
                                <label class="ras-form-item__label" for="descrizione">{{'translate.description' | translate}}</label>
                                <!-- <textarea [(ngModel)]="descrizioneOst[idx].descrizione"></textarea> -->
                                <quill-editor [(ngModel)]="descrizioneOst[idx].descrizione" [modules]="quillModules" (blur)="onBlurMethod($event.target.value,idx)" (editor-change)="edited()"></quill-editor>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>

<div mat-dialog-actions class="ras-modal__actions">
    <div class="status">
        <label>{{'status.label' | translate }}:</label>
        <mat-form-field>
            <select matNativeControl required (change)="changeStatus($event)" [(ngModel)]="stato">
            <option [value]="apiservice.STATO_DRAFT">{{'status.DRAFT' | translate }}</option>
            <option [value]="apiservice.STATO_VALIDAZIONE">{{'status.VALIDAZIONE' | translate }}</option>
            <option [value]="apiservice.STATO_PUBBLICATA" *ngIf="stato==apiservice.STATO_PUBBLICATA || isAdmin">{{'status.PUBBLICATA' | translate }}</option>
        </select>
        </mat-form-field>
    </div>
    <a class="ras-btn ras-btn-light ras-btn-icon ras-btn-undo ng-star-inserted" (click)="close()"><i
            class="fal fa-times-circle"></i> {{'common.cancel' | translate}}</a>
    <button class="ras-btn" (click)="save()">{{'common.save' | translate}}</button>
</div>