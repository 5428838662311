<div class="ras-search-autocomplete" *ngIf="!showDetails">
    <mat-form-field>
        <div class="ras-search-bar">
            <input type="text" placeholder="{{'header.findph'|translate}}" matInput [formControl]="searchControl"
                [matAutocomplete]="auto">
            <i class="far fa-search"></i>
        </div>
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option (onSelectionChange)="onSelect($event)" *ngFor="let option of filterAutoComplete(results)" [value]="option">
            <i aria-hidden="true" class="far fa-route"></i>{{option.name}} <a class="ras-search-autocomplete__add"
                [routerLink]="[]"><i aria-hidden="true" *ngIf="isPoi(option)" class="far fa-plus-circle"></i>
                <i aria-hidden="true" *ngIf="!isPoi(option)" class="far fa-search"></i></a>
        </mat-option>
    </mat-autocomplete>
</div>
<div class="ras-search-logo" *ngIf="showDetails">
    <a [routerLink]="['/search']" class="ras-logo" title="Vai alla Homepage">
        <img alt="Logo Intense" src="assets/images/logo.png" srcset="assets/images/logo@2x.png 2x">
    </a>
</div>
<div class="ras-record-title-wrapper" *ngIf="!showDetails">

    <h1 class="ras-record-title">{{intense?.name}}</h1>

    <div class="ras-record-features" *ngIf="!showFilters">
        <div class="ras-record-features__item"><i aria-hidden="true"
                class="icon wm-icon-distance"></i><span>{{(intense.distanza/1000) | number:'1.0-1':'it' }}km</span>
        </div>
        <div class="ras-record-features__item"><i aria-hidden="true"
                class="icon wm-icon-ascent"></i><span>{{(intense.dislivelloPositivo) | number:'1.0-0':'it' }}m</span>
        </div>
        <div class="ras-record-features__item"><i aria-hidden="true"
                class="icon wm-icon-descent"></i><span>{{(intense.dislivelloNegativo) | number:'1.0-0':'it' }}m</span>
        </div>
        <div class="ras-record-features__item"><i aria-hidden="true"
                class="icon wm-icon-descent"></i><span>{{(intense.altezzaMassima) | number:'1.0-0':'it' }}m</span>
        </div>
        <div class="ras-record-features__item"><i aria-hidden="true"
                class="far fa-arrow-to-top"></i><span>{{(intense.altezzaMinima) | number:'1.0-0':'it' }}m</span>
        </div>
        <div class="ras-record-features__item">
            <span class="ras-icon-group"><i aria-hidden="true" class="far fa-arrow-to-top"></i><i aria-hidden="true"
                    class="far fa-play"></i></span>
        </div>
        <div class="ras-record-features__item">
            <span class="ras-icon-group">
                <i aria-hidden="true" class="far fa-arrow-to-bottom"></i><i
                    class="far fa-stop"></i></span>
            <span>{{(intense.altezzaEnd) | number:'1.0-0':'it' }}m</span>
        </div>
    </div>
</div>


<ul class="ras-header__user-area">
    <li>
        <a [routerLink]="[]" class="ras-record-map__btn" [ngClass]="{'is-active': !showDetails}"
            (click)="toggleDetails(false)">
            <i class="far fa-map-marked-alt"></i><span>{{'header.map'|translate}}</span></a>
    </li>

    <li>
        <a [routerLink]="[]" class="ras-record-map__btn" [ngClass]="{'is-active': showDetails}"
            (click)="toggleDetails(true)">
            <i class="far fa-edit"></i><span>{{'header.detail'|translate}}</span></a>
    </li>
    <li>
        <a mat-icon-button [matMenuTriggerFor]="appMenu" class="ras-header__user-profile">
            <img [src]="userImg" *ngIf="userImg">
            <i class="fas fa-user-tie ras-header__user-img" *ngIf="!userImg"></i>
        </a>
        <mat-menu #appMenu="matMenu">
            <!-- <button mat-menu-item>Settings</button> -->
            <button mat-menu-item (click)="link('help')">{{'header.help'|translate}}</button>
            <button mat-menu-item (click)="link('support')">{{'header.support'|translate}}</button>
            <button mat-menu-item (click)="link('forum')">{{'header.forum'|translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="howto">Howto</button>
            <button mat-menu-item (click)="logout()">{{'header.logout'|translate}}</button>
        </mat-menu>

        <mat-menu #howto="matMenu">
            <button mat-menu-item (click)="link('howto_search')">{{'header.howto_search'|translate}}</button>
            <button mat-menu-item (click)="link('howto_edit')">{{'header.howto_edit'|translate}}</button>
            <button mat-menu-item (click)="link('howto_publish')">{{'header.howto_publish'|translate}}</button>
            <button mat-menu-item (click)="link('howto_translate')">{{'header.howto_translate'|translate}}</button>
        </mat-menu>
    </li>
</ul>
