/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./snackbar.component";
import * as i3 from "@angular/material/snack-bar";
var styles_SnackbarComponent = [];
var RenderType_SnackbarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SnackbarComponent, data: {} });
export { RenderType_SnackbarComponent as RenderType_SnackbarComponent };
function View_SnackbarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "i", [], null, [[null, "ngClass"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngClass" === en)) {
        var pd_0 = (_co.classForFruizione(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_SnackbarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "ras-snackbar-line"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SnackbarComponent_2)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.fruizione; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
export function View_SnackbarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SnackbarComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.osts; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SnackbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-snackbar-component", [], null, null, null, View_SnackbarComponent_0, RenderType_SnackbarComponent)), i0.ɵdid(1, 49152, null, 0, i2.SnackbarComponent, [i3.MAT_SNACK_BAR_DATA, i3.MatSnackBar], null, null)], null, null); }
var SnackbarComponentNgFactory = i0.ɵccf("app-snackbar-component", i2.SnackbarComponent, View_SnackbarComponent_Host_0, {}, {}, []);
export { SnackbarComponentNgFactory as SnackbarComponentNgFactory };
