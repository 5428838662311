/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./add-tratto-popup.component";
import * as i2 from "@ngrx/store";
import * as i3 from "@ngx-translate/core";
var styles_AddTrattoPopupComponent = [];
var RenderType_AddTrattoPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddTrattoPopupComponent, data: {} });
export { RenderType_AddTrattoPopupComponent as RenderType_AddTrattoPopupComponent };
export function View_AddTrattoPopupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "ras-tooltip"], ["style", "max-width: 380px; margin: 0 auto;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "ras-tooltip__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h2", [["class", "ras-tooltip__track-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "ras-tooltip__footer"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.ost == null) ? null : _co.ost.name); _ck(_v, 3, 0, currVal_0); }); }
export function View_AddTrattoPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "add-tratto-popup", [], null, null, null, View_AddTrattoPopupComponent_0, RenderType_AddTrattoPopupComponent)), i0.ɵdid(1, 114688, null, 0, i1.AddTrattoPopupComponent, [i2.Store, i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddTrattoPopupComponentNgFactory = i0.ɵccf("add-tratto-popup", i1.AddTrattoPopupComponent, View_AddTrattoPopupComponent_Host_0, { ost: "ost", feature: "feature", index: "index" }, {}, []);
export { AddTrattoPopupComponentNgFactory as AddTrattoPopupComponentNgFactory };
