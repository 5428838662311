import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { UtilsService } from './utils.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "angular-oauth2-oidc";
import * as i3 from "./utils.service";
var AUTHTOKEN_KEY = 'authToken';
var USER_ID_KEY = 'userId';
var AsrAuthGuard = /** @class */ (function () {
    function AsrAuthGuard(router, oauthService, utils) {
        var _this = this;
        this.router = router;
        this.oauthService = oauthService;
        this.utils = utils;
        this.authToken = null;
        this.isOauth = true;
        this.utils.getLocal(AUTHTOKEN_KEY).then(function (val) {
            if (val) {
                _this.authToken = val;
            }
        });
        this.utils.getLocal(USER_ID_KEY).then(function (val) {
            if (val) {
                _this.userId = val;
            }
        });
    }
    AsrAuthGuard.prototype.canActivate = function (route, state) {
        var valid = this.oauthService.hasValidAccessToken() || !!this.authToken;
        if (!valid) {
            this.router.navigate(['login']);
        }
        return valid;
    };
    AsrAuthGuard.prototype.login = function (user, pwd) {
        var _this = this;
        return this.oauthService.fetchTokenUsingPasswordFlow(user, pwd).then(function (resp) {
            // invalidare sistema token
            _this.setToken(null, resp['user_id']);
            _this.isOauth = true;
            // this.userId = resp['user_id'];
            return resp;
        });
    };
    AsrAuthGuard.prototype.logout = function () {
        if (this.isOauth) {
            this.oauthService.logOut();
        }
        this.setToken(null, null);
        this.isOauth = false;
        this.setUserData(null);
    };
    AsrAuthGuard.prototype.isLogged = function () {
        var auth = this.oauthService.hasValidAccessToken();
        // console.log('-----------: AsrAuthGuard -> isLogged -> auth', auth,this.authToken);
        return auth || !!this.authToken;
    };
    AsrAuthGuard.prototype.getProfileImg = function () {
        return null; // get user image
    };
    AsrAuthGuard.prototype.setToken = function (tok, userId) {
        console.log('-----------: AsrAuthGuard -> setToken -> setToken');
        if (tok) {
            // TODO invalidare login oauth
            this.isOauth = false;
        }
        this.userId = userId;
        this.authToken = tok;
        this.utils.setLocal(AUTHTOKEN_KEY, tok);
        this.utils.setLocal(USER_ID_KEY, userId);
    };
    AsrAuthGuard.prototype.resetToken = function () {
        this.setToken(null, null);
    };
    AsrAuthGuard.prototype.getToken = function () {
        return this.isOauth ? null : this.authToken;
    };
    AsrAuthGuard.prototype.getUserId = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.userId) {
                    return [2 /*return*/, this.userId];
                }
                else {
                    return [2 /*return*/, this.utils.getLocal(USER_ID_KEY)];
                }
                return [2 /*return*/];
            });
        });
    };
    AsrAuthGuard.prototype.setUserData = function (userData) {
        // console.log('++++++++++++> setUserData -> userData', userData);
        this.userData = userData;
    };
    AsrAuthGuard.prototype.isUserDataSet = function () {
        return !!this.userData;
    };
    AsrAuthGuard.prototype.isUserWithValidRoles = function () {
        if (!this.userData || !this.userData.roles) {
            return false;
        }
        console.log('-----------: AsrAuthGuard -> isUserWithValidRoles -> this.userData', this.userData);
        if (this.userData.roles.length === 1 && this.userData.roles[0].target_id === 'graphql_readonly') {
            return false;
        }
        return true;
    };
    AsrAuthGuard.prototype.isUserValidator = function () {
        // console.log('-----------: AsrAuthGuard -> isUserValidator -> this.userData', this.userData);
        if (!this.userData || !this.userData.roles) {
            return null;
        }
        var ret = false;
        this.userData.roles.forEach(function (element) {
            ret = ret || element.target_id === 'validatore' || element.target_id === 'administrator';
        });
        // console.log('------------> isUserValidator -> ret', ret);
        return ret;
    };
    AsrAuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AsrAuthGuard_Factory() { return new AsrAuthGuard(i0.inject(i1.Router), i0.inject(i2.OAuthService), i0.inject(i3.UtilsService)); }, token: AsrAuthGuard, providedIn: "root" });
    return AsrAuthGuard;
}());
export { AsrAuthGuard };
