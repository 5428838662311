/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./add-ost-modal.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../services/utils.service";
import * as i6 from "@ngrx/store";
var styles_AddOstModalComponent = [];
var RenderType_AddOstModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddOstModalComponent, data: {} });
export { RenderType_AddOstModalComponent as RenderType_AddOstModalComponent };
function View_AddOstModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "legend", [["class", "ras-component-column-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("modal.nopoi")); _ck(_v, 1, 0, currVal_0); }); }
function View_AddOstModalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "legend", [["class", "ras-component-column-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("modal.onepoi")); _ck(_v, 1, 0, currVal_0); }); }
function View_AddOstModalComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "legend", [["class", "ras-component-column-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("modal.manypoi", _co.poinum)); _ck(_v, 1, 0, currVal_0); }); }
function View_AddOstModalComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "ras-form-item ras-checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["name", "attrattori"], ["type", "checkbox"]], [[8, "id", 0], [8, "value", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.setPoints(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_3); }); }
function View_AddOstModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddOstModalComponent_3)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddOstModalComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddOstModalComponent_5)), i0.ɵdid(6, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pointList.length == 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.pointList.length > 1); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.pointList; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AddOstModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "ras-modal ras-modal--has-columns"], ["mat-dialog-content", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "ras-modal__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "ras-modal__body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "fieldset", [["class", "ras-component-column"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddOstModalComponent_1)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddOstModalComponent_2)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 8, "div", [["class", "ras-modal__actions ras-modal__actions-justified"], ["mat-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "button", [["class", "ras-btn ras-btn-light ras-btn-icon ras-btn-undo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-times-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(13, 0, null, null, 3, "button", [["class", "ras-btn ras-btn-light ras-btn-icon"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-plus-circle"]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pointList.length == 0); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.pointList.length > 0); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("common.cancel")); _ck(_v, 11, 0, currVal_2); var currVal_3 = !_co.somethingSelected(); _ck(_v, 13, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 15, 0, i0.ɵnov(_v, 16).transform("modal.addost")); _ck(_v, 15, 0, currVal_4); }); }
export function View_AddOstModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-ost-modal", [], null, null, null, View_AddOstModalComponent_0, RenderType_AddOstModalComponent)), i0.ɵdid(1, 114688, null, 0, i3.AddOstModalComponent, [i4.MAT_DIALOG_DATA, i4.MatDialogRef, i5.UtilsService, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddOstModalComponentNgFactory = i0.ɵccf("app-add-ost-modal", i3.AddOstModalComponent, View_AddOstModalComponent_Host_0, {}, {}, []);
export { AddOstModalComponentNgFactory as AddOstModalComponentNgFactory };
