import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { validate } from '@babel/types';
import { AddOst } from 'src/app/logic/search-ost';
import { DisplayModal } from 'src/app/logic/common';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-add-poi-popup',
  templateUrl: './add-poi-popup.component.html',
})
export class AddPoiPopupComponent implements OnInit {

  @Input() ost;
  @Input() feature;
  @Input() index: number;

  constructor(private store: Store<any>, private translate: TranslateService) { }

  ngOnInit() {
  }

  onClick() {
    if (this.index > -1) {
      this.store.dispatch(new AddOst({ ost: this.ost, position: this.index }));
    } else {
      this.translate.get('modal.poierror').subscribe(msg => {
        this.store.dispatch(new DisplayModal({
          type: 'confirm', data: {
            message: msg
          }
        }));

      })
    }
    this.feature.closePopup();
  }



}
