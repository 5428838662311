<div mat-dialog-content class="ras-modal">
    <div class="ras-modal__content">
        <header class="ras-modal__header">
            <h1 class="ras-modal__title">
                <i aria-hidden="true" class="far fa-route"></i> {{'modal.stage' | translate}} {{tappaIdx+1}} {{'modal.of' | translate}} <strong>
                    {{currentIntese.name}}</strong>
            </h1>
        </header>

        <div class="ras-modal__body">

            <div class="ras-modal__add-content">
                <form>
                    <div class="ras-form-item ras-form-item__textarea">
                        <label for="name" class="ras-form-item__label">{{'modal.stagename' | translate}}:</label>
                        <input type="text" id="name" name="tappaname" [(ngModel)]="tappa.name">
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>

<div mat-dialog-actions class="ras-modal__actions">
    <button class="ras-btn" (click)="save()">{{'common.save' | translate}}</button>
    <button class="ras-btn ras-btn-light" (click)="cancel()">{{'common.cancel' | translate}}</button>
</div>