import { Action } from '@ngrx/store';
export enum EditOSTActionTypes {
    setName = '[EditOST] SET_NAME',
    setNameSuccess = '[EditOST] SET_NAME_SUCCESS',
    setNameFail = '[EditOST] SET_NAME_FAIL'
}

export class SetName implements Action {
    readonly type = EditOSTActionTypes.setName;
}
export class SetNameSuccess implements Action {
    readonly type = EditOSTActionTypes.setNameSuccess;
}
export class SetNameFail implements Action {
    readonly type = EditOSTActionTypes.setNameFail;
}

export type EditOSTActions = SetName | SetNameFail | SetNameSuccess;
