/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i4 from "ngx-spinner";
import * as i5 from "./app.component";
import * as i6 from "angular-oauth2-oidc";
import * as i7 from "@angular/common/http";
import * as i8 from "@ngrx/store";
import * as i9 from "@ngx-translate/core";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ngx-spinner", [["type", "ball-scale-ripple-multiple"]], null, null, null, i3.View_NgxSpinnerComponent_0, i3.RenderType_NgxSpinnerComponent)), i1.ɵdid(3, 770048, null, 0, i4.NgxSpinnerComponent, [i4.NgxSpinnerService, i1.ChangeDetectorRef], { type: [0, "type"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "ball-scale-ripple-multiple"; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i5.AppComponent, [i6.OAuthService, i7.HttpClient, i8.Store, i1.Injector, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
