<div mat-dialog-content class="ras-modal ras-modal--has-columns">
    <div class="ras-modal__content">
        <div class="ras-modal__body">
            <fieldset class="ras-component-column">
                <legend class="ras-component-column-title" *ngIf="pointList.length == 0">
                    {{'modal.nopoi' | translate}}
                </legend>
                <ng-container *ngIf="pointList.length > 0">
                    <legend class="ras-component-column-title" *ngIf="pointList.length ==  1">
                        {{'modal.onepoi' | translate}}
                    </legend>
                    <legend class="ras-component-column-title" *ngIf="pointList.length > 1">
                        {{'modal.manypoi' | translate:poinum}}
                    </legend>
                    <div class="ras-form-item ras-checkbox" *ngFor="let point of pointList">
                        <input name="attrattori" [id]="point.id" [value]="point" type="checkbox"
                            (change)="setPoints(point,$event)">
                        <label [for]="point.id"> {{point.name}} </label>
                    </div>
                </ng-container>
            </fieldset>
        </div>

    </div>
</div>

<div mat-dialog-actions class="ras-modal__actions ras-modal__actions-justified">
    <button class="ras-btn ras-btn-light ras-btn-icon ras-btn-undo" (click)="cancel()"><i aria-hidden="true"
            class="fal fa-times-circle"></i> {{'common.cancel' | translate}}
    </button>
    <button class="ras-btn ras-btn-light ras-btn-icon" (click)="add()" [disabled]="!somethingSelected()"><i
            aria-hidden="true" class="far fa-plus-circle"></i>{{'modal.addost' | translate}}</button>
</div>